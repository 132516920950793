import { action, makeObservable, observable } from "mobx"

import IEvents from "@/models/IEvents"

export default class EventsStore implements IEvents {
  @observable public startTime?: string //  首页日期弹窗开始时间
  @observable public endTime?: string  // 首页日期弹窗结束时间
  @observable public selected?: string = "60"  // 首页日期弹窗下拉选择时间

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setStartTime(data?: string) {
    this.startTime = data
  }

  @action.bound
  public setEndTime(data?: string): void {
    this.endTime = data
  }

  @action.bound
  public setSelected(value?: string): void {
    this.selected = value
  }

}
