import { Icon, Link, Modal } from "@shopify/polaris"
import { CheckCircleIcon } from "@shopify/polaris-icons"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { DashboardSetupGuideEnum } from "@/enums/dashboard"
import StepWrapper from "@/pages/Dashboard/components/QuickSetupGuide/StepWrapper"

import styles from "./index.module.scss"

type IProps = {
  active: boolean
  isDone?: boolean
  step: DashboardSetupGuideEnum
  toggleCompleted: (status: DashboardSetupGuideEnum, completed: boolean) => Promise<any>
  titleClick?: () => void
}

const HowParcelPanelWorks = (props: IProps) => {
  const { t } = useTranslation(["dashboard", "common"])
  const [exampleModal, setExampleModal] = useState<{
    title: string,
    open: boolean,
    children?: React.ReactNode
  }>({
    children: undefined,
    open: false,
    title: "Default title",
  })
  const { isDone, active, step, toggleCompleted } = props


  useEffect(() => {
    if (active) {
      if (typeof toggleCompleted === "function" && !isDone) {
        toggleCompleted(step, true)
      }
    }
  }, [active])

  const viewExampleModal = (<Modal
    sectioned
    size={"large"}
    onClose={() => setExampleModal({ open: false, title: exampleModal.title })}
    secondaryActions={[{
      content: t("common:Close"), onAction: () => setExampleModal({ open: false, title: exampleModal.title }),
    }]}
    {...exampleModal}
  />)

  const viewExampleAddLink = () => {
    const children = (
      <div className={"flex flex-col gap-3"}>
        <p>{t("dashboard:SyncTrackingNumbers.p1")}</p>
        <img alt="Sync tracking numbers" style={{ width: "100%" }}
          src="https://cdn.parcelpanel.com/front-end/2022/images/user-guide/sync_tracking_numbers.png"
        />
        <p>{t("dashboard:SyncTrackingNumbers.p3")}</p>
      </div>
    )

    setExampleModal({
      children,
      open: true,
      title: t("dashboard:SyncTrackingNumbers.title"),
    })
  }

  return (
    <StepWrapper
      {...props}
      title={t("dashboard:HowParcelPanelWorks")}
      type={"svg"}
      mediaNode={<img src="https://cdn.parcelpanel.com/images/guide3.svg" />}
    >
      {viewExampleModal}
      <div className={styles.flexSpaceBetween}>
        <ul className={styles.descUl}>
          <li>
            <span className="inline-block">
              <Icon source={CheckCircleIcon} tone="success" />
            </span>
            <p>{t("dashboard:DashboardEdgeTable.HowWorks.p1")}</p>
          </li>
          <li>
            <span className="inline-block">
              <Icon source={CheckCircleIcon} tone="success" />
            </span>
            <p>{t("dashboard:DashboardEdgeTable.HowWorks.p2")}</p>
          </li>
          <li>
            <span className="inline-block">
              <Icon source={CheckCircleIcon} tone="success" />
            </span>
            <p>{t("dashboard:DashboardEdgeTable.HowWorks.p4")}</p>
          </li>
          <li>
            <span className="inline-block">
              <Icon source={CheckCircleIcon} tone="success" />
            </span>
            <p>
              <Trans
                ns="dashboard"
                i18nKey="DashboardEdgeTable.HowWorks.p3"
              >
                <Link
                  removeUnderline
                  onClick={viewExampleAddLink}
                />
              </Trans>
            </p>
          </li>
        </ul>
      </div>
    </StepWrapper>
  )
}

export default HowParcelPanelWorks
