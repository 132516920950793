import "@/assets/css/OptionListDrag.scss"

import {
  AppliedFilterInterface,
  Badge,
  Banner,
  Button,
  Divider,
  Icon,
  InlineStack,
  Key,
  LegacyCard,
  Modal,
  Popover,
  SkeletonBodyText,
  Spinner,
  TabProps,
  Tabs,
  Text,
  TextContainer,
  TextField,
} from "@shopify/polaris"
import { CheckCircleIcon, GiftCardIcon, InfoIcon, LayoutColumns3Icon } from "@shopify/polaris-icons"
import classNames from "classnames"
import dayjs from "dayjs"
import { debounce, isEmpty, isObject, isString, isUndefined, remove } from "lodash-es"
import { observer } from "mobx-react"
import qs from "qs"
import React, { Suspense } from "react"
import { Trans, WithTranslation, withTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import dashboard from "@/api/dashboard"
import logs from "@/api/logs"
import OrderUrl from "@/api/orders"
import { IFilterOption, IOrderDefaultData } from "@/api/orders/type"
import {
  Container,
  GetReviewModalSection,
  IRouter,
  PPReturnRecommendation,
  SortButton,
  SortButtonChoice,
  SortButtonDirection,
  withRouter,
} from "@/component"
import ChoiceRightHelpText from "@/component/ChoiceRightHelpText/ChoiceRightHelpText"
import CustomPage from "@/component/CustomPage/CustomPage"
import DynamicLoadError from "@/component/DynamicLoadError/DynamicLoadError"
import { OptionListDragProps } from "@/component/OptionListDrag"
import ShipmentFilterKey from "@/enums/shipment-filter-key"
import DashboardInit from "@/pages/Dashboard/init"
import { InputTwoThumbsRange } from "@/pages/Orders/components/InputTwoThumbsRange/InputTwoThumbsRange"
import stores from "@/stores"
import { LanguageUtils } from "@/utils/languages"
import { IListenerNameEnum } from "@/utils/languages.d"
import { fuzzySearch } from "@/utils/StringUtils"
import toast from "@/utils/toast"

import {
  ChoiceWithSearch,
  DateChoiceList,
  DateOption,
  ExportShipmentsControl,
  NotificationHistoryModalMobx,
  OrdersFilters,
  OrdersTable,
  ReSyncControl,
} from "./components"
import SkeletonOptionListDrag from "./components/SkeletonOptionListDrag/SkeletonOptionListDrag"
import { tabNavConfig } from "./config"
import styles from "./Orders.module.scss"
import { CustomChoice, IOrdersState, ITabNav, OrderDirection, OrderReSyncStatus, QueryParam, QueryParamsType } from "./types"

interface IProps extends WithTranslation<"orders" | "common" | "menu"> {
  router: IRouter
}

export const PER_PAGE = 20

const OptionListDrag = React.lazy(() => import("./components/OptionListDrag/OptionListDrag"))

const sortDirectionIndexToStr = {
  [OrderDirection.ASC]: SortButtonDirection.Asc,
  [OrderDirection.DESC]: SortButtonDirection.Desc,
}

const sortDirectionValueMap = {
  [SortButtonDirection.Asc]: OrderDirection.ASC,
  [SortButtonDirection.Desc]: OrderDirection.DESC,
}

// 默认排序为 orderedAt Desc
const DEFAULT_SORT_SELECTED = "orderedAt"
const DEFAULT_SORT_DIRECTION_SELECTED = SortButtonDirection.Desc

const INIT_STATUS_CHOICES = [
  {
    "tran_key": "Pending",
    "value": "Pending",
    "count": 0,
  },
  {
    "tran_key": "InfoReceived",
    "value": "Info received",
    "count": 0,
  },
  {
    "tran_key": "InTransit",
    "value": "In transit",
    "count": 0,
  },
  {
    "tran_key": "OutForDelivery",
    "value": "Out for delivery",
    "count": 0,
  },
  {
    "tran_key": "Delivered",
    "value": "Delivered",
    "count": 0,
  },
  {
    "tran_key": "Exception",
    "value": "Exception",
    "count": 0,
  },
  {
    "tran_key": "FailedAttempt",
    "value": "Failed attempt",
    "count": 0,
  },
  {
    "tran_key": "Expired",
    "value": "Expired",
    "count": 0,
  },
]

@withRouter
@observer
class Orders extends React.Component<IProps, IOrdersState> {
  // private readonly pageRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  languageListenerName = ""
  defaultSortDirectionMap: { [field: string]: SortButtonDirection } = {
    orderedAt: SortButtonDirection.Desc,
    fulfilledAt: SortButtonDirection.Desc,
    transit: SortButtonDirection.Asc,
    residence: SortButtonDirection.Asc,
    lastCheckpointTime: SortButtonDirection.Desc,
  }
  private interval?: NodeJS.Timer

  constructor(props: any) {
    super(props)
    const nowDayjs = dayjs()
      .tz()
    const { t } = this.props

    const statusChoices = this.translateStatusChoices(createChoiceList(INIT_STATUS_CHOICES))

    // 首次进入 默认选项
    this.state = {
      reSync: {
        loaded: false,
        count: 0, // 订单数量
        show: false, // 是否显示弹窗
        status: OrderReSyncStatus.Unknown, // 订单同步状态
      },

      isFirstLoad: true,
      selectedTabIndex: -1,
      isSelectedCustomNav: false,
      // 目前只存放 url参数中解析出来的tab，切换tab不会改变此值
      selectedTabId: "",

      // 状态
      tabsShipmentCount: {
        "All": 0,
        "Pending": 0,
        "In transit": 0,
        "Out for delivery": 0,
        "Delivered": 0,
        "Expired": 0,
        "Failed attempt": 0,
        "Exception": 0,
        "Info received": 0,
      },

      navTitle: "",
      navSaved: false,

      syncTimeList: [],

      orderMessage: {
        tableData: [],
        numberCount: 0,
      },

      tablePageNum: 1,
      prevPage: void 0,
      nextPage: void 0,

      pageConfigLoading: true,
      ordersLoading: true,

      /* 筛选项 */
      queryValue: "",

      dateSelected: void 0,
      dateStartingSelected: nowDayjs,
      dateEndingSelected: nowDayjs,

      fulfilledDateOption: {
        selected: void 0,
        starting: nowDayjs,
        ending: nowDayjs,
      },

      statusChoices: statusChoices,
      statusSelected: [],
      courierChoices: [],
      courierSelected: [],
      destinationChoices: [],
      destinationSelected: [],
      paypalSyncStatusChoices: [],
      paypalSyncStatusSelected: [],
      orderTagChoices: [],
      orderTagSelected: [],
      transitTimeValue: void 0,

      // 该State用于触发组件更新用的
      isDateFilterCustomItemSelected: false,

      orderTagsKeyword: "",
      orderTagChoicesShow: void 0,

      headingColumnPopoverActive: false,

      sortSelected: "",
      sortDirectionSelected: SortButtonDirection.Desc,
      exception_shipment_banner_closed: true,
    }

    this._updateQueryParams = debounce(this._updateQueryParams, 500)
    this.getAllFilterCount = debounce(this.getAllFilterCount, 500)
    this.saveColumnsConfig = debounce(this.saveColumnsConfig, 500)
    this.orderTagFilterSearchChoices = debounce(this.orderTagFilterSearchChoices, 200)
  }

  get dateChoices() {
    const { t } = this.props

    return [
      { label: t("Today"), value: DateOption.Today },
      { label: t("LastNDays", { day: 7 }), value: DateOption.PastWeek },
      { label: t("LastNDays", { day: 30 }), value: DateOption.PastMonth },
      { label: t("LastNDays", { day: 60 }), value: DateOption.PastTwoMonths },
      { label: t("LastNDays", { day: 90 }), value: DateOption.PastQuarter },
      { label: t("Custom"), value: DateOption.Custom },
    ]
  }

  componentDidMount() {
    this.parsePathSearch()
    // 进入页面后立即获取订单同步状态
    this.listenReSync(0)

    LanguageUtils.addListener(IListenerNameEnum.OrdersListener, () => {
      // 翻译一级 tabs
      tabNavConfig.translateTabs()

      // 翻译 status 筛选项
      const statusChoices = this.translateStatusChoices(this.state.statusChoices)

      // 翻译表格标题
      this.translateTableColumnChoices()

      this.setState({
        statusChoices,
      })
    })
  }

  public componentWillUnmount() {
    LanguageUtils.removeListener(IListenerNameEnum.OrdersListener)
    this.listenReSync()
    this.clearListenReSync()
  }

  clearListenReSync() {
    null !== this.interval && clearTimeout(this.interval as NodeJS.Timeout)
  }

  listenReSync(interval?: number) {
    this.clearListenReSync()

    this.interval = setTimeout(() => {
      logs.reSyncLog()
        .then(({ data: { data } }) => {
          // 如果是 0 表示没有任何操作
          if (0 === data.sync_status || data.sync_status === OrderReSyncStatus.SyncComplete) {
            console.log("同步完成了")
            // 同步完成了
            this.clearListenReSync()

            this.setState({
              reSync: {
                ...this.state.reSync,
                count: data.order_count,
                show: data.show_modal ?? false,
                status: data.sync_status, // 取值范围请参照 enum: OrderReSyncStatus
              },
            })
          } else {
            if (data.sync_status === OrderReSyncStatus.Synchronizing) {
              // console.log('同步中')
              this.setState({
                reSync: {
                  ...this.state.reSync,
                  status: OrderReSyncStatus.Synchronizing,
                },
              })
            }

            this.listenReSync()
          }
        })
        .finally(() => {
          this.setState(({ reSync }) => ({
            reSync: {
              ...reSync,
              loaded: true,
            },
          }))
        })

    }, interval !== undefined ? interval : 2000)
  }

  parsePathSearch() {
    // 在这里加 comma 选项好像无效，因为 parse() 解析 逗号的逻辑 比 解码search 的逻辑先执行，导致解析不出逗号(如果URL逗号进行了编码)
    const _queryParams = qs.parse(this.props.router.location.search, { allowDots: true, ignoreQueryPrefix: true })

    const queryParams: QueryParamsType = {}

    let dateType = 0

    const orderedAtMin = _queryParams[QueryParam.orderedAtMin]
    const orderedAtMax = _queryParams[QueryParam.orderedAtMax]
    const fulfilledAtMin = _queryParams[QueryParam.fulfilledAtMin]
    const fulfilledAtMax = _queryParams[QueryParam.fulfilledAtMax]

    if (_queryParams[QueryParam.orderedAt]) {
      const value = (_queryParams[QueryParam.orderedAt] as string)
      // console.log(value)

      if (this.dateChoices.some(({ value: _val }) => _val !== DateOption.Custom && _val === value)) {
        this.setState({ dateSelected: value })
        queryParams[QueryParam.orderedAt] = value
        dateType = 1
      }
    } else if (isString(orderedAtMin) && isString(orderedAtMax)) {
      const dateStartingSelected = dayjs(orderedAtMin)
      const dateEndingSelected = dayjs(orderedAtMax)
      // console.log(dateStartingSelected, dateEndingSelected)

      if (dateStartingSelected.isValid() && dateEndingSelected.isValid()) {
        this.setState({ dateEndingSelected, dateSelected: DateOption.Custom, dateStartingSelected })
        queryParams[QueryParam.orderedAtMin] = orderedAtMin
        queryParams[QueryParam.orderedAtMax] = orderedAtMax
        dateType = 2
      }
    }

    if (0 === dateType) {
      // 进入页面时解析到 Path 的日期参数为空时, 默认选择 Last 60 days
      // 手动 清除 日期筛选参数(就是不进行日期范围筛选)的情况: 由于清除选项后参数会同时在 path 中删除, 所以刷新页面后, 也会取默认筛选项进行查询, 而不是不筛选时间范围
      queryParams[QueryParam.orderedAt] = DateOption.PastTwoMonths
      this.setState({ dateSelected: DateOption.PastTwoMonths })
    }

    if (_queryParams[QueryParam.fulfilledAt]) {
      const value = (_queryParams[QueryParam.fulfilledAt] as string)

      if (this.dateChoices.some(({ value: _val }) => _val !== DateOption.Custom && _val === value)) {
        this.setState(prevState => ({ fulfilledDateOption: { ...prevState.fulfilledDateOption, selected: value } }))
        queryParams[QueryParam.fulfilledAt] = value
      }
    } else if (isString(fulfilledAtMin) && isString(fulfilledAtMax)) {
      const starting = dayjs(fulfilledAtMin)
      const ending = dayjs(fulfilledAtMax)

      if (starting.isValid() && ending.isValid()) {
        this.setState({
          fulfilledDateOption: {
            selected: DateOption.Custom,
            starting,
            ending,
          },
        })
        queryParams[QueryParam.fulfilledAtMin] = fulfilledAtMin
        queryParams[QueryParam.fulfilledAtMax] = fulfilledAtMax
      }
    }

    if (_queryParams[QueryParam.status]) {
      const value = (_queryParams[QueryParam.status] as string).split(",")

      // todo 参数过滤？
      this.setState({ statusSelected: selectedDateFormat(value) })
      queryParams[QueryParam.status] = value
    }
    if (_queryParams[QueryParam.paypalSyncStatus]) {
      const value = (_queryParams[QueryParam.paypalSyncStatus] as string).split(",")

      // todo 参数过滤？
      this.setState({ paypalSyncStatusSelected: selectedDateFormat(value) })
      queryParams[QueryParam.paypalSyncStatus] = value
    }

    if (_queryParams[QueryParam.couriers]) {
      const value = (_queryParams[QueryParam.couriers] as string).split(",")
      // console.log(value)
      // todo 参数过滤？

      this.setState({ courierSelected: selectedDateFormat(value) })
      queryParams[QueryParam.couriers] = value
    }

    if (_queryParams[QueryParam.countries]) {
      const value = (_queryParams[QueryParam.countries] as string).split(",")
      // console.log(value)
      // todo 参数过滤？

      this.setState({ destinationSelected: selectedDateFormat(value) })
      queryParams[QueryParam.countries] = value
    }

    if (_queryParams[QueryParam.orderTags]) {
      const value = (_queryParams[QueryParam.orderTags] as string).split(",")

      this.setState({ orderTagSelected: selectedDateFormat(value) })
      queryParams[QueryParam.orderTags] = value
    }

    if (_queryParams[QueryParam.transitTime]) {
      const value = (_queryParams[QueryParam.transitTime] as string).split(",")
        .map(_ => parseInt(_))
        .filter(_ => _)
      // console.log(value)

      if (2 === value.length) {
        this.setState({ transitTimeValue: value as [number, number] })
        // queryParams[QueryParam.transitTime] = _queryParams[QueryParam.transitTime]
        queryParams[QueryParam.transitTime] = value
      }
    }

    if (_queryParams[QueryParam.residenceTime]) {
      const value = (_queryParams[QueryParam.residenceTime] as string).split(",")
        .map(_ => parseInt(_))
        .filter(_ => _)

      if (2 === value.length) {
        this.setState({ residenceTimeValue: value as [number, number] })
        // queryParams[QueryParam.residenceTime] = _queryParams[QueryParam.residenceTime]
        queryParams[QueryParam.residenceTime] = value
      }
    }

    if (_queryParams[QueryParam.Query]) {
      const value = (_queryParams[QueryParam.Query] as string)

      this.setState({ queryValue: value })
      queryParams[QueryParam.Query] = value
    }

    // todo 越写越复杂了
    const orderBy = _queryParams[QueryParam.orderBy]

    if (isObject(orderBy)) {
      const [[sortField, sortDirection]] = Object.entries(orderBy) as [string, string][]

      if (!queryParams[QueryParam.orderBy]) {
        queryParams[QueryParam.orderBy] = {}
      }

      if (sortField in this.defaultSortDirectionMap) {
        // 如果参数非法, 则取排序字段的默认排序方向
        const _sortDirection = sortDirectionIndexToStr[parseInt(sortDirection) as OrderDirection] || this.defaultSortDirectionMap[sortField]

        queryParams[QueryParam.orderBy][sortField] = sortDirectionValueMap[_sortDirection]

        this.setState({ sortSelected: sortField, sortDirectionSelected: _sortDirection })
      }
    }

    const page = _queryParams[QueryParam.page]

    if (isString(page)) {
      const value = parseInt(page)

      this.setState({ tablePageNum: value })
      queryParams[QueryParam.page] = value
    }

    const selectedTabId = (_queryParams.tab || "") as string

    stores.ordersStore.setQueryParams(queryParams)

    this.setState({ selectedTabId }, () => this.getPageDefaultData())
  }

  putQueryParamsToStore() {
    stores.ordersStore.setQueryParams(this.getQueryParams())
  }

  updateQueryParamsPagination = () => {
    this.putQueryParamsToStore()

    // todo 自定义视图翻页参数
    if (!this.state.isSelectedCustomNav) {
      this.props.router.navigate({
        search: qs.stringify(stores.ordersStore.queryParams, {
          allowDots: true,
          arrayFormat: "comma",
          skipNulls: true,
        }),
      })
    }

    this.getTableMessage()
  }

  updateQueryParams = () => {
    this.setState({ ordersLoading: true })
    this._updateQueryParams()
  }

  /** 不直接调这个方法, 一般用 updateQueryParams() */
  _updateQueryParams = () => {
    this.setState({
      // 修改筛选项后页码回到首页
      tablePageNum: 1,
    }, () => {

      this.putQueryParamsToStore()

      this.props.router.navigate({
        search: qs.stringify(stores.ordersStore.queryParams, {
          allowDots: true,
          arrayFormat: "comma",
          skipNulls: true,
        }),
      })

      this.getTableMessage()
    })
  }

  getQueryParams = () => {
    const {
      dateSelected,
      dateStartingSelected,
      dateEndingSelected,
      fulfilledDateOption,
      statusSelected,
      courierSelected,
      destinationSelected,
      orderTagSelected,
      transitTimeValue,
      residenceTimeValue,
      queryValue,
      tablePageNum,
      sortSelected,
      sortDirectionSelected,
      paypalSyncStatusSelected,
    } = this.state

    let orderBy

    const _sortSelected = sortSelected || DEFAULT_SORT_SELECTED

    if (_sortSelected !== DEFAULT_SORT_SELECTED || sortDirectionSelected !== DEFAULT_SORT_DIRECTION_SELECTED) {
      orderBy = { [_sortSelected]: sortDirectionValueMap[sortDirectionSelected] }
    }

    const params: QueryParamsType = {
      [QueryParam.orderedAt]: void 0,
      [QueryParam.orderedAtMin]: void 0,
      [QueryParam.orderedAtMax]: void 0,
      [QueryParam.fulfilledAt]: void 0,
      [QueryParam.fulfilledAtMin]: void 0,
      [QueryParam.fulfilledAtMax]: void 0,
      [QueryParam.status]: statusSelected.length ? statusSelected : void 0,
      [QueryParam.paypalSyncStatus]: paypalSyncStatusSelected.length ? paypalSyncStatusSelected : void 0,
      [QueryParam.couriers]: courierSelected.length ? courierSelected : void 0,
      [QueryParam.countries]: destinationSelected.length ? destinationSelected : void 0,
      [QueryParam.orderTags]: orderTagSelected.length ? orderTagSelected : void 0,
      [QueryParam.transitTime]: transitTimeValue || void 0,
      [QueryParam.residenceTime]: residenceTimeValue || void 0,
      [QueryParam.Query]: queryValue || void 0,
      [QueryParam.orderBy]: orderBy,
      [QueryParam.page]: 1 < tablePageNum ? tablePageNum : void 0,
    }


    if (DateOption.Custom === dateSelected) {
      params[QueryParam.orderedAtMin] = dateStartingSelected.format("YYYY-MM-DD")
        .toString()
      params[QueryParam.orderedAtMax] = dateEndingSelected.format("YYYY-MM-DD")
        .toString()
    } else if (dateSelected) {
      params[QueryParam.orderedAt] = dateSelected
    }

    if (DateOption.Custom === fulfilledDateOption.selected) {
      params[QueryParam.fulfilledAtMin] = fulfilledDateOption.starting.format("YYYY-MM-DD")
        .toString()
      params[QueryParam.fulfilledAtMax] = fulfilledDateOption.ending.format("YYYY-MM-DD")
        .toString()
    } else if (fulfilledDateOption.selected) {
      params[QueryParam.fulfilledAt] = fulfilledDateOption.selected
    }

    return params
  }

  /** Tab 选项切换 */
  handleTabChange = (selectedTabIndex: number) => {
    this.switchTab(selectedTabIndex)
    // 针对 Tab 获取对应筛选参数 并进行数据刷新
    this.tabChangeFresh(selectedTabIndex)
  }

  /** 将当前选中 tab 存入 state */
  switchTab(index: number) {
    const tab = tabNavConfig.tabs[index] || tabNavConfig.tabs[0]

    // 是否选择自定义导航
    const isCustomized = tab.isCustomized || false


    this.setState({ selectedTabIndex: index })

    if (!isCustomized) {
      this.setState({ navSaved: false, navTitle: "", isSelectedCustomNav: false })
    } else {
      this.setState({
        navSaved: true,
        navTitle: tab.content as string,
        isSelectedCustomNav: true,
      })
    }
  }

  putTabNameToUrlPath(tabId: string) {
    this.props.router.navigate({ search: qs.stringify({ tab: tabId }) })
  }

  /** 针对 Tab 获取对应筛选参数 */
  tabChangeFresh(num: number) {
    // 获取一级导航对应的条件写入 URL
    const tab = tabNavConfig.tabs[num] || tabNavConfig.tabs[0]

    const tabId = tab.id
    const filters = tab.filters || {}
    // 是否选择自定义导航
    const isCustomized = tab.isCustomized || false

    if (!isCustomized) {
      // 处理系统内置 Tab
      this.tabChangeSystem(tab)
      return
    }

    /* 自定义 Tab 处理逻辑 */

    const orderAtParams: {
      dateSelected?: string
      dateStartingSelected: dayjs.Dayjs
      dateEndingSelected: dayjs.Dayjs
    } = {
      dateEndingSelected: this.state.dateEndingSelected,
      dateSelected: void 0,
      dateStartingSelected: this.state.dateStartingSelected,
    }

    if (filters[QueryParam.orderedAt]) {
      const value = filters[QueryParam.orderedAt]

      if (this.dateChoices.some(({ value: _val }) => _val !== DateOption.Custom && _val === value)) {
        orderAtParams.dateSelected = value
      }
    } else if ("string" === typeof filters[QueryParam.orderedAtMin] && "string" === typeof filters[QueryParam.orderedAtMax]) {
      const dateStartingSelected = dayjs(filters[QueryParam.orderedAtMin])
      const dateEndingSelected = dayjs(filters[QueryParam.orderedAtMax])
      // console.log(dateStartingSelected, dateEndingSelected)

      if (dateStartingSelected.isValid() && dateEndingSelected.isValid()) {
        orderAtParams.dateSelected = DateOption.Custom
        orderAtParams.dateStartingSelected = dateStartingSelected
        orderAtParams.dateEndingSelected = dateEndingSelected
      }
    }

    const fulfilledDateOption: IOrdersState["fulfilledDateOption"] = {
      selected: void 0,
      starting: this.state.fulfilledDateOption.starting,
      ending: this.state.fulfilledDateOption.ending,
    }

    const fulfilledAtMin = filters[QueryParam.fulfilledAtMin]
    const fulfilledAtMax = filters[QueryParam.fulfilledAtMax]

    if (filters[QueryParam.fulfilledAt]) {
      const value = filters[QueryParam.fulfilledAt]

      if (this.dateChoices.some(({ value: _val }) => _val !== DateOption.Custom && _val === value)) {
        fulfilledDateOption.selected = value
      }
    } else if (isString(fulfilledAtMin) && isString(fulfilledAtMax)) {
      const starting = dayjs(fulfilledAtMin)
      const ending = dayjs(fulfilledAtMax)

      if (starting.isValid() && ending.isValid()) {
        fulfilledDateOption.selected = DateOption.Custom
        fulfilledDateOption.starting = starting
        fulfilledDateOption.ending = ending
      }
    }


    // todo 是否需要加入规则验证
    this.setState({
      ...orderAtParams,
      fulfilledDateOption,
      courierSelected: selectedDateFormat(filters[QueryParam.couriers] || []),
      destinationSelected: selectedDateFormat(filters[QueryParam.countries] || []),
      queryValue: filters[QueryParam.Query] || "",
      statusSelected: selectedDateFormat(filters[QueryParam.status] || []),
      orderTagSelected: selectedDateFormat(filters[QueryParam.orderTags] || []),
      tablePageNum: 1,
      transitTimeValue: filters[QueryParam.transitTime] || void 0,
      residenceTimeValue: filters[QueryParam.residenceTime] || void 0,
    }, () => {
      this.putQueryParamsToStore()

      this.putTabNameToUrlPath(tabId)

      this.getTableMessage()
    })
  }

  /** 系统内置导航项 切换选择 */
  tabChangeSystem(tab: ITabNav) {
    const { id } = tab

    this.setState({
      statusSelected: id !== "All" ? [id] : [],
      isSelectedCustomNav: false,
      tablePageNum: 1,
    }, () => this.updateQueryParams())
  }

  /** 筛选项值改变事件处理 */
  handleQueryChange = (queryValue: string) => this.setState({ queryValue }, () => this.updateQueryParams())

  handleChoiceListChange = (dateSelected: string) => {
    this.setState(
      { dateSelected },
      () => {
        // 选中 Custom 时不调用 更新参数的函数 节约资源
        DateOption.Custom !== this.state.dateSelected && this.updateQueryParams()
      },
    )
  }

  handleStartingChange = (dateStartingSelected: dayjs.Dayjs) => this.setState({ dateStartingSelected }, () => this.updateQueryParams())

  handleEndingChange = (dateEndingSelected: dayjs.Dayjs) => this.setState({ dateEndingSelected }, () => this.updateQueryParams())

  handleFulfilledAtChoiceListChange = (selected: string) => {
    this.setState(
      (prevState) => ({ fulfilledDateOption: { ...prevState.fulfilledDateOption, selected } }),
      () => {
        // 选中 Custom 时不调用 更新参数的函数 节约资源
        DateOption.Custom !== this.state.fulfilledDateOption.selected && this.updateQueryParams()
      },
    )
  }

  handleFulfilledAtStartingChange = (starting: dayjs.Dayjs) => this.setState(prevState => ({
    fulfilledDateOption: {
      ...prevState.fulfilledDateOption,
      starting,
    },
  }), () => this.updateQueryParams())

  handleFulfilledAtEndingChange = (ending: dayjs.Dayjs) => this.setState(prevState => ({
    fulfilledDateOption: {
      ...prevState.fulfilledDateOption,
      ending,
    },
  }), () => this.updateQueryParams())

  handleStatusChange = (statusSelected: string[]) => this.setState({ statusSelected }, () => this.updateQueryParams())

  handleCourierChange = (courierSelected: string[]) => this.setState({ courierSelected }, () => this.updateQueryParams())

  handleDestinationChange = (destinationSelected: string[]) => this.setState({ destinationSelected }, () => this.updateQueryParams())

  handlePaypalSyncStatusChange = (paypalSyncStatusSelected: string[]) => this.setState({ paypalSyncStatusSelected }, () => this.updateQueryParams())

  handleOrderTagChange = (orderTagSelected: string[]) => this.setState({ orderTagSelected }, () => this.updateQueryParams())

  handleTransitTimeChange = (transitTimeValue: [number, number]) => this.setState({ transitTimeValue }, () => this.updateQueryParams())
  // 筛选组件类型，1：单选，2：复选，3：日期，4：双向进度条，5：带搜索的复选（本地搜索）

  handleResidenceTimeChange = (residenceTimeValue: [number, number]) => this.setState({ residenceTimeValue }, () => this.updateQueryParams())

  // 分页事件处理
  handleTablePaginate = (page: number) => {
    // 将滚动条调至 pageRef 处
    // this.pageRef.current?.scrollIntoView()
    this.setState(
      { tablePageNum: page },
      () => this.updateQueryParamsPagination(),
    )
  }

  /** 移除筛选项 */
  handleStatusRemove = () => this.setState({ statusSelected: [] }, () => this.updateQueryParams())

  handlepaypalSyncStatusRemove = () => this.setState({ paypalSyncStatusSelected: [] }, () => this.updateQueryParams())

  handleDateRemove = () => this.setState({ dateSelected: void 0 }, () => this.updateQueryParams())

  handleFulfilledDateRemove = () => this.setState({
    fulfilledDateOption: {
      ...this.state.fulfilledDateOption,
      selected: void 0,
    },
  }, () => this.updateQueryParams())

  handleCourierRemove = () => this.setState({ courierSelected: [] }, () => this.updateQueryParams())

  handleDestinationRemove = () => this.setState({ destinationSelected: [] }, () => this.updateQueryParams())

  handleOrderTagRemove = () => this.setState({ orderTagSelected: [] }, () => this.updateQueryParams())

  handleTransitTimeRemove = () => this.setState({ transitTimeValue: void 0 }, () => this.updateQueryParams())

  handleResidenceTimeRemove = () => this.setState({ residenceTimeValue: void 0 }, () => this.updateQueryParams())

  saveColumnsConfig = () => {
    const data = stores.ordersStore.tableColumnChoices.map(({ id, disabled = false }) => {
      if (disabled) {
        return
      }

      const selected = stores.ordersStore.tableColumnSelected.includes(id)

      return {
        id,
        selected,
      }
    })
      .filter(_ => _)

    OrderUrl.saveTableColumns(data)
  }

  toggleHeadingColumnPopoverActive = () => {
    this.setState({ headingColumnPopoverActive: !this.state.headingColumnPopoverActive })
  }

  /** 当位置改变时触发 */
  handleChoicesColumnChange = (headingColumnChoices: OptionListDragProps["choices"]) => {
    stores.ordersStore.setTableColumnChoices(headingColumnChoices)

    this.saveColumnsConfig()
  }

  /** 当选项选中时触发 */
  handleSelectedColumnChange = (item: string[]) => {
    stores.ordersStore.setTableColumnSelected(item)

    this.saveColumnsConfig()
  }

  /**
   * 同步操作
   * 该组件使用了 store，选项数据来自子组件
   */
  handleSyncAndSave = (selected: string, successMsg: string) => {
    const syncDay = parseInt(selected)
    // 推送到后台进行用户同步

    return OrderUrl.syncTimeSet({ syncDay })
      .then(({ data: { code } }) => {
        if (APICode.OK === code) {
          stores.userInfoSore.setDataSingle("syncDay", syncDay)
          toast(successMsg)
          this.setState({ reSync: { ...this.state.reSync, status: OrderReSyncStatus.Synchronizing } })
          this.listenReSync()
        }
      })
  }

  /** 一级导航添加 */
  handleFiltersControlSave = (viewName: string) => {
    const _filters = stores.ordersStore.queryParams

    // 允许存储的过滤项
    const accessKeys = [
      QueryParam.orderedAt,
      QueryParam.orderedAtMin,
      QueryParam.orderedAtMax,
      QueryParam.fulfilledAt,
      QueryParam.fulfilledAtMin,
      QueryParam.fulfilledAtMax,
      QueryParam.status,
      QueryParam.paypalSyncStatus,
      QueryParam.couriers,
      QueryParam.countries,
      QueryParam.orderTags,
      QueryParam.transitTime,
      QueryParam.residenceTime,
    ]

    const filters = accessKeys.reduce((result, k) => {
      if (!isUndefined(_filters[k])) {
        result[k] = _filters[k]
      }
      return result
    }, {} as QueryParamsType)

    const nameNow = this.state.navTitle

    const params = {
      filters,
      title: viewName,
      name: nameNow,
    }

    // 检测是否重复
    const checkNowSame = tabNavConfig.checkTabNameExists(viewName)

    if (nameNow !== viewName && checkNowSame) {
      toast("A view with this name already exists. Please choose a different name.", 4e3, true)
      return
    }

    return OrderUrl.firstNavAddNew(params)
      .then(({ data: { code, msg, data } }) => {
        if (APICode.OK !== code) {
          toast(msg, 2e3, true)
          return
        }

        const tab = tabNavConfig.tabs.find(v => v.content === nameNow)

        if (tab) {
          tab.id = data.id
          tab.content = data.content
          tab.filters = filters
        } else {
          tabNavConfig.tabs.push({ ...data, filters, isCustomized: true })
        }

        // 选中当前保存的导航
        const selectedTabIndex = tabNavConfig.tabs.findIndex(v => v.id === data.id)

        if (0 <= selectedTabIndex) {
          // 激活当前tab，将SaveView设为已保存且将navTitle设为当前content
          this.switchTab(selectedTabIndex)
          // 并改变url参数
          this.putTabNameToUrlPath(data.id)
        }

        toast(msg, 2e3)
      })
  }

  /** 一级导航删除 */
  handleFiltersControlDelete = () => {
    const name = this.state.navTitle

    return OrderUrl.firstNavDelNew({ name })
      .then(({ data: { msg } }) => {
        remove(tabNavConfig.tabs, v => v.content === name)
        this.handleTabChange(0)
        toast(msg, 2e3)
      })
  }

  /** Order tag 筛选组件 keyword 变更事件 */
  handleOrderTagFilterKeywordChange = (orderTagsKeyword: string) => {
    this.setState({ orderTagsKeyword }, () => this.orderTagFilterSearchChoices())
  }

  /** Order tag 筛选组件 tag 列表筛选 */
  orderTagFilterSearchChoices() {
    const orderTagChoicesShow = this.state.orderTagChoices.filter((item) => fuzzySearch(this.state.orderTagsKeyword, item.value))

    this.setState({ orderTagChoicesShow })
  }

  /** 排序 Key 变更事件 */
  handleSortButtonChangeKey = (key: string) => {
    this.setState({
      sortSelected: key,
      sortDirectionSelected: this.defaultSortDirectionMap[key],
    }, () => this.updateQueryParams())
  }

  /** 排序 Direction 变更事件 */
  handleSortButtonChangeDirection = (sortDirectionSelected: string) => {
    this.setState({ sortDirectionSelected: sortDirectionSelected as SortButtonDirection }, () => this.updateQueryParams())
  }

  getAppliedFilters() {
    const {
      dateSelected,
      dateStartingSelected,
      dateEndingSelected,
      fulfilledDateOption,
      statusSelected,
      courierSelected,
      destinationSelected,
      orderTagSelected,
      transitTimeValue,
      residenceTimeValue,
      paypalSyncStatusSelected,
    } = this.state


    const appliedFilters: AppliedFilterInterface[] = []

    if (!isEmpty(statusSelected)) {
      const key = ShipmentFilterKey.Status

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, statusSelected),
        onRemove: this.handleStatusRemove,
      })
    }
    if (!isEmpty(paypalSyncStatusSelected)) {
      const key = ShipmentFilterKey.PaypalSyncStatus

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, paypalSyncStatusSelected),
        onRemove: this.handlepaypalSyncStatusRemove,
      })
    }
    if (!isEmpty(dateSelected)) {
      const key = ShipmentFilterKey.OrderDate

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, {
          ending: dateEndingSelected,
          selected: dateSelected,
          starting: dateStartingSelected,
        }),
        onRemove: this.handleDateRemove,
      })
    }
    if (!isEmpty(fulfilledDateOption.selected)) {
      const key = ShipmentFilterKey.FulfillmentDate

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, fulfilledDateOption),
        onRemove: this.handleFulfilledDateRemove,
      })
    }
    if (!isEmpty(courierSelected)) {
      const key = ShipmentFilterKey.Courier

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, courierSelected),
        onRemove: this.handleCourierRemove,
      })
    }
    if (!isEmpty(destinationSelected)) {
      const key = ShipmentFilterKey.Destination

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, destinationSelected),
        onRemove: this.handleDestinationRemove,
      })
    }
    if (!isEmpty(orderTagSelected)) {
      const key = ShipmentFilterKey.OrderTag

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, orderTagSelected),
        onRemove: this.handleOrderTagRemove,
      })
    }
    if (!isEmpty(transitTimeValue)) {
      const key = ShipmentFilterKey.TransitTime

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, transitTimeValue),
        onRemove: this.handleTransitTimeRemove,
      })
    }
    if (!isEmpty(residenceTimeValue)) {
      const key = ShipmentFilterKey.ResidenceTime

      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, residenceTimeValue),
        onRemove: this.handleResidenceTimeRemove,
      })
    }
    return appliedFilters
  }

  getFilters(appliedFilters: any[]) {
    const {
      dateSelected,
      dateStartingSelected,
      dateEndingSelected,
      fulfilledDateOption,
      statusSelected,
      courierChoices,
      courierSelected,
      destinationChoices,
      destinationSelected,
      orderTagChoices,
      orderTagChoicesShow,
      orderTagSelected,
      transitTimeValue,
      residenceTimeValue,
      orderTagsKeyword,
      paypalSyncStatusChoices,
      paypalSyncStatusSelected,
    } = this.state
    const { t } = this.props

    const orderTagRemoveCallback = appliedFilters.find((filter) => filter.key === ShipmentFilterKey.OrderTag)?.onRemove
    const orderTagRemoveHandler =
      orderTagRemoveCallback === undefined
        ? undefined
        : () => {
          orderTagRemoveCallback(ShipmentFilterKey.OrderTag)
        }

    const Filters = [
      {
        filter: (
          <DateChoiceList
            choices={this.dateChoices}
            selected={dateSelected}
            startingSelected={dateStartingSelected}
            endingSelected={dateEndingSelected}
            // disableDatesBefore={disableDatesBefore}
            // disableDatesAfter={disableDatesAfter}
            onChoiceListChange={this.handleChoiceListChange}
            onStartingChange={this.handleStartingChange}
            onEndingChange={this.handleEndingChange}
            onCustomItemSelected={(selected) => {
              this.setState({ isDateFilterCustomItemSelected: selected })
            }}
          />
        ),
        key: ShipmentFilterKey.OrderDate,
        label: t("OrderDate"),
      },
      {
        filter: (
          <DateChoiceList
            choices={this.dateChoices}
            selected={fulfilledDateOption.selected}
            startingSelected={fulfilledDateOption.starting}
            endingSelected={fulfilledDateOption.ending}
            // disableDatesBefore={disableDatesBefore}
            // disableDatesAfter={disableDatesAfter}
            onChoiceListChange={this.handleFulfilledAtChoiceListChange}
            onStartingChange={this.handleFulfilledAtStartingChange}
            onEndingChange={this.handleFulfilledAtEndingChange}
            onCustomItemSelected={(selected) => {
              this.setState({ isDateFilterCustomItemSelected: selected })
            }}
          />
        ),
        key: ShipmentFilterKey.FulfillmentDate,
        label: t("FulfillmentDate"),
      },
      {
        filter: (
          <ChoiceRightHelpText
            title={null}
            choices={this.state.statusChoices}
            selected={statusSelected}
            onChange={this.handleStatusChange}
            allowMultiple
          />
        ),
        key: ShipmentFilterKey.Status,
        label: t("Status"),
        shortcut: true,
      },
      {
        filter: (
          <ChoiceRightHelpText
            title={null}
            choices={courierChoices}
            selected={courierSelected}
            onChange={this.handleCourierChange}
            allowMultiple
          />
        ),
        key: ShipmentFilterKey.Courier,
        label: t("Courier"),
        shortcut: true,
      },
      {
        filter: (
          <ChoiceRightHelpText
            title={null}
            choices={destinationChoices}
            selected={destinationSelected}
            onChange={this.handleDestinationChange}
            allowMultiple
          />
        ),
        key: ShipmentFilterKey.Destination,
        label: t("Destination"),
        shortcut: true,
      },
      {
        filter: (
          <>
            <ChoiceWithSearch
              autoComplete="off"
              placeholder={t("Search", { ns: "common" })}
              choices={orderTagsKeyword && orderTagChoicesShow !== undefined ? orderTagChoicesShow : orderTagChoices}
              selected={orderTagSelected}
              onChange={this.handleOrderTagChange}
              allowMultiple
              keyword={orderTagsKeyword}
              onKeywordChange={this.handleOrderTagFilterKeywordChange}
            />
            <div
              className="PP-Filters__ClearButtonContainer"
            >
              <Button

                disabled={orderTagRemoveHandler === undefined}
                onClick={orderTagRemoveHandler}
                variant="plain"
              >
                {t("Clear")}
              </Button>
            </div>
          </>
        ),
        key: ShipmentFilterKey.OrderTag,
        label: t("OrderTag"),
        shortcut: true,
        hideClearButton: true,
      },
      {
        filter: (
          <InputTwoThumbsRange value={transitTimeValue} onChange={this.handleTransitTimeChange} />
        ),
        key: ShipmentFilterKey.TransitTime,
        label: t("TransitTime"),
      },
      {
        filter: (
          <InputTwoThumbsRange value={residenceTimeValue} onChange={this.handleResidenceTimeChange} />
        ),
        key: ShipmentFilterKey.ResidenceTime,
        label: t("ResidenceTime"),
      },
    ]

    if (stores.userInfoSore.data.is_paypal_connected) {
      Filters.push({
        filter: (
          <ChoiceRightHelpText
            title={null}
            choices={paypalSyncStatusChoices}
            selected={paypalSyncStatusSelected}
            onChange={this.handlePaypalSyncStatusChange}
            allowMultiple
          />
        ),
        key: ShipmentFilterKey.PaypalSyncStatus,
        label: t("PayPalSyncStatus"),
      })
    }

    return Filters
  }

  /**
   * 免费用户首次同步完成
   */
  renderFirstSyncComplete() {

    DashboardInit.checkFirstSync()

    const { first_finish_sync_time = 0 } = stores.userInfoSore.data
    const { t } = this.props

    return (
      <div className={styles.OrdersTip__Padding}>
        <Banner
          // @ts-ignore
          icon={GiftCardIcon}
          // title="Special offer - free sync & track your last-30-day orders 🎉"
          onDismiss={!stores.userInfoSore.isFirstSyncing ? () => {
            dashboard.closeFirstSyncBanner()
              .then(({ data: { code } }) => {
                if (code === APICode.OK) stores.userInfoSore.setDataSingle("notice_fiv", 1)
              })
          } : void 0}
        >
          <p className={styles.OrdersTip__h}>{t("firstSyncComplete.p1")}</p>
          <p>{t("firstSyncComplete.p2")}</p>
          {
            stores.userInfoSore.isFirstSyncing
              ? (
                <div style={{ position: "absolute", right: "1rem", top: "1.25rem" }}>
                  <Spinner size="small" />
                </div>
              )
              : (
                first_finish_sync_time > 0 && (
                  <div style={{ bottom: "0.875rem", position: "absolute", right: "1rem" }}>
                    <Badge tone="success">{`Completed on ${dayjs.unix(first_finish_sync_time)
                      .format("MMM DD")}`}</Badge>
                  </div>
                )
              )
          }
        </Banner>
      </div>
    )
  }

  getSortButtonChoices = (): SortButtonChoice[] => {
    const { t } = this.props

    return [
      { label: t("OrderDate"), value: "orderedAt asc", directionLabel: t("OldestToNewest") },
      { label: t("OrderDate"), value: "orderedAt desc", directionLabel: t("NewestToOldest") },
      { label: t("FulfillmentDate"), value: "fulfilledAt asc", directionLabel: t("OldestToNewest") },
      { label: t("FulfillmentDate"), value: "fulfilledAt desc", directionLabel: t("NewestToOldest") },
      { label: t("TransitTime"), value: "transit asc", directionLabel: t("ShortestToLongest") },
      { label: t("TransitTime"), value: "transit desc", directionLabel: t("LongestToShortest") },
      { label: t("ResidenceTime"), value: "residence asc", directionLabel: t("ShortestToLongest") },
      { label: t("ResidenceTime"), value: "residence desc", directionLabel: t("LongestToShortest") },
      { label: t("LastCheckpointTime"), value: "lastCheckpointTime asc", directionLabel: t("OldestToNewest") },
      { label: t("LastCheckpointTime"), value: "lastCheckpointTime desc", directionLabel: t("NewestToOldest") },
    ]
  }

  render() {
    const { t } = this.props
    const {
      pageConfigLoading,
      ordersLoading,
      queryValue,
      orderMessage: { numberCount },
      syncTimeList,
      navTitle,
      navSaved,
      prevPage,
      nextPage,

      headingColumnPopoverActive,

      sortSelected,
      sortDirectionSelected,
      exception_shipment_banner_closed,
    } = this.state

    const appliedFilters = this.getAppliedFilters()

    const filters = this.getFilters(appliedFilters)

    const _sortSelected = sortSelected || DEFAULT_SORT_SELECTED
    const _sortDirectionSelected = sortDirectionSelected || this.defaultSortDirectionMap[_sortSelected]
    const _sortButtonSelected = [`${_sortSelected} ${_sortDirectionSelected}`]

    const PageHeading = (
      <CustomPage
        title={t("orders", { ns: "menu" }) as string}
        rightChildren={(
          <div className={"flex justify-between items-center flex-wrap gap-[var(--p-space-200)] Polaris-ActionMenu-SecondaryAction"}>
            <ExportShipmentsControl />

            <ReSyncControl
              disabledSyncBtn={!this.state.reSync.loaded || this.state.reSync.status === OrderReSyncStatus.Synchronizing}
              syncTimeList={syncTimeList}
              onSyncAndSave={this.handleSyncAndSave}
            />
          </div>
        )}
      >
      </CustomPage>
    )

    return (
      <Container
        id="Page-Orders"
        fullWidth
      >
        {PageHeading}

        {
          stores.commonStore.banner20493?.visible && <div className="mb-4"><PPReturnRecommendation /></div>
        }

        <LegacyCard>
          <div>

            {
              pageConfigLoading && (
                <div className={styles.TabSkeleton}>
                  <div>
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div>
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
              )
            }

            {
              !pageConfigLoading && (
                <Tabs
                  tabs={this.getTabsRenderData()}
                  selected={this.getSelectedTabIndex()}
                  onSelect={this.handleTabChange}
                  disclosureText={t("MoreViews", "More views")}
                />
              )
            }

            <Divider />

            {/* TODO: chuwen 2022/5/19 14:45 配合产品需求，暂时隐藏 */}
            {/* {stores.userInfoSore.showFirstSync && this.renderFirstSyncComplete()} */}
            {/* 隐藏同步之后的Modal*/}
            {/* {this.SyncModal()}*/}
            {/* <button onClick={() => OrderUrl.patchShipmentsException(false).then(() =>*/}
            {/*  this.setState({ exception_shipment_banner_closed: false }),*/}
            {/* )}*/}
            {/* >测试开启banner</button>*/}
            {/* banner_closed未关闭且只在Exception下显示*/}
            {!exception_shipment_banner_closed && this.getSelectedTabIndex() === 6 && <div className={"p-3 pb-0"}>
              <Banner
                tone="info"
                onDismiss={() => {
                  OrderUrl.patchShipmentsException(true)
                    .then(() =>
                      this.setState({ exception_shipment_banner_closed: true }),
                    )
                }
                }
              >
                <Trans ns="orders" i18nKey="ExceptionBanner">
                  <Button
                    onClick={() => this.props.router.navigate("/settings", { state: { notificationsType: 1, regional_tips: true } })}
                    variant="plain"
                  ></Button>
                </Trans>
              </Banner>
            </div>}

            <div className={styles.OrdersFilters__Padding}>
              {
                pageConfigLoading ? (
                  <div>
                    <TextField disabled label={null} autoComplete="off" />

                    <div className="Polaris-Filters__TagsContainer mt-2" aria-live="polite">
                      <span className="Polaris-Tag Polaris-Tag--removable">
                        <span
                          className="Polaris-Tag__TagText"
                        >{t("OrderDateDisLab", { value: this.dateChoices.find(({ value: _val }) => _val === DateOption.PastTwoMonths)?.label || "" })}</span>
                        <button type="button" className="Polaris-Tag__Button">
                          <span className="Polaris-Icon">
                            <span className="Polaris-VisuallyHidden"></span>
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                              <path
                                d="M6.707 5.293a1 1 0 0 0-1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 0 0-1.414-1.414l-3.293 3.293-3.293-3.293Z"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </span>
                    </div>
                  </div>
                ) : (
                  <OrdersFilters
                    filters={filters}
                    appliedFilters={appliedFilters}
                    queryValue={queryValue}
                    onQueryChange={this.handleQueryChange}
                    navSaved={navSaved}
                    navName={navTitle}
                    onFiltersControlSave={this.handleFiltersControlSave}
                    onFiltersControlDelete={this.handleFiltersControlDelete}
                  />
                )
              }
            </div>

            <div
              className={styles.Sort__Select}
            >
              <span className="mr-1" style={{ minWidth: 112 }}>{t("ShowingShipmentCount", {
                count: numberCount,
                ns: "common",
              })}</span>

              <InlineStack gap={"200"} wrap={false}>
                <Popover
                  active={headingColumnPopoverActive}
                  activator={<Button
                    // @ts-ignore
                    icon={LayoutColumns3Icon}
                    onClick={this.toggleHeadingColumnPopoverActive}
                  >{t("Columns")}</Button>}
                  onClose={this.toggleHeadingColumnPopoverActive}
                  fluidContent
                >
                  <DynamicLoadError>
                    <Suspense fallback={<SkeletonOptionListDrag lines={13} />}>
                      <OptionListDrag
                        choices={stores.ordersStore.tableColumnChoices}
                        selected={stores.ordersStore.tableColumnSelected}
                        disabled={["order"]}
                        disabledCanSort
                        onChoicesChange={this.handleChoicesColumnChange}
                        onSelectedChange={this.handleSelectedColumnChange}
                      />
                    </Suspense>
                  </DynamicLoadError>
                </Popover>
                <div className={styles.Sort__Select__button}>
                  <SortButton
                    choices={this.getSortButtonChoices()}
                    selected={_sortButtonSelected}
                    onChange={() => undefined}
                    onChangeKey={this.handleSortButtonChangeKey}
                    onChangeDirection={this.handleSortButtonChangeDirection}
                  />
                </div>
              </InlineStack>
            </div>

            <OrdersTable
              items={stores.ordersStore.tableData}
              loading={ordersLoading}
              paginationDisplay={!!(prevPage || nextPage)}
              pagination={{
                hasPrevious: !!prevPage,
                hasNext: !!nextPage,
                previousKeys: [Key.KeyJ],
                previousTooltip: t("Pagination.previous", { ns: "common" }) + " (J)",
                nextKeys: [Key.KeyK],
                nextTooltip: t("Pagination.next", { ns: "common" }) + " (K)",
                onPrevious: () => this.handleTablePaginate(prevPage as number),
                onNext: () => this.handleTablePaginate(nextPage as number),
              }}
            />
          </div>
        </LegacyCard>

        <NotificationHistoryModalMobx />

      </Container>
    )
  }


  disambiguateLabel(key: ShipmentFilterKey, value: any) {
    const mapper = {
      [ShipmentFilterKey.Courier]: this.state.courierChoices,
      [ShipmentFilterKey.Destination]: this.state.destinationChoices,
      [ShipmentFilterKey.OrderTag]: this.state.orderTagChoices,
      [ShipmentFilterKey.Status]: this.state.statusChoices,
      [ShipmentFilterKey.PaypalSyncStatus]: this.state.paypalSyncStatusChoices,
    }
    const { t } = this.props

    let labelValue: string = ""

    switch (key) {
      case ShipmentFilterKey.Status:
      case ShipmentFilterKey.Destination:
      case ShipmentFilterKey.Courier:
      case ShipmentFilterKey.OrderTag:
        labelValue = (value as string[]).map((v) => mapper[key].find(({ value }) => value === v)?.label)
          .join(", ")
        break
      case ShipmentFilterKey.PaypalSyncStatus:
        labelValue = (value as string[]).map((v) => mapper[key].find(({ value }) => value === v)?.label)
          .join(", ")
        break
      case ShipmentFilterKey.FulfillmentDate:
      case ShipmentFilterKey.OrderDate:
        if (DateOption.Custom === value.selected) {
          labelValue = `${value.starting.format("MMM D, YYYY")
            .toString()} - ${value.ending.format("MMM D, YYYY")
            .toString()}`
          break
        }
        labelValue = this.dateChoices.find(({ value: _val }) => _val === value.selected)?.label || ""
        break
      case ShipmentFilterKey.TransitTime:
        // 因为翻译文本用的是变量占位符的形式拼接的, 这里就不用拼接数据了
        break
      case ShipmentFilterKey.ResidenceTime:
        labelValue = `${value[0]} - ${value[1]}`
        break
      default:
        labelValue = value
        break
    }

    // 翻译字段写变量会报错? 如果能写出合适的变量类型, 也可以优化掉
    switch (key) {
      case ShipmentFilterKey.Status:
        return t("StatusDisLab", { value: labelValue })
      case ShipmentFilterKey.PaypalSyncStatus:
        return t("PaypalSyncStatusDisLab", { value: labelValue })
      case ShipmentFilterKey.Destination:
        return t("DestinationDisLab", { value: labelValue })
      case ShipmentFilterKey.Courier:
        return t("CourierDisLab", { value: labelValue })
      case ShipmentFilterKey.OrderTag:
        return t("OrderTagDisLab", { value: labelValue })
      case ShipmentFilterKey.OrderDate:
        return t("OrderDateDisLab", { value: labelValue })
      case ShipmentFilterKey.FulfillmentDate:
        return t("FulfillmentDateDisLab", { value: labelValue })
      case ShipmentFilterKey.TransitTime:
        return t("TransitTimeRange", { max: value[1], min: value[0] })
      case ShipmentFilterKey.ResidenceTime:
        return t("ResidenceTimeDisLab", { value: labelValue })
      default:
        return labelValue
    }
  }

  /** 根据条件获取表数据 */
  getTableMessage(queryParams?: QueryParamsType) {
    queryParams = queryParams || stores.ordersStore.queryParams

    const perPage = PER_PAGE
    const nowPage = 1 < queryParams[QueryParam.page] ? queryParams[QueryParam.page] : 1

    this.setState({ ordersLoading: true })

    this.getAllFilterCount()

    OrderUrl.getShipmentList(queryParams)
      .then(({ data: { data: orderMessage } }) => {
        const count = orderMessage.numberCount

        const { prevPage, nextPage } = this.getPageParams(nowPage, count, perPage)

        this.setState({
          nextPage,
          orderMessage,
          prevPage,
        })

        this.setState({ ordersLoading: false })

        stores.ordersStore.setTableData(orderMessage.tableData)
        stores.ordersStore.setItemsCount(count)
      })
    // .finally(() => this.state.isFirstLoad && this.setState({ isFirstLoad: false }))

  }

  /** 计算上一页下一页 */
  getPageParams(nowPage: number, count: number, perPage: number = 10) {

    const totalPage = Math.ceil(count / perPage)

    const nextPage = nowPage + 1
    const prevPage = nowPage - 1

    return {
      nextPage: nextPage <= totalPage ? nextPage : void 0,
      prevPage: 1 <= prevPage ? prevPage : void 0,
    }
  }

  /**
   * 设置 Tab
   */
  // setTabs() {
  //   if (this.state.isFirstLoad) return
  //
  //   const { t } = this.props
  //
  //   // this.getAllFilterCount()
  //
  //   OrderUrl.getOrderDefaultMessage().then(
  //     ({ data: { data: { tabs, syncTimeList } } }) => {
  //       // console.log(TabNav, countries_list, couriers_list, statusList, syncTimeList)
  //
  //       // this.statusChoices = statusList.map((item: any) => {
  //       //   // @ts-ignore
  //       //   item.label = t(`ShipmentStatus.${item.tran_key}`, { ns: "common" })
  //       //   return item
  //       // })
  //
  //       tabNavConfig.setTabNavList(tabs)
  //     },
  //   )
  // }

  /** 获取页面基本数据集合 */
  getPageDefaultData() {
    OrderUrl.getOrderDefaultMessage()
      .then(
        ({ data: { data: { tabs, syncTimeList, columns, exception_shipment_banner_closed } } }) => {
          tabNavConfig.setTabNavList(tabs)

          this.initSelectedTab()

          this.loadTableColumns(columns)

          this.setState({
            pageConfigLoading: false,
            syncTimeList,
            exception_shipment_banner_closed,
          })

          setTimeout(() => {
            this.setState({
              pageConfigLoading: false,
            })
          }, 0)

        },
      )
  }

  initSelectedTab() {
    // tab 参数优先级最高, 指定了 tab 参数, 直接切换到对应 tab.
    // 其他情况直接触发查询

    const { selectedTabId } = this.state

    if (!selectedTabId) {
      this.getTableMessage()
      return
    }

    const selectedTabIndex = tabNavConfig.tabs.findIndex(tab => tab.id === selectedTabId)

    if (selectedTabIndex === -1) {
      this.getTableMessage()
      return
    }

    const selectedTab = tabNavConfig.tabs[selectedTabIndex]

    if (selectedTab.isCustomized) {
      this.handleTabChange(selectedTabIndex)
    } else {
      this.getTableMessage()
    }
  }


  loadTableColumns(columns: IOrderDefaultData["columns"]) {
    if (!stores.userInfoSore.data.is_paypal_connected) {
      columns = columns.filter(item => item.id !== "paypalSyncStatus")
    }
    stores.ordersStore.setTableColumnSelected(columns.filter(({ selected }) => selected)
      .map(({ id }) => id))

    this.translateTableColumnChoices(columns)
  }

  /** 更新 choice 翻译文本 */
  translateTableColumnChoices(choices?: any) {
    const _columnChoices = (choices || stores.ordersStore.tableColumnChoices).map((item: any) => ({
      ...item,
      label: this.props.t(item.tran_key),
    }))

    stores.ordersStore.setTableColumnChoices(_columnChoices)
  }

  // getTabs(tabNav: {
  //   id: string
  //   content: string
  //   tran_key: string
  //   total?: number
  //   filters?: QueryParamsType
  // }[]) {
  //   const { t } = this.props
  //
  //   return tabNav.map(({ id, total, tran_key, content }) => {
  //     const text = !tran_key ? content : t(tran_key as any, { defaultValue: content, ns: "common" })
  //
  //     return ({
  //       content: "undefined" === typeof total ? text : `${text} (${total})`,
  //       id,
  //     })
  //   })
  // }


  /** @deprecated */
  SyncModal() {
    const { t } = this.props

    const handleChange = () => {
      this.clearListenReSync()
      this.setState({
        reSync: {
          count: this.state.reSync.count,
          show: false,
          status: this.state.reSync.status,
        },
      })
    }

    return (
      <Modal
        open={this.state.reSync.show}
        onClose={handleChange}
        title={t("SyncOrders.title")}
        secondaryActions={[
          {
            content: t("Close", { ns: "common" }),
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <div className={classNames(styles.notice_CheckCircleIcon)}>
              <Icon
                // @ts-ignore
                source={CheckCircleIcon} tone="success"
              />
            </div>
            <h3 style={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              marginBottom: "12px",
              textAlign: "center",
            }}
            >
              <span style={{ paddingRight: "12px" }}>🎉</span>
              <Trans ns="orders" i18nKey="SyncOrders.p1" count={this.state.reSync.count}>
                {{ count: this.state.reSync.count }} order have been synced successfully.
              </Trans>
            </h3>
          </TextContainer>
        </Modal.Section>
        <GetReviewModalSection onSelected={handleChange} />
      </Modal>
    )
  }

  getSelectedTabIndex = () => {
    if (this.state.isSelectedCustomNav) {
      // 在自定义 Tab 下不自动关联状态
      return this.state.selectedTabIndex
    }

    let tabIndexTo = -1

    const tabs = this.getTabsRenderData()

    // 当只选择了一个状态筛选项时, 关联选择的筛选项到 tab 中
    if (this.state.statusSelected.length === 1) {
      tabIndexTo = tabs.findIndex(v => v.id === this.state.statusSelected[0])
    }

    if (tabIndexTo === -1) {
      // tabIndexTo = tabs.findIndex(v => v.id === "All")
      // 暂定 0 是 All 选项, 若不是, 请修正
      tabIndexTo = 0
    }

    return tabIndexTo
  }

  getAllFilterCount = () => {
    const filterFields: {
      field: string
      state: keyof IOrdersState
    }[] = [
      {
        field: "status",
        state: "statusChoices",
      },
      {
        field: "courier",
        state: "courierChoices",
      },
      {
        field: "destination",
        state: "destinationChoices",
      },
      {
        field: "order-tag",
        state: "orderTagChoices",
      },
    ]

    if (stores.userInfoSore.data.is_paypal_connected) {
      filterFields.push({
        field: "paypal-sync-status",
        state: "paypalSyncStatusChoices",
      })
    }

    // todo 合并 courier & destination, 节省请求资源
    // this.getMultiFilterCount(["courier", "destination"])
    //   .then(({ data: { data } }) => {
    //     console.log(data)
    //   })

    filterFields.forEach(({ field, state }) => {
      this.getFilterCount(field)
        .then(({ data: { data } }) => {
          let _data = data

          if (field === "status") {
            _data = this.translateStatusChoices(_data)
          }

          if (field === "paypal-sync-status") {
            _data = this.addPaypalSyncStatusEnumChoicesLabel(_data)
          }

          const choiceList = createChoiceList(_data)

          this.setState({ [state]: choiceList } as any)

          if (field === "status") {
            // tab 计数统计
            const tabsShipmentCount = _data.reduce((acc: any, { value, count }: any) => {
              acc[value] = count
              return acc
            }, {})

            tabsShipmentCount.All = _data.reduce((acc: number, { count }: any) => acc + count, 0)

            this.setState({
              tabsShipmentCount,
            })
          }
        })
    })
  }

  translateStatusChoices(choices?: any) {
    return choices.map((item: any) => ({
      ...item,
      label: this.props.t(`ShipmentStatus.${item.tran_key}` as any, { ns: "common" }),
    }))
  }

  addPaypalSyncStatusEnumChoicesLabel(choices?: any) {
    return choices.map((item: any) => ({
      ...item,
      label: this.props.t(`${item.tran_key}` as any, { ns: "orders" }),
      value: String(item.value),
    }))
  }

  getFilterCount = (filterKey: string) => {
    return OrderUrl.getFilterItems(filterKey, stores.ordersStore.queryParams)
  }

  /**
   * 获取 tabs 渲染数据
   */
  private getTabsRenderData() {
    return tabNavConfig.tabs.map(({ id, content }) => {
      // 附加 shipment 数量
      const total = this.state.tabsShipmentCount[id]
      let tabContent: string | React.ReactNode = "undefined" === typeof total ? `${content}` : `${content} (${total})`

      if (!this.state.exception_shipment_banner_closed && id === "Exception") {
        tabContent = <div className={"inline-flex gap-1 items-center"}>
          {tabContent}
          <div className="Polaris-Badge Polaris-Badge--toneInfo">
            <div style={{ margin: "-2px -8px", color: "var(--p-color-icon-info)" }}>
              <Icon source={InfoIcon} />
            </div>
          </div>
        </div>
      }

      return ({
        content: tabContent,
        id,
      }) as TabProps
    })
  }
}


function selectedDateFormat(v: string | string[]) {
  return "string" === typeof v ? [v] : v
}

function createChoiceList(data: IFilterOption[]): CustomChoice[] {
  return data.map(({ count, value, label, ...params }) => {
    return {
      ...params,
      value: value !== undefined ? value : (label as string),
      label: label !== undefined ? label : (value as string),
      helpText: <Text as="span" numeric>{count}</Text>,
    }
  })
}

export default withTranslation("orders")(Orders)
