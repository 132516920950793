export enum DashboardHelpEnum {
  GetSupport,
  StartLiveChat,
  HelpDocs,
  WechatSupport,
}

export enum DashboardSetupGuideEnum {
  ADD_TRACKING_PAGE = 2,
  CUSTOMIZE_SHIPPING_NOTIFICATIONS = 4,
  DROPSHIPPING_MODE = 8,
  HOW_PP_WORKS = 16,
}
