import { useTranslation } from "react-i18next"

import { DateOption } from "@/pages/Orders/components"

export enum AppliedStatus {
  "CoverStatus"="coverStatus",
  "CreatedDate"="createdDate"
}

export enum auditRetEnum {
  "planError"="Plan error",
  "currencyTypeError"="Currency not supported",
  "nonPayingUsers"="Non paying users",
  "blackList"="Black List",
  "examPassed"="Exam Passed",
  "underReview"="Under review",
  "sorryGreenDelivery"="Sorry, Green Delivery",
  "talkToAnExpert"="Talk to an expert",
  "paymentIssue"="Payment issue",
  "highLossRatio"="High loss ratio"
}

export const getDataTime = () => {
  const { t } = useTranslation("orders")

  return [
    { label: t("LastNDays", { day: 7 }), value: DateOption.PastWeek },
    { label: t("LastNDays", { day: 30 }), value: DateOption.PastMonth },
    { label: t("LastNDays", { day: 90 }), value: DateOption.PastQuarter },
  ]
}

export const getCoverStatus = () => {
  const { t } = useTranslation("delivery_guarantee")

  return [
    { label: t("CoverStatus.Pending"), value: "Pending" },
    { label: t("CoverStatus.Active"), value: "Active" },
    { label: t("CoverStatus.Cancelled"), value: "Cancelled" },
    { label: t("CoverStatus.Expired"), value: "Expired" },
  ]
}

export const getClaimsStatus = () => {
  const { t } = useTranslation("delivery_guarantee")

  return [
    { label: t("CoverStatus.Accepted"), value: "Accepted" },
    { label: t("CoverStatus.Pending"), value: "Pending" },
    { label: t("CoverStatus.Rejected"), value: "Rejected" },
  ]
}


export const getDays = {
  "Last 7 days": "LAST_7_DAYS",
  "Last 30 days": "LAST_30_DAYS",
  "Last 90 days": "LAST_90_DAYS",
}

