import { action, makeObservable, observable } from "mobx"

import { ProductCollection } from "@/models/ITrackingPageOptions"

export default class productCollection {
  @observable public productCollection: ProductCollection | undefined

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setProductCollection(data: any): any {
    this.productCollection = data
  }

}
