import React, { useEffect, useRef, useState } from "react"

interface ImageProps {
  src: string
  alt?: string
  className?: string
  style?: React.CSSProperties
}

const LazyLoadImage = ({ src, ...props }: ImageProps) => {
  const [imageSrc, setImageSrc] = useState("")
  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    let observer: IntersectionObserver

    if (imgRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => {
          // 当图片进入可视区域时，设置图片地址进行加载
          if (entry.isIntersecting && imgRef.current) {
            setImageSrc(src)
            observer.unobserve(imgRef.current)
          }
        },
        {
          rootMargin: "0px 0px 200px 0px", // 可视区域的边距设置为200px
        },
      )
      observer.observe(imgRef.current)
    }
    return () => {
      if (observer && observer.unobserve && imgRef.current) {
        observer.unobserve(imgRef.current)
      }
    }
  }, [src])

  return <img ref={imgRef} alt="" {...{ src: imageSrc, ...props }} />
}

export default LazyLoadImage
