import { PermissionRules } from "@/config/permission-rules"
import { PaidServiceFuncConfig } from "@/models/IUserInfo"
import stores from "@/stores"

/**
 * 规则使用了 "或" 验证，就是只要符合其中一个条件就会通过
 */
export default (permissionRules: PermissionRules) => {
  const { data: { plan_member, plan_price, paid_service_func_config }, oldPayingUsers, isOldUser } = stores.userInfoSore

  return Object.entries(permissionRules)
    .some(([k, v]) => {
      switch (k) {
        case "plan_member":
          return (v as number) <= plan_member
        case "plan_price":
          return (v as number) <= plan_price
        case "paid_service_func_config":
          return paid_service_func_config?.[(v as keyof PaidServiceFuncConfig)]
        case "oldPayingUsers":
          return oldPayingUsers === (v as boolean)
        case "isOldUser":
          return isOldUser === (v as boolean)
      }

      return false
    })
}
