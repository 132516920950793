import { Redirect } from "@shopify/app-bridge/actions"
import { Button } from "@shopify/polaris"
import { ClipboardIcon } from "@shopify/polaris-icons"
import copy from "copy-to-clipboard"
import i18n from "i18next"
import React from "react"

import AppBridge from "@/utils/appBridge"
import toast from "@/utils/toast"

import styles from "./index.module.scss"
import { NormalLinkProp } from "./NormalLink"

export default function NormalLink({
  children,
  external,
  link,
  showIcon,
  useAppBridgeRedirect,
}: NormalLinkProp) {
  const inputProps = {
    target: external ? "_blank" : undefined,
  }

  const buttonMarkup = showIcon && (
    <span className={styles.normalBlock}>
      <Button icon={ClipboardIcon} onClick={() => copyNumber()} variant="plain" />
    </span>
  )

  if (useAppBridgeRedirect && AppBridge.redirect) {
    return <>
      <button
        type="button"
        className={styles.normalLink}
        onClick={() => {
          AppBridge.redirect.dispatch(Redirect.Action.REMOTE, {
            newContext: external,
            url: link,
          })
        }}
      >
        {children}
      </button>

      {buttonMarkup}
    </>
  }

  return <>
    <a className={styles.normalLink} href={link} {...inputProps}>{children}</a>

    {buttonMarkup}

  </>

  // 复制 number
  function copyNumber() {
    let copyRes = false

    if (typeof children === "string") {
      copyRes = copy(children)
    }
    const msg = copyRes ? i18n.t("common:CopiedSuccessfully") : i18n.t("common:CopiedUnsuccessfully")

    toast(msg, 2e3)
  }
}
