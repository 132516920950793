import "./ChoiceRightHelpText.scss"

import { ChoiceList, ChoiceListProps } from "@shopify/polaris"
import React from "react"

function ChoiceRightHelpText(props: ChoiceListProps) {
  return (
    <div className="PP-Choice">
      <ChoiceList
        {...props}
      />
    </div>
  )
}

export default ChoiceRightHelpText
