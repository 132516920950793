import classNames from "classnames"
import { includes, isNumber, map } from "lodash-es"
import numeral from "numeral"
import React from "react"

import styles from "./index.module.scss"
import { SimpleTableListItemProp, SimpleTableListProps } from "./types"

export default function SimpleTableList(props: SimpleTableListProps) {
  const fields: string[] = map(props.fields, "field")

  const classNameMap: Map<string, string> = new Map<string, string>()

  props.fields.map(item => classNameMap.set(item.field, item.className ?? ""))

  // 空数据设置
  let emptyData

  if (props.data.length === 0 && props.emptyData) {
    emptyData = <SimpleTableListItem
      fields={fields} values={props.emptyData} key={"head-0"}
      classNameMap={classNameMap}
    />
  }

  let thead

  if (!props.noHead) {
    thead = (<thead>
      <tr>
        {props.fields.map((item) => {
          return (<th style={{ paddingRight: 0 }} key={`${item.field}`} className={item.className}>{item.title}</th>)
        })}
      </tr>
    </thead>)
  } else {
    thead = <></>
  }

  // 最终渲染输出
  return (
    <table className={`${styles.table} ${props.className}`}>
      {thead}
      <tbody>
        {props.data.map((values: any, index: number) => {
          return <SimpleTableListItem
            thousandsSplitFields={props.thousandsSplitFields || []} fields={fields}
            values={values} key={index + ""} classNameMap={classNameMap}
          />
        })}
        {emptyData}
      </tbody>
    </table>
  )
}


function SimpleTableListItem(props: SimpleTableListItemProp) {
  return (<tr>
    {
      props.fields.map((field, index) => {
        let val = props.values[field]

        if (includes(props.thousandsSplitFields, field) && isNumber(val)) val = numeral(val)
          .format("0,0")
        return (<td style={{ paddingRight: 0 }} key={`td-${field}-${index}`}
          className={classNames(props.classNameMap.get(field), "leading-5")}
        >{val}</td>)
      })
    }
  </tr>)
}
