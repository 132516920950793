import { AxiosResponse } from "axios"
import dayjs from "dayjs"

import { BannerResponse, SetupGuideData, ShipmentsAndLookupData, ValueCoefficientData } from "@/api/dashboard/type"
import { ExportDiscountUpsell, ExportProductRecommendation } from "@/api/dashboard/type/export.d"
import { DashboardSetupGuideEnum } from "@/enums/dashboard"
import baseRequest from "@/utils/baseRequest"

import request from "../../utils/request"
import { CancelTypesEnum } from "../../utils/request.d"
import { FilterDateParam } from "./type/FilterDateParam"

export default {
  /**
   * 开始检查首次同步
   */
  checkFirstSync() {
    return request.post("userinfo/is-show-sync-tip")
  },

  /**
   * 关闭新手引导板块
   */
  closeBeginnersGuide() {
    return request.post("userinfo/close-twe", {
      _t: dayjs()
        .valueOf(),
      cancelType: CancelTypesEnum.PATH,
    })
  },

  /**
   * 关闭评价窗口
   */
  closeEvaluation() {
    return request.post("userinfo/close-fou", {
      "_t": new Date().getTime(),
    })
  },

  /** 关闭首次同步 Banner */
  closeFirstSyncBanner() {
    return request.post("userinfo/close-fiv", {
      "_t": new Date().getTime(),
    })
  },

  /**
   * 关闭"免费用户提升免费额度 20-50 的 banner"
   */
  closeFreeOrderAddBanner() {
    return request.post("userinfo/close-free_order_add", {
      _t: dayjs()
        .valueOf(),
      cancelType: CancelTypesEnum.PATH,
    })
  },

  /** 关闭合作伙伴友好 Banner */
  closePartnerFriendly($type: { checkT: number }) {
    return request.post("userinfo/close-part-friendly", {
      "_t": new Date().getTime(),
      "type": $type,
    })
  },


  /** 关闭异常套餐提示 */
  closeShowAccountTip(type: any) {
    return request.post("userinfo/close-tip", {
      "_t": new Date().getTime(),
      "type": type,
    })
  },

  /**
   * 关闭"显示联系我们移除 branding 的 banner"
   */
  closeRemoveBrandingBanner() {
    return request.post("userinfo/close-remove_branding", {
      _t: dayjs()
        .valueOf(),
      cancelType: CancelTypesEnum.PATH,
    })
  },


  /**
   * 关闭评论引导
   */
  closeReviewGuide(force: boolean = false) {
    return request.post("userinfo/close-fou", {
      _t: dayjs()
        .valueOf(),
      cancelType: CancelTypesEnum.PATH,
      force: force,
    })
  },


  /**
   * 关闭 Sharing Banner
   */
  closeSharingBanner() {
    return request.post("userinfo/close-sharing", {
      _t: dayjs()
        .valueOf(),
      cancelType: CancelTypesEnum.PATH,
    })
  },


  /**
   * 禁用 DropshippingMode 并触发相关的配置
   */
  disableDropshippingModeEnable() {
    return request.post("dashboard/disable-dropshipping-mode-enable", {
      enable: true,
    })
  },


  /**
   * 导出 DiscountUpsell CSV 文件
   */
  exportDiscountUpsell(start: string, end: string) {
    return request.post<ExportDiscountUpsell>("export/discount-upsell", {
      end,
      start,
    })
  },


  exportProductRecommendation(start: string, end: string) {
    return request.post<ExportProductRecommendation>("export/product-recommendation", {
      end,
      start,
    })
  },


  productRecommendation(param?: FilterDateParam) {
    const url = "dashboard/pro-recommendation"

    if (!param) return request.get(url)
    return request.post(url, {
      ...param,
      cancelType: CancelTypesEnum.PATH,
    })
  },


  /**
   * 获取推荐 APP 列表
   */
  recommendedAPP() {
    return request.get("dashboard/getAppData")
  },


  /**
   * 启用 DropshippingMode 并触发相关的配置
   */
  setDropshippingModeEnable() {
    return request.post("dashboard/set-dropshipping-mode-enable", {
      enable: true,
    })
  },


  /**
   * 获取订单维度和浏览表 Shipments / Lookups
   */
  shipmentsData(param?: FilterDateParam) {
    const url = "dashboard/shipments-lookups"

    if (!param) return request.get(url)
    return request.post(url, {
      ...param,
      cancelType: CancelTypesEnum.PATH,
    })
  },


  shippingNotifications(param?: FilterDateParam) {
    const url = "dashboard/shipping-notification"

    if (!param) return request.get(url)
    return request.post(url, {
      ...param,
      cancelType: CancelTypesEnum.PATH,
    })
  },


  /**
   * "表格"统计数据
   */
  statistics(param?: FilterDateParam) {
    const url = "dashboard/delivery-day/get-data"

    if (!param) return request.get(url)
    return request.post(url, {
      ...param,
      cancelType: CancelTypesEnum.PATH,
    })
  },


  /**
   * 获取新手引导进度
   */
  getSetupGuide() {
    return baseRequest.get("v3/dashboard/setup_guide", {
      // @ts-ignore
      useDefaultExceptionHandler: false,
    }) as Promise<AxiosResponse<SetupGuideData>>
  },

  /**
   * 更改步骤状态
   */
  setSetupGuideStatusById(id: DashboardSetupGuideEnum, completed: boolean) {
    return baseRequest.patch<{ id: DashboardSetupGuideEnum, is_completed: boolean }>("v3/dashboard/setup_guide/" + id, {
      completed: completed,
    })
  },

  /**
   * 关闭新手引导板块
   */
  closeSetupGuide() {
    return baseRequest.delete("v3/dashboard/setup_guide")
  },
  /**
   * 展示新手引导板块
   */
  showSetupGuide() {
    return baseRequest.post("v3/dashboard/setup_guide")
  },
  /**
   * 获取首页统计数据信息
   */
  getShipmentsAndLookups(params?: FilterDateParam) {
    const time_window = params?.value6
    const date_from = params?.Startup ? dayjs.unix(params?.Startup)
      .format("YYYY-MM-DD") : undefined
    const date_to = params?.StartEnd ? dayjs.unix(params?.StartEnd)
      .format("YYYY-MM-DD") : undefined

    return baseRequest.get<ShipmentsAndLookupData>("v3/dashboard/shipments_lookups", {
      params: {
        time_window,
        date_from,
        date_to,
      },
    })
  },

  /**
   * 获取首页Banner
   * @deprecated
   */
  getBanner() {
    return baseRequest.get<BannerResponse>("v3/common/banners")
  },

  /**
   * 撤销关闭Banner
   * @deprecated
   */
  undoCloseBannerById(id: number) {
    return baseRequest.post<BannerResponse>("v3/common/banners/" + id)
  },
  /**
   * 关闭Banner
   * @deprecated
   */
  closeBannerById(id: number) {
    return baseRequest.delete<BannerResponse>("v3/common/banners/" + id)
  },

  /**
   * 更新价值计算
   */
  updateValueCoefficientGuide(params:{
    "save_time": string
    "save_cost": string
    "cost_monetary_unit": string
  }) {
    return baseRequest.patch<ValueCoefficientData>("v3/dashboard/as_value_coefficient", params)
  },
}
