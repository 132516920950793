import { Observer } from "mobx-react"
import React from "react"

import { Mode } from "@/models/IOrderTagsManageStore"
import stores from "@/stores"

import TagsManageModal from "./TagsManageModal"

interface TagsManageModalMobxProps {
  type?: "detail"
}

function TagsManageModalMobx(props: TagsManageModalMobxProps) {

  const { orderTagsManageStore, ordersStore } = stores

  return <Observer>{() => (
    <TagsManageModal
      type={props.type}
      open={orderTagsManageStore.open}
      isAdd={orderTagsManageStore.mode === Mode.ADD_TAGS}
      isDetail={orderTagsManageStore.isDetail}
      selected={orderTagsManageStore.selected}
      orderTags={ordersStore.orderInfoData?.orderTags || []}
      tags={orderTagsManageStore.tags}
      loadingTags={orderTagsManageStore.loading}
      title={orderTagsManageStore.title}
      saving={orderTagsManageStore.saving}
      sortType={orderTagsManageStore.sortType}
      onSelected={orderTagsManageStore.handleSelected}
      onClose={orderTagsManageStore.handleClose}
      onSave={orderTagsManageStore.handleSave}
      onSortTypeChange={orderTagsManageStore.handleSortTypeChange}
      // listAllLimit={250}
    />
  )}</Observer>
}

export default TagsManageModalMobx
