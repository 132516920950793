import { TabProps } from "@shopify/polaris"
import { action, computed, makeObservable, observable } from "mobx"

import i18next from "@/i18n/config"
import { Menu } from "@/i18n/i18next"
import IGlobalMenu from "@/models/IGlobalMenu"


export interface ITabs extends TabProps {
  i18nKey: keyof Menu
}

export default class GlobalMenuStore implements IGlobalMenu {
  @observable public selectedTabIndex: number = 0
  @observable public tabs: ITabs[] = [
    {
      content: i18next.t("menu:dashboard"),
      i18nKey: "dashboard",
      id: "/",
    },
    {
      content: i18next.t("menu:tracking_page"),
      i18nKey: "tracking_page",
      id: "/tracking-page",
    },
    {
      content: i18next.t("menu:orders"),
      i18nKey: "orders",
      id: "/orders",
    },
    {
      content: i18next.t("menu:delivery_guarantee"),
      i18nKey: "delivery_guarantee",
      id: "/protection",
    },
    {
      content: i18next.t("menu:settings"),
      i18nKey: "settings",
      id: "/settings",
    },
    {
      content: i18next.t("menu:analytics"),
      i18nKey: "analytics",
      id: "/analytics",
    },
    {
      content: i18next.t("menu:integration"),
      i18nKey: "integration",
      id: "/integration",
    },
    {
      content: i18next.t("menu:billing"),
      i18nKey: "billing",
      id: "/billing",
    },
    // {
    //   content: i18next.t("menu:account"),
    //   i18nKey: "account",
    //   id: "/account",
    // },
    // {
    //   content: i18next.t("menu:get_help"),
    //   i18nKey: "get_help",
    //   id: "/get-help",
    // },
    // {
    //   content: i18next.t("menu:add_ons"),
    //   i18nKey: "add_ons",
    //   id: "/add-ons",
    // },
  ]

  constructor() {
    makeObservable(this)
  }

  @computed get activePageTitle(): React.ReactNode | undefined {
    const { content } = this.tabs[this.selectedTabIndex] || {}

    return content
  }

  @computed get pageTitleIl8n(): ITabs["i18nKey"] {
    const { i18nKey } = this.tabs[this.selectedTabIndex] || {}

    return i18nKey
  }

  @action.bound
  public selectTab(selectedTabIndex: number): void {
    this.selectedTabIndex = selectedTabIndex
  }

  @action.bound
  public setTabs(tabs: ITabs[]) {
    this.tabs = tabs
  }
}
