import { SkeletonBodyText, SkeletonDisplayText } from "@shopify/polaris"
import classNames from "classnames"
import React from "react"

import styles from "@/pages/Account/components/PlanList/index.module.scss"

const PlanSkeleton = () => {
  const list = [0, 1, 2, 3]

  return <>
    {
      list.map(item => <div key={item}
        className={classNames(styles.PlanList__ItemWrapper, "relative w-full md:w-1/2 lg:w-1/4 mt-4")}
      >
        <div className={classNames(styles.PlanList__Item, "Polaris-LegacyCard")}>
          <div className="Polaris-LegacyCard__Header pt-[40px]">
            <h2 className="Polaris-Heading flex justify-center">
              <SkeletonDisplayText size="small" />
            </h2>
          </div>

          <div className="Polaris-LegacyCard__Section Polaris-TextContainer pt-2">
            <SkeletonBodyText lines={2} />

            <div className={"text-left mt-7 flex justify-center"}>
              <SkeletonDisplayText size="small" />
            </div>

            <SkeletonBodyText lines={5} />

            <div className={"flex justify-center w-full"}>
              <SkeletonDisplayText />
            </div>

            <div className={"text-left mt-2"}>
              <SkeletonBodyText lines={12} />
            </div>
          </div>
        </div>
      </div>)
    }
  </>
}

export default PlanSkeleton
