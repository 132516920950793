// 1134.65 => 1,134.65
const commafy = (num: string) => {
  num = num + ""
  const re = /(-?\d+)(\d{3})/

  while (re.test(num))
    num = num.replace(re, "$1,$2")
  return num
}

// 1134.65 => 1,135
function noDecimalsformatMoney(amount: string) {
  const num = parseFloat(String(amount).replace(/,/g, ""))

  return num.toLocaleString("en-US", { maximumFractionDigits: 0 })
}

// 1134.65 => 1.134,65
function separatorFormatMoney(amount: string | number) {
  const parts = amount.toString()
    .split(".")

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return parts.join(",")
}

// 1134.65 => 1.134,65
function noDecimalsWithSeparatorFormatMoney(amount: string) {
  const num = parseFloat(String(amount).replace(/,/g, ""))

  return separatorFormatMoney(Math.round(num))
}

// 1134.65 => 1'134.65
function apostropheSeparatorFormatMoney(money: string) {
  return money.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}

export enum currencyEnum {
  Amount = "{{amount}}",
  AmountNoDecimals = "{{amount_no_decimals}}",
  AmountWithCommaSeparator = "{{amount_with_comma_separator}}",
  AmountNoDecimalsWithCommaSeparator = "{{amount_no_decimals_with_comma_separator}}",
  AmountWithApostropheSeparator = "{{amount_with_apostrophe_separator}}",
}

/**
 * shopify 货币格式
 * {{ amount }}    1,134.65
 * {{ amount_no_decimals }}  ✓  1,135
 * {{ amount_with_comma_separator }}    1.134,65
 * {{ amount_no_decimals_with_comma_separator }}  ✓  1.135
 * {{ amount_with_apostrophe_separator }}    1'134.65
 */
const currencyFormat = (value: string, format: string, defaultValue?: string) => {
  if (defaultValue && (!value || ["0", "0.00", "0.0", 0].includes(value))) {
    return defaultValue
  }
  if (!format) return value

  if (format.includes(currencyEnum.Amount)) {
    return format.replace(currencyEnum.Amount, commafy(value))
  }

  if (format.includes(currencyEnum.AmountNoDecimals)) {
    return format.replace(currencyEnum.AmountNoDecimals, noDecimalsformatMoney(value))
  }

  if (format.includes(currencyEnum.AmountWithCommaSeparator)) {
    return format.replace(currencyEnum.AmountWithCommaSeparator, separatorFormatMoney(value))
  }

  if (format.includes(currencyEnum.AmountNoDecimalsWithCommaSeparator)) {
    return format.replace(currencyEnum.AmountNoDecimalsWithCommaSeparator, noDecimalsWithSeparatorFormatMoney(value))
  }
  if (format.includes(currencyEnum.AmountWithApostropheSeparator)) {
    return format.replace(currencyEnum.AmountWithApostropheSeparator, apostropheSeparatorFormatMoney(value))
  }

  return value
}

export default currencyFormat
