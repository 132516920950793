import { BaseResponse } from "@/api/base"
import { FetchOrderTagsParams, IFilterCountRes, IOrderDefaultRes } from "@/api/orders/type"
import { QueryParamsType } from "@/pages/Orders/types"
import { UpdateStatusData } from "@/pages/Orders/UpdateStatus/types"
import baseRequest from "@/utils/baseRequest"
import request from "@/utils/request"

export default {
  /**
   * 获取更新状态列表
   * @param params
   */
  getUpdateStatusList(params: any) {
    interface RES extends BaseResponse {
      data: UpdateStatusData
    }

    return request.get<RES>("orders/update-status", { params })
  },

  /**
   * 获取 order 页面基本信息
   * @deprecated
   */
  orderDefaultMessage() {
    return request.get("orders/order-default")
  },

  /**
   * 获取 order 页面基本信息
   */
  getOrderDefaultMessage() {
    return baseRequest.get<IOrderDefaultRes>("v2/shipments/order-default")
  },

  /**
   * 获取 order 页面基本信息
   */
  patchShipmentsException(status: boolean) {
    return baseRequest.patch("v2/shipments/first-exception", {
      "close_banner": status,
    })
  },

  /**
   * 获取 table 信息
   */
  getShipmentList(param: object) {
    return baseRequest.post("v2/shipments", param)
  },

  /**
   * 获取 table 信息
   */
  getAllOrderTags(params: FetchOrderTagsParams) {
    return baseRequest.get("v2/shipments/order-tags", { params })
  },

  /**
   * 添加/移除 order tags
   */
  manageOrderTags(params: object, action?: "REMOVE" | "ADD") {
    return baseRequest.post(`v2/shipments/manage-order-tags?action=${action}`, params)
  },

  /**
   * 导出条件获取
   */
  exportChesApi() {
    return baseRequest.post("v2/shipments/export-condition")
  },

  /**
   * 数据导出
   * @param param
   */
  exportDataApi(param: object) {
    return baseRequest.post("v2/shipments/export-data", param)
  },

  /**
   * 一级导航添加
   * @param param
   */
  firstNavAddNew(param: object) {
    return request.post("orders/nav-add", param)
  },

  /**
   * 一级导航删除
   * @param param
   */
  firstNavDelNew(param: object) {
    return request.post("orders/nav-delete", param)
  },

  /**
   * 更新订单状态
   * @param params
   */
  setUpdateStatusList(params: any) {
    interface RES extends BaseResponse {
      data: UpdateStatusData
    }

    return request.post<RES>("orders/update-status", params)
  },

  /**
   * 邮件通知记录
   * @param param
   */
  mailRecords(param: object) {
    return request.post("orders/mail-records", param)
  },

  /**
   * 获取运输商列表
   */
  getCourier(){
    return baseRequest.get("/v2/trackings/couriers")
  },

  /**
   * 更新单号运输商
   * @param params
   */
  updateShipmentCourier(params: {
    nids: string[]
    courier_code: string
  }) {
    return baseRequest.post("/v2/shipments/couriers", params)
  },

  /**
   * 手动同步到PayPal
   * @param param
   * 如果全选，则传filters，如果不是全选则传入选中number_ids
   */
  syncPayPal(params: { number_ids?: string[], filters?: any }) {
    return baseRequest.post("v2/shipments/sync-paypal", params)
  },

  setUpdateStatusListOld(params: any) {
    type RES = BaseResponse

    return request.post<RES>("old-api?hmac=a3094542008fe8b9275c54b996eb450f93f7803411490e0c817e90c883515c2f&host=bm93dGltZS1wcmV2aWV3Lm15c2hvcGlmeS5jb20vYWRtaW4&ids%5B%5D=4354178056266&locale=zh-CN&session=d64f7b9e6e05b9e2509efe9f54e0813fc3431efabac1817b3f1b7633b715772a&shop=trackingmore-5.myshopify.com&timestamp=1658193632", params)
  },

  /**
   * 根据参数，获取 number 表 id
   * @param params
   */
  queryFieldByParam(params: QueryParamsType) {
    return baseRequest.post("v2/shipments/query-field-by-param", params)
  },

  /**
   * 保存自动同步时间配置
   * @param param
   */
  syncTimeSet(param: object) {
    return request.post("orders/sync-time", param)
  },

  /**
   * 获取筛选项
   */
  getFilterItems(filterField: string, param: object) {
    return baseRequest.post<IFilterCountRes>(`v2/shipments/field-count/${filterField}`, param)
  },

  /**
   * 获取筛选项
   */
  saveTableColumns(columns: object) {
    return baseRequest.post("v2/shipments/table-columns", { columns })
  },
}
