import * as React from "react"

const SvgShareFacebook = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 12c0-6.629-5.371-12-12-12S0 5.371 0 12c0 5.99 4.388 10.954 10.125 11.855v-8.386H7.077V12h3.048V9.356c0-3.007 1.79-4.668 4.532-4.668 1.314 0 2.687.234 2.687.234v2.952H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.386C19.612 22.954 24 17.989 24 12Z"
      fill="#006BDF"
    />
  </svg>
)

export default SvgShareFacebook
