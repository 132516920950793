import i18n from "i18next"

import { ITabNav } from "@/pages/Orders/types"

class Config {
  private _tabs: ITabNav[] = []

  get tabs() {
    return this._tabs
  }

  setTabNavList(list: ITabNav[]) {
    this._tabs = list
    this.translateTabs()
  }

  translateTabs() {
    this._tabs = this._tabs.map((item) => {
      const { tran_key, content } = item
      const text = !tran_key ? content : i18n.t(tran_key as any, {
        defaultValue: content,
        ns: "common",
      })

      return {
        ...item,
        content: text,
      }
    })
  }

  checkTabNameExists(tabName: string) {
    return this._tabs.some(({ content }) => content === tabName)
  }
}

const tabNavConfig = new Config()

export { tabNavConfig }
