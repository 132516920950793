import { Banner } from "@shopify/polaris"
import { GiftCardFilledIcon } from "@shopify/polaris-icons"
import { Trans, useTranslation } from "react-i18next"

import stores from "@/stores"

export default () => {
  const { t } = useTranslation("orders", { keyPrefix: "PPReturnRecommendationBanner" })

  return (
    <Banner
      tone="info"
      icon={GiftCardFilledIcon}
      // @ts-ignore
      title={<Trans ns="orders" i18nKey="PPReturnRecommendationBanner.title"><span className="emoji-font">❤️</span></Trans>}
      action={{
        // @ts-ignore
        content: <Trans ns="orders" i18nKey="PPReturnRecommendationBanner.action"><span className="emoji-font">❤️</span></Trans>,
        onAction: () => window.open("https://api-return-pro.parcelpanel.com/recommend/parcelpanel"),
      }}
      onDismiss={() => {
        stores.commonStore.closeBanner20493()
      }}
    >
      <p>{t("desc")}</p>
    </Banner>
  )
}
