import { Icon, Text } from "@shopify/polaris"
import { LockIcon } from "@shopify/polaris-icons"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"

import Permission from "@/enums/permission"
import stores from "@/stores"

import styles from "./index.module.scss"

type planNameType = "Essential" | "Professional"

const PermissionBadge = ({ text, permission }: { text: planNameType, permission: Permission }) => {
  const navigator = useNavigate()
  const { pathname } = useLocation()
  const { commonStore, upgradeStore } = stores

  const handleBadgeClick = () => {
    // upgradeStore.showModal(permissionRules[permission])
    navigator("/account")
    commonStore.setAccountSource(pathname)
  }

  return (
    <div
      className={styles.BadgeWrapperBorder}
      onClick={handleBadgeClick}
    >
      <div className={styles.BadgeWrapper}>
        <Icon source={LockIcon} tone={"base"} />
        <div className={"ml-1 flex align-center items-center"}>
          <Text variant={"headingXs"} as={"span"} fontWeight={"medium"}>{text}</Text>
          <div className={"ml-1"}>
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.68311 4.41064C9.68311 4.57568 9.60693 4.74072 9.47998 4.86133L6.22363 8.11133C6.09033 8.23828 5.94434 8.30176 5.79199 8.30176C5.44922 8.30176 5.19531 8.0542 5.19531 7.71777C5.19531 7.54639 5.26514 7.40039 5.37305 7.28613L6.30615 6.34668L7.85498 4.9375L6.67432 5.02002H0.885254C0.51709 5.02002 0.269531 4.76611 0.269531 4.41064C0.269531 4.05518 0.51709 3.80762 0.885254 3.80762H6.67432L7.84863 3.88379L6.30615 2.48096L5.37305 1.53516C5.26514 1.4209 5.19531 1.2749 5.19531 1.10352C5.19531 0.76709 5.44922 0.519531 5.79199 0.519531C5.94434 0.519531 6.09033 0.583008 6.22998 0.709961L9.47998 3.95996C9.60693 4.08057 9.68311 4.24561 9.68311 4.41064Z"
                fill="#303030"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PermissionBadge
