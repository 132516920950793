import { Icon, LegacyCard } from "@shopify/polaris"
import { CheckCircleIcon } from "@shopify/polaris-icons"
import React, { ReactNode } from "react"

import styles from "./index.module.scss"

type CircleTickListCardProps = {
  title: string
  subTitle?: string
  children?: ReactNode
  list: string[] | ReactNode[]
}
const CircleTickListCard = (props: CircleTickListCardProps) => {
  const { title, subTitle, list, children } = props

  return (
    <LegacyCard title={title}>
      <LegacyCard.Section>
        {
          subTitle && <div className={styles.subTitle}>{subTitle}</div>
        }

        <ul className={styles.program_ul}>
          {
            list.map((item, index) => <li key={index}>
              <Icon source={CheckCircleIcon} tone={"success"} />
              <div>{item}</div>
            </li>,
            )
          }
        </ul>
        {children}
      </LegacyCard.Section>
    </LegacyCard>
  )
}

export default CircleTickListCard
