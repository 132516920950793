import dayjs from "dayjs"

import DashboardInit from "@/pages/Dashboard/init"

/**
 * 处理日期选择事件
 *
 * @param selected
 * @param startingDate
 * @param endingDate
 * @constructor
 */
export function TableDatePickerOnChangeHandle(selected: number, startingDate: Date, endingDate: Date) {
  if (selected > 0) {
    DashboardInit.batchGetChart(true, { value6: selected })
  } else {
    const startDayjs = dayjs(startingDate), endingDayjs = dayjs(endingDate)
    let startTime = startDayjs.unix(), endTime = endingDayjs.add(1, "d")
      .subtract(1, "s")
      .unix()

    if (startTime > endTime) [startTime, endTime] = [endTime, startTime]

    DashboardInit.batchGetChart(true, {
      StartEnd: endTime,
      Startup: startTime,
    })
  }
}
