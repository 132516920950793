
import { AppProviderProps } from "@shopify/polaris/build/ts/src/components/AppProvider/AppProvider"
import deTranslations from "@shopify/polaris/locales/de.json"
// 语言包导入
import enTranslations from "@shopify/polaris/locales/en.json"
import esTranslations from "@shopify/polaris/locales/es.json"
import frTranslations from "@shopify/polaris/locales/fr.json"
import itTranslations from "@shopify/polaris/locales/it.json"
import ptTranslations from "@shopify/polaris/locales/pt-PT.json"
import zhTranslations from "@shopify/polaris/locales/zh-CN.json"
import { Callback } from "i18next"

import app from "@/api/app"
import i18next from "@/i18n/config"
import { SupportLanguages } from "@/i18n/SupportLanguages"
import stores from "@/stores"

import { IListenerNameEnum } from "./languages.d"

export { IListenerNameEnum } from "./languages.d"

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LanguageUtils {
  const observers = new Map()

  /**
   * 设置语言
   * @param local
   * @param callback
   * @param ignoreSave
   */
  export function setLocale(local: SupportLanguages, callback?: Callback, ignoreSave: boolean = false) {
    if (!ignoreSave) {
      // 设置激活列表
      stores.commonStore.setActiveLanguageItem(local)
    }

    if (local === i18next.language) return

    if (!ignoreSave) {
      // 请求 API 保存选择的语言
      app.setConfig({ selected_lang: local as any })
        .then()
    }

    i18next.changeLanguage(local, callback)

    for (const listenerName of observers.keys()) {
      observers.get(listenerName)()
    }
  }

  /**
   * 添加监听器
   * @param listenerName
   * @param callback
   */
  export function addListener(listenerName: IListenerNameEnum, callback: Callback) {
    !observers.has(listenerName) && observers.set(listenerName, callback)
  }

  /**
   * 移除监听器
   * @param listenerName
   */
  export function removeListener(listenerName: IListenerNameEnum) {
    return observers.delete(listenerName)
  }

  /**
   * 获取 Shopify polaris i18n 文件
   */
  export function getAppProviderI18n(): AppProviderProps["i18n"] {
    const { language } = i18next

    switch (language) {
      case "de":
        return deTranslations
      case "es":
        return esTranslations
      case "fr":
        return frTranslations
      case "it":
        return itTranslations
      case "pt":
        return ptTranslations
      case "zh":
        return zhTranslations
      case "en":
      default:
        return enTranslations
    }
  }
}
