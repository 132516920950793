import { IBase } from "@/models/IBase"
import { QueryParamsType } from "@/pages/Orders/types"

export enum Mode {
  ADD_TAGS,
  REMOVE_TAGS,
}

export enum SortType {
  Popular = "Popular",
  Alphabetical = "Alphabetical",
}

export interface IOrderTagsManageStore extends IBase {
  shipmentCount: number
  loading: boolean
  sortType: SortType
  open: boolean
  mode: Mode
  selected: string[]
  tags: string[]
  saving: boolean
  shipmentSelectedItems?: string[]
  queryParams?: QueryParamsType
  /** 窗口打开时间, 用于确定是否是同一个操作 */
  openTime?: number

  handleSelected(selected: string[]): void

  setOpen(open: boolean): void

  handleClose(): void

  handleSave(): void

  fetchTagsData(): void

  get title(): string
}
