export enum APICode {
  OK = 200,
  ACCEPTED = 201, // 接受了但是未处理完成或出现了错误

  REDIRECT = 302, // 需要跳转

  PARAM_ERROR = 400, // 参数传入错误
  NOT_LOGIN = 401, // 客户端未提供授权信息
  NOT_AUTH = 403, // 没有权限访问
  NOT_FOUND = 404, // 资源未找到
  CONFLICT = 409, // 客户端提交的数据过于陈旧，或者已经存在

  SERVER_ERROR = 500, // 服务器内部错误
  NOT_IMPLEMENTED = 501, // 服务器未实现这个功能
  SERVICE_UNAVAILABLE = 503, // 服务器繁忙，未能处理这个问题
}
