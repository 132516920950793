import { Button } from "@shopify/polaris"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { DashboardSetupGuideEnum } from "@/enums/dashboard"
import StepWrapper from "@/pages/Dashboard/components/QuickSetupGuide/StepWrapper"

type IProps = {
  active: boolean
  isDone?: boolean
  step: DashboardSetupGuideEnum
  toggleCompleted: (status: DashboardSetupGuideEnum, completed: boolean) => Promise<any>
  titleClick?: () => void
}

const DropshippingMode = (props: IProps) => {
  const { t } = useTranslation(["dashboard", "common"])
  const navigate = useNavigate()
  const { isDone, active, step, toggleCompleted } = props


  useEffect(() => {
    if (active) {
      if (typeof toggleCompleted === "function" && !isDone) {
        toggleCompleted(step, true)
      }
    }
  }, [active])

  return <StepWrapper
    {...props}
    title={t("dashboard:DropshippingMode")}
    type={"svg"}
    mediaNode={<img src="https://cdn.parcelpanel.com/images/guide2.svg" />}
  >
    {t("dashboard:DropshippingModeDesc")}

    <div className={"mt-4 flex items-center"}>
      <Button external onClick={() => navigate("/settings", { state: { dropShippingModeTip: true } })}>
        {t("dashboard:GoToEnable")}
      </Button>
    </div>
  </StepWrapper>
}

export default DropshippingMode
