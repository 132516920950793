import * as React from "react"

const SvgGoodFace = (props: any) => (
  <svg
    className="good_face_svg__icon"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}
  >
    <path
      d="M512 64c247.424 0 448 200.576 448 448S759.424 960 512 960 64 759.424 64 512 264.576 64 512 64zm134.241 544.967-.228.477C621.786 661.25 569.922 695 511.985 695c-57.336 0-108.728-33.056-133.268-83.956l-.735-1.548c-7.493-16.006-26.543-22.908-42.549-15.415-16.006 7.493-22.907 26.542-15.415 42.549C354.68 710.675 428.972 759 511.985 759c83.043 0 157.356-48.359 192.002-122.444 7.487-16.01.578-35.056-15.431-42.543-15.85-7.412-34.676-.715-42.315 14.954zM358 377c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm308 0c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46z"
      fill="currentColor"
    />
  </svg>
)

export default SvgGoodFace
