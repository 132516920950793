import { Icon, Text, UnstyledButton } from "@shopify/polaris"
import { ArrowDownIcon, ArrowUpIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import type { ReactNode } from "react"
import React from "react"

import styles from "./DirectionButton.module.scss"

type DirectionButtonDirection = "asc" | "desc";

export interface DirectionButtonProps {
  onClick: (value: string[]) => void;
  active: boolean;
  children: ReactNode;
  direction: DirectionButtonDirection;
  value: string;
}

export function DirectionButton({
  onClick,
  active,
  children,
  direction,
  value,
}: DirectionButtonProps) {
  const classes = classNames(
    styles.DirectionButton,
    active && styles["DirectionButton--active"],
  )

  function handleClick() {
    onClick([value])
  }

  return (
    <UnstyledButton
      className={classes}
      onClick={handleClick}
    >
      <Icon
        source={direction === "asc" ? ArrowUpIcon : ArrowDownIcon}
        tone="base"
      />
      <Text
        variant="bodySm"
        fontWeight="medium"
        as="span"
      >
        {children}
      </Text>
    </UnstyledButton>
  )
}
