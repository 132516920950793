import { Button as BridgeButton, History, Modal as BridgeModal } from "@shopify/app-bridge/actions"
import { Style } from "@shopify/app-bridge/actions/Button"
import { Modal } from "@shopify/polaris"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { usePrompt } from "@/hooks/usePrompt"
import stores from "@/stores"
import AppBridge, { getAppLink } from "@/utils/appBridge"

type TypeProps = {
  when: boolean
  title?: string
  message?: string
  backUrl?: string
  secondaryLabel?: string
  isShopifyEmbedded?: boolean
  isTrackingPage?: boolean
  confirm?: () => void
}

export default function UnsavedPrompt({
  when,
  title,
  message,
  confirm,
  backUrl,
  isShopifyEmbedded,
  secondaryLabel,
  isTrackingPage,
}: TypeProps): JSX.Element {
  const { t } = useTranslation(["settings", "common"])
  const [open, setOpen] = useState(false)
  const [blockRefCurrent, setBlockRefCurrent] = useState( { action: "", location: { pathname: "" } })
  const navigate = useNavigate()
  const { globalMenuStore, commonStore } = stores

  const changeMenu = (location: { pathname: string }) => {
    const targetTab = globalMenuStore.tabs.find(item => {
      const path = getAppLink(item.id)

      return location.pathname.includes(path)
    })

    if (targetTab) {
      const targetLink = AppBridge.navigationMenu.children.find(nav => nav?.destination === getAppLink(targetTab?.id))

      targetLink && AppBridge.navigationMenu.set({ active: targetLink })

      const history = History.create(AppBridge.app)

      history.dispatch(History.Action.PUSH, location.pathname)
    }
  }

  const pushUrl = (current: { action: any; location: any }) => {
    const { action, location } = current || {}

    if (action && action === "push") {
      setTimeout(() => {
        // if (isShopifyEmbedded) changeMenu(location)
        navigate(location.pathname.includes(getAppLink())
          ? location.pathname.replace(getAppLink(), "/")
          : location.pathname)
      }, 10) // 为了确保ContextualSaveBar隐藏之后执行，这里增加短暂延迟
    } else {
      // 这里需要加入异步等when状态更新才会执行
      backUrl && setTimeout(() => navigate(backUrl))
    }
  }

  usePrompt(([current]) => {
    // AppBridge subscribe事件不可用BlockRefCurrent，因为会为更新，所以Polaris Modal用BlockRefCurrent,AppBridge使用currentData
    const currentData = { action: "push", location: current }

    setBlockRefCurrent(currentData)
    
    if (isTrackingPage && current.pathname && current.pathname.includes(getAppLink("/tracking-page"))) {
      commonStore.setContextualSaveBar("unlocking", true)
      pushUrl(currentData)
      setTimeout(() => commonStore.setContextualSaveBar("unlocking", false))
      return true
    }

    if (isShopifyEmbedded) {
      const okButton: BridgeButton.Button = BridgeButton.create(AppBridge.app, {
        label: t("settings:EmailTemplateEditor.UnsavedModal.PrimaryButton"),
        style: Style.Danger,
      })
      const cancelButton: BridgeButton.Button = BridgeButton.create(AppBridge.app, { label: secondaryLabel || "Stay" })

      cancelButton.subscribe(BridgeButton.Action.CLICK, () => {
        current.pathname && changeMenu({ pathname: current.pathname })
        AppBridge.dispatchModal(BridgeModal.Action.CLOSE)
      })

      okButton.subscribe(BridgeButton.Action.CLICK, () => {
        AppBridge.dispatchModal(BridgeModal.Action.CLOSE)
        typeof confirm === "function" && confirm()

        setOpen(false)

        commonStore.setContextualSaveBar("show", false)
        pushUrl(currentData)
      })

      AppBridge
        .modal()
        .set({
          footer: { buttons: { primary: okButton, secondary: [cancelButton] } },
          message,
          title,
        })
        .dispatch(BridgeModal.Action.OPEN)
      return false
    }

    setOpen(true)
    return false
  }, when)

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={title || t("settings:EmailTemplateEditor.UnsavedModal.title")}
      primaryAction={{
        content: t("settings:EmailTemplateEditor.UnsavedModal.PrimaryButton"),
        onAction: () => {
          const { action, location } = blockRefCurrent || {}

          typeof confirm === "function" && confirm()
          setOpen(false)
          if (action && action === "push") {
            setTimeout(() => {
              navigate(location.pathname.includes(getAppLink())
                ? location.pathname.replace(getAppLink(), "/")
                : location.pathname)
            })
          } else {
            // 这里需要加入异步等when状态更新才会执行
            backUrl && setTimeout(() => navigate(backUrl))
          }
        },
        destructive: true,
      }}
      secondaryActions={[
        {
          content: secondaryLabel || t("common:Cancel"),
          onAction: () => setOpen(false),
        },
      ]}
    >
      <Modal.Section>
        {message || t("settings:EmailTemplateEditor.UnsavedModal.content")}
      </Modal.Section>
    </Modal>
  )
}
