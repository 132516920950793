import * as React from "react"

const SvgBadFace = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    style={{
      enableBackground: "new 0 0 32 32",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M16 2c7.7 0 14 6.3 14 14s-6.3 14-14 14S2 23.7 2 16 8.3 2 16 2zm-4.2 21.2c.8-1.6 2.4-2.7 4.2-2.7 1.8 0 3.4 1 4.2 2.6.2.5.8.7 1.3.5.5-.2.7-.8.5-1.3-1.1-2.3-3.4-3.8-6-3.8s-4.9 1.5-6 3.8c-.2.5 0 1.1.5 1.3.5.3 1.1 0 1.3-.4zm-.6-11.4c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4 1.4-.6 1.4-1.4-.6-1.4-1.4-1.4zm9.6 0c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4 1.4-.6 1.4-1.4-.6-1.4-1.4-1.4z"
      style={{
        fill: "currentColor",
      }}
    />
  </svg>
)

export default SvgBadFace
