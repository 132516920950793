import { BlockStack, Button, Card, InlineStack, SkeletonBodyText, Text, Tooltip } from "@shopify/polaris"
import { ClipboardIcon, EditIcon } from "@shopify/polaris-icons"
import copy from "copy-to-clipboard"
import dayjs from "dayjs"
import { get, isArray, isEmpty } from "lodash-es"
import { Observer } from "mobx-react"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

import OrdersAPI from "@/api/orders"
import { ITrackingResultItem } from "@/api/TP2/tracking-info"
import { hasPermission } from "@/component"
import PermissionBadge from "@/component/PermissionBadge"
import TagList from "@/component/TagList/TagList"
import { OrderSyncToPayPal } from "@/enums/order-status"
import Permission from "@/enums/permission"
import { Mode } from "@/models/IOrderTagsManageStore"
import AddTagInput from "@/pages/Orders/components/OrderInfo/AddTagInput"
import CourierInfo from "@/pages/Orders/components/OrderInfo/CourierInfo/CourierInfo"
import { ShipmentItems } from "@/pages/Orders/components/OrderInfo/index"
import InfoItem from "@/pages/Orders/components/OrderInfo/InfoItem/InfoItem"
import stores from "@/stores"
import { navigateToCustomerById, navigateToOrderById } from "@/utils/redirectShopify"
import toast from "@/utils/toast"

// edd 来源的枚举
export enum ProviderSourceEnum
{
  ETA = "ETA",
  SHOPIFY = "SHOPIFY",
  CARRIER = "CARRIER",
  CUSTOM = "CUSTOM",
}

interface ShipmentInfoProps{
  tracking?: ITrackingResultItem
}
export default function ShipmentInfo({
  tracking,
}:ShipmentInfoProps){
  const {
    ordersStore: { orderInfoDataLoadedTime, orderInfoData },
    userInfoSore,
    orderTagsManageStore,
  } = stores
  const { t } = useTranslation("orders")
  const { t: commonT } = useTranslation("common")
  const orderDate = get(tracking, "order_create_time", "-")
  const fulfilledAt = get(tracking, "order_fulfill_time")
  const transitDay = get(tracking, "transitDay")
  const residenceDay = get(tracking, "residenceDay")
  const shippingTimeCon = get(tracking, "shipping_time_con")
  const customerID = get(orderInfoData, "customer_id")
  const customerName = get(orderInfoData, "customer_name", "-")
  const phoneNumber = get(orderInfoData, "phone_number")
  const customerEmail = get(orderInfoData, "email", "-")
  const trackingNumber = get(tracking, "track_number") as string
  const dataLoading = orderInfoDataLoadedTime === 0

  useEffect(() => {
    userInfoSore.data.timezone && dayjs.tz.setDefault(userInfoSore.data.timezone)
    orderTagsManageStore.fetchTagsData()
  }, [])

  const dataList = [
    {
      filed: t("OrderNumber"),
      value: <Button
        onClick={() => navigateToOrderById(String(get(orderInfoData, "order_id")))}
        variant="plain"
      >{get(orderInfoData, "order", "-")}</Button>,
    },
    {
      filed: t("OrderDate"),
      value: orderDate === "-" ? orderDate : dayjs.unix(orderDate)
        .tz()
        .format("MMM DD, hh:mm a"),
    },
    {
      filed: t("FulfillmentDate"),
      value: fulfilledAt ? dayjs.unix(fulfilledAt)
        .tz()
        .format("MMM DD, hh:mm a") : "-",
    },
    {
      filed: t("ShipmentStatus"),
      value: get(tracking, "status", "-") || "-",
    },
    {
      filed: t("TransitTime"),
      value: transitDay ? t("OrderInfo.nDay", { count: transitDay }) : "-",
    },
    {
      filed: t("ResidenceTime"),
      value: residenceDay ? t("OrderInfo.nDay", { count: residenceDay }) : "-",
    },
  ]

  // 连接paypal后展示详情的订单同步状态
  if (stores.userInfoSore.data.is_paypal_connected) {
    // @ts-ignore
    const status = get<ITrackingResultItem>(tracking, "paypalSyncStatus")
    let statusValue = "-"

    switch (status) {
      case OrderSyncToPayPal.OtherPaymentMethods:
        statusValue = t("OtherPaymentMethods")
        break
      case OrderSyncToPayPal.SyncedSuccessfully:
        statusValue = t("SyncedSuccessfully")
        break
      case OrderSyncToPayPal.SyncFailed:
        statusValue = t("SyncFailed")
        break
      case OrderSyncToPayPal.Unsynced:
        statusValue = t("Unsynced")
        break
    }

    dataList.push({
      filed: t("PayPalSyncStatus"),
      value: statusValue,
    })
  }

  const loading = <BlockStack gap="400">{
    new Array(3).fill("")
      .map((_, index) => <Card key={index}><SkeletonBodyText lines={12} /></Card>)
  }</BlockStack>

  const ProviderSourceMap: Record<ProviderSourceEnum, string> = {
    [ProviderSourceEnum.ETA]: t("SourcedFromETA"),
    [ProviderSourceEnum.SHOPIFY]: t("SourcedFromShopify"),
    [ProviderSourceEnum.CARRIER]: t("SourcedFromCarrier"),
    [ProviderSourceEnum.CUSTOM]: t("SourcedFromCustom"),
  }

  return (
    <Observer>{() => dataLoading
      ? loading
      : <BlockStack gap="400">
        <Card>
          <BlockStack gap="400">
            {/* ShipmentInfo */}
            <BlockStack gap="200">
              <Text as="h2" variant="headingSm">
                {t("ShipmentInfo")}
              </Text>

              {!isEmpty(trackingNumber) ? (
                <CourierInfo
                  courierName={get(tracking, "carrier_name") as string}
                  courierURL={get(tracking, "url") as string}
                  courierLogoURL={get(tracking, "img") as string}
                  trackingNumber={trackingNumber}
                  copyTrackingNumberTooltip={t("CopyTrackingNumber")}
                  onCopyTrackingNumber={() => toast(commonT("CopiedSuccessfully"))}
                />
              ) : (<div style={{ marginTop: "var(--p-space-200)", marginBottom: "var(--p-space-200)" }}>-</div>)}

              {dataList.map(({ filed, value }, index) => (
                <InfoItem key={index} field={filed} content={value} />
              ))}
            </BlockStack>

            {/* EstimatedDeliveryTime */}
            <BlockStack gap="200">
              <InlineStack>
                <Text as="h2" variant="headingSm">
                  <div className={"flex items-center"}>
                    {t("EstimatedDeliveryTime")}
                    {!hasPermission(Permission.EstimatedDeliveryTime)
                        && <div style={{ display: "inline-block" }}>
                          <PermissionBadge text={"Essential"} permission={Permission.EstimatedDeliveryTime} />
                        </div>}
                  </div>
                </Text>
              </InlineStack>

              <div className={"flex flex-col gap-1"}>
                {shippingTimeCon ? (
                  <>
                    <Text as="p" variant="bodyMd" fontWeight="regular">
                      {shippingTimeCon || "-"}
                    </Text>

                    <Text as="p" variant="bodyMd" tone={"subdued"}>
                      {tracking ? ProviderSourceMap[tracking.edd.source] : ""}
                    </Text>
                  </>
                ) : (
                  <Text as="p" variant="bodyMd">-</Text>
                )}
              </div>
            </BlockStack>
          </BlockStack>
        </Card>

        <Card>
          <BlockStack gap="400">
            <BlockStack gap="200">
              <Text as="h2" variant="headingSm">
                {t("Customer")}
              </Text>
              <Text as="p" variant="bodyMd" fontWeight="regular">
                {customerID ?
                  (<Button
                    variant="plain" target="_blank"
                    onClick={() => navigateToCustomerById(String(customerID))}
                  >{customerName}</Button>)
                  : customerName || "-"
                }
              </Text>
            </BlockStack>

            <BlockStack gap="200">
              <Text as="h2" variant="headingSm">
                {t("ContactInformation")}
              </Text>

              <div className={"flex flex-col gap-1"}>
                <InfoItem
                  field={customerEmail || "-"}
                  fieldTone={"base"}
                  content={!isEmpty(customerEmail) ? (
                    <Tooltip content={t("CopyEmail")}>
                      <Button variant="plain" size={"slim"} icon={ClipboardIcon} onClick={() => {
                        copy(String(customerEmail))
                        toast(commonT("CopiedSuccessfully"))
                      }}
                      />
                    </Tooltip>
                  ) : (<></>)}
                />

                <InfoItem
                  field={phoneNumber || "-"}
                  fieldTone={"base"}
                  content={!isEmpty(phoneNumber) ? (
                    <Tooltip content={t("CopyPhoneNumber")}>
                      <Button variant="plain" size={"slim"} icon={ClipboardIcon} onClick={() => {
                        copy(String(phoneNumber))
                        toast(commonT("CopiedSuccessfully"))
                      }}
                      />
                    </Tooltip>
                  ) : (<></>)}
                />
              </div>
            </BlockStack>

            <BlockStack gap="200">
              <Text as="h2" variant="headingSm">
                {t("ShippingAddress")}
              </Text>

              <Text breakWord as="p" variant="bodyMd">
                {orderInfoData?.shipping_address || "-"}
              </Text>
            </BlockStack>
          </BlockStack>
        </Card>

        <Card>
          <BlockStack gap="200">
            <InlineStack align="space-between">
              <Text as="h2" variant="headingSm">
                {t("Tags")}
              </Text>

              <Button variant="tertiary" icon={EditIcon} onClick={() => {
                stores.orderTagsManageStore.mode = Mode.ADD_TAGS
                stores.orderTagsManageStore.shipmentCount = 1
                stores.orderTagsManageStore.shipmentSelectedItems = [`${tracking?.nid}`]
                stores.orderTagsManageStore.queryParams = undefined
                stores.orderTagsManageStore.setOpen(true, true)
              }}
              />
            </InlineStack>

            <AddTagInput numberID={tracking?.nid} />

            <OrderTags numberID={tracking?.nid} />
          </BlockStack>
        </Card>

        <ShipmentItems
          product_urls={convertToArray(tracking?.product_urls ?? [])}
          title={convertToArray(tracking?.title ?? [])}
          titleImg={convertToArray(tracking?.title_img ?? [])}
        />
      </BlockStack>
    }</Observer>
  )
}

const convertToArray = (val: any) => isArray(val) ? val : []

function OrderTags({ numberID }: { numberID: number|null|undefined }) {
  return (<Observer>{() => {
    const { orderTags } = stores.ordersStore.orderInfoData || {}

    const handleRemove = useCallback((tag: string) => {
      OrdersAPI
        .manageOrderTags({
          action: "REMOVE",
          number_ids: [numberID],
          tags: [tag],
        })
        .then(({ data }) => {
          if (data.code === 200) {
            stores.ordersStore.removeTagsToShipments([tag], [])
          }
        })
    }, [numberID])

    return !orderTags?.length || !numberID ? (<></>) : (
      <TagList tags={orderTags} onRemove={handleRemove} />
    )
  }}</Observer>)
}
