import { Button, LegacyCard, Text } from "@shopify/polaris"
import classNames from "classnames"
import { t } from "i18next"
import { Observer, observer } from "mobx-react"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { IRouter, SimpleTableListFields, Spacing, withRouter } from "@/component"
import BlockMaskLayer from "@/component/BlockMaskLayer/BlockMaskLayer"
import CasingPly from "@/component/CasingPly/CasingPly"
import DynamicLoadError from "@/component/DynamicLoadError/DynamicLoadError"
import stores from "@/stores"
import { LanguageUtils } from "@/utils/languages"
import { IListenerNameEnum } from "@/utils/languages.d"
import { MathUtils } from "@/utils/MathUtils"

import SimpleTableList from "../../SimpleTableList"
import STableStyles from "../../SimpleTableList/index.module.scss"
import SpinnerContainer from "../../SpinnerContainer/SpinnerContainer"
import styles from "./index.module.scss"

interface IProps extends WithTranslation<"dashboard"> {
  router?: IRouter
}

// const ReactECharts = React.lazy(() => import("echarts-for-react"))
const DashboardLineChart = React.lazy(() => import("@/component/Charts/DashboardLineChart"))

// import DashboardLineChart from "@/component/Charts/DashboardLineChart"

@withRouter
@observer
class ShippingNotificationsChart extends React.Component<IProps, any> {
  public componentDidMount() {
    // 因为切换语言，数据未改变，不会触发组件更新导致内容未翻译，所以用此方法触发
    LanguageUtils.addListener(IListenerNameEnum.ShippingNotificationsListener, function () {
      stores.shippingNotificationsDataStore.setLegendData([t("ShippingNotificationsChart.title")])
    })
  }

  public componentWillUnmount() {
    LanguageUtils.removeListener(IListenerNameEnum.ShippingNotificationsListener)
  }

  render() {
    const { t } = this.props

    return (
      <Observer>{() => {
        const { userInfoSore } = stores,
          { shippingNotificationsDataStore } = stores

        const loadingData = shippingNotificationsDataStore?.loadedDataTime === 0,
          emailSendAll = shippingNotificationsDataStore?.emailSendAll || "-"

        let showBlockMaskLayer = false

        if (!loadingData) {
          showBlockMaskLayer = userInfoSore?.isFreeUser === true

          // 如果是付费老用户，就不显示
          if (userInfoSore?.isOldUser && !userInfoSore?.oldPayingUsers) {
            showBlockMaskLayer = true
          }
        }

        return <>
          <LegacyCard.Section>
            <CasingPly
              show={loadingData}
            >
              <p
                className={styles.CardSection__Allocation}
              >{MathUtils.noDashConvertThousands(emailSendAll)}</p>

              {this.DataTable()}

              <p className={styles.Chart__Subhead}>
                <Text as="span" tone="subdued"  variant={"bodySm"} fontWeight={"semibold"}>
                  {t("ShippingNotificationsChart.subTitle")}
                </Text>
              </p>
            </CasingPly>

            <BlockMaskLayer
              show={showBlockMaskLayer}
              maskNode={(
                <div className={classNames(["flex", "items-center", "flex-col", "-mt-20", "mb-0"])}>
                  <p>{t("ShippingNotificationsChart.enableMsg")}</p>
                  <Spacing />
                  <Button

                    onClick={() => this.props.router?.navigate("/settings", { state: { regional_tips: true } })}
                    variant="primary"
                  >
                    {t("ShippingNotificationsChart.enableThisFeature")}
                  </Button>
                </div>
              )}
            >
              <SpinnerContainer
                loading={loadingData}
                spinnerClassName={["relative"]}
              >
                <DynamicLoadError errorMsg={"Network error, unable to load charts"}>
                  <React.Suspense fallback={
                    <SpinnerContainer loading={!loadingData}>
                      <div className={styles.CardSection__ReactECharts} />
                    </SpinnerContainer>
                  }
                  >
                    <DashboardLineChart
                      className={styles.CardSection__ReactECharts}
                      option={shippingNotificationsDataStore?.options}
                    />
                  </React.Suspense>
                </DynamicLoadError>
              </SpinnerContainer>
            </BlockMaskLayer>
          </LegacyCard.Section>

        </>
      }
      }</Observer>
    )
  }

  DataTable() {
    const { t } = this.props
    const percentage = stores.shippingNotificationsDataStore.recipients,
      { emailSendSuc } = stores.shippingNotificationsDataStore,
      { emailSendAll } = stores.shippingNotificationsDataStore,
      { emailSendOpen } = stores.shippingNotificationsDataStore,
      { emailSendClick } = stores.shippingNotificationsDataStore


    const emailSendSucStr = emailSendSuc || "-",
      percentageStr = percentage || "-",
      emailSendOpenStr = emailSendOpen || "-",
      emailSendClickStr = emailSendClick || "-"

    const fields: Array<SimpleTableListFields> = [
      { field: "title", title: "title" },
      { className: STableStyles.AlignRight, field: "val", title: "Val" }, // 第2列
      { className: STableStyles.AlignRight, field: "percentage", title: "percentage" }, // 第3列
    ],
      data = [
        {
          percentage: MathUtils.noDashConvertThousands(percentageStr),
          title: t("ShippingNotificationsChart.recipients"),
          val: "",
        },
        {
          percentage: MathUtils.getPercentage(emailSendSuc || 0, emailSendAll || 0),
          title: t("ShippingNotificationsChart.deliveryPerformance"),
          val: MathUtils.noDashConvertThousands(emailSendSucStr),
        },
        {
          percentage: MathUtils.getPercentage(emailSendOpen || 0, emailSendAll || 0),
          title: t("ShippingNotificationsChart.openRate"),
          val: MathUtils.noDashConvertThousands(emailSendOpenStr),
        },
        {
          percentage: MathUtils.getPercentage(emailSendClick || 0, emailSendAll || 0),
          title: t("ShippingNotificationsChart.clickRate"),
          val: MathUtils.noDashConvertThousands(emailSendClickStr),
        },
      ]

    return (<SimpleTableList noHead fields={fields} data={data} />)
  }
}

export default withTranslation("dashboard")(ShippingNotificationsChart)

