import { Autocomplete } from "@shopify/polaris"
import { PlusCircleIcon } from "@shopify/polaris-icons"
import { Observer } from "mobx-react"
import { useTranslation } from "react-i18next"

import stores from "@/stores"
import { compareArrays } from "@/utils/arrayUtils"

interface AddTagInput {
  numberID: number|null|undefined
}
export default function AddTagInput({
  numberID,
}:AddTagInput) {
  const { t } = useTranslation("orders")
  const { orderTagsManageStore, ordersStore } = stores

  return <Observer>{() => {
    const { tagInputValue, handleTagInputChange, tagOptions, loading } = orderTagsManageStore
    const hasSameTag = !!tagOptions.find(item => item.label === tagInputValue)

    const { orderTags: selectedOptions } = ordersStore.orderInfoData || {}

    const updateSelection = (selected: string[]) => {
      const [addTags, removeTags ] = compareArrays(selectedOptions || [], selected)

      addTags.length && orderTagsManageStore.handleAddTags(addTags, numberID)
      removeTags.length && orderTagsManageStore.handleRemoveTags(removeTags, numberID)
    }

    return <Autocomplete
      actionBefore={tagInputValue === "" || hasSameTag ? undefined : {
        accessibilityLabel: "Action label",
        content: t("TagModal.actions.content", { tag: tagInputValue }),
        icon: PlusCircleIcon,
        wrapOverflow: true,
        onAction: () => {
          orderTagsManageStore.handleAddTags([tagInputValue], numberID)
          handleTagInputChange("")
        },
      }}
      allowMultiple
      options={tagInputValue && !loading ? tagOptions : []}
      selected={selectedOptions || []}
      loading={loading}
      onSelect={updateSelection}
      preferredPosition={"below"}
      textField={<Autocomplete.TextField
        onChange={handleTagInputChange}
        label="Tags"
        labelHidden
        clearButton
        onClearButtonClick={() => handleTagInputChange("")}
        value={tagInputValue}
        autoComplete="off"
      />}
    />
  }}</Observer>
}
