import { Divider, EmptySearchResult, LegacyCard, Tabs } from "@shopify/polaris"
import classNames from "classnames"
import React, { Component } from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import settingsApi from "@/api/settings"
import { ArrowSortType, CourierMatchingTable, SpinnerContainer } from "@/component"
import CardTitle from "@/component/TitleGroup/CardTitle"
import { IListenerNameEnum, LanguageUtils } from "@/utils/languages"
import toast from "@/utils/toast"

export enum TabId {
  Enabled = "Enabled",
  Disabled = "Disabled",
}


export interface ICourierItem {
  express: string;
  name: string;
  img: string;
}


export interface IState {
  isFirstGetCourierListData: boolean;
  courierTabs: any;
  loading: boolean;
  selectedCourierTabIndex: number;
  perPage: number;
  selectedCourierTabId: TabId;
  couriers: { [key in TabId]: ICourierItem[] };
  selectedCouriers: string[];
  queryValue: string;
}

type IProps = WithTranslation<"settings" | "common">

class CourierMatching extends Component<IProps, IState> {

  state: Readonly<IState> = {
    courierTabs: [{ content: TabId.Enabled, id: TabId.Enabled }, { content: TabId.Disabled, id: TabId.Disabled }],

    couriers: { [TabId.Enabled]: [], [TabId.Disabled]: [] },
    isFirstGetCourierListData: true,
    loading: true,
    perPage: 20,
    queryValue: "",

    selectedCourierTabId: TabId.Enabled,

    selectedCourierTabIndex: 0,

    selectedCouriers: [],
  }

  idForItem = (item: any) => item.express

  componentDidMount() {
    this.requestCourierListData()
    this.rerenderTabsContent()
    LanguageUtils.addListener(IListenerNameEnum.CourierMatchingListener, () => this.rerenderTabsContent())
  }

  public componentWillUnmount() {
    LanguageUtils.removeListener(IListenerNameEnum.CourierMatchingListener)
  }

  /**
   * 渲染 Tab 文本
   */
  rerenderTabsContent() {
    const { t } = this.props

    this.setState({
      courierTabs: [
        { content: t(TabId.Enabled, { ns: "common" }), id: TabId.Enabled },
        { content: t(TabId.Disabled, { ns: "common" }), id: TabId.Disabled },
      ],
    })
  }

  requestCourierListData(isCache = true) {
    // const {selectedCourierTabId, selectedCouriers} = this.state

    setTimeout(() => {
      settingsApi.getCouriersList()
        .then(({ data }) => {
          const { enable, disable } = data.couriers

          this.setState({
            couriers: {
              [TabId.Enabled]: enable,
              [TabId.Disabled]: disable,
            },

            loading: false,

            selectedCouriers: [],
          })

          if (this.state.isFirstGetCourierListData) {
            this.setState({ isFirstGetCourierListData: false })
            !enable.length && this.handleCourierTabSelect(1)
          }
        })
        .finally(() => {
          this.setState({
            loading: false,
          })
        })

    }, 600)
  }

  /** 处理运输商排序 */
  handleCourierSortChange = (ids: string[], mode: ArrowSortType) => {
    const { selectedCourierTabId, loading, couriers } = this.state

    if (loading) {
      return
    }

    const TYPE = { [ArrowSortType.Up]: "up", [ArrowSortType.Down]: "down" }

    this.setState({
      loading: true,
    })

    settingsApi.setSort({
      courier: ids,
      type: TYPE[mode],
    })
      .then(({ data: { code, msg } }) => {
        if (code !== APICode.OK) {
          return
        }

        toast(msg, 1500)

        const list = couriers[selectedCourierTabId]

        const id0 = list.findIndex(item => ids[0] === item.express)
        const id1 = list.findIndex(item => ids[1] === item.express)

        this.setState({
          couriers: {
            ...couriers,
            [selectedCourierTabId]: Object.assign([], list, {
              // 交换两项
              [id0]: list[id1],
              [id1]: list[id0],
            }),
          },
          loading: false,
          selectedCouriers: [],  // 要在这里设置 loading 为 false，不然会看到复选框是灰色的
        })

      })
    // 暂时先注释掉了，如果要处理接口异常情况则到时候统一处理
    // .finally(() => {
    //   this.setState({
    //     loading: false,
    //   })
    // })
  }

  /** 处理 enable 运输商 */
  handleCourierEnable = (enabledList: string[]) => {
    this.setState({
      loading: true,
    })

    settingsApi.setEnable(enabledList)
      .then(({ data: { code, msg } }) => {
        if (code !== APICode.OK) {
          return
        }

        toast(msg, 1500)

        this.requestCourierListData(false)
      })


    // settingsApi.setEnable({ enabledList })
    //   .then(({ data }) => {
    //
    //     const { enable, disable } = data.couriers
    //
    //     this.setState({
    //       couriers: {
    //         [TabId.Enabled]: enable,
    //         [TabId.Disabled]: disable,
    //       },
    //
    //       loading: false,
    //
    //       selectedCouriers: [],
    //     })
    //
    //     if (this.state.isFirstGetCourierListData) {
    //       this.setState({ isFirstGetCourierListData: false })
    //       !enable.length && this.handleCourierTabSelect(1)
    //     }
    //
    //     toast(t("CourierListUpdated"), 1500)
    //   })
  }

  /** 处理 disable 运输商 */
  handleCourierDisable = (disabledList: string[]) => {
    this.setState({
      loading: true,
    })

    settingsApi.setDisable(disabledList)
      .then(({ data: { code, msg } }) => {
        if (code !== APICode.OK) {
          return
        }

        toast(msg, 1500)

        this.requestCourierListData(false)
      })
  }

  /** 处理选项卡切换 */
  handleCourierTabSelect = (selectedCourierTabIndex: number) => {
    this.setState({
      queryValue: "",
      selectedCourierTabId: this.state.courierTabs[selectedCourierTabIndex].id,

      selectedCourierTabIndex,

      selectedCouriers: [],
    })
  }

  /** 处理运输商选择选项发生改变 */
  handleCourierSelectionChange = (v: any[]) => {
    if (Array.isArray(v)) {
      this.setState({
        selectedCouriers: v,
      })
    }
  }

  /** 接收查询的文本内容 */
  handleQueryChange = (queryValue: string) => {
    this.setState({
      queryValue,
    })
  }

  render() {
    const { t } = this.props
    const {
      courierTabs,
      selectedCourierTabIndex,
      selectedCourierTabId,
      loading,
      couriers,
      selectedCouriers,
      perPage,
      queryValue,
    } = this.state

    const couriersData = couriers[selectedCourierTabId] || []

    const emptyStateMarkup = TabId.Enabled === selectedCourierTabId ? (
      <EmptySearchResult
        title={t("CourierMatching.EmptySearchResult.title")}
        description={t("CourierMatching.EmptySearchResult.desc")}
        withIllustration
      />
    ) : (
      // 没有 description 时会多出一行 20px空白，需要利用上边距补上
      <div className="mt-4">
        <EmptySearchResult
          title={t("CourierMatching.EmptySearchResult.title")}
          withIllustration
        />
      </div>
    )

    return (
      <div id="CourierMatching" className={classNames("mt-4", "CourierMatching" + selectedCourierTabIndex)}>
        <LegacyCard
          title={<CardTitle title={t("CourierMatching.title")} description={t("CourierMatching.content")} />}
        >
          <Divider />
          <div className={"p-2"}>
            <Tabs
              tabs={courierTabs}
              selected={selectedCourierTabIndex}
              onSelect={this.handleCourierTabSelect}
            />
          </div>
          <Divider />
          <SpinnerContainer loading={loading}>
            <CourierMatchingTable
              emptyState={<div className={"py-4"}>{emptyStateMarkup}</div>}
              items={couriersData}
              arrowSortable={TabId.Enabled === selectedCourierTabId}
              enableButton={TabId.Disabled === selectedCourierTabId}
              perPage={TabId.Enabled === selectedCourierTabId ? 0 : perPage}
              selectedCouriers={selectedCouriers}
              idForItem={this.idForItem}
              onArrowSort={this.handleCourierSortChange}
              onDisable={this.handleCourierDisable}
              onEnable={this.handleCourierEnable}
              onSelectionChange={this.handleCourierSelectionChange}
              queryValue={queryValue}
              onQueryChange={this.handleQueryChange}
            />
          </SpinnerContainer>
        </LegacyCard>
      </div>
    )
  }
}

export default withTranslation("settings")(CourierMatching)
