import { LegacyCard, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris"
import React, { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { RecommendedAPPProp } from "@/component/Dashboard/RecommendedAPP/types"
import CardLoadErr from "@/component/DynamicLoadError/CardLoadErr"


const RecommendAppWrapper = React.lazy(() => import("./RecommendAppWrapper"))

export default function RecommendAppCard(props: RecommendedAPPProp) {

  const { t } = useTranslation(["dashboard", "common"])

  const navigate = useNavigate()

  return (
    <LegacyCard
      title={t("dashboard:RecommendedAPP.title")}
      actions={[{ content: t("common:ViewMore"), onAction: () => navigate("/recommended-app") }]}
    >
      <CardLoadErr>
        <Suspense fallback={
          <>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={5} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={5} />
              </TextContainer>
            </LegacyCard.Section>
          </>
        }
        >
          <RecommendAppWrapper lists={props.lists} />
        </Suspense>
      </CardLoadErr>
    </LegacyCard>
  )
}
