import { BannerResponse } from "@/api/common/types/Banner"
import { getSessionToken } from "@/api/common/utils"
import { IConvertWebVitalsData } from "@/reportWebVitals"
import baseRequest from "@/utils/baseRequest"

export default {
  getBanner(bannerId: number) {
    return baseRequest.get<BannerResponse>(`v4/common/banners/${bannerId}`)
  },

  updateBanner(bannerId: number, visible: boolean) {
    return baseRequest.put<BannerResponse>(`v4/common/banners/${bannerId}`, { banner: { visible } })
  },
}

/**
 * 上报数据
 * @param events
 */
export const sendWebVitals = (events: IConvertWebVitalsData[]) => {
  const { downlink = null, saveData = null, effectiveType = null, type = null, rtt = null } = navigator.connection || {}
  const connection = { downlink, saveData, effectiveType, type, rtt }

  let url = `${baseRequest.defaults.baseURL}v3/common/client_metrics`

  const body = JSON.stringify({
    connection,
    events,
    local: window.navigator.language,
    pathname: window.location.pathname,
    timezone: `UTC${(0 - new Date().getTimezoneOffset() / 60)}`,
  })

  getSessionToken()
    .then((token: string) => {
      url = `${url}?_jwt_token=${token}`

      if (navigator.sendBeacon) {
        navigator.sendBeacon(url, body)
      } else {
        fetch(url, { body, method: "POST", keepalive: true })
      }
    })
}
