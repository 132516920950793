import { Spinner } from "@shopify/polaris"
import classNames from "classnames"
import React from "react"

/**
 * 全屏加载效果
 * @param props
 * @constructor
 */
export default function FullScreenLoading(props: { loading?: boolean }) {
  const _loading = props?.loading || true

  return _loading ? (
    <div
      className={classNames(["flex items-center justify-center absolute top-0 bg-white bg-opacity-90 w-full h-full"])}
      style={{
        zIndex: 100,
      }}
    >
      <Spinner hasFocusableParent={false} />
    </div>
  ) : <></>
}
