interface IAdminPostRouteMessage {
  data: object
  from: "parcel_panel"
  type: "route" | "generate_session_token"
}

export enum AppActionName {
  SessionToken_Respond = "APP::SESSION_TOKEN::RESPOND",
  SelectLanguage_Respond = "APP::SELECT_LANGUAGE::RESPOND"
}

type ActionMap = {
  [AppActionName.SessionToken_Respond]: AppSessionTokenResponseData;
  [AppActionName.SelectLanguage_Respond]: AppSelectLanguageResponseData;
  // 添加其他 action 对应的类型...
};

type ActionType = keyof ActionMap;

export interface ReceivedData<T extends ActionType> {
  action: T;
  data: ActionMap[T];
}

export interface AppSessionTokenResponseData {
  expired_at: number
  token: string
}

export interface AppSelectLanguageResponseData {
  language: string
}


/**
 * 推送路由消息到超管后台
 *
 * @param path
 * @param params
 */
export function postRouteMessage(path: string, params = "") {
  if (!window.isFromAdmin || !window.parent) {
    console.warn("postRouteMessage::NO_FROM_ADMIN")
    return
  }

  const msg: IAdminPostRouteMessage = {
    data: {
      path,
      params,
    },
    from: "parcel_panel",
    type: "route",
  }

  window.parent?.postMessage(msg, "*")
}
