/* eslint-disable prefer-destructuring */
import { Spinner } from "@shopify/polaris"
import { Observer } from "mobx-react"
import React, { useEffect } from "react"

import APIApp from "@/api/app"
import stores from "@/stores"

const BoardToken = "b021933f-6ed5-481e-7e95-1abe3c8b61d4"

const CannyWrapper = () => {


  useEffect(() => {

    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0], e = d.createElement(s)

          e.type = "text/javascript", e.async = !0, e.src = "https://canny.io/sdk.js", f.parentNode.insertBefore(e, f)
        }
      }

      if ("function" !== typeof w.Canny) {
        const c = function (...args) {
          c.q.push(args)
        }

        c.q = [], w.Canny = c, "complete" === d.readyState ? l() : w.attachEvent ? w.attachEvent("onload", l) : w.addEventListener("load", l, !1)
      }
    })(window, document, "canny-jssdk", "script")


    async function requestBaseJWT() {
      try {

        const { data } = await APIApp.getCannyJWT()

        Canny("render", {
          basePath: "/get-help",
          boardToken: BoardToken,
          // See step 3
          onLoadCallback: () => {
            setTimeout(() => {
              stores.commonStore.setIsCannyLoaded(true)
            }, 1000)
          },

          // See step 2
          ssoToken: data.canny.ssoToken,
        })

      } catch (err) {
        // eslint-disable-next-line no-empty
      }
    }

    requestBaseJWT()

    return () => {
      stores.commonStore.setIsCannyLoaded(false)
    }
  }, [])


  return <Observer>{() => {
    const isLoaded = stores.commonStore.isCannyLoaded

    return (
      <div style={{
        height: !isLoaded ? "500px" : "",
      }}
      >
        {!isLoaded && <div className="h-full flex justify-center items-center">
          <Spinner hasFocusableParent={false} />
        </div>}
        {/* 这个div必须要存在才能渲染出内容 */}
        <div data-canny style={{
          display: isLoaded ? "block" : "none",
        }}
        />
      </div>
    )
  }}</Observer>
}


export default CannyWrapper
