import { History } from "@shopify/app-bridge/actions"
import { NavigateFunction } from "react-router"

import { PostTemplate } from "@/component/PostLayer/PostTemplate.d"
import { PublicModalId } from "@/component/PublicModal"
import stores from "@/stores"
import AppBridge, { getAppLink } from "@/utils/appBridge"
import TrackingPageUtils from "@/utils/TrackingPageUtils"

// 关于这个的用法可以参照：https://github.com/remix-run/history/blob/main/docs/navigation.md
// export const history = createBrowserHistory();

// interface HistoryRouterProps {
//   history: typeof history;
// }

// export const HistoryRouter: React.FC<HistoryRouterProps> = ({history, children}) => {
//   const [ state, setState ] = React.useState({
//     action:   history.action,
//     location: history.location,
//   });
//
//   console.log(history.location.pathname)
//   if (history.location.pathname === '/') {
//     stores.Post.showNumb = stores.Post.showNumb + 1
//     if (stores.Post.showNumb < 2) {
//       stores.Post.setPostTemplate(PostTemplate.GREET)
//     }
//   }
//   React.useLayoutEffect(() => {
//     history.listen(listener => {
//       const pathname = listener.location.pathname
//       pathnameHandle(pathname)
//       setState(listener)
//
//       let selectedTabIndex = _.map(stores.globalMenuStore.tabs, 'id').indexOf(pathname);
//       stores.globalMenuStore.selectTab(selectedTabIndex)
//
//       //跳转后，返回页面顶部
//       window.scrollTo(0, 0);
//     });
//   }, [ history ]);
//
//   React.useLayoutEffect(() => {
//     orderPost()
//   }, [ history.location.pathname ])
//
//   // const basename = import.meta.env.MODE !== 'development' ? '/app' : void 0
//   const basename = '/app'
//
//   // return React.createElement(Router, Object.assign({children, navigator: history, basename}, state));
//   return React.createElement(BrowserRouter, Object.assign({children, basename}, state));
// };

let orderPostTimer: NodeJS.Timeout

export const orderPost = (pathname: string, navigate: NavigateFunction, searchParams: URLSearchParams) => {
  orderPostTimer && clearTimeout(orderPostTimer)
  setTimeout(() => {
    switch (pathname) {
      case "/orders":
        orderPostTimer = setTimeout(() => {
          stores.Post.setPostTemplate(PostTemplate.ORDER_MESSAGE)
        }, 10 * 1000)
        break

      case "/":
        if (stores.Post.showNumb < 1) {
          stores.Post.showNumb = stores.Post.showNumb + 1
          stores.Post.setPostTemplate(PostTemplate.GREET)
        }
        break

      case "/account":
        if (searchParams.get("first_tries") === "true" && searchParams.get("tries") === "true") {
          navigate({ pathname: "/account" }, { replace: true })

          // MARK: chuwen 2022/6/16 18:07 配合 6 月份 POST 评论引导
          // stores.Post.setPostTemplate(PostTemplate.GET_GOOD_REVIEWS)

          // MARK: chuwen 2022/6/17 09:04 如果用户咩有留过五星评论，则进行弹窗引导
          stores.commonStore.openPublicModal(PublicModalId.UserFirstUpgradePlan)
        }
        // 是否为升降级套餐
        if (searchParams.get("ud_plan") === "true") {
          navigate({ pathname: "/account" }, { replace: true })
          stores.Post.setPostTemplate(PostTemplate.UPGRADE_DOWN_PLAN_POST)
        }
        break
    }
  }, 100)
}

export const pathnameHandle = (pathname: string) => {
  if (AppBridge.exist()) {
    // 参考文档：https://shopify.dev/tools/app-bridge/actions/navigation/history
    const history = History.create(AppBridge.app)

    // 避免重复触发
    if (getCurrentShopifyAppPath() !== pathname) {
      history.dispatch(History.Action.PUSH, getAppLink(pathname))
    }
  }

  if (!pathname.startsWith("/tracking-page")) {
    TrackingPageUtils.destroyListener(true)
  }
}

export const clearPathnameQuery = (pathname: string) => {
  if (AppBridge.exist()) {
    // 参考文档：https://shopify.dev/tools/app-bridge/actions/navigation/history
    const history = History.create(AppBridge.app)

    history.dispatch(History.Action.REPLACE, getAppLink(pathname))
  } else {
    // 清除查询字符串
    const cleanUrl = window.location.origin + window.location.pathname

    // 通过修改地址栏来更新 URL
    window.history.replaceState({}, document.title, cleanUrl)
  }
}

const historyRoute = {
  currentShopifyAppPath: window.location.pathname,
}

export const getCurrentShopifyAppPath = () => {
  return historyRoute.currentShopifyAppPath
}

export const setCurrentShopifyAppPath = (path: string) => {
  historyRoute.currentShopifyAppPath = path

  return path
}
