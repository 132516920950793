import { Modal, TextContainer, TextField } from "@shopify/polaris"
import { Observer } from "mobx-react"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import app from "@/api/app"
import stores from "@/stores"
import toast from "@/utils/toast"

import { FeedbackModalProps } from "./FeedbackModal.d"

export default function ({ onClose, show, rate = 0 }: FeedbackModalProps) {
  const { t } = useTranslation("common")

  const [email, setEmail] = useState<string>("")
  const [emailErr, setEmailErr] = useState<string>("")
  const emailHandleChange = (newValue: string) => {
    if (newValue === "" || checkEmail(newValue)) {
      setEmailErr("")
    }
    setEmail(newValue)
  }

  const [msg, setMsg] = useState<string>("")
  const [msgErr, setMsgErr] = useState<string>("")
  const msgHandleChange = (newValue: string) => {
    setMsg(newValue)
  }

  useEffect(() => {
    if (show) {
      setMsgErr("")
      setEmailErr("")
      if (msg === "") {
        document.getElementById("FeedbackModalTellUs")
          ?.focus()
      } else if (email === "") {
        document.getElementById("FeedbackModalEmail")
          ?.focus()
      }
    }
  }, [show])

  /** 邮件地址检测 */
  const checkEmail = (v: string) => {
    const partner = /^.+@.+\..+/

    return v && partner.test(v)
  }

  const [emailFlag, setEmailFlag] = useState<boolean>(false)
  const emailFlagHandleChange = useCallback((active) => setEmailFlag(active), [emailFlag])

  // modal 主按钮是否在加载中
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const toggleBtnLoading = useCallback(setBtnLoading, [])

  const { userInfoSore } = stores

  const checkEmailV = () => {
    setEmail(userInfoSore.data.user_email)
    setEmailErr("")
  }

  if ((!checkEmail(email) || !email) && !show && userInfoSore.data.user_email) {
    checkEmailV()
  }

  return (
    <>
      <Observer>{() =>
        <Modal
          open={show}
          onClose={() => {
            onClose && onClose()
            setMsgErr("")
            setEmailErr("")
          }}
          title={t("FeedbackModal.title")}
          primaryAction={{
            content: t("Send"),
            loading: btnLoading,
            disabled: msg === "" || email === "",
            onAction: () => {
              setMsgErr(msg ? "" : t("inputErrorBlank"))
              if (email) {
                setEmailErr(checkEmail(email) ? "" : t("InvalidEmail", { ns: "email_edit" }))
              } else {
                setEmailErr(t("inputErrorBlank"))
              }

              if (!msg || !email || !checkEmail(email)) {
                return
              }

              toggleBtnLoading(true)
              app.sendFeedback(!email ? userInfoSore.data.user_email : email, msg, "index-back", rate)
                .then(({ data: response }) => {
                  if (response.code === APICode.OK) {
                    toast(response.msg || "Sent successfully")
                    msgHandleChange("")
                    onClose && onClose()
                  }
                })
                .finally(() => toggleBtnLoading(false))
            },
          }}
          secondaryActions={[
            {
              content: t("Cancel"),
              onAction: () => {
                onClose && onClose()
                setMsgErr("")
                setEmailErr("")
              },
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <TextField
                id={"FeedbackModalTellUs"}
                label={t("FeedbackModal.TellUs")}
                value={msg}
                placeholder={t("FeedbackModal.EditMsg")}
                onChange={msgHandleChange}
                maxHeight={"10.6rem"}
                multiline={4}
                autoComplete="off"
              />

              {/* 当不存在值时，使用外面传来的邮箱 */}
              {!emailFlag && <TextField
                id="FeedbackModalEmail"
                type="email"
                error={emailErr}
                label={t("FeedbackModal.YourEmail")} value={userInfoSore.data.user_email}
                autoComplete="off"
                onChange={v => {
                  emailHandleChange(v)
                  emailFlagHandleChange(true)
                }}
              />}

              {/* 只要一存在，马上切换为真是输入框 */}
              {emailFlag && <TextField
                id="FeedbackModalEmail"
                type="email"
                label={t("FeedbackModal.YourEmail")} value={email}
                error={emailErr}
                placeholder="e.g. parcelpanel100@gmail.com"
                autoComplete="off" focused onChange={emailHandleChange}
                onBlur={() => {
                  if (email && !checkEmail(email)) {
                    setEmailErr(t("InvalidEmail", { ns: "email_edit" }))
                  }
                }}
              />}
            </TextContainer>
          </Modal.Section>
        </Modal>
      }</Observer>
    </>
  )
}
