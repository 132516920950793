import { Text } from "@shopify/polaris"
import classnames from "classnames"
import { useEffect, useMemo, useRef, useState } from "react"

import style from "./index.module.scss"

type radioOption = {
  value: string;
  label: string;
};

type ISwitchTabProps = {
  className?: string,
  radioOptions: Array<radioOption>;
  value: string;
  handleRadio: (value: string) => void;
  radioStyle?: React.CSSProperties;
  radioActiveBgBoxStyle?: React.CSSProperties;
};

const SwitchTab = (props: ISwitchTabProps) => {
  const {
    className,
    radioOptions,
    value,
    handleRadio,
    radioStyle,
    radioActiveBgBoxStyle,
  } = props

  // 保存每个Tab元素的 ref
  const itemRefs = useRef<HTMLDivElement[]>([])

  const [radioWidth, setRadioWidth] = useState(0)

  // 计算背景块宽度
  useEffect(() => {
    itemRefs.current = new Array(radioOptions.length).fill(null)
      .map((_, index) => itemRefs.current[index] || null)

    if (radioOptions.length) {
      let target: HTMLDivElement | null = null

      radioOptions.forEach((item, index) => {
        if (item.value === value) {
          target = itemRefs.current[index]
        }
      })

      if (target) setRadioWidth((target as HTMLDivElement).clientWidth)
    }

  }, [radioOptions])

  // 计算背景块偏移量
  const initialBgOffset = useMemo(() => {
    const index = radioOptions.findIndex((item) => item.value === value)

    if (index !== -1) {
      let offset = 0

      for (let i = 0; i < index; i++) {
        offset += itemRefs.current[i]?.clientWidth ?? 0
        if (offset !== 0) offset += 6 // 加4px的flexGap、加4px的padding，-1px border
      }
      return offset
    }
    return 0
  }, [value, radioOptions, itemRefs.current])

  return (
    <div className={classnames(style.radiosBoxBorderWrapper, className)}>
      <div className={style.radiosBox}>
        <div className={style.radioActiveBgBox} style={radioActiveBgBoxStyle}>
          {!!radioWidth && <div
            className={style.radioActiveBg}
            // 动画样式绑定
            style={{
              width: `${radioWidth}px`,
              transform: `translateX(${initialBgOffset}px)`,
            }}
          />
          }
        </div>

        {radioOptions.map((options, index) => {
          return (
            <div
              key={options.value}
              className={style.radioBox}
              ref={(el: HTMLDivElement) => (itemRefs.current[index] = el)}
              onClick={() => handleRadio(options.value)}
            >
              <div
                className={classnames({
                  [style.radio]: true,
                  [style.radioActive]: value === options.value,
                })}
                style={radioStyle}
              >
                <Text as={"span"} variant={"bodyMd"} fontWeight={"medium"}>
                  {options.label}
                </Text>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SwitchTab
