import { Icon, Spinner, Text, Tooltip } from "@shopify/polaris"
import classNames from "classnames"
import { Observer } from "mobx-react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import HoverCircle from "@/assets/svg/HoverCircle"
import { DashboardSetupGuideEnum } from "@/enums/dashboard"

import styles from "./index.module.scss"

type IProps = {
  type?: "svg"
  title: string
  active: boolean
  isDone?: boolean
  step: DashboardSetupGuideEnum
  toggleCompleted: (status: DashboardSetupGuideEnum, completed: boolean) => Promise<any>
  titleClick?: () => void
  children?: React.ReactNode
  mediaNode?: React.ReactNode | string
  node?: React.ReactNode | string
}

const MaskPicture = (props: { guidePic: React.ReactNode }) => {
  return <div className={styles.mediaWrapper}>
    <div className={styles.pictureWrapper}>
      {props.guidePic}
      <div className={styles.pictureMask}></div>
    </div>
  </div>
}

const StepWrapper = (props: IProps) => {
  const { t } = useTranslation(["dashboard", "common"])
  const [doneLoading, setDoneLoading] = useState(false)
  const { active, isDone, title, titleClick, children, mediaNode, toggleCompleted, step, type } = props

  const handleToggleDoneStatus = () => {
    setDoneLoading(true)
    if (typeof toggleCompleted === "function") {
      toggleCompleted(step, !isDone)
        .finally(() => setDoneLoading(false))
    }
  }

  const CheckCircleIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_22_1183)">
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#1A1A1A"
          />
          <path
            d="M15.2738 6.52629C15.6643 6.91682 15.6643 7.54998 15.2738 7.94051L9.4405 13.7738C9.05 14.1644 8.4168 14.1644 8.0263 13.7738L5.3596 11.1072C4.96908 10.7166 4.96908 10.0835 5.3596 9.693C5.75013 9.3024 6.38329 9.3024 6.77382 9.693L8.7334 11.6525L13.8596 6.52629C14.2501 6.13577 14.8833 6.13577 15.2738 6.52629Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_22_1183">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return <Observer>{() => <div className={classNames(styles.stepWrapper, active ? styles.active : "")}>
    <div className={styles.stepIcon}>
      {
        doneLoading
          ? <div className={styles.iconWrapper}><Spinner size="small" /></div>
          : <Tooltip content={isDone ? t("dashboard:MarkAsNotDone") : t("dashboard:MarkAsDone")} dismissOnMouseOut>
            <div className={styles.iconWrapper} onClick={handleToggleDoneStatus}>
              {isDone ? <Icon source={CheckCircleIcon} /> : <HoverCircle />}
            </div>
          </Tooltip>
      }
    </div>

    <div className={classNames(styles.content, active ? styles.activeContent : "")}>
      <div className={classNames(styles.textContent, active ? styles.activeTextContent : "")}>
        <div
          className={classNames(styles.stepTitle, active ? styles.activeStepTitle : "")}
          onClick={() => typeof titleClick === "function" && titleClick()}
        >
          {
            active
              ? <Text variant="headingSm" as="h6">{title}</Text>
              : <Text variant="bodyMd" as="h6">{title}</Text>
          }
        </div>

        {
          active && children && <div className={styles.desc}>{children}</div>
        }
      </div>

      {
        active && mediaNode && (type === "svg"
          ? <MaskPicture guidePic={mediaNode} />
          : <div className={styles.mediaWrapper}>{mediaNode}</div>
        )
      }

    </div>
  </div>}
  </Observer>
}

export default StepWrapper
