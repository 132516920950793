import { EChartsOption } from "echarts-for-react/lib/types"
import { cloneDeep, sumBy } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"
import numeral from "numeral"
import React from "react"

import dashboard from "@/api/dashboard"
import { ShipmentsAndLookupData } from "@/api/dashboard/type"
import { ShipmentsLookupsChartOption } from "@/component/Dashboard/Chart/ShipmentsLookupsChartOption"
import { ShipmentsStatusData } from "@/component/Dashboard/Chart/ShipmentsStatusData"
import i18next from "@/i18n/config"
import {
  Country,
  Courier,
  IShipmentsLookupsData,
  ShipmentsLookups,
  ShipmentsLookupsChartOptionsData,
  ShipmentStatus,
  ShipmentStatusParse,
  ShipmentStatusToL,
} from "@/models/IShipmentsLookupsData"
import stores from "@/stores"
import { formatNumber, toFixedNumber } from "@/utils/analyticsUtils"
import { MathUtils } from "@/utils/MathUtils"

export default class ShipmentsLookupsDataStore implements IShipmentsLookupsData {
  /** 数据加载完成时间 */
  @observable public loadedDataTime: number = 0

  /* 绘制图表的配置 */
  @observable public options: EChartsOption = ShipmentsLookupsChartOption

  /* 发货状态 */
  @observable public shipmentStatus: Array<ShipmentStatus> = ShipmentsStatusData

  /* 日期数组 */
  @observable public date: string[][] = []

  /* 点击次数，应该是 tracking page 的点击次数 */
  @observable public clickCount: number = 0
  @observable public deliveredCount: number = 0
  @observable public lookups: Array<number> = []
  @observable public noPendingCount: number = 0
  @observable public orderCount: number = 0
  @observable public shipmentStatusToL: Array<ShipmentStatusToL> = []
  @observable public shipments: Array<number> = []
  @observable public shipment_status: ShipmentsAndLookupData["shipments_lookups"]["shipment_status"] = []
  @observable public lookups_count: number = 0
  @observable public shipments_count: number = 0

  @observable public delivery_performance: ShipmentsAndLookupData["shipments_lookups"]["delivery_performance"] = {
    value: 0,
    percentage: 0,
  }
  @observable public conversion_revenue: ShipmentsAndLookupData["shipments_lookups"]["conversion_revenue"] = {
    total: "",
    tracking_page: "",
    shipping_notifications: "",
  }
  @observable public valid_tracking: ShipmentsAndLookupData["shipments_lookups"]["valid_tracking"] = {
    value: 0,
    percentage: 0,
  }
  @observable public as_value_coefficient: ShipmentsAndLookupData["shipments_lookups"]["as_value_coefficient"] = {
    save_time: "10",
    save_cost: "19",
    cost_monetary_unit: "USD",
  }
  @observable public save_loading: boolean = false

  @observable public shipment_status_chart_data: ShipmentsAndLookupData["shipments_lookups"]["shipment_status_chart_data"] = {
    exception: [],
    failed_attempt: [],
    pending: [],
  }

  @observable public country: Country[] = []
  @observable public courier: Courier[] = []

  constructor() {
    makeObservable(this)
  }

  /**
   * 获取发货状态相关信息
   */
  @computed
  get getShipmentStatus(): Array<ShipmentStatusParse> {
    // 如果改了颜色记得在 /src/enums/track-status.ts  这里也改一下，为什么不统一起来呢？
    // 我也不知道 🤷‍♂️
    const colorSet = [
      "#6D7175", // Pending
      "#1E93EB", // In Transit
      "#FCAF30", // Out of Delivery
      "#1BBE73", // Delivered
      "#BABEC3", // Expired
      "#8109FF", // Failed Attempt
      "#FD5749", // Exception
      "#00A0AC", // Info Received
    ]

    const data: Array<ShipmentStatusParse> = []
    const e = React.createElement

    const valueSum = sumBy(this.shipmentStatus, "value")

    this.shipmentStatus.map((item, index) => {
      const title = e("div", {
        key: `div-${index}`,
        style: {
          alignItems: "center",
          display: "flex",
        },
      }, [
        e("span", {
          key: `span-${index}`,
          style: {
            backgroundColor: colorSet[item.type - 1],
            borderRadius: "100%",
            display: "inline-block",
            height: "15px",
            marginRight: "8px",
            width: "15px",
          },
        }),
        e("span", { key: `span2-${index}` }, i18next.t(item.tran_key as any, { ns: "common" })),
      ])

      data.push({
        percentage: MathUtils.getPercentage(item.value, valueSum),
        title,
        val: item.value > 0 ? numeral(item.value)
          .format("0,0") : "-",
      })
    })

    return data
  }

  /**
   * 计算人员所需时间
   * Average time per WISMO ticket handled (minute) * Lookups / 60
   */
  @computed
  get getStaffingTime(): string | number{
    const value = Number(this.as_value_coefficient.save_time) * this.lookups_count / 60

    if (value === 0) return value

    return formatNumber(
      toFixedNumber(Number(this.as_value_coefficient.save_time) * this.lookups_count / 60),
    )
  }

  /**
   * 计算节省的费用
   * Average time per WISMO ticket handled (minute) * Lookups / 60
   */
  @computed
  get getStaffingCost(): number{
    const hours = Number(toFixedNumber(Number(this.as_value_coefficient.save_time) * this.lookups_count / 60))
    const cost = Number(this.as_value_coefficient.save_cost).toFixed(2)

    return hours * Number(cost)
  }

  /**
   * 将从 API 获取的数据复制给声明变量
   * @param data
   * @param legendData
   */
  @action.bound
  public setData(data: ShipmentsLookups, legendData: string[]): void {
    let currentKeys: (keyof ShipmentsLookups)

    for (currentKeys in data) {
      // @ts-ignore
      this[currentKeys] = data[currentKeys]
    }

    this.setOptionsData({
      lookupsSeriesData: data.lookups,
      shipmentsSeriesData: data.shipments,
      xAxisData: data.date,
    })
    this.setShipmentStatus(data.shipmentStatus)
    this.setLegendData(legendData)
  }

  /**
   * 将从 API 获取的数据复制给声明变量
   * @param data
   * @param legendData
   */
  @action.bound
  public getCurrency(): string {
    const { currency } = stores.userInfoSore.data

    if (window.Currency){
      return window.Currency.rates[currency] ? currency : "USD"
    } else {
      return "USD"
    }
  }

  /**
   *  2023721 v6迭代新的setDataNew，用于替代上面的setData函数
   * 将从 API 获取的数据复制给声明变量
   * @param data
   * @param legendData
   */
  @action.bound
  public setNewData(data: ShipmentsAndLookupData): void {
    const { shipments_lookups } = data

    this.setOptionsData({
      lookupsSeriesData: shipments_lookups.chart_data.lookups,
      shipmentsSeriesData: shipments_lookups.chart_data.shipments,
      xAxisData: shipments_lookups.chart_data.date.map(item => [item]),
    })

    // 将shipment_status的数量设置到shipmentStatus中
    shipments_lookups.shipment_status.forEach(item => {
      this.shipmentStatus.forEach(status => {
        if (item.status === status.type) {
          status.value = item.quantity
        }
      })
    })

    this.shipment_status = shipments_lookups.shipment_status
    this.delivery_performance = shipments_lookups.delivery_performance
    this.valid_tracking = shipments_lookups.valid_tracking
    this.conversion_revenue = shipments_lookups.conversion_revenue
    this.shipments_count = shipments_lookups.shipments_count
    this.lookups_count = shipments_lookups.lookups_count

    if (shipments_lookups.as_value_coefficient) {
      this.as_value_coefficient = shipments_lookups.as_value_coefficient
    }

    const { cost_monetary_unit } = shipments_lookups.as_value_coefficient

    const currency = stores.userInfoSore.data.currency || "USD" // 默认为USD

    // 如果cost储存的货币单位与店铺的货币单位不同，此时进行货币换算数值
    if (cost_monetary_unit !== currency){
      this.exchangeCurrency(currency)
    }

    this.shipment_status_chart_data = shipments_lookups.shipment_status_chart_data
  }

  /**
   * 设置图表数据
   * @param data
   */
  @action.bound
  setOptionsData(data: ShipmentsLookupsChartOptionsData): void {
    const newOption = cloneDeep(this.options)

    // 设置 x 轴数据
    if (newOption?.xAxis[0]?.data) {
      newOption.xAxis[0].data = data.xAxisData
    }

    // Shipments 数据
    if (newOption?.series[0]?.data) {
      newOption.series[0].data = data.shipmentsSeriesData
    }

    // Lookups 数据
    if (newOption?.series[1]?.data) {
      newOption.series[1].data = data.lookupsSeriesData
    }

    // 最终赋值数据
    this.options = newOption
  }

  /**
   * 设置数据加载完成时间
   * @param time
   */
  @action.bound
  public setLoadedDataTime(time: number): void {
    this.loadedDataTime = time
  }
  /**
   * 货币转换
   * @param currency
   */
  @action.bound
  public exchangeCurrency(currency: string): void {
    const { save_cost, cost_monetary_unit } = this.as_value_coefficient

    if (window.Currency){
      currency = window.Currency.rates[currency] ? currency : "USD"
      const convertValue = window.Currency.convert(
        Number(save_cost),
        cost_monetary_unit,
        currency,
      )

      const newValueCoefficient = {
        save_cost: String(toFixedNumber(convertValue)),
        cost_monetary_unit: currency,
      }

      // convert货币转换NaN的情况->一般是缺少汇率，只有转出了具体值，才调用更新函数
      if (!isNaN(convertValue)){
        this.saveValueCoefficient(newValueCoefficient)
      }
    } else {
      setTimeout(() => this.exchangeCurrency(currency), 200)
    }
  }
  /**
   * 保存价值计算值
   * @param data
   */
  @action.bound
  saveValueCoefficient(data: Partial<ShipmentsLookupsDataStore["as_value_coefficient"]>){
    return new Promise((resolve, reject) => {
      this.save_loading = true
      dashboard.updateValueCoefficientGuide({ ...this.as_value_coefficient, ...data }).then((res) => {
        if (res.status === 200){
          this.as_value_coefficient = res.data.as_value_coefficient
          resolve(res)
        } else {
          reject(res)
        }
      })
        .catch(() => reject())
        .finally(() => {
          this.save_loading = false
        })
    })
  }

  /**
   * 设置发货状态
   * @param data
   */
  @action.bound
  setShipmentStatus(data: Array<ShipmentStatus>): void {
    this.shipmentStatus = data
  }

  /**
   * 设置价值计算时间值
   * @param time
   */
  @action.bound
  public setCoefficientTime(time: string): void {
    this.as_value_coefficient.save_time = time
  }

  /**
   * 设置价值计算时薪
   * @param cost
   */
  @action.bound
  public setCoefficientCost(cost: string): void {
    this.as_value_coefficient.save_cost = cost
  }

  @action.bound
  setLegendData(data: string[]): void {
    const options = cloneDeep(this.options)
    const [name0, name1] = data

    options.series[0].name = name0
    options.series[1].name = name1

    options.legend.data[0] = name0
    options.legend.data[1] = name1

    this.options = options
  }
}
