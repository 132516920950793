import { Card, Icon, Key, Layout, Page, SkeletonBodyText, SkeletonDisplayText, Text } from "@shopify/polaris"
import { EmailIcon, OrdersStatusIcon, ViewIcon } from "@shopify/polaris-icons"
import { find, isArray, isEmpty } from "lodash-es"
import { Observer } from "mobx-react"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

import { APICode } from "@/api/APICode"
import TP2 from "@/api/TP2"
import { ITrackingResultItem } from "@/api/TP2/tracking-info"
import { Container, InfoCard, TrackInfo } from "@/component"
import TrackStatusProgressBar from "@/component/TrackStatusProgressBar/TrackStatusProgressBar"
import { NotificationHistoryModalMobx, TagsManageModalMobx } from "@/pages/Orders/components"
import { ShipmentInfo } from "@/pages/Orders/components/OrderInfo"
import { UpdateStatusSelectFrom } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"
import { TrackStatus } from "@/utils/TrackStatus"
import UrlUtils from "@/utils/UrlUtils"
import IStatus = TrackStatus.IStatus
import { History } from "@shopify/app-bridge/actions"

import { postRouteMessage } from "@/admin-bridge/admin-bridge"
import { BetaFeature } from "@/models/IUserInfo"
import AppBridge from "@/utils/appBridge"
import { canIUseBetaFeature } from "@/utils/BetaFeature"

import styles from "./Orders.module.scss"

interface OrdersDetailProps {
  numberID?: string
  numberIDList?: string
}

const OrdersDetail = ({ numberID, numberIDList }: OrdersDetailProps) => {
  const { t } = useTranslation("orders")
  const [searchParams] = useSearchParams()
  const navigator = useNavigate()

  const pageTitle = searchParams.get("orderName")
  const ordersQuery = searchParams.get("query")

  let ordersListString = searchParams.get("orders")

  if (isEmpty(ordersListString)) {
    if (numberIDList) {
      ordersListString = numberIDList
    } else if (numberID) {
      ordersListString = numberID
    }
  }

  const ordersList = ordersListString ? ordersListString.split(",") : []

  let __index = ordersList.findIndex((item) => item === numberID)

  if (searchParams.has("orderIndex")) {
    __index = Number(searchParams.get("orderIndex") || "0")
  } else {
    if (__index === -1) {
      __index = 0
    }
  }

  // order index
  const [orderIndex, setOrderIndex] = useState(__index)

  const { userInfoSore, ordersStore } = stores


  const [data, setData] = useState<{
    expandTrackInfo: boolean
    track_link: string
    tracking?: ITrackingResultItem
  }>({ expandTrackInfo: false, track_link: "", tracking: undefined })


  const onBack = () => {
    navigator("/orders" + decodeURIComponent(typeof ordersQuery === "string" ? ordersQuery : ""))
  }

  const convertToArray = (val: any) => {
    if (isArray(val)) return val
    return []
  }

  const getData = (id: number) => {
    ordersStore.setOrderInfoDataLoadedTime(0)
    ordersStore.setOrderInfoData(undefined)

    // 如果参数都没有，直接跳回Orders
    if (!ordersListString || !id) {
      navigator("/orders")
    }

    const primaryLocale = userInfoSore.data.primary_locale

    // console.log('OrderInfo.tsx', '如果和上一次不相同，就去执行获取数据', [this.props.id, prevProps.id])
    id && TP2.getTrackingInfo(id, primaryLocale)
      .then(({ data: { data, code, msg } }) => {
        if (APICode.OK !== code) {
          stores.ordersStore.setOrderInfoShow(false)
          return
        }

        stores.ordersStore.setOrderInfoData(data)
        stores.ordersStore.setOrderInfoDataLoadedTime(new Date().getTime())

        const tracking = find<ITrackingResultItem>(data?.tracking, item => parseInt(item.nid + "") === parseInt(id + ""))

        setData({ expandTrackInfo: false, track_link: data.track_link, tracking })

      })
      .catch(() => {
        // MARK: chuwen 2022/7/12 10:56 当出现错误就关闭弹窗
        stores.ordersStore.setOrderInfoShow(false)

      })
  }

  useEffect(() => {
    if (canIUseBetaFeature(BetaFeature.NEW_SHIPMENT_INFO_PAGE)){
      const targetPath = `/orders/shipments/${ordersList[orderIndex]}`

      if (AppBridge.exist()) {
        // 参考文档：https://shopify.dev/tools/app-bridge/actions/navigation/history
        const history = History.create(AppBridge.app)

        history.dispatch(History.Action.PUSH, targetPath)
      } else {
        postRouteMessage(targetPath)
      }
    }

    getData(Number(ordersList[orderIndex]))
  }, [orderIndex])

  const getTransitDays = () => {
    const transitDay = data.tracking?.transitDay

    if (!transitDay) {
      return "-"
    }

    return t("OrderInfo.nDay", { count: transitDay })
  }

  // 计算标题文本
  const trackInfoTitle = () => {
    const status = data.tracking?.status || "-"

    return `${status} · ${getTransitDays()}`
  }

  // 上一页
  const handlePgUp = () => {
    setOrderIndex(orderIndex - 1 < 0 ? 0 : orderIndex - 1)
    UrlUtils.setSearchByKey("orderIndex", String(orderIndex))
  }
  // 最大index
  const maxIndex = ordersList.length - 1
  // 下一页
  const handlePgDown = () => {
    setOrderIndex(orderIndex + 1 > maxIndex ? maxIndex : orderIndex + 1)
    UrlUtils.setSearchByKey("orderIndex", String(orderIndex))
  }

  const navigate = useNavigate()

  // useKeyPress("j", (event) => {
  //   if (!canIUseBetaFeature(BetaFeature.SHORTCUTS) || isInputElement(event.target)) {
  //     return
  //   }
  //
  //   handlePgUp()
  // }, { exactMatch: true })
  //
  // useKeyPress("k", (event) => {
  //   if (!canIUseBetaFeature(BetaFeature.SHORTCUTS) || isInputElement(event.target)) {
  //     return
  //   }
  //
  //   handlePgDown()
  // }, { exactMatch: true })

  return (
    <Observer>{() => {
      const { orderInfoData, orderInfoDataLoadedTime } = ordersStore
      const dataLoading = orderInfoDataLoadedTime === 0
      const numberID = Number(ordersList[orderIndex])

      return (
        <Container id="PageOrdersDetail" className={"pb-5"}>
          <Page
            backAction={{ content: "Back", onAction: onBack }}
            title={orderInfoData?.order}
            titleMetadata={dataLoading ? <div className={"w-20"}><SkeletonDisplayText size="small" /></div> : null}
            secondaryActions={[
              {
                content: t("OrderInfo.ViewOnTrackingPage"),
                loading: dataLoading,
                icon: ViewIcon,
                onAction: () => window.open(orderInfoData?.track_link, "_blank"),
              },
            ]}
            actionGroups={[
              {
                // @ts-ignore
                title: t("email_edit:MoreActions"),
                disabled: dataLoading,
                actions: [
                  {
                    // @ts-ignore
                    content: t("orders:ViewNotificationHistory"),
                    prefix: <Icon source={EmailIcon} />,
                    accessibilityLabel: "Individual action label",
                    onAction: () => stores.ordersStore.loadMailSendRecords(numberID),
                  },
                  {
                    // @ts-ignore
                    content: t("orders:ManuallyUpdateStatus"),
                    prefix: <Icon source={OrdersStatusIcon} />,
                    accessibilityLabel: "Individual action label",
                    onAction: () => {
                      const { search } = window.location

                      // 构建正则表达式，查找orderIndex的值
                      const regex = new RegExp("(orderIndex=)([^&]*)")

                      const searchUrl = search.replace(regex, "$1" + orderIndex)

                      const searchObj = {
                        from: UpdateStatusSelectFrom.MultiSelect,
                        query: encodeURIComponent(searchUrl),
                        number_ids: [numberID], // 当没有选择更多时，会有此参数
                      }

                      navigate({
                        pathname: "/orders/update-status",
                        search: qs.stringify(searchObj),
                      })
                    },
                  },
                ],
              },
            ]}
            pagination={{
              hasPrevious: dataLoading ? false : orderIndex > 0,
              hasNext: dataLoading ? false : orderIndex < maxIndex,

              onNext: handlePgDown,
              onPrevious: handlePgUp,

              previousKeys: [Key.KeyJ],

              // @ts-ignore
              previousTooltip: t("common:Pagination.previous") + " (J)",

              nextKeys: [Key.KeyK],

              // @ts-ignore
              nextTooltip: t("common:Pagination.next") + " (K)",
            }}
          >
            <div className={styles.sectionContainer}>
              <Layout>
                <Layout.Section>
                  <Card>
                    <Text as="h2" variant="headingSm">
                      {t("TrackingDetails")}
                    </Text>

                    <div className={"flex flex-col gap-[var(--p-space-300)] mt-4"}>
                      {
                        dataLoading
                          ? <>
                            <SkeletonBodyText lines={1} />

                            <SkeletonBodyText lines={14} />
                          </>
                          : <>
                            <Text variant="headingMd" as="h2">{trackInfoTitle()}</Text>

                            {/* 订单状态进度指示器 */}
                            <TrackStatusProgressBar orderStatus={(data.tracking?.stausDataNum ?? 1) as IStatus} />

                            {/* 物流信息 */}
                            {
                              !data.tracking?.trackinfo.length
                                ? (<p>{t("OrderInfo.NotYet")}</p>)
                                : <TrackInfo tracking={data.tracking} />
                            }
                          </>
                      }
                    </div>
                  </Card>

                </Layout.Section>
                <Layout.Section variant="oneThird">
                  <ShipmentInfo tracking={data.tracking} />
                </Layout.Section>
              </Layout>

            </div>
            <TagsManageModalMobx type={"detail"} />

            <NotificationHistoryModalMobx />
          </Page>
        </Container>
      )
    }}</Observer>
  )
}

export default OrdersDetail
