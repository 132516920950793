import { isEmpty, isEqual, isObject, transform } from "lodash-es"
import { deepObserve } from "mobx-utils"
import { IDisposer } from "mobx-utils/lib/utils"

import { ITrackingPageOptions } from "@/models/ITrackingPageOptions"
import { getDiffOptions } from "@/pages/TrackingPage/TrackingPage"
import stores from "@/stores"

export let disposer: IDisposer | null = null
let defaultOptions: ITrackingPageOptions

export default {

  /**
   * 销毁监听器
   */
  destroyListener(focus: boolean = false) {
    // window.onbeforeunload = null
    disposer && disposer()
    focus && stores.trackingPageStore.setLoadedDataTime(0)
    stores.commonStore.setContextualSaveBar("show", false)
    return true
  },


  /**
   * Deep diff between two object, using lodash
   * @param  {Object} object Object compared
   * @param  {Object} base   Object to compare with
   * @return {Object}        Return a new object who represent the diff
   */
  difference(object: object, base: object) {
    function changes(object: object, base: object) {
      return transform(object, function (result: any, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
        }
      })
    }

    return changes(object, base)
  },


  /**
   * 获取初始值配置
   */
  getInitOptions(): ITrackingPageOptions {
    return defaultOptions
  },


  /**
   * 注册监听器
   */
  registerListener() {
    console.info("TrackingPageUtils: registerListener")
    disposer = deepObserve(stores.trackingPageStore, (change, path) => {
      if (change.type === "update" && change.observableKind === "object") {
        stores.commonStore.setContextualSaveBar("show", !isEmpty(getDiffOptions()))
        // !window.onbeforeunload && (window.onbeforeunload = () => "Unsaved changes!")
      }
    })
    return true
  },


  /**
   * 设置初始值
   * @param options
   */
  setInitOptions(options: ITrackingPageOptions) {
    defaultOptions = options
    return true
  },
}
