import stores from "@/stores"

const originalFetch = window.fetch
const REGEX = /^shopify:admin\/api\/(\d{4}-\d{2})?\/?graphql\.json$/

async function customFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
  if (typeof input === "string" && REGEX.test(input) && (window.ParcelPanel?.isFromAdmin || import.meta.env.MODE === "development")) {
    let version = "latest"

    const match = input.match(REGEX)

    if (match && match[1]) {
      // eslint-disable-next-line prefer-destructuring
      version = match[1]
    }

    const modifiedUrl = `${import.meta.env.VITE_API_BASE_URL}v3/shopify/app_proxy/graphql.json?version=${version}`

    const modifiedInit: RequestInit = {
      ...init,
      headers: {
        ...(init?.headers),
        "Authorization": `Bearer ${stores.commonStore.authToken}`,
      },
    }

    return originalFetch(modifiedUrl, modifiedInit)
  }

  return originalFetch(input, init)
}

window.fetch = customFetch as any
