import "./OrderInfo.scss"

import { ActionList, Button, Icon, Popover, Scrollable, Sheet, SkeletonBodyText, Text } from "@shopify/polaris"
import {
  EmailIcon,
  MinusCircleIcon,
  OrdersStatusIcon,
  PlusCircleIcon,
  ViewIcon,
  XIcon,
} from "@shopify/polaris-icons"
import classNames from "classnames"
import { find, isArray } from "lodash-es"
import { observer } from "mobx-react"
import qs from "qs"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import TP2 from "@/api/TP2"
import { ITrackingResultItem } from "@/api/TP2/tracking-info"
import { InfoCard, TrackInfo, withRouter } from "@/component"
import TrackStatusProgressBar from "@/component/TrackStatusProgressBar/TrackStatusProgressBar"
import { Mode } from "@/models/IOrderTagsManageStore"
import { UpdateStatusSelectFrom } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"
import { TrackStatus } from "@/utils/TrackStatus"

import { CustomerInfo, ShipmentInfo, ShipmentItems } from "./"
import styles from "./OrderInfo.module.scss"
import { IProps, IState } from "./types"
import IStatus = TrackStatus.IStatus

/**
 * @deprecated
 */
@withRouter
@observer
class OrderInfo extends Component<IProps, IState> {
  readonly state: IState = {
    expandTrackInfo: false,
    progress: 0,
    moreActionsActive: false,
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const { show, id } = this.props

    // 如果当前执行的关闭操作，就不往下执行
    if (!show) {
      return
    }

    // 如果和上一次不相同，就去执行获取数据 || 是空的
    if (id !== prevProps.id || !stores.ordersStore.orderInfoData) {
      stores.ordersStore.setOrderInfoDataLoadedTime(0)
      stores.ordersStore.setOrderInfoData(undefined)

      const primaryLocale = stores.userInfoSore.data.primary_locale

      // console.log('OrderInfo.tsx', '如果和上一次不相同，就去执行获取数据', [this.props.id, prevProps.id])
      TP2.getTrackingInfo(id, primaryLocale)
        .then(({ data: { data, code } }) => {
          if (APICode.OK !== code) {
            stores.ordersStore.setOrderInfoShow(false)
            return
          }

          stores.ordersStore.setOrderInfoData(data)
          stores.ordersStore.setOrderInfoDataLoadedTime(new Date().getTime())

          const tracking = find<ITrackingResultItem>(data?.tracking, item => parseInt(item.nid + "") === parseInt(id + ""))

          this.setState({ expandTrackInfo: false, track_link: data.track_link, tracking })
        })
        .catch(() => {
          // MARK: chuwen 2022/7/12 10:56 当出现错误就关闭弹窗
          stores.ordersStore.setOrderInfoShow(false)
        })
    }
  }

  public componentWillUnmount() {
    stores.ordersStore.setOrderInfoData(undefined)
  }

  public render() {
    const { show, onClose, t } = this.props
    const { tracking, track_link } = this.state
    const { orderInfoData, orderInfoDataLoadedTime } = stores.ordersStore

    // 计算标题文本
    const trackInfoTitle = () => {
      const status = tracking?.status || "-"

      return `${status} · ${this.getTransitDays()}`
    }

    const dataLoading = orderInfoDataLoadedTime === 0

    const bodyMarkup = <>
      {/* 订单状态进度指示器 */}
      <div className="mb-4">
        <TrackStatusProgressBar orderStatus={(tracking?.stausDataNum ?? 1) as IStatus} />
      </div>

      {/* 物流信息 */}
      {
        !tracking?.trackinfo.length
          ? (<p>{t("OrderInfo.NotYet")}</p>)
          : <TrackInfo tracking={tracking} />
      }
    </>

    const syncButtonMarkup = (
      <Button
        disclosure="down"
        onClick={() => {
          this.setState((prevState) => ({
            moreActionsActive: !prevState.moreActionsActive,
          }))
        }}
        variant="plain"
      >
        More actions
      </Button>
    )

    const handlePopoverClose = () => this.setState({ moreActionsActive: false })

    const moreActions = (
      <Popover
        active={this.state.moreActionsActive}
        activator={syncButtonMarkup}
        onClose={handlePopoverClose}
      >
        <ActionList
          items={[
            {
              prefix: <Icon source={ViewIcon} />,
              content: t("OrderInfo.ViewOnTrackingPage"),
              onAction: () => {
                window.open(track_link, "_blank")
              },
            },
            {
              prefix: <Icon source={EmailIcon} />,
              content: "View notification history",
              onAction: () => {
                stores.ordersStore.loadMailSendRecords(this.props.id)
              },
            },
            {
              prefix: <Icon source={OrdersStatusIcon} />,
              content: t("ManuallyUpdateStatus"),
              onAction: () => {
                const searchObj = {
                  from: UpdateStatusSelectFrom.MultiSelect,
                  number_ids: [this.props.id], // 当没有选择更多时，会有此参数
                }

                this.props.router?.navigate({
                  pathname: "/orders/update-status",
                  search: qs.stringify(searchObj),
                })

                onClose()
              },
            },
            {
              prefix: <Icon source={PlusCircleIcon} />,
              content: t("AddTags"),
              onAction: () => {
                stores.orderTagsManageStore.mode = Mode.ADD_TAGS
                stores.orderTagsManageStore.shipmentCount = 1
                stores.orderTagsManageStore.shipmentSelectedItems = [`${this.props.id}`]
                stores.orderTagsManageStore.queryParams = undefined
                stores.orderTagsManageStore.setOpen(true)
              },
            },
            {
              prefix: <Icon source={MinusCircleIcon} />,
              content: t("RemoveTags"),
              onAction: () => {
                stores.orderTagsManageStore.mode = Mode.REMOVE_TAGS
                stores.orderTagsManageStore.shipmentCount = 1
                stores.orderTagsManageStore.shipmentSelectedItems = [`${this.props.id}`]
                stores.orderTagsManageStore.queryParams = undefined
                stores.orderTagsManageStore.setOpen(true)
              },
            },
          ]}
          onActionAnyItem={handlePopoverClose}
        />
      </Popover>

    )

    const actionHeaderMarkup = dataLoading
      ? <Button loading={dataLoading} size="slim" variant="plain" />
      : moreActions

    const convertToArray = (val: any) => {
      if (isArray(val)) return val
      return []
    }

    return (
      <div id="OrderInfo" className={classNames(show && styles.OrderInfo)}>
        <Sheet
          open={show} onClose={onClose}
          // 不要试图更改 accessibilityLabel 的值（特别是 Order）这个单词
          // 除非你看过 /src/pages/Orders/components/OrderInfo/OrderInfo.scss 文件
          accessibilityLabel={`Order ${orderInfoData?.order}`}
        >
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          >

            {/* 头部 */}
            {this.headerMarkup()}

            <Scrollable>
              {/* 通过 stores.ordersStore.orderInfoDataLoadedTime === 0 判断 */}
              <InfoCard
                title={<>{dataLoading ?
                  <div style={{ width: "200px" }}><SkeletonBodyText lines={1} /></div> : trackInfoTitle()}</>}
                actionHeader={actionHeaderMarkup}
              >

                {dataLoading ? <SkeletonBodyText lines={4} /> : bodyMarkup}
              </InfoCard>

              <ShipmentInfo tracking={tracking} />

              <CustomerInfo />

              <ShipmentItems title={convertToArray(tracking?.title ?? [])}
                titleImg={convertToArray(tracking?.title_img ?? [])}
              />
            </Scrollable>
          </div>
        </Sheet>
      </div>
    )
  }

  private headerMarkup() {
    const { onClose, t } = this.props

    return (
      <div className="Polaris-Filters__FiltersContainerHeader">
        <Text variant="headingLg" as="h3">{this.props?.defaultOrderName ?? ""}</Text>
        <Button
          icon={XIcon}

          accessibilityLabel={t("Cancel", { ns: "common" })}
          onClick={onClose}
          variant="plain"
        />
      </div>
    )
  }

  private getTransitDays() {
    const { t } = this.props
    const { tracking } = this.state
    const transitDay = tracking?.transitDay

    if (!transitDay) {
      return "-"
    }

    return t("OrderInfo.nDay", { count: transitDay })
  }
}

/**
 * @deprecated
 */
export default withTranslation("orders")(OrderInfo)
