import { Banner } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

/**
 * 店铺套餐异常提示
 * @constructor
 */
function ShowAccountPlanErr() {
  const { t } = useTranslation("dashboard", { keyPrefix: "quotaAccountPlan" })

  return <>
    <Banner
      title={t("title")}
      tone="critical"
    >
      <p>{t("content")}</p>
    </Banner>
  </>
}


export default ShowAccountPlanErr
