import { Link, Text } from "@shopify/polaris"
import React, { Component } from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { ITrackingResultItem, TrackInfoItem as ITrackInfoItem } from "@/api/TP2/tracking-info.d"
import { ITrackingPageStatus } from "@/models/IUserInfo"
import stores from "@/stores"

import styles from "./TrackInfo.module.scss"

interface IProps extends WithTranslation<"common"> {
  tracking: ITrackingResultItem | undefined;
}

interface IStates {
  expand: boolean
}

class TrackInfo extends Component<IProps, IStates> {
  state: IStates = {
    expand: true, // 默认打开
  }

  render() {
    const { t, tracking } = this.props,
      trackInfoLength = tracking?.trackinfo.length ?? 0,
      renderShortItem = [0, 1, trackInfoLength - 1],
      checkPointStatus = this.getCheckPointStatus("blank")

    return (<div className={styles.TrackInfo}>
      {tracking?.trackinfo.map((item, index) => {
        const key = `track-info-item-${index}`

        // 如果不需要「显示更多」就直接渲染
        if (this.state.expand && trackInfoLength > 3) {
          return this.infoMarkup(item, key)
        }

        // 仅仅显示「3 条记录」
        if (renderShortItem.includes(index)) {
          return this.infoMarkup(item, key)
        }

        // 展示「显示更多信息按钮」
        if (index === trackInfoLength - 2) {
          return (
            <div className={styles.TrackInfoItem} key={key}>
              <div className={styles.TrackInfoItem__Icon}>
                <img src={checkPointStatus?.img} alt={checkPointStatus?.en} data-status="blank" />
              </div>

              <div className={styles.TrackInfoItem__Info}>
                <Link removeUnderline onClick={() => {
                  this.setState({ expand: true })
                }}
                >{t("ShowMoreDetails")}</Link>
              </div>
            </div>
          )
        }

        return undefined
      })}
    </div>)
  }

  public getTrackText(item: ITrackInfoItem) {
    const local = item.Details
    let event = item.StatusDescription

    if (local && event !== local) event += `, ${local}`

    return event.trim()
  }

  public infoMarkup(trackInfo: ITrackInfoItem, key: string) {
    const checkPointStatus = this.getCheckPointStatus(trackInfo.checkpoint_status as keyof ITrackingPageStatus)

    return (
      <div className={styles.TrackInfoItem} key={key}>
        <div className={styles.TrackInfoItem__Icon}>
          <img src={checkPointStatus?.img} alt={checkPointStatus?.en} data-status={trackInfo.checkpoint_status} />
        </div>

        <div className={styles.TrackInfoItem__Info}>
          <Text variant={"bodyMd"} as={"p"} tone={"subdued"}>{trackInfo.Date}</Text>
          <Text variant={"bodyMd"} as={"p"}>{this.getTrackText(trackInfo)}</Text>
        </div>
      </div>
    )
  }

  private getCheckPointStatus = (status: keyof ITrackingPageStatus) => stores.userInfoSore.data.tracking_page?.status_config[status]
}

export default withTranslation("common")(TrackInfo)
