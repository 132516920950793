import { Icon, Image } from "@shopify/polaris"
import classNames from "classnames"
import React from "react"

import styles from "./Thumbnail.module.scss"

type Size = "small" | "medium" | "large" | "48";

export interface ThumbnailProps {
  /**
   * Size of thumbnail
   * @default 'medium'
   */
  size?: Size;
  /** URL for the image */
  source: string | React.SFC<React.SVGProps<SVGSVGElement>>;
  /** Alt text for the thumbnail image */
  alt: string;
}

export function Thumbnail({ source, alt, size = "medium" }: ThumbnailProps) {
  const prefixCls = "Thumbnail"
  const className = classNames(
    styles[prefixCls],
    size && styles[`${prefixCls}--size${size.charAt(0)
      .toUpperCase()}${size.slice(1)}`],
  )

  const content =
    typeof source === "string" ? (
      <Image alt={alt} source={source} />
    ) : (
      <Icon accessibilityLabel={alt} source={source} />
    )

  return <span className={className}>{content}</span>
}
