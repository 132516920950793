import { LegacyFilters, LegacyStack } from "@shopify/polaris"
import { useKeyPress } from "ahooks"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { BetaFeature } from "@/models/IUserInfo"
import { SaveFiltersControl } from "@/pages/Orders/components"
import { canIUseBetaFeature } from "@/utils/BetaFeature"

import styles from "./OrdersFilters.module.scss"
import { IOrdersFiltersProps } from "./types"

export function OrdersFilters({
  filters,
  appliedFilters,
  queryValue,
  onQueryChange,
  navName,
  navSaved,
  onFiltersControlSave,
  onFiltersControlDelete,
}: IOrdersFiltersProps) {

  const handleFiltersClearAll = useCallback(
    () => appliedFilters.forEach(({ key, onRemove }) => onRemove(key)),
    [appliedFilters],
  )
  const { t } = useTranslation("orders")

  useKeyPress("F", function (event){
    if (!canIUseBetaFeature(BetaFeature.SHORTCUTS)){
      return
    }

    // @ts-ignore
    if (["INPUT", "TEXTAREA"].includes(event.target?.tagName?.toUpperCase() || "")) {
      return
    }

    // @ts-ignore
    document
      ?.querySelector(".Polaris-LegacyFilters-ConnectedFilterControl__CenterContainer .Polaris-TextField > input[type=\"text\"]")
      ?.focus()
  }, { useCapture: true, events: ["keyup"] })

  return (
    <>
      <LegacyFilters
        queryValue={queryValue || ""}
        filters={filters}
        queryPlaceholder={t("SearchOrderPlaceholder", "Search Order number, Tracking number or Customer")}
        appliedFilters={appliedFilters}
        onQueryChange={onQueryChange}
        onQueryClear={() => onQueryChange("")}
        onClearAll={handleFiltersClearAll}
      >
        <div className={styles.OrdersFilters__PaddingLeft}>
          <LegacyStack spacing="tight" wrap={false}>
            <SaveFiltersControl
              appliedFilters={appliedFilters}
              saved={navSaved}
              currentTabName={navName}
              onSave={onFiltersControlSave}
              onDelete={onFiltersControlDelete}
            />
          </LegacyStack>
        </div>
      </LegacyFilters>
    </>
  )
}
