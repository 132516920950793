import {
  ActionList,
  Bleed,
  BlockStack,
  Box,
  Button,
  EmptySearchResult,
  Icon,
  IndexTable,
  InlineStack,
  Modal,
  Popover,
  Tag,
  Text,
  Tooltip,
  useEventListener,
  useIndexResourceState,
} from "@shopify/polaris"
import { BulkActionsProps } from "@shopify/polaris/build/ts/src/components/BulkActions"
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable"
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types"
import { AlertCircleIcon, ChevronDownIcon, EmailIcon } from "@shopify/polaris-icons"
import { useSetState } from "ahooks"
import classNames from "classnames"
import i18n from "i18next"
import { get } from "lodash-es"
import qs from "qs"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import OrderUrl from "@/api/orders"
import { NormalLink } from "@/component"
import { TableItem } from "@/models/IOrders"
import { Mode } from "@/models/IOrderTagsManageStore"
import { BetaFeature } from "@/models/IUserInfo"
import { ShipmentStatusBadge, TagsManageModalMobx } from "@/pages/Orders/components"
import UpdateShipmentCourier from "@/pages/Orders/components/OrdersTable/UpdateShipmentCourier"
import { PER_PAGE } from "@/pages/Orders/Orders"
import { UpdateStatusSelectFrom } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"
import { canIUseBetaFeature } from "@/utils/BetaFeature"
import toast from "@/utils/toast"

import styles from "./OrdersTable.module.scss"
import SyncStatusTag from "./SyncStatusTag"
import { IColumnConfig, IOrdersTableProps } from "./types"

// 需要阻止向上冒泡的列
const stopPropagationColumn = ["orderName", "trackingNumber", "transit", "orderTag"]

export function OrdersTable({
  items,
  loading,
  paginationDisplay,
  pagination,
}: IOrdersTableProps) {
  const { t } = useTranslation("orders")
  const { t: t_dashboard } = useTranslation("dashboard")
  const { t: t_common } = useTranslation("common")
  const { ordersStore } = stores
  const navigate = useNavigate()

  // 显示模式 图标 或 图标+文本
  const [condensed, setCondensed] = useState(false)

  const [popoverOpenId, setPopoverOpenId] = useState<string>()
  const [tooltipActiveId, setTooltipActiveId] = useState<string>()

  const [exampleModal, setExampleModal] = useState<{
    title: string,
    open: boolean,
    children?: React.ReactNode
  }>({
    children: undefined,
    open: false,
    title: "Default title",
  })

  const openTagsManageModal = (mode: Mode) => {
    stores.orderTagsManageStore.mode = mode
    stores.orderTagsManageStore.shipmentCount = allResourcesSelected ? stores.ordersStore.itemsCount : selectedResources.length
    stores.orderTagsManageStore.shipmentSelectedItems = allResourcesSelected ? undefined : selectedResources
    stores.orderTagsManageStore.queryParams = allResourcesSelected ? stores.ordersStore.queryParams : undefined
    stores.orderTagsManageStore.setOpen(true)
  }

  const handleEmailIconClick = (tid: number) => {
    ordersStore.loadMailSendRecords(tid)
  }

  const viewExampleAddLink = () => {
    const children = <BlockStack gap="300">
      <p>{t_dashboard("SyncTrackingNumbers.p1", { ns: "dashboard" })}</p>
      <img alt="Sync tracking numbers" style={{ width: "100%" }}
        src="https://cdn.parcelpanel.com/front-end/2022/images/user-guide/sync_tracking_numbers.png"
      />
      <p>{t_dashboard("SyncTrackingNumbers.p3", { ns: "dashboard" })}</p>
    </BlockStack>


    setExampleModal({
      children,
      open: true,
      title: t_dashboard("SyncTrackingNumbers.title", { ns: "dashboard" }),
    })
  }

  const viewExampleModal = (
    <Modal
      sectioned
      size={"large"}
      onClose={() => setExampleModal({ open: false, title: exampleModal.title })}
      secondaryActions={[{
        content: t_common("Close", { ns: "common" }),
        onAction: () => setExampleModal({ open: false, title: exampleModal.title }),
      }]}
      {...exampleModal}
    />
  )

  const columnConfig: IColumnConfig = {
    order: {
      headingTitle: t("Order"),
      id: "orderName",
      // className: "PP-Column-OrderName",
      render: ({ order: { order_link, order_name } }) => (
        <NormalLink
          link={order_link}
          external
          useAppBridgeRedirect
          showIcon
        >
          {order_name}
        </NormalLink>
      ),
    },
    trackingNumber: {
      headingTitle: (
        <div
          className={styles.TableTipIcon}
        >
          {t("TrackingNumber")}
          <Button
            icon={AlertCircleIcon}
            variant={"plain"}
            onClick={() => viewExampleAddLink()}
          />
        </div>
      ),
      // className: "PP-Column-TrackingNumber",
      render: ({ trackingNumber: { number_link, number } }) => (
        <NormalLink
          link={number_link}
          external
          showIcon
        >
          {number}
        </NormalLink>
      ),
    },
    courier: {
      headingTitle: t("Courier"),
    },
    lastCheckPoint: {
      headingTitle: t("LastCheckPoint"),
    },
    transit: {
      headingTitle: t("TransitTime"),
      alignment: "end",
      render: ({ transit, key }) => (
        transit ? (
          (<>
            <span className={styles.transitValue}>{transit}</span>
            <Button
              variant="plain"
              icon={EmailIcon}
              onClick={() => handleEmailIconClick(parseInt(key, 10))}
            />
          </>)
        ) : null
      ),
    },
    orderDate: {
      headingTitle: t("OrderDate"),
    },
    status: {
      headingTitle: t("Status"),
      id: "shipmentStatus",
      flush: true,
      render: ({ status: { color, tran_key } }) => (
        <Box paddingInline="200">
          <ShipmentStatusBadge
            content={t_common(tran_key, { ns: "common" })}
            color={color}
          />
        </Box>
      ),
    },
    fulfillmentDate: {
      headingTitle: t("FulfillmentDate"),
    },
    destination: {
      headingTitle: t("Destination"),
    },
    customerName: {
      headingTitle: t("Customer"),
    },
    lastCheckpointTime: {
      headingTitle: t("LastCheckpointTime"),
    },
    residenceTime: {
      headingTitle: t("ResidenceTime"),
      alignment: "end",
    },
    orderTag: {
      headingTitle: t("OrderTag"),
      flush: true,
      render: (rowData, id) => {
        return (
          <OrderTagColumn
            rowData={rowData}
            active={popoverOpenId === id}
            onOpen={() => setPopoverOpenId(popoverOpenId === id ? undefined : id)}
            onClose={() => setPopoverOpenId(popoverOpenId === id ? undefined : id)}
          />
        )
      },
    },
    paypalSyncStatus: {
      headingTitle: t("PayPalSyncStatus"),
      render: (rowData, id) => {
        return rowData.paypalSyncStatus !== undefined && <SyncStatusTag status={rowData.paypalSyncStatus} />
      },
    },
  }

  // 获取需要展示的列
  let tableColumnChoices = stores.ordersStore.tableColumnChoices.filter(({ id }) => stores.ordersStore.tableColumnSelected.includes(id))

  if (!tableColumnChoices.length) {
    tableColumnChoices = [{
      id: "order",
      tran_key: "Order",
      label: t("Order"),
    }, {
      id: "trackingNumber",
      tran_key: "Order",
      label: t("Order"),
    }]
  }

  // 生成表格的 headings 数据
  const headings: NonEmptyArray<IndexTableHeading> = useMemo(
    () => (tableColumnChoices.map(({ id }) => ({ title: columnConfig[id]?.headingTitle, alignment: columnConfig[id]?.alignment })) as NonEmptyArray<IndexTableHeading>),
    [i18n.language, stores.ordersStore.tableColumnChoices, stores.ordersStore.tableColumnSelected],
  )

  // 空状态组件
  const emptyStateMarkup = <CustomEmptyState headings={condensed ? [] : headings} />

  const cleanItems = useMemo(() => items.map(item => {
    return { id: item.key }
  }), [items])

  // 储存选中的项
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(cleanItems)

  const rowMarkup = items.map(condensed ? renderMobileRow : renderRow)

  const itemCount = useMemo(() => {
    clearSelection()// 当变化了,就清空已选中列表

    // 当变化了, 就隐藏弹出层; 比如在 翻页场景
    setPopoverOpenId(undefined)

    return items.length
  }, [items])

  useEffect(() => {
    if (!loading) {
      setTimeout(function () {
        // 手动触发窗口resize事件
        if (document.createEvent) {
          const event = document.createEvent("HTMLEvents")

          event.initEvent("resize", true, true)
          window.dispatchEvent(event)
        }
      }, 0)
    }
  }, [loading, headings])

  const handleResize = useCallback(() => {
    // console.log("resize")
    /* 借鉴了 Shopify 的 resize 逻辑 */
    condensed !== (() => {
      if ("undefined" === typeof window) {
        return false
      }
      const e = window.matchMedia("(max-width: 765px)")

      return e.matches
    })() && setCondensed(!condensed)
  }, [condensed, setCondensed])

  useEventListener("resize", handleResize)

  const [active, setActive] = useState(false)

  const toggleActive = useCallback(() => {
    setActive((active) => !active)

    setTimeout(() => {
      const idsNodes = document.querySelectorAll<HTMLDivElement>("#PP-Orders-Popover-MoreActions")

      if (idsNodes.length > 1){
        idsNodes[0].style.display = "none"
      }
    }, 0)
  }, [])


  const bulkActions = [
    {
      content: t("AddTags"),
      onAction: () => {
        openTagsManageModal(Mode.ADD_TAGS)
      },
    },
    {
      content: t("RemoveTags"),
      onAction: () => {
        openTagsManageModal(Mode.REMOVE_TAGS)
      },
    },
  ]

  const promotedBulkActions: BulkActionsProps["promotedActions"] = [
    {
      content: t("ManuallyUpdateStatus"),
      onAction: () => {
        const searchObj = {
          from: allResourcesSelected ? UpdateStatusSelectFrom.SelectAll : UpdateStatusSelectFrom.MultiSelect,
          number_ids: allResourcesSelected ? [] : selectedResources, // 当没有选择更多时，会有此参数
        }

        navigate({
          pathname: "/orders/update-status",
          search: qs.stringify(searchObj),
        }, {
          state: {
          // 当选择更多时，会有此参数
            queryParams: allResourcesSelected ? JSON.parse(JSON.stringify(stores.ordersStore.queryParams)) : [],
          },
        })
      },
    },
  ]

  const [updateShipmentCourierState, setUpdateShipmentCourierState] = useSetState<{
    open: boolean
    number_ids: string[]
  }>({
    open: false,
    number_ids: [],
  })

  if (canIUseBetaFeature(BetaFeature.UPDATE_SHIPMENT_COURIER)) {
    promotedBulkActions?.push({
      content: t("UpdateCarrier"),
      onAction: () => {
        const searchObj = {
          from: allResourcesSelected ? UpdateStatusSelectFrom.SelectAll : UpdateStatusSelectFrom.MultiSelect,
          number_ids: allResourcesSelected ? [] : selectedResources, // 当没有选择更多时，会有此参数
        }

        setUpdateShipmentCourierState({ open: true, number_ids: searchObj.number_ids })
      },
    })
  }

  promotedBulkActions?.push({
    // @ts-ignore
    content: (
      <Popover
        // @ts-ignore
        id="PP-Orders-Popover-MoreActions"
        active={active}
        activator={
          <Bleed marginBlock="050">
            <InlineStack
              wrap={false}
            >
              <Text as="span">More actions</Text>
              <Icon source={ChevronDownIcon} />
            </InlineStack>
          </Bleed>
        }
        onClose={toggleActive}
      >
        <ActionList
          sections={[
            {
              items: bulkActions,
            },
          ]}
        />
      </Popover>
    ),
    onAction: toggleActive,
  })

  const [showSyncModal, setShowSyncModal] = useState(false)
  const [syncPayPalLoading, setSyncPayPalLoading] = useState(false)
  const [syncPayPalParams, setSyncPayPalParams] = useState<any>()

  // 连接了paypal才显示手动同步到paypal操作
  if (stores.userInfoSore.data.is_paypal_connected) {
    bulkActions.push({
      content: t("ManuallySyncToPayPal"),
      onAction: () => {
        setShowSyncModal(true)
        // 如果全选，则传filters，如果不是全选则传入选中ids
        if (allResourcesSelected) {
          const filters = JSON.parse(JSON.stringify(stores.ordersStore.queryParams))

          setSyncPayPalParams({ filters })
        } else {
          setSyncPayPalParams({ number_ids: selectedResources })
        }
      },
    })
  }

  // SyncOrders按钮点击提交
  const handleSyncOrdersButtonClick = () => {
    setSyncPayPalLoading(true)
    syncPayPalParams && OrderUrl.syncPayPal(syncPayPalParams)
      .then(() => {
        toast(t("Syncing", { ns: "orders" }))
      })
      .finally(() => {
        setSyncPayPalLoading(false)
        setShowSyncModal(false)
      })
  }

  function renderMobileRow(x: TableItem, index: number) {
    const rowId: string = `${x.key}`

    const { search } = window.location

    return (
      <IndexTable.Row
        id={rowId}
        key={rowId}
        position={index}
        selected={selectedResources.includes(rowId)}
        onClick={() => {
          stores.ordersStore.setOrderInfoItem(x)
          // stores.ordersStore.setOrderInfoShow(true)


          const searchObj = {
            // 原orders的查询信息
            query: encodeURIComponent(search),
            orders: String(stores.ordersStore.tableData.map(item => item.key)),
            orderIndex: index,
            orderName: x.order.order_name,
          }

          if (canIUseBetaFeature(BetaFeature.NEW_SHIPMENT_INFO_PAGE)) {
            navigate({
              pathname: `/orders/shipments/${x.key}`,
              search: qs.stringify({
                "number-ids": searchObj.orders,
              }),
            })
            return
          }

          navigate({
            pathname: "/orders/detail",
            search: qs.stringify(searchObj),
          }, {
            state: {
              // 当选择更多时，会有此参数
              queryParams: allResourcesSelected ? JSON.parse(JSON.stringify(stores.ordersStore.queryParams)) : [],
            },
          })
        }}
      >
        <div className={styles.NumberMobileList}>
          <div className={styles.MobileOrderStyle}>
            <div onClick={(e) => e.stopPropagation()}>
              <NormalLink link={x.order.order_link} external useAppBridgeRedirect>{x.order.order_name}</NormalLink>
            </div>
            <span>{x.courier}</span>
          </div>
          <div className={classNames(styles.MobileOrderStyle, styles.MobileOrderLinkStyle)}>
            <div onClick={(e) => e.stopPropagation()}>
              <NormalLink link={x.trackingNumber.number_link} external>{x.trackingNumber.number}</NormalLink>
            </div>
            <span>{x.orderDate}</span>
          </div>
          <div className={styles.MobileOrderStyle}>
            <div className={styles.StatusStyle}>
              <ShipmentStatusBadge content={t_common(x.status.tran_key)} color={x.status.color} />
            </div>
          </div>
        </div>
      </IndexTable.Row>
    )
  }

  function handleTextElementMouseOver(e: any, cellId: string) {
    const ele = e.target as HTMLElement

    if (ele.offsetWidth < ele.scrollWidth) {
      setTooltipActiveId(cellId)
    }
  }

  function handleTableCellClick(e: any, rowId: string, colId: string) {
    stopPropagationColumn.includes(colId) && e.stopPropagation()
    if (colId !== "orderTag") {
      // 关闭 order tag 弹出层
      setPopoverOpenId(undefined)
    }
  }

  function renderRow(x: TableItem, index: number) {
    const rowId: string = `${x.key}`

    // const { search } = useLocation()

    const { search } = window.location

    return (
      <IndexTable.Row
        id={rowId}
        key={rowId}
        position={index}
        selected={selectedResources.includes(rowId)}
        onClick={() => {
          // 关闭 order tag 弹出层
          setPopoverOpenId(undefined)

          stores.ordersStore.setOrderInfoItem(x)

          const searchObj = {
            // 原orders的查询信息
            query: encodeURIComponent(search),
            orders: String(stores.ordersStore.tableData.map(item => item.key)),
            orderIndex: index,
            orderName: x.order.order_name,
          }

          if (canIUseBetaFeature(BetaFeature.NEW_SHIPMENT_INFO_PAGE)) {
            navigate({
              pathname: `/orders/shipments/${x.key}`,
              search: qs.stringify({
                "number-ids": searchObj.orders,
              }),
            })
            return
          }

          navigate({
            pathname: "/orders/detail",
            search: qs.stringify(searchObj),
          }, {
            state: {
              // 当选择更多时，会有此参数
              queryParams: allResourcesSelected ? JSON.parse(JSON.stringify(stores.ordersStore.queryParams)) : [],
            },
          })
        }}
      >
        {
          tableColumnChoices.map(({ id: field }) => {
            const { className, id, render, flush } = columnConfig[field] || {}

            const colId = id || field
            const cellId = `${x.key}-${colId}`

            const content = render?.(x, rowId) || get(x, field)

            const isEmpty = content === null || content === "" || content === undefined

            // 纯字符串才加入溢出提示 Tooltip, 不对组件内容生效
            const contentMarkup = isEmpty
              ? "-" // 空白内容时显示 `-`
              : (
                typeof content !== "string"
                  ? content // 非字符串内容, 不增加溢出提示逻辑
                  : (
                    tooltipActiveId === cellId ? (
                      <Tooltip activatorWrapper="div" content={content}>
                        <div
                          className="truncate"
                        >
                          {content}
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        onMouseOver={(e) => handleTextElementMouseOver(e, cellId)}
                        className="truncate"
                      >
                        {content}
                      </div>
                    )
                  )
              )

            return (
              <IndexTable.Cell
                key={colId}
                flush={flush}
                className={classNames(className)}
              >
                <div
                  // 如果当前点击的列是在 stopPropagationColumn 就阻止向上冒泡
                  onClick={(e) => handleTableCellClick(e, rowId, colId)}
                  data-id={colId} data-lang={i18n.language}
                  className={classNames(styles.column)}
                >
                  {contentMarkup}
                </div>
              </IndexTable.Cell>
            )
          })
        }
      </IndexTable.Row>
    )
  }

  return (
    <>
      {viewExampleModal}
      {/* 订单详细信息弹窗 */}
      {/* <Observer>{() => <>*/}
      {/*  <OrderInfo*/}
      {/*    defaultOrderName={stores.ordersStore.orderInfoItem?.order.order_name || "Order info"}*/}
      {/*    id={parseInt(stores.ordersStore.orderInfoItem?.key || "", 10)}*/}
      {/*    show={!!stores.ordersStore.orderInfoItem?.key && stores.ordersStore.orderInfoShow}*/}
      {/*    onClose={handleOrderInfoShow}*/}
      {/*  />*/}
      {/* </>}</Observer>*/}
      {/* 需要放到 OrderInfo 组件的后面, 因为弹窗层级关系 */}
      <TagsManageModalMobx />

      {canIUseBetaFeature(BetaFeature.UPDATE_SHIPMENT_COURIER) && (
        <UpdateShipmentCourier
          open={updateShipmentCourierState.open}
          numberIDs={updateShipmentCourierState.number_ids}
          i18n={{
            title: t("UpdateCarrier"),
            confirm: t_common("Update"),
            cancel: t_common("Cancel"),
            success: t_common("UpdateSuccessfully"),
            error: t_common("UpdateFailed"),
          }}
          onClose={() => setUpdateShipmentCourierState({ open: false, number_ids: [] })}
        />
      )}

      <Modal
        open={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={t("SyncOrders.title")}
        primaryAction={{
          content: t("SyncOrders.title"),
          onAction: handleSyncOrdersButtonClick,
          loading: syncPayPalLoading,
        }}
        secondaryActions={[
          {
            content: t_common("Cancel"),
            onAction: () => setShowSyncModal(false),
          },
        ]}
        sectioned
      >
        <Modal.Section>
          <p>
            {t("SyncOrders.p3", { ns: "orders" })}
          </p>
        </Modal.Section>
      </Modal>

      <div className={styles.OrdersTable}>
        <IndexTable
          emptyState={emptyStateMarkup}
          loading={loading}
          lastColumnSticky={false}
          // selectable={false}
          headings={headings}
          itemCount={itemCount}
          resourceName={{ plural: t_common("Shipment", { count: 2 }), singular: t_common("Shipment", { count: 1 }) }}
          condensed={condensed}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          // MARK: chuwen 2022/7/20 18:59 如果订单总量大于 10 才显示选择全部
          hasMoreItems={stores.ordersStore.itemsCount > PER_PAGE}
          paginatedSelectAllActionText={t("SelectAllCountShipment", { count: stores.ordersStore.itemsCount })}
          promotedBulkActions={promotedBulkActions}
          pagination={paginationDisplay ? pagination : undefined}
        >
          {rowMarkup}
        </IndexTable>
      </div>
    </>
  )
}

function OrderTagColumn({
  rowData,
  active,
  onOpen,
  onClose,
}: {
  rowData: TableItem
  active: boolean
  onOpen(): void
  onClose(): void
}) {
  const { orderTags } = rowData

  const displayTags = takeDisplayOrderTags(orderTags)
  const remainingCount = orderTags.length - displayTags.length

  const activatorMarkup = () => {
    return <>
      <button
        className={styles.DropdownButton}
        onClick={onOpen}
      >
        <div className="mr-2">
          <InlineStack
            gap="100"
            wrap={false}
          >
            {
              displayTags.map(tag => <Tag key={tag}>{tag}</Tag>)
            }
            <Tag>+{remainingCount}</Tag>
          </InlineStack>
        </div>
        <div className={styles.Dropdown}>
          <Icon
            source={ChevronDownIcon}
            tone="base"
          />
        </div>
      </button>
    </>
  }

  return (
    remainingCount ? (
      <Popover
        active={active}
        activator={activatorMarkup()}
        onClose={onClose}
        preferredAlignment="right"
        sectioned
      >
        <Box maxWidth="300px" paddingBlockStart="100" paddingBlockEnd="150">
          <InlineStack wrap gap="100">
            {
              orderTags.map(tag => <Tag key={tag}>{tag}</Tag>)
            }
          </InlineStack>
        </Box>
      </Popover>
    ) : (
      <Box
        paddingInline="200"
      >
        {
          displayTags.length
            ? (
              <InlineStack
                gap="100"
                wrap={false}
              >
                {
                  displayTags.map(tag => <Tag key={tag}>{tag}</Tag>)
                }
              </InlineStack>
            )
            : "-"
        }
      </Box>
    )
  )
}

function CustomEmptyState({
  headings,
}: { headings: IndexTableHeading[] }) {
  const { t } = useTranslation("orders")

  return (
    <div className={styles.CustomEmptyState__Wrapper}>
      {
        headings && (
          <table className="Polaris-IndexTable__Table Polaris-IndexTable__Table--unselectable">
            <thead>
              <tr>
                {
                  headings.map(({ title }) => <th key={title as string} className="Polaris-IndexTable__TableHeading">{title}</th>)
                }
              </tr>
            </thead>
            <tbody>
              <tr className="Polaris-IndexTable__TableRow">
                {
                  headings.map(({ title }) => <td key={title as string} />)
                }
              </tr>
            </tbody>
          </table>
        )
      }
      <div className={styles.EmptySearchState__Wrapper}>
        <EmptySearchResult
          title={t("NoOrdersYet", "No orders yet")} withIllustration
          description={t("NoOrdersYetDesc", "Try changing the filters or search term")}
        />
      </div>
    </div>
  )
}


export function takeDisplayOrderTags(arr: string[], size = 50) {
  let count = 0

  return arr
    .map(item => {
      if (count + item.length <= size) {
        count += item.length
        return item
      }

      return
    })
    .filter(v => v !== undefined)
}
