import { BlockStack, Box, Button, Card, Divider, InlineGrid, Text } from "@shopify/polaris"
import classNames from "classnames"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { cloneDeep, find, get, map } from "lodash-es"
import { Observer } from "mobx-react"
import React, { useCallback, useState } from "react"
import { Trans, WithTranslation, withTranslation } from "react-i18next"

import { Switch } from "@/component"
import { ISearchParams, StatusList, UpdateStatusItemOnClickType } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"

import styles from "./UpdateStatus.module.scss"
import UpdateStatusItem from "./UpdateStatusItem"

dayjs.extend(timezone)
dayjs.extend(utc)

interface IProps {
  from: ISearchParams["from"];
  count: number;
  t: WithTranslation["t"];
}

const dateFormat = "dddd, MMMM D, YYYY h:mm a"

const UpdateStatusList: React.FC<IProps> = ({ t }) => {
  const [displayDatePick, setDisplayDatePick] = useState(false)
  const [selectItemIndex, setSelectItemIndex] = useState(stores.ordersStore.updateOrderStatus.data?.status_checked ?? -100)
  const [unixTime, setUnixTime] = useState(dayjs().unix())

  const handleChecked = useCallback((newChecked: boolean, id: string) => {
    const [status, index] = id.split("--")
    const $status = parseInt(status)
    const $index = parseInt(index)

    const { updateOrderStatus: { data }, setUpdateStatusSingleData } = stores.ordersStore
    const currentDayjs = dayjs().tz()

    const changeResult = map(data?.status_list, (value, itemIndex) => {
      const item = cloneDeep<StatusList>(value)

      if (skipStatusItem(item)) {
        return item
      }

      item.value = $index > itemIndex

      if (item.status === $status) {
        item.value = newChecked
        setUpdateStatusSingleData("status_checked", $index)

        setDisplayDatePick(item.value)
        setSelectItemIndex($index)
      }

      if (item.value && !item.timestamp) {
        item.date = currentDayjs.format(dateFormat)
        item.timestamp = currentDayjs.unix()
      }

      return item
    })

    setUpdateStatusSingleData("status_list", changeResult)
  }, [])

  const updateDatetime = useCallback((currentIndex: number, t: number) => {
    const { updateOrderStatus: { data }, setUpdateStatusItem } = stores.ordersStore

    data?.status_list.forEach((value, index) => {
      if (currentIndex === index) {
        const item = cloneDeep(value)

        item.timestamp = t
        item.date = dayjs.unix(t).tz()
          .format(dateFormat)

        setUpdateStatusItem(index, item)
      }
    })
  }, [])

  const handleUpdateStatusItemClick = useCallback(({ currentIndex, currentSelected, currentChecked }: UpdateStatusItemOnClickType) => {
    if (currentSelected && currentChecked) {
      setDisplayDatePick(false)
      setSelectItemIndex(currentIndex)
    } else if (!currentSelected && currentChecked) {
      setDisplayDatePick(true)
      setSelectItemIndex(currentIndex)
    }
  }, [])

  const skipStatusItem = useCallback((item: StatusList) => item.key === "ordered", [])

  const checkStatusInCustomStatus = useCallback((statusName: string) => {
    const { data } = stores.ordersStore.updateOrderStatus

    return !!find(data?.custom_status, ["status", statusName])
  }, [])

  const getDefaultStatusList: () => StatusList[] = useCallback(() => {
    return [
      { date: "", key: "ordered", name: "Ordered", status: 1001, value: false },
      { date: "", key: "order_ready", name: "Order Ready", status: 1100, value: false },
      { date: "", key: "transit", name: "In Transit", status: 2, value: false },
      { date: "", key: "pickup", name: "Out for Delivery", status: 3, value: false },
      { date: "", key: "delivered", name: "Delivered", status: 4, value: false },
    ]
  }, [])

  const bodyMarkup = () => {
    const { updateOrderStatus: { data, loading }, autoUpdateStatusOrder } = stores.ordersStore
    const statusList = data?.status_list ?? getDefaultStatusList()
    const statusListLength = statusList.length

    const getBorderWidth = (index: number) => {
      if (statusListLength > 1) {
        return "025"
      }

      return autoUpdateStatusOrder || index === 4 ? "0" : "025"
    }


    return statusList.map((item, index) => {
      const isOrderedItem = skipStatusItem(item)
      const idFlag = `${item.status}--${index}`

      let show = true

      if (!loading) {
        show = checkStatusInCustomStatus(item.name) || isOrderedItem || !autoUpdateStatusOrder
      }

      const checked = isOrderedItem || get(item, "value", false)

      return show && (
        <div key={index}>
          <UpdateStatusItem
            onChange={handleChecked}
            onClick={handleUpdateStatusItemClick}
            loading={loading} disabled={isOrderedItem}
            checked={checked}
            id={idFlag} index={index}
            title={item.name} subTitle={item.date}
            isSelected={displayDatePick && checked && index === selectItemIndex}
            timestamp={item?.timestamp ?? unixTime}
            onDatetimeChange={updateDatetime}
          />
          <Divider borderWidth={getBorderWidth(index)} />
        </div>
      )
    })
  }

  const { updateOrderStatus: { loading, data }, autoUpdateStatusOrder, setUpdateStatusSingleData } = stores.ordersStore

  return (<Observer>{() => (
    <Card>
      <BlockStack gap="100">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant={"headingMd"}>
            <Trans ns="orders" i18nKey="UpdateStatus.AutoUpdate" />
          </Text>

          <Switch
            disabled={loading} checked={!!data?.auto}
            onChange={checked => setUpdateStatusSingleData("auto", checked)}
          />
        </InlineGrid>

        <Text as="p" tone={"subdued"} variant={"bodyMd"}>
          <Trans ns="orders" i18nKey="ManuallyUpdateStatusTip">
            <Button
              url={"https://docs.parcelpanel.com/shopify/tracking-page/customize-shipment-status/#Manuallyupdateshipmentstatus"}
              variant={"plain"} external
            >Learn more</Button>
          </Trans>
        </Text>

        <div className={"mt-3"}>
          <div className={classNames("Polaris-ShadowBevel", styles["ItemContainer-ShadowBevel"])}>
            <Box>
              {bodyMarkup()}
            </Box>
          </div>
        </div>
      </BlockStack>
    </Card>
  )}</Observer>)
}

export default withTranslation("orders")(UpdateStatusList)
