import { Text } from "@shopify/polaris"
import { TextProps } from "@shopify/polaris/build/ts/src/components/Text/Text"
import { ReactNode } from "react"

import styles from "./InfoItem.module.scss"

export interface InfoItemProps {
  field: string
  content: ReactNode

  fieldTone?: TextProps["tone"]
}

export default function InfoItem({ field, content, fieldTone }: InfoItemProps) {
  return (
    <div className={styles.InfoItem}>
      <Text as="span" variant="bodyMd" tone={fieldTone || "subdued"}>{field}</Text>

      <Text
        as="p" variant="bodyMd"
        alignment="end"
      >{content}</Text>
    </div>
  )
}
