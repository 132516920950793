import { TextField } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { toFixedNumber } from "@/utils/analyticsUtils"

import { StaffingCalculatorModal } from "./StaffingModal"

type StaffingTimeProps = {
  open: boolean
  loading: boolean
  initValue: string
  onSave: (value: string) => void
  onClose: () => void
}
export default function StaffingTime({
  open,
  loading,
  initValue,
  onClose,
  onSave,
}: StaffingTimeProps){
  const { t } = useTranslation("dashboard")
  const [value, setValue] = useState(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const handleClose = () => {
    onClose()
    setValue(initValue)
  }
  const handleChange = (value:string) => {
    if (value.includes("e")) return
    setValue(value.slice(0, 10))
  }
  
  return <StaffingCalculatorModal
    open={open}
    loading={loading}
    title={t("SavedStaffingTimeCalculator")}
    description={t("HowCalculatedTimeDesc")}
    disabled={!value || Number(value) <= 0 || initValue === value}
    onClose={handleClose}
    onSave={() => {
      onSave(value)
    }}
  >
    <TextField
      label={t("AverageTime")}
      type={"number"}
      min={0}
      value={value}
      onChange={handleChange}
      onBlur={() => handleChange(String(toFixedNumber(value)))}
      autoComplete={"off"}
      connectedRight={<div className={"ml-1"}>{t("minute")}</div>}
      helpText={t("AverageTimelyHelp")}
    />
  </StaffingCalculatorModal>
}
