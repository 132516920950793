export interface ShipmentStatus {
  value: number;
  name: string;
  color: string;
  type: number;
  tran_key?: string;// 2022-04-20 新加字段
}

export const ShipmentsStatus = {
  1: {
    name: "Pending",
    color: "#6D7175",
    translationKey: "Pending",
  },
  2: {
    name: "In Transit",
    color: "#1E93EB",
    translationKey: "InTransit",
  },
  3: {
    name: "Out for Delivery",
    color: "#FCAF30",
    translationKey: "OutForDelivery",
  },
  4: {
    name: "Delivered",
    color: "#1BBE73",
    translationKey: "Delivered",
  },
  5: {
    name: "Expired",
    color: "#BABEC3",
    translationKey: "Expired",
  },
  6: {
    name: "Failed Attempt",
    color: "#8109FF",
    translationKey: "FailedAttempt",
  },
  7: {
    name: "Exception",
    color: "#FD5749",
    translationKey: "Exception",
  },
  8: {
    name: "Info Received",
    color: "#00A0AC",
    translationKey: "InfoReceived",
  },
}
