import {
  orderStatusType,
  ResetTemplateType,
  sendTestEmailResult,
  TemplateConfigType,
} from "@/pages/Settings/EmailEdit/type"
import baseRequest from "@/utils/baseRequest"

export default {
  /**
   * 获取 email template配置信息
   * @param locale string 语言简码
   */
  getEmailTemplate(locale?: string) {
    return baseRequest.get<{ code: number, data: TemplateConfigType }>("v2/settings/email-templates", {
      params: { locale },
    })
  },

  /**
   * 获取 email template variable列表
   * @param locale string 语言简码 不传此参数，则获取默认店铺语言的变量列表。不会校验 locale 是否为用户发布的语言列表中
   */
  getEmailVariable(locale?: string) {
    return baseRequest.get("v2/settings/email-templates/variable", {
      params: { locale },
    })
  },

  /**
   * 设置 email template配置信息
   * @param config TemplateConfigType 配置信息
   */
  setEmailTemplate(config: TemplateConfigType) {
    return baseRequest.put<{ code: number, data: TemplateConfigType }>("v2/settings/email-templates", config)
  },
  /**
   * 切换自动多语言
   * @param auto_multilingual 是否为自动多语言
   */
  switchAutomaticMultilingual(auto_multilingual: boolean) {
    return baseRequest.put<{ code: number, data: TemplateConfigType }>("v2/settings/email-templates", {
      branding_config: { auto_multilingual },
    })
  },
  /**
   * 重置默认模板
   * @param locale 语言
   * @param shipment_status 物流状态
   */
  resetEmailTemplate(params: { locale: string, shipment_status: orderStatusType }) {
    return baseRequest.post<{ code: number, data: ResetTemplateType }>("v2/settings/email-templates/reset", params)
  },
  /**
   * 发送测试邮件
   * @param email 测试邮箱
   * @param subject 标题
   * @param content 内容
   */
  sendTestEmail(params: { email: string, subject: string, content: string }) {
    return baseRequest.post<{ code: number, msg: string, data: sendTestEmailResult }>("v2/settings/email-templates/send-test", params)
  },
}
