import {
  Layout, LegacyCard, ProgressBar, TextContainer,
} from "@shopify/polaris"
import React from "react"

import { SpinnerContainer } from "@/component"

export default function SkeletonTable() {

  return (
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SpinnerContainer loading>
              <div style={{ height: "664px" }} />
            </SpinnerContainer>
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  )
}
