import { Button, InlineStack, Tag } from "@shopify/polaris"
import { InlineStackProps } from "@shopify/polaris/build/ts/src/components/InlineStack/InlineStack"
import React, { useCallback, useMemo, useState } from "react"

export interface TagListProp {
    tags: string[]
    threshold?: number
    nameText?: string
    gap?: InlineStackProps["gap"]

    onRemove?(tag: string, index: number): void
}

const TagList = ({ tags, nameText = "tag", gap = "100", threshold = 20, onRemove }: TagListProp) => {
  const [showMore, setShowMore] = useState(false)
  const tagsLength = tags.length

  // 使用 useMemo 缓存显示的标签
  const displayedTags = useMemo(() => tags.sort().slice(0, showMore ? tagsLength : threshold), [tags, showMore])

  // 使用 useCallback 缓存函数，避免重复创建
  const handleRemove = useCallback((tag: string, index: number) => {
    onRemove && onRemove(tag, index)
  }, [onRemove])

  const handleShowMore = useCallback(() => {
    setShowMore(true)
  }, [])

  return (
    <InlineStack gap={gap}>
      {displayedTags.map((tag, index) => (
        <Tag key={tag} onRemove={() => handleRemove(tag, index)}>
          {tag}
        </Tag>
      ))}
      {tagsLength > threshold && !showMore && (
        <Button variant="plain" onClick={handleShowMore}>
                    +{String(tagsLength - threshold)} {nameText}
        </Button>
      )}
    </InlineStack>
  )
}

// 使用 React.memo 包裹组件，防止不必要的重新渲染
export default React.memo(TagList)
