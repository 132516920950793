import { Text, Tooltip } from "@shopify/polaris"
import React, { Suspense } from "react"

import { formatNumber } from "@/utils/analyticsUtils"

import styles from "./styles.module.scss"
const SvgLineChart = React.lazy(() => import("./SvgLineChart"))

interface OrderStatusCardProps {
  title: string
  type: number
  description?: string
  value?: number
  chartData: number[]
  onClick?: (type: number) => void
}
export default function OrderStatusCard({
  title,
  description,
  value,
  type,
  chartData,
  onClick,
}:OrderStatusCardProps){
  return <div className={styles.cardWrapper} onClick={() => onClick && onClick(type)}>
    <Tooltip content={description} hasUnderline>
      <Text as={"span"} variant={"headingMd"}>{title}</Text>
    </Tooltip>

    <div className={"mt-3 flex justify-between"}>
      <Text as={"p"} variant={"headingLg"}>{formatNumber(value || "-")}</Text>

      <Suspense fallback={null}>
        <SvgLineChart chartData={chartData} />
      </Suspense>
    </div>
  </div>
}
