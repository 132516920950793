import { BlockStack, Box, Button, ChoiceList, Modal, Popover, Text } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import stores from "@/stores"

import { IReSyncControlProps, ISingleChoiceSelectList } from "./types"


export function ReSyncControl({
  syncTimeList,
  onSyncAndSave,
  disabledSyncBtn,
}: IReSyncControlProps) {
  const [active, setActive] = useState(false)

  const toggleActive = useCallback(
    () => setActive(!active),
    [active],
  )

  const { t } = useTranslation(["orders", "common"])

  const translation = {


    disabledSyncBtn,


    // 同步按钮
    syncBtn: t("orders:ReSync.SyncAndSave"),


    // 同步弹出显示
    syncOpen: t("orders:ReSync.title"),
    // 同步弹出层标题
    syncTitle: t("orders:ReSync.SelectSyncTime"),
  }

  const syncButtonMarkup = (
    <Button disclosure onClick={toggleActive} variant="secondary">
      {translation.syncOpen}
    </Button>
  )

  const _syncTimeList = syncTimeList.map(item => {
    if (item?.tran_key) {
      item.label = t(item.tran_key as any)

      if (item.tran_key === "LastNDays") {
        item.label = t(item?.tran_key as any, { day: item.value })
      }
    }

    return item
  })

  const [showSyncModal, setShowSyncModal] = useState(false)
  const [syncingAndSaving, setSyncingAndSaving] = useState(false)
  const [selected, setSelected] = useState("")

  const handleSyncOrdersButtonClick = async () => {
    setSyncingAndSaving(true)
    await onSyncAndSave(selected, t("orders:Syncing"))
    setShowSyncModal(false)
    setSyncingAndSaving(false)
    return
  }

  const handleClick = (selected: string) => {
    setSelected(selected)
    setShowSyncModal(true)
  }

  return (
    <>
      <Modal
        open={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={t("orders:SyncOrders.title")}
        primaryAction={{
          content: t("orders:SyncOrders.title"),
          onAction: handleSyncOrdersButtonClick,
          loading: syncingAndSaving || translation.disabledSyncBtn,
        }}
        secondaryActions={[
          {
            content: t("common:Cancel"),
            onAction: () => setShowSyncModal(false),
          },
        ]}
        sectioned
      >
        <Modal.Section>
          <p>
            {t("orders:SyncOrders.p3")}
          </p>
        </Modal.Section>
      </Modal>

      <Popover
        sectioned
        preferredAlignment="right"
        active={active}
        activator={syncButtonMarkup}
        onClose={() => setActive(false)}
      >
        <Popover.Pane>
          <Box
            paddingBlock="200"
            paddingInline="300"
          >
            <SingleChoiceSelectList
              translation={translation}
              choices={_syncTimeList}
              handleClick={handleClick}
            />
          </Box>
        </Popover.Pane>
      </Popover>
    </>
  )
}


function SingleChoiceSelectList({
  translation,
  choices,
  handleClick,
}: ISingleChoiceSelectList) {
  // 使用了 store
  const defaultValue = `${stores.userInfoSore.data.syncDay}` || "30"

  const [selected, setSelected] = useState(defaultValue)

  return (<>
    <BlockStack
      gap="100"
    >
      <Text
        as="span"
        fontWeight="semibold"
      >{translation.syncTitle}</Text>
      <div className="PP-Choice-List-2">
        <ChoiceList
          title={null}
          titleHidden
          choices={choices}
          selected={[selected]}
          onChange={(selected) => setSelected(selected[0] || defaultValue)}
        />
      </div>
      <Button
        fullWidth
        onClick={() => handleClick(selected)}
        disabled={translation.disabledSyncBtn}
      >
        {translation.syncBtn}
      </Button>
    </BlockStack>
  </>)
}
