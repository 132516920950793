import { LegacyCard, Link } from "@shopify/polaris"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import CircleTickListCard from "@/component/CircleTickListCard"

const DescCards = () => {
  const { t } = useTranslation("account")

  return <>
    {/* <Card title={t("toBookADemoTitle")} sectioned>*/}
    {/*  <Trans i18nKey={"toBookADemoDesc"} ns="account">*/}
    {/*    <Link*/}
    {/*      url="https://calendly.com/parcelpanel/demo"*/}
    {/*      removeUnderline*/}
    {/*      external*/}
    {/*    />*/}
    {/*  </Trans>*/}
    {/* </Card>*/}

    <CircleTickListCard
      title={t("quotaMeanTitle")}
      list={[
        t("quotaMean1"),
        t("quotaMean2"),
        t("quotaMean3"),
      ]}
    />

    <CircleTickListCard
      title={t("ChangeMyPlanTitle")}
      subTitle={t("ChangeMyPlanDesc")}
      list={[
        <Trans i18nKey={"ChangeMyPlanDescItem1"} ns="account" key={"ChangeMyPlanDescItem1"}>
          <Link
            url="https://help.shopify.com/manual/your-account/manage-billing/your-invoice/apps#app-prorating-upgrades-downgrades"
            removeUnderline
            external
          />
        </Trans>,
        t("ChangeMyPlanDescItem2"),
        t("ChangeMyPlanDescItem3"),
      ]}
    />

    <CircleTickListCard
      title={t("question1Title")}
      list={[
        <div key={"question1Desc1"}>
          {t("question1Desc1")}
        </div>,
        <Trans i18nKey={"question1Desc4"} ns="account" key={"question1Desc4"}>
          <Link
            url="https://docs.parcelpanel.com/shopify/plan-billing/change-the-spending-limit/"
            removeUnderline
            external
          />
        </Trans>,
      ]}
    />

    <LegacyCard title={t("question2Title")} sectioned>
      {t("question2Desc")}
    </LegacyCard>
  </>
}

export default DescCards
