import classNames from "classnames"
import React, { memo, NamedExoticComponent } from "react"

import { elementChildren, wrapWithComponent } from "@/utils/components"

import { Item } from "./components/Item"
import styles from "./DoubleColumnGuide.module.scss"

interface TwoColumnsProps {
  children?: React.ReactNode;
}

export const DoubleColumnGuide = memo(function DoubleColumnGuide({ children }: TwoColumnsProps) {
  const className = classNames(
    styles.DoubleColumnGuide,
  )

  const itemMarkup = elementChildren(children)
    .map((child, index) => {
      const props = { key: index }
      // @ts-ignore

      return wrapWithComponent(child, Item, props)
    })

  return <div className={className}>{itemMarkup}</div>
}) as NamedExoticComponent<TwoColumnsProps> & {
  Item: typeof Item;
}

DoubleColumnGuide.Item = Item
