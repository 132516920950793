import classNames from "classnames"

import { TimelineProps } from "@/pages/Orders/components/NotificationHistoryModal/Timeline"
import TimelineItem from "@/pages/Orders/components/NotificationHistoryModal/TimelineItem"

import styles from "./Timeline.module.scss"

const TimelineItemList: React.FC<TimelineProps> = ({
  items,
  ...restProps
}: TimelineProps) => {
  const mergedItems = [...(items || [])]

  const itemsCount = mergedItems.length

  const lastCls = styles.Timeline_Item_Last

  const itemsList = mergedItems
    .map((item, idx) => {
      const readyClass = idx === itemsCount - 1 ? lastCls : ""
      const { className: itemClassName, ...itemProps } = item

      return (
        <TimelineItem
          {...itemProps} key={item?.key || idx}
          className={classNames([
            itemClassName,
            readyClass,
          ])}
        />
      )
    })

  return (
    <div {...restProps} className={styles.TimelineItemList}>
      {itemsList}
    </div>
  )
}

export default TimelineItemList
