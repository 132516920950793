enum ShipmentFilterKey {
  OrderDate = "OrderDate",
  FulfillmentDate = "FulfillmentDate",
  Status = "Status",
  Destination = "Destination",
  Courier = "Courier",
  OrderTag = "OrderTag",
  TransitTime = "TransitTime",
  ResidenceTime = "ResidenceTime",
  PaypalSyncStatus = "PaypalSyncStatus",
}

export default ShipmentFilterKey
