import { CountriesAndTimeZonesData } from "@/api/base"
import { ITrackingPageOptions } from "@/models/ITrackingPageOptions"
import request, { graphqlShopifyRequest } from "@/utils/request"

const GRAPHQL_ENDPOINT = "https://atlas.shopifysvc.com/graphql"

export const queryCountriesAndTimeZones = `
  query CountriesAndTimeZones($locale: SupportedLocale!) {
    countries(locale: $locale) {
      code
      continent
      currencyCode
      name
      provinceKey
      provinces {
        name
        code
      }
    }
    timeZones(locale: $locale) {
        olsonName,
        description
    }
  }
`

export default {
  /**
   * 获取基础配置信息
   */
  baseInfo() {
    return request.get("tracking-page/meassage")
  },

  productCollects() {
    return request.get("tracking-page/collects")
  },

  /**
   * 保存配置信息
   * @param changeDateArr
   */
  save(changeDateArr: Partial<ITrackingPageOptions>) {
    return request.post("tracking-page/save", { changeDateArr })
  },

  fetchCountriesAndTimeZones(variables: { locale: string }) {
    return graphqlShopifyRequest<CountriesAndTimeZonesData>(GRAPHQL_ENDPOINT, queryCountriesAndTimeZones, variables)
  },
}


