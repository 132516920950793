import { SkeletonBodyText } from "@shopify/polaris"
import React from "react"

interface IProps {
  lines?: number;
  width?: number | string;
}
function SkeletonOptionListDrag({ lines, width }: IProps) {
  return (
    <div style={{ width: width || "216px", padding: "var(--p-space-300)" }}>
      <SkeletonBodyText lines={lines ?? 4} />
    </div>
  )
}

export default SkeletonOptionListDrag
