import { Path } from "@remix-run/router/history"
import { useCallback, useContext, useEffect } from "react"
import { NavigateOptions } from "react-router/dist/lib/context"
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom"


type pushArgs = [to: Partial<Path>, state?: any, opts?: NavigateOptions]

function useConfirmExit(confirmExit: (arg: pushArgs) => boolean, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) {
      return
    }

    const { push } = navigator

    navigator.push = (...args: pushArgs) => {
      const result = confirmExit(args)

      if (result) {
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}


export type BlockerFunction = (args: pushArgs) => boolean;

export function usePrompt(blocker: BlockerFunction, when = true) {
  useEffect(() => {
    if (when) {
      // 离开页面时候触发
      window.onbeforeunload = function () {
        console.log("leave page")
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [blocker, when])

  const confirmExit = useCallback((args: pushArgs) => blocker(args), [blocker])

  useConfirmExit(confirmExit, when)
}
