import { PostPurchaseEDD, PostPurchaseEDDModule } from "@/models/ITrackingPageOptions"

export const defaultEDDModuleConfig: PostPurchaseEDDModule = {
  "fulfillment_workflow": {
    "cutoff_time": {
      "time": "23:30",
      "tz": "America/Phoenix",
    },
    "business_days": [0, 1, 2, 3, 4, 5, 6],
    "processing_time": 0,
  },
  "transit_time": {
    "rules": [],
    "default": {
      "date_range": {
        "min": 10,
        "max": 20,
      },
    },
  },
}

export const defaultEDDConfig: PostPurchaseEDD = {
  "enabled": true,
  "calc_from": 1,
  ...defaultEDDModuleConfig,
}
