import { Observer } from "mobx-react"
import React from "react"

import { CurrentSubscription } from "@/api/account/type.d"
import appApi from "@/api/app"
import { ImageBanner } from "@/component/ImageBanner/ImageBanner"
import { MarketingPicture } from "@/component/ImageBanner/MarketingPicture"
import stores from "@/stores"

import styles from "./index.module.scss"
import PlanUpdateBanner from "./PlanUpdate"
import ShowAccountPlanErr from "./ShowAccountPlanErr"

type AccountInfoProps = {
  currentSubscription: CurrentSubscription
}

const AccountBanners = ({ currentSubscription }: AccountInfoProps) => {
  return <Observer>{() => {
    const { userInfoSore } = stores

    const hidePlanUpdateBanner = currentSubscription?.version === 4
      || !currentSubscription?.pricing_update?.display_banner
      || (currentSubscription?.next_plan && currentSubscription?.next_plan.version === 4) // 或者存在next
      // plan，因为若是降级套餐不会立即生效，会现有next
      // plan 到了日子version才会变成3
      || currentSubscription?.level === 1 // 免费套餐的时候也没必要显示

    return <div className={styles.bannersWrapper}>
      <MarketingPicture
        show={userInfoSore.data.banner_discount_v2024_04.billing}
        src={userInfoSore.data.banner_discount_v2024_04.second_banner_url}
        onClose={() => {
          appApi.dispatchMarketingBanner("billing")
            .then(({ data }) => {
              userInfoSore.setDataSingle("banner_discount_v2024_04", {
                ...userInfoSore.data.banner_discount_v2024_04,
                ...data.banner_discount_v2024_04,
              })
            })
        }}
      />

      {userInfoSore.data.discount.banner_id_account && userInfoSore.data.discount_start && <ImageBanner
        id={userInfoSore.data.discount.banner_id_account}
        show={!userInfoSore.data.discount.banner_displayed_account}
        onClose={() => userInfoSore.setDiscountSingle("banner_displayed_account", true)}
        src={userInfoSore.data.discount.banner_img}
      />}

      {/* 套餐更新banner */}
      {currentSubscription?.pricing_update && !hidePlanUpdateBanner
      && <PlanUpdateBanner
        discountCode={currentSubscription?.pricing_update.discount_code}
      />}

      {/* 用户套餐异常提示 */}
      {stores.userInfoSore.data.show_account_tip_a && <ShowAccountPlanErr />}
    </div>
  }
  }</Observer>
}

export default AccountBanners

