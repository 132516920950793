import { ActionList, Banner, Button, Icon, Popover } from "@shopify/polaris"
import { ChatIcon, HideIcon, MenuHorizontalIcon, XIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
// import * as React from "react";
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import dashboard from "@/api/dashboard"
import { BadFace, GoodFace } from "@/assets/svg"
import styles from "@/pages/Dashboard/Dashboard.module.scss"
import stores from "@/stores"

interface IProps {
  /* 是否显示关闭 Banner 按钮 */
  doIconHidden: boolean,
  showDismiss: boolean,
  showButton: boolean
}

export default function ReviewGuide(props: IProps) {
  const { t } = useTranslation("common")
  const { userInfoSore } = stores
  const closeReviewGuide = (force: boolean = false) => {
    dashboard.closeReviewGuide(force)
      .then(res => {
        const { data } = res

        if (data.code === APICode.OK) userInfoSore.setDataSingle("notice_fou", 1)
      })
  }

  const badFaceHandle = () => {
    stores.commonStore.setShowFeedbackModal(true)
    props.doIconHidden && closeReviewGuide(true)

    // 弹出提示框 默认都为4星 区分之前的 1-5
    stores.Post.setShowCommentModal(true)
    stores.Post.setRate(1)
  }

  function ActionListWithMediaExample() {

    const [active, setActive] = useState(false)

    const toggleActive = useCallback(
      () => setActive(!active),
      [active],
    )

    const activator = (
      <Button onClick={toggleActive} icon={MenuHorizontalIcon} variant="plain">
      </Button>
    )

    const selectDisActive = () => {
      closeReviewGuide()
    }

    const selectActive = () => {
      closeReviewGuide(true)
    }

    return (<>
      <div>
        <Popover
          active={active}
          activator={activator}
          autofocusTarget="first-node"
          onClose={toggleActive}
        >
          <ActionList
            actionRole="menuitem"
            items={[
              { content: t("Dismiss"), icon: XIcon, onAction: selectDisActive },
              { content: t("RemindMeLater"), icon: HideIcon, onAction: selectActive },
            ]}
          />
        </Popover>
      </div>
    </>)
  }

  let cssA = [styles.reviewsOperate, styles.reviewsLine_0]

  if (props.showButton) {
    cssA = [styles.reviewsOperate, styles.reviewsLine_0, "mr-10"]
  }

  return (
    <Banner
      icon={ChatIcon}
      onDismiss={props.showDismiss ? () => {
        closeReviewGuide(false)
      } : undefined}
    >
      <div className={classNames(["flex", "flex-wrap", "justify-between", styles.reviewsLine])}>
        <p className={styles.feedbackContent}>{t("FeedbackBanner.p1")}</p>
        <div className={classNames(cssA)}>
          <a className={styles.badFace} onClick={badFaceHandle}>
            <Icon source={BadFace} />&nbsp;<span className={classNames(styles.reviewsLine)}>{t("Bad")}</span>
          </a>
          <a className={styles.goodFace} href="https://apps.shopify.com/parcelpanel?reveal_new_review=true"
            target="_blank" onClick={() => props.doIconHidden && closeReviewGuide(true)} rel="noreferrer"
          >
            <Icon source={GoodFace} />&nbsp;<span className={classNames(styles.reviewsLine)}>{t("Good")}</span>
          </a>
        </div>
      </div>

      {
        props.showButton
          ? (
            <div style={{ position: "absolute", right: "1rem", top: "0.725rem" }}>
              {ActionListWithMediaExample()}
            </div>
          )
          : ""
      }
    </Banner>
  )
}
