import { Layout, LegacyCard, Page, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

import { Container } from "@/component"

export default function SkeletonSettings() {
  const { t } = useTranslation("menu")

  return (
    <Container id="Page-Settings">
      <Page title={t("settings")}></Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={5} />
            </TextContainer>
          </LegacyCard>

          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={5} />
            </TextContainer>
          </LegacyCard>


          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={5} />
            </TextContainer>
          </LegacyCard>


          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={5} />
            </TextContainer>
          </LegacyCard>

        </Layout.Section>
      </Layout>
    </Container>
  )
}
