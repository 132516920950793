import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import OrdersDetail from "@/pages/Orders/OrdersDetail"

export function ShipmentInfo() {
  const { shipmentID } = useParams()
  const [searchParams] = useSearchParams()

  const navigator = useNavigate()

  if (!shipmentID) {
    navigator("/orders")
    return (<div>404</div>)
  }

  return (
    <OrdersDetail numberID={shipmentID} numberIDList={searchParams.get("number-ids") || undefined} />
  )
}
