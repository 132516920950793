import { Layout, LegacyCard, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris"
import React from "react"

interface IProps {
  blockNums?: number;
  lines?: number;
}

export default function SkeletonTrackingPage({ blockNums = 3, lines = 2 }: IProps) {
  return (
    <Layout>
      <Layout.Section variant="oneThird">
        {new Array(blockNums).fill(0)
          .map((_, index) => (
            <LegacyCard sectioned key={index}>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={lines} />
              </TextContainer>
            </LegacyCard>
          ))}
      </Layout.Section>
    </Layout>
  )
}
