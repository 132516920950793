const SVGDefault = (props: any) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#00A0AC" />
    <path
      d="M7.29232 6.29487V8.08974H12.6769V6.29487H13.5775C13.8234 6.29487 14.0231 6.49455 14.0231 6.74044V13.9262C14.023 14.0444 13.976 14.1576 13.8925 14.2412C13.8089 14.3247 13.6957 14.3717 13.5775 14.3718H6.39174C6.27361 14.3717 6.16034 14.3247 6.0768 14.2412C5.99327 14.1576 5.94629 14.0444 5.94617 13.9262V6.74044C5.94617 6.49455 6.14585 6.29487 6.39174 6.29487H7.29232ZM8.18976 5.39743H11.7795V7.1923H8.18976V5.39743Z"
      fill="white"
    />
    <path
      d="M10.7415 13.5C10.7415 14.7813 11.7802 15.82 13.0615 15.82C14.3429 15.82 15.3815 14.7813 15.3815 13.5C15.3815 12.2187 14.3429 11.18 13.0615 11.18C11.7802 11.18 10.7415 12.2187 10.7415 13.5Z"
      fill="white" stroke="#00A0AC" strokeWidth="0.64"
    />
  </svg>
}

export default SVGDefault
