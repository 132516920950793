import classNames from "classnames"
import React from "react"

import { BlockMaskLayerProps } from "./types"

export default function BlockMaskLayer(props: BlockMaskLayerProps) {
  const _classNames = props.className ?? []

  const sectionMarkup = props.show ? (<>
    <div style={props.style} className={classNames([
      "absolute", "z-10", "w-full", "h-full", "flex",
      "items-center", "justify-center", "bg-white", "bg-opacity-80",
      ..._classNames,
    ])}
    >
      {props.maskNode}
    </div>
    {props.children}
  </>) : (props.children)

  return (
    <div className={classNames(["relative"])}>
      {sectionMarkup}
    </div>
  )
}
