import { isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { Badge, Button, Card, Icon, LegacyCard, Link, OptionList, Page, Popover, Text } from "@shopify/polaris"
import {
  CalendarIcon,
  CartUpIcon,
  CodeIcon,
  LanguageIcon,
  LinkIcon,
  MarketsIcon,
  MenuHorizontalIcon,
  OrdersStatusIcon,
  StoreIcon,
} from "@shopify/polaris-icons"
import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { HotBadge } from "@/component"
import stores from "@/stores"
import { openLink } from "@/utils/redirectShopify"

import SkeletonTrackingPage from "../SkeletonTrackingPage/SkeletonTrackingPage"
import styles from "./TabPage.module.scss"

export default function TabPage(props: any) {
  const { t } = useTranslation("tracking_page")
  const { userInfoSore } = stores
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selected, setSelected] = useState<string[]>(["appearance"])


  const [popoverActive, setPopoverActive] = useState(false)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )

  useEffect(() => {
    if (pathname.startsWith("/tracking-page/")) {
      const urlName = pathname.substring(15)  // 获取/tracking-page/后面的

      setSelected([urlName])
    }
    if (pathname === "/tracking-page") {
      setSelected(["appearance"])
    }
  }, [pathname])


  const tabListText = [
    {
      icon: StoreIcon, id: "appearance", name: t("TabPage.Appearance"),
    },
    {
      icon: LanguageIcon, id: "languages", name: t("TabPage.Languages"),
    },
    {
      icon: OrdersStatusIcon,
      id: "customShipmentStatus",
      name: t("TabPage.CustomShipmentStatus"),
    },
    {
      icon: CalendarIcon,
      id: "estimatedDeliveryTime",
      name: t("TabPage.EstimatedDeliveryTime"),
    },
    {
      icon: CartUpIcon,
      id: "productRecommendation",
      name: t("TabPage.ProductRecommendation"),
    },
    // {
    //   id: 'additionalText', icon: TextIcon, name: t('TabPage.AdditionalText'),
    // },
    {
      icon: MarketsIcon,
      id: "seoOptimization",
      name: t("TabPage.SEOOptimization"),
    },
    {
      icon: LinkIcon,
      id: "trackingPageUrl",
      name: t("TabPage.TrackingPageUrl"),
    },
    {
      icon: CodeIcon, id: "CSS&HTML", name: t("TabPage.CSS&HTML"),
    },
  ]
  const hotList = ["productRecommendation", "estimatedDeliveryTime", "customShipmentStatus"]

  return <>
    <Page
      maxWidth={"1096px"}
      // @ts-ignore
      title={t("tracking_page", { ns: "menu" })}
      // @ts-ignore
      subtitle={
        <div
          className={"hover:underline cursor-pointer"}
          onClick={() => openLink(`https://${userInfoSore.data.last_track_url}`)}
        >
          <Text variant={"bodySm"} as={"span"}>https://{userInfoSore.data.last_track_url}</Text>
        </div>
      }
      secondaryActions={
        <div className={classNames(styles.tabPageTitle, styles["tabPageTitle-mobile"])}>
          <Popover
            active={popoverActive}
            activator={
              <Button onClick={togglePopoverActive} variant={"tertiary"} icon={MenuHorizontalIcon} />
            }
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
          >
            <div className={classNames("p-[var(--p-space-150)]", styles.mobileOptionList)}>{<OptionList
              onChange={(e) => {
                setSelected(e)
                togglePopoverActive()
                navigate("/tracking-page/" + e[0])
              }}
              options={tabListText.map(item => {
                return {
                  label: <div key={item.id}
                    className={"flex items-center gap-[var(--p-space-200)]"}
                  >
                    <Icon source={item.icon} />
                    {item.name}
                  </div>,
                  value: item.id,
                }
              })}
              selected={selected}
            />}</div>
          </Popover>
        </div>
      }
      primaryAction={
        <Button url={`https://${userInfoSore.data.last_track_url}?nums=1234&preview=parcelpanel`} external
          variant="primary"
        >
          {t("preview")}
        </Button>
      }
    />

    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.isTabPage}>
        <div className={styles.tabPage} style={{ top: isShopifyEmbedded() ? 20 : 70 }}>
          <LegacyCard>
            <OptionList
              onChange={(e) => {
                setSelected(e)
                navigate("/tracking-page/" + e[0])
              }}
              options={tabListText.map(item => {
                return {
                  label: <div key={item.id}
                    className={"flex items-center gap-[var(--p-space-100)]"}
                  >
                    <Icon source={item.icon} />
                    <div className={"ml-1"}>{item.name}
                      {hotList.includes(item.id) && <HotBadge className={["inline-block", "ml-1"]}>Hot</HotBadge>}
                    </div>
                  </div>,
                  value: item.id,
                }
              })}
              selected={selected}
            />
          </LegacyCard>
        </div>
      </div>
      <div className={styles.tabPageContent}>
        <div className={styles.tabPageContentList}>
          <div>
            {
              props.loading
                ? <SkeletonTrackingPage />
                : <Outlet />
            }
          </div>
        </div>
      </div>
    </div>
  </>
}
