import { TextField } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { toFixedNumber } from "@/utils/analyticsUtils"

import { StaffingCalculatorModal } from "./StaffingModal"

type StaffingCostProps = {
  open: boolean
  loading: boolean
  inputLoading: boolean
  initValue: string
  prefix?: string
  onSave: (value: string) => void
  onClose: () => void
}
export default function StaffingCost({
  open,
  loading,
  inputLoading,
  initValue,
  prefix,
  onClose,
  onSave,
}: StaffingCostProps){
  const { t } = useTranslation("dashboard")
  const [value, setValue] = useState(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const handleClose = () => {
    onClose()
    setValue(initValue)
  }

  const handleChange = (value: string) => {
    if (value.includes("e")) return
    
    // 需要货币转换的情况，输入框中的值loading，不可编辑
    !inputLoading && setValue(value.slice(0, 10)) // 最多取十位
  }

  return <StaffingCalculatorModal
    open={open}
    loading={loading}
    title={t("SavedStaffingCostCalculator")}
    description={t("HowCalculatedCostDesc")}
    disabled={!value || Number(value) <= 0 || initValue === value}
    onClose={handleClose}
    onSave={() => {
      onSave(value)
    }}
  >
    <TextField
      label={t("AverageHourly")}
      type={"number"}
      min={0}
      disabled={inputLoading}
      loading={inputLoading}
      prefix={prefix}
      value={inputLoading ? "" : value}
      onChange={handleChange}
      onBlur={() => handleChange(String(toFixedNumber(value)))}
      autoComplete={"off"}
      helpText={t("AverageHourlyHelp")}
    />
  </StaffingCalculatorModal>
}
