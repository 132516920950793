import { BlockStack, Button, Modal, RangeSlider, Text, TextField } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"
import validator from "validator"

import accountApi from "@/api/account"
import stores from "@/stores"
import { formatNumber } from "@/utils/analyticsUtils"
import toast from "@/utils/toast"

const CustomPlan = () => {
  const { t } = useTranslation(["account", "common"])
  const { userInfoSore } = stores
  const [showModal, setShowModal] = useState(false)
  const [saving, setSaving] = useState(false)
  const [rangeValue, setRangeValue] = useState<[number, number]>([70000, 110000])
  const [email, setEmail] = useState(userInfoSore.data.user_email) // 默认取用户的联系邮箱
  const [emailError, setEmailError] = useState("")
  const [content, setContent] = useState("")
  const { showNewMessages } = useIntercom()
  const disabledSaveButton = email === ""

  const handleModalSave = () => {
    if (!validator.isEmail(email)) return

    setSaving(true)

    accountApi.requestCustomPlan({
      email,
      content,
      order_volume_min: String(formatNumber(rangeValue[0])),
      order_volume_max: String(getEndValue(rangeValue[1])),
    })
      .then(() => {
        toast(t("common:SentSuccessfully"))
        setShowModal(false)
      })
      .catch(() => {
        toast(t("common:SentFailed"), 4e3, true)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const handleRangeSliderChange = useCallback((value: [number, number]) => {
    setRangeValue(value)
  }, [])
  const handleEmailChange = useCallback((value: string) => {
    setEmail(value)
    setEmailError("")
  }, [])
  const handleContentChange = useCallback((value: string) => {
    setContent(value)
  }, [])

  const [startValue, endValue] = rangeValue

  // 设置最后一部有20w+选项
  const getEndValue = (value: number) => value > 200000 ? "200,000+" : formatNumber(value)

  const orderVolume = `${formatNumber(startValue)} - ${getEndValue(endValue)}`

  return <>
    <div className={"text-left"}>
      <Trans
        ns="account"
        i18nKey="NeedMoreQuota"
      >
        <Button onClick={() => setShowModal(true)} variant="plain" />
      </Trans>
    </div>

    <Modal
      instant
      open={showModal}
      onClose={() => setShowModal(false)}
      title={t("account:RequestCustomPlan")}
      primaryAction={{
        content: t("account:SendRequest"),
        disabled: disabledSaveButton,
        loading: saving,
        onAction: handleModalSave,
      }}
      sectioned
    >
      <BlockStack gap="300">
        <p>{t("account:RequestCustomPlanDesc1")}</p>
        <Text as="p" fontWeight="medium">{t("account:RequestCustomPlanDesc2")}</Text>

        <RangeSlider
          label={`${t("account:MonthlyOrderVolume")} ${orderVolume}`}
          value={rangeValue}
          min={50000}
          max={210000} // 使用21w的刻度来代替20w+（不可打开output）
          step={10000}
          prefix={"50,000"}
          suffix={"200,000+"}
          onChange={handleRangeSliderChange}
        />

        <TextField
          label={t("account:YourContactEmail")}
          value={email}
          error={emailError}
          onChange={handleEmailChange}
          autoComplete="off"
          onBlur={() => {
            if (email && !validator.isEmail(email)) {
              setEmailError(t("common:InvalidEmail"))
            }
          }}
        />

        <TextField
          multiline={5}
          maxHeight={130}
          label={t("account:TellUsMore")}
          value={content}
          onChange={handleContentChange}
          autoComplete="off"
        />

        <Text as="p">
          <Trans
            ns="account"
            i18nKey="ChatUsNow"
          >
            <Button
              onClick={() => showNewMessages()}
              variant="plain"
            />
          </Trans>
        </Text>
      </BlockStack>
    </Modal>
  </>
}

export default CustomPlan
