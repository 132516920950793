import { Box, Button, EventListener, InlineStack, LegacyStack, Modal, Popover, Tag, TextField } from "@shopify/polaris"
import { StarFilledIcon, StarIcon } from "@shopify/polaris-icons"
import { debounce } from "lodash-es"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { tabNavConfig } from "@/pages/Orders/config"

import { ConfirmModalProps, SaveFiltersControlProps } from "./types"

export function SaveFiltersControl({
  appliedFilters,
  saved,
  currentTabName,
  onSave,
  onDelete,
}: SaveFiltersControlProps) {
  const { t } = useTranslation(["orders", "common"])
  const MAX_LENGTH = 40

  // 显示模式 图标 或 图标+文本
  const [condensed, setCondensed] = useState(true)

  // 关闭弹窗后组件没有销毁，状态会一直保留
  const [viewName, setViewName] = useState(currentTabName)

  // useEffect(() => {
  //   setViewName(!trim(currentTabName) ? "" : currentTabName)
  // }, [ currentTabName ])

  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [popoverActive, setPopoverActive] = useState(false)
  const [saving, setSaving] = useState(false)

  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive])
  const toggleConfirmation = useCallback(
    () => {
      setAlertModalOpen(prevState => !prevState)
      setPopoverActive(false)
    },
    [],
  )

  useEffect(() => {
    handleResize()
  }, [])

  const handleResize = () => {
    /* 借鉴了 Shopify 的 resize 逻辑 */
    condensed !== (() => {
      if ("undefined" === typeof window) {
        return false
      }
      const e = window.matchMedia("(max-width: 650px)")

      return e.matches
    })() && setCondensed(!condensed)
  }


  const handleSaveClick = async () => {
    setSaving(true)
    await onSave?.(viewName)
    togglePopoverActive()
    setSaving(false)
  }

  const isDuplicatedName = currentTabName !== viewName && tabNavConfig.checkTabNameExists(viewName)

  const disabled = !viewName.trim().length || isDuplicatedName


  const filterLabelsMarkup = appliedFilters.map(({ key, label }) => <Tag key={key}>{label}</Tag>)

  const buttonMarkup = (
    <Button
      icon={saved ? StarFilledIcon : StarIcon}
      onClick={() => {
        // MARK: 如果关闭弹窗后再次打开需要保持更改的名称 就移除逻辑
        setViewName(currentTabName)
        togglePopoverActive()
      }}
      size="large"
    >
      {condensed ? undefined : saved ? t("orders:EditView") : t("orders:SaveView")}
    </Button>
  )

  return <>
    <Popover
      preferredAlignment="right"
      preferInputActivator={false}
      onClose={togglePopoverActive}
      active={popoverActive}
      activator={buttonMarkup}
      sectioned
    >
      <Popover.Pane>
        <Box
          padding="300"
        >
          <LegacyStack spacing="tight">
            {filterLabelsMarkup}
          </LegacyStack>

          <Box
            paddingBlockStart="200"
            paddingBlockEnd="400"
          >
            <TextField
              label={t("orders:ViewName")}
              autoComplete="off"
              helpText={t("orders:ViewNameHelpText")}
              value={viewName}
              onChange={(v) => setViewName(v)}
              maxLength={MAX_LENGTH}
              showCharacterCount
              error={isDuplicatedName && "A view with this name already exists. Please choose a different name."}
            />
          </Box>

          <InlineStack gap="200" align="end">
            <Button
              onClick={saved ? toggleConfirmation : togglePopoverActive}
              tone={saved ? "critical" : undefined}
            >
              {saved ? t("common:Delete") : t("common:Cancel")}
            </Button>
            <Button
              disabled={disabled}
              loading={saving}
              onClick={handleSaveClick}
              variant="primary"
            >
              {t("orders:SaveView")}
            </Button>
          </InlineStack>
        </Box>
      </Popover.Pane>
    </Popover>

    <ConfirmModal
      tabName={currentTabName}
      open={alertModalOpen}
      onDelete={onDelete}
      onClose={toggleConfirmation}
    />

    <EventListener
      event="resize"
      handler={debounce(handleResize, 100)}
    />
  </>
}

function ConfirmModal({
  open,
  tabName,
  onDelete,
  onClose,
}: ConfirmModalProps) {
  const [alertModalRemoving, setAlertModalRemoving] = useState(false)

  const handleDeleteClick = async () => {
    setAlertModalRemoving(true)
    await onDelete()
    onClose()
    setAlertModalRemoving(false)
  }

  const { t } = useTranslation(["orders", "common"])

  return (
    <Modal
      open={open}
      title={t("orders:SaveFiltersControl.title", { tabName })}
      onClose={onClose}
      primaryAction={{
        content: t("common:Remove"),
        destructive: true,
        loading: alertModalRemoving,
        onAction: handleDeleteClick,
      }}
      secondaryActions={[{ content: t("common:Cancel"), onAction: onClose }]}
      sectioned
    >
      <p>{t("orders:SaveFiltersControl.content")}</p>
    </Modal>
  )
}
