import i18n from "i18next"
import { action, computed, makeObservable, observable } from "mobx"

import OrdersAPI from "@/api/orders"
import { IOrderTagsManageStore, Mode, SortType } from "@/models/IOrderTagsManageStore"
import { QueryParamsType } from "@/pages/Orders/types"
import stores from "@/stores/index"
import { compareArrays } from "@/utils/arrayUtils"
import toast from "@/utils/toast"

export default class OrderTagsManageStore implements IOrderTagsManageStore {
  @observable shipmentCount = 0
  @observable loading: boolean = true
  @observable sortType: SortType = SortType.Popular
  @observable open: boolean = false
  @observable mode: Mode = Mode.ADD_TAGS
  @observable isDetail: boolean = false
  @observable selected: string[] = []
  @observable tags: string[] = []
  @observable saving: boolean = false
  @observable shipmentSelectedItems?: string[]
  @observable queryParams?: QueryParamsType
  /**
   * Tag add输入框数据
   */
  @observable tagInputValue = ""
  @observable timeoutId:null|NodeJS.Timeout = null

  /** 窗口打开时间, 用于确定是否是同一个操作 */
  openTime?: number
  /** @deprecated */
  loadedDataTime: number = 0


  constructor() {
    makeObservable(this)
  }

  @computed
  get title() {
    if (this.isDetail){
      return i18n.t("orders:AddTags")
    }
    return i18n.t(
      this.mode === Mode.ADD_TAGS ? "orders:AddTagsTitle" : "orders:RemoveTagsTitle",
      { count: this.shipmentCount },
    )
  }


  @computed
  get tagOptions() {
    const filterRegex = new RegExp(this.tagInputValue, "i")

    return this.tags.filter(tag => tag.match(filterRegex))
      .map(item => {return { label: item, value: item }})
  }

  @action.bound
  handleTagInputChange(value: string) {
    this.tagInputValue = value

    // 空值不需查询
    // if (value){
    //   if (this.timeoutId) {
    //     clearTimeout(this.timeoutId)
    //   }
    //   // this.loading = true
    //   this.timeoutId = setTimeout(() => {
    //     this.fetchTagsData()
    //   }, 500)
    // }
  }

  /** @deprecated */
  setLoadedDataTime(time: number): void {
    throw new Error("Method not implemented.")
  }

  @action.bound
  handleSelected(selected: string[]) {
    this.selected = selected
  }

  @action.bound
  setOpen(open: boolean, isDetail?: boolean): void {
    this.isDetail = !!isDetail

    this.open = open
    if (open) {
      this.openTime = new Date().getTime()
      this.fetchTagsData()
      if (this.isDetail){
        this.handleSelected(stores.ordersStore.orderInfoData?.orderTags || [])
      }
    } else {
      this.saving = false
      this.selected = []
    }
  }


  @action.bound
  handleAddTags(tags:string[], numberID:string|number|null|undefined) {
    const reqParams:any = { action: "ADD", tags, number_ids: [numberID] }

    return new Promise((resolve, reject) => {
      OrdersAPI
        .manageOrderTags(reqParams, "ADD")
        .then(({ data }) => {
          if (data.code === 200) {
            stores.ordersStore.addTagsToShipments(tags, this.shipmentSelectedItems || "All")
            this.tags = [...new Set([...this.tags, ...tags])]
            resolve(data)
          }
          reject(data)
        })
        .catch(res => {
          reject(res)
        })
    })
  }

  @action.bound
  handleRemoveTags(tags:string[], numberID:number|string|null|undefined) {
    const reqParams:any = { action: "REMOVE", tags, number_ids: [numberID]  }

    return new Promise((resolve, reject) => {
      OrdersAPI
        .manageOrderTags(reqParams, "REMOVE")
        .then(({ data }) => {
          if (data.code === 200) {
            stores.ordersStore.removeTagsToShipments(tags, this.shipmentSelectedItems || "All")
            
            resolve(data)
          } else {
            reject(data)
          }
        })
        .catch((res) => {
          reject(res)
        })
    })
  }

  @action.bound
  handleClose(): void {
    this.setOpen(false)
  }

  @action.bound
  handleSave() {
    this.saving = true

    // detail页面的add tag modal的逻辑处理
    if (this.isDetail && this.shipmentSelectedItems && this.shipmentSelectedItems[0]){
      const { orderTags: selectedOptions } = stores.ordersStore.orderInfoData || {}
      const [addTags, removeTags ] = compareArrays(selectedOptions || [], this.selected)
      const promiseArray = []

      addTags.length && promiseArray.push(this.handleAddTags(addTags, this.shipmentSelectedItems[0]))
      removeTags.length && promiseArray.push(this.handleRemoveTags(removeTags, this.shipmentSelectedItems[0]))

      Promise.all(promiseArray).then(() => {
        toast(i18n.t("common:SavedSuccess"))
        this.setOpen(false)
      })
        .finally(() => {
          this.saving = false
        })
      return
    }

    // orders页面的add/remove tag modal的逻辑处理
    const currentOpenTime = this.openTime

    const reqParams: any = {
      action: this.mode === Mode.ADD_TAGS ? "ADD" : "REMOVE",
      tags: this.selected,
    }

    if (this.queryParams) {
      reqParams.filters = this.queryParams
    } else if (this.shipmentSelectedItems) {
      reqParams.number_ids = this.shipmentSelectedItems.map(v => parseInt(v, 10))
    } else {
      return
    }

    OrdersAPI
      .manageOrderTags(reqParams)
      .then(({ data }) => {
        if (data.code === 200) {
          toast(i18n.t(this.mode === Mode.ADD_TAGS ? "orders:TagModal.Toast.added" : "orders:TagModal.Toast.removed"))
          if (this.mode === Mode.ADD_TAGS) {
            stores.ordersStore.addTagsToShipments(this.selected, this.shipmentSelectedItems || "All")
          } else {
            stores.ordersStore.removeTagsToShipments(this.selected, this.shipmentSelectedItems || "All")
          }
          if (currentOpenTime === this.openTime) {
            this.setOpen(false)
          }
        }
      })
      .finally(() => {
        this.saving = false
        this.fetchTagsData()
      })
  }

  @action.bound
  handleSortTypeChange(sortType: SortType) {
    this.sortType = sortType
    this.loading = true
    this.fetchTagsData()
  }

  @action.bound
  fetchTagsData() {
    this.loading = true

    OrdersAPI
      .getAllOrderTags({
        sortType: this.sortType,
      })
      .then(({ data: { data } }) => {
        this.tags = data.tags
        this.loading = false
      })
  }
}
