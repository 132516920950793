// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { flatten } from "flat"
import { cloneDeep, isEqual } from "lodash-es"
import { deepObserve, IDisposer } from "mobx-utils"

import stores from "@/stores"

export const disposer: IDisposer | null = null
export let defaultOptions: any
export let modulesDisposer: IDisposer | null = null

/**
 * 设置初始值
 * @param options
 */
const setInitOptions = (options: any) => {
  defaultOptions = cloneDeep(options)
}


/**
 * 获取初始值配置
 */
const getInitOptions = () => {
  return defaultOptions
}

/**
 * 注册二级监听器
 * @param options
 */
const registerModuleListener = () => {
  const { eddModulesStore, trackingPageStore } = stores

  modulesDisposer = deepObserve(eddModulesStore, (change) => {
    if (change.type === "update" && change.observableKind === "object") {
      const base = flatten({
        fulfillment_workflow: trackingPageStore.post_purchase_edd.fulfillment_workflow,
        transit_time: trackingPageStore.post_purchase_edd.transit_time,
      })


      const now = flatten(eddModulesStore.config)
      const isChange = !isEqual(base, now)

      if (eddModulesStore.saveBtnActive !== isChange) {
        eddModulesStore.setSaveBtnActive(isChange)
      }
    }
  })
  return true
}

/**
 * 初始化ModulesStore
 */
export const initModulesStore = () => {
  const { eddModulesStore, trackingPageStore } = stores

  eddModulesStore.setConfig(cloneDeep({
    fulfillment_workflow: trackingPageStore.post_purchase_edd.fulfillment_workflow,
    transit_time: trackingPageStore.post_purchase_edd.transit_time,
  }))

  eddModulesStore.setSaveBtnActive(false)
  registerModuleListener()
}

/**
 * 销毁二级监听器
 * @param options
 */
const destroyModuleListener = () => {
  modulesDisposer && modulesDisposer()
  // 初始数据
  initModulesStore()
}

/**
 * 保存二级模块的数据
 */
const saveModulesConfig = () => {
  const { eddModulesStore, trackingPageStore } = stores
  const config = cloneDeep(eddModulesStore.config)

  // 保存的时候过了国家为空的规则
  config.transit_time.rules = config.transit_time.rules.filter(item => item.countries.length > 0)

  trackingPageStore.setOptions("post_purchase_edd", config)
  eddModulesStore.setSaveBtnActive(false)
}

export {
  destroyModuleListener,
  getInitOptions,
  registerModuleListener,
  saveModulesConfig,
  setInitOptions,
}
