import { ActionListItemDescriptor, ToastProps } from "@shopify/polaris"
import { action, computed, makeObservable, observable } from "mobx"

import { Country, timeZone } from "@/api/base"
import commonApi from "@/api/common"
import { PublicModalId } from "@/component/PublicModal"
import { SupportLanguages } from "@/i18n/SupportLanguages"
import {
  default as ICommonStore,
  IContextualSaveBar,
  IPublicModal,
  IToastProps,
  IUserGuide,
  ValueOf,
} from "@/models/ICommonStore"
import { LanguageUtils } from "@/utils/languages"

export default class CommonStore implements ICommonStore {
  @observable public showFeedbackModal: boolean = false
  @observable public contextualSaveBar: IContextualSaveBar = {
    disabled: false,
    loading: false,
    show: false,
    unlocking: false,
  }

  // 国家地区，暂时EDD有使用倒
  @observable public countries: Country[] = []
  @observable public timeZones: timeZone[] = []

  @observable public toastItems: { [id: string]: ToastProps } = {}
  @observable authToken: string | null = null
  @observable public languageItems: ActionListItemDescriptor[] = []
  @observable public isCannyLoaded = false
  @observable public userGuide: IUserGuide = {
    dropshipper: undefined,
    emailAccentColor: "#0066cc",
    emailLang: "en",
    isGetEmailSet: false,
    langList: [{ label: "Dutch", value: "nl" }, { label: "English", value: "en" }, {
      label: "French",
      value: "fr",
    }, { label: "German", value: "de" }, { label: "Italian", value: "it" }, { label: "Spanish", value: "es" }, {
      label: "Danish",
      value: "da",
    }, { label: "Portuguese", value: "pt" }],
    openModal: false,
    header: {
      logo_url: "",
    },
    colors: {
      body_btn_bg: "#0066CC",
    },
  }
  @observable public publicModal: IPublicModal = {
    id: PublicModalId.DEFAULT,
    open: false,
  }
  @observable public ordersBannerShow: boolean = true

  // change Plan页面的跳转来源
  @observable public accountSource: string = "/billing"

  @observable banner20493: {visible: boolean}|null = null

  constructor() {
    makeObservable(this)
    this.languageItems = this.getLanguageItems()
  }

  @computed
  get selectLanguage() {
    return this.languageItems.find(item => item.active)?.content ?? "English"
  }

  @action.bound
  public setOrdersBannerShow(show: boolean) {
    this.ordersBannerShow = show
  }

  @action.bound
  public setAccountSource(path: string) {
    this.accountSource = path
  }

  @action.bound
  public setCountries(countries: Country[]) {
    this.countries = countries
  }

  @action.bound
  public setTimeZones(timeZones: timeZone[]) {
    this.timeZones = timeZones
  }

  @action.bound
  public addToast(toastProps: IToastProps): void {
    // const id = uniqueId("toast")
    const id = "toast"

    this.toastItems[id] = {
      ...toastProps,
      onDismiss: () => this.removeToast(id),
    }
  }

  @action.bound
  public removeToast(id: string): void {
    delete this.toastItems[id]
  }

  @action.bound
  public setIsCannyLoaded(value: boolean) {
    this.isCannyLoaded = value
  }

  @action.bound
  public setShowFeedbackModal(status: boolean): void {
    this.showFeedbackModal = status
  }

  @action.bound
  public setContextualSaveBar<K extends keyof IContextualSaveBar>(key: K, value: IContextualSaveBar[K]) {
    this.contextualSaveBar[key] !== value && (this.contextualSaveBar[key] = value)
  }

  @action.bound
  setAuthToken(token: string): void {
    this.authToken = token
  }

  @action.bound
  setUserGuideOption<T extends IUserGuide>(key: keyof T, val: ValueOf<T>): void {
    // @ts-ignore
    this.userGuide[key] = val
  }

  @action.bound
  public setActiveLanguageItem(local: SupportLanguages): void {
    this.languageItems.forEach(item => {
      item.active = local === item.id
    })
  }

  @action.bound
  openPublicModal(id: PublicModalId): void {
    this.publicModal.id = id
    this.publicModal.open = true
  }

  @action.bound
  closePublicModal(): void {
    this.publicModal.open = false
  }

  private getLanguageItems() {
    const langDisplayNameMap: { [lang in SupportLanguages]?: string } = {
      [SupportLanguages.Chinese]: "Chinese (Simplified)",
      [SupportLanguages.Chinese_Traditional]: "Chinese (Traditional)",
      [SupportLanguages.Portuguese_Portugal]: "Portuguese (Portugal)",
      [SupportLanguages.Portuguese_Brazil]: "Portuguese (Brazil)",
    }

    return Object.entries(SupportLanguages)
      .sort()// 从 A-Z 排序
      .map(([language, local]): ActionListItemDescriptor => {
        return {
          active: local === "en",
          content: langDisplayNameMap[local] || language.replace("_", " "),
          id: local, // 默认选择英语
          onAction() {
            LanguageUtils.setLocale(local)
          },
        }
      })
  }


  @action.bound
  public async getBanner20493(): Promise<{ visible: boolean }> {
    if (this.banner20493 !== null) {
      return this.banner20493
    }

    const visible = await commonApi.getBanner(20493)
      .then(resp => resp.data.banner.visible)

    this.banner20493 = {
      visible,
    }

    return this.banner20493
  }

  @action.bound
  public closeBanner20493() {
    if (this.banner20493) {
      this.banner20493.visible = false
    }

    commonApi.updateBanner(20493, false)
  }
}
