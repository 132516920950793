import { Grid,
  Layout, LegacyCard, SkeletonBodyText, SkeletonDisplayText, TextContainer,
} from "@shopify/polaris"
import React from "react"

export default function SkeletonDelivery() {

  return (
    <Layout>
      <Layout.Section>
        <div>
          <Grid gap={{ xs: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "1rem" }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 4 }}>
              <LegacyCard sectioned>
                <SkeletonBodyText lines={3} />
              </LegacyCard>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 4 }}>
              <LegacyCard sectioned>
                <SkeletonBodyText lines={3} />
              </LegacyCard>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 4 }}>
              <LegacyCard sectioned>
                <SkeletonBodyText lines={3} />
              </LegacyCard>
            </Grid.Cell>
          </Grid>
        </div>
      </Layout.Section>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={6} />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={15} />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={4} />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section>
        <LegacyCard sectioned>
          <SkeletonBodyText lines={10} />
        </LegacyCard>
      </Layout.Section>

    </Layout>
  )
}
