import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { AuthButton } from "@/component"
import { UpgradeTipSkeletonNew } from "@/component/UpgradeTipSkeleton"
import Permission from "@/enums/permission"

import styles from "./index.module.scss"

type UpgradeProps = {
  lines?: number
  primaryButton?: boolean
  paidGuidance?: () => void
  subButton?: React.ReactNode
  permission?: Permission
  hiddenButton?: boolean
}

const AnalyticsPaidUpgrade = (props: UpgradeProps) => {
  const {
    paidGuidance,
    lines,
    primaryButton,
    hiddenButton,
    subButton,
    permission = Permission.AdvancedAnalytics, // 默认统计类型的校验
  } = props
  const { t } = useTranslation("common")

  return <UpgradeTipSkeletonNew lines={lines || 2} className={[primaryButton ? styles.primaryButtonWrapper : ""]}>
    {
      !primaryButton && <Trans ns="dashboard" i18nKey="upgradeTipSkeleton.upgradeFunc">
        {!hiddenButton && <AuthButton
          variant={"plain"}
          permission={permission}
          onClick={() => typeof paidGuidance === "function" && paidGuidance()}
        >
          {t("Upgrade")}
        </AuthButton>}
      </Trans>
    }

    {
      primaryButton && <div>
        <div className={styles.primaryWhiteWrapper}>
          {subButton}
        </div>
        {!hiddenButton && <div className={styles.primaryWhiteWrapper}>
          <AuthButton
            primary
            permission={permission}
            onClick={() => typeof paidGuidance === "function" && paidGuidance()}
          >
            {t("UpgradeNow")}
          </AuthButton>
        </div>}
      </div>
    }

  </UpgradeTipSkeletonNew>
}

export default AnalyticsPaidUpgrade
