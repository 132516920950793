import { BlockStack, Box, Button, ChoiceList, ChoiceListProps, Popover, SortButtonChoice, Text } from "@shopify/polaris"
import { SortIcon } from "@shopify/polaris-icons"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { DirectionButton } from "@/component/SortButton/components/DirectionButton"

import { SortButtonDirection } from "./types"

export type { SortButtonChoice }

export interface SortButtonProps {
  choices: SortButtonChoice[];
  selected: ChoiceListProps["selected"];
  onChange: (selected: string[]) => void;
  disabled?: boolean;
  onChangeKey?: (key: string) => void;
  onChangeDirection?: (direction: string) => void;
}

export function SortButton({
  choices,
  selected,
  onChange,
  disabled,
  onChangeKey,
  onChangeDirection,
}: SortButtonProps) {

  const [active, setActive] = useState(false)
  const [selectedValueKey, selectedDirection] = selected[0].split(" ")
  const { t } = useTranslation("orders")

  function handleClick() {
    setActive((pastActive) => !pastActive)
  }

  function handleClose() {
    setActive(false)
  }

  function handleChangeChoiceList(sel: string[]) {
    if (onChangeKey) {
      const [key] = sel[0].split(" ")

      onChangeKey(key)
    } else {
      onChange(sel)
    }
  }

  function handleChangeDirection(sel: string[]) {
    if (onChangeDirection) {
      const [, direction] = sel[0].split(" ")

      onChangeDirection(direction)
    } else {
      onChange(sel)
    }
  }

  const choiceListChoices = useMemo(
    () => choices.reduce(
      (acc: ChoiceListProps["choices"], curr) => {
        const alreadyExists = acc.some((option) => option.label === curr.label)
        const [, currentValueDirection] = curr.value.split(" ")
        const isSameDirection = currentValueDirection === selectedDirection

        if (!alreadyExists) {
          return [...acc, curr]
        }
        if (isSameDirection) {
          return acc.map((option) => {
            if (option.label === curr.label) {
              return curr
            }
            return option
          })
        }
        return acc
      },
      [],
    ),
    [choices, selectedDirection],
  )

  const selectedChoices = choices.filter((choice) => {
    const [currentKey] = choice.value.split(" ")

    return currentKey === selectedValueKey
  })

  const sortButton = (
    <Button
      icon={SortIcon}
      onClick={handleClick}
    >
      {t("Sort")}
    </Button>
  )

  return (
    <Popover
      active={active && !disabled}
      activator={sortButton}
      // autofocusTarget="first-node"
      onClose={handleClose}
      fluidContent
    >
      {/* stopPropagation 阻止冒泡, 因为在 onChangeKey 中改变 direction 会使 Popover 关闭 */}
      <div onClick={(e) => e.stopPropagation()}>
        <Box
          paddingBlock="200"
          paddingInline="300"
          borderColor="border-secondary"
          borderStyle="solid"
          borderBlockEndWidth="025"
        >
          <BlockStack
            gap={{ xs: "400", md: "0" }}
          >
            <Box
              paddingBlockEnd={{ xs: "0", md: "100" }}
            >
              <Text
                as="span"
              >
                {t("SortBy")}
              </Text>
            </Box>
            <div className="PP-Choice-List-2">
              <ChoiceList
                title={null}
                choices={choiceListChoices}
                selected={selected}
                onChange={handleChangeChoiceList}
              />
            </div>
          </BlockStack>
        </Box>

        <Box
          paddingBlock="200"
          paddingInline="150"
        >
          <DirectionButton
            direction="asc"
            active={selectedDirection === SortButtonDirection.Asc}
            onClick={handleChangeDirection}
            value={selectedChoices?.[0]?.value}
          >
            {selectedChoices?.[0]?.directionLabel}
          </DirectionButton>
          <DirectionButton
            direction="desc"
            active={selectedDirection === SortButtonDirection.Desc}
            onClick={handleChangeDirection}
            value={selectedChoices?.[1]?.value}
          >
            {selectedChoices?.[1]?.directionLabel}
          </DirectionButton>
        </Box>
      </div>
    </Popover>
  )
}
