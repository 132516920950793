import { action, makeObservable, observable } from "mobx"

import { Country, Courier, Discount, IDashboardStatistics, IStatisticsStore } from "@/models/IDashboardStatistics"

export default class StatisticsStore implements IStatisticsStore {
  @observable public loadedDataTime: number = 0
  @observable public country: Country[] = []
  @observable public courier: Courier[] = []
  @observable public discount: Discount[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setData(data: IDashboardStatistics) {
    let currentKeys: (keyof IDashboardStatistics)

    for (currentKeys in data) {
      // @ts-ignore
      this[currentKeys] = data[currentKeys]
    }
  }

  @action.bound
  public setLoadedDataTime(time: number): void {
    this.loadedDataTime = time
  }
}
