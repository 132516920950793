import { Banner, Link } from "@shopify/polaris"
import { AlertCircleIcon } from "@shopify/polaris-icons"

export default () => {
  return (
    <Banner
      tone="warning"
      icon={AlertCircleIcon}
      title="ACTION REQUIRED: The legacy Klaviyo integration will be unavailable after June 30, 2024."
    >
      <p>Klaviyo has officially announced that the current Public API Key integration in use will become obsolete and
        will no longer be supported after June 30, 2024.</p>
      <p>Please follow our guidance to seamlessly migrate to the new Klaviyo OAuth integration in under 1 minute
        😘. <Link url="https://docs.google.com/document/d/1QT1gKf2EIGpT_3I8MDaEjcjRlRsYnrBgLvyFvn137TA/edit?usp=sharing"
        external
      >Learn more</Link></p>
    </Banner>
  )
}
