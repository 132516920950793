import { EChartsOption } from "echarts-for-react/lib/types"
import { cloneDeep } from "lodash-es"
import { action, makeObservable, observable } from "mobx"

import { ProductRecommendationChartOption } from "@/component/Dashboard/Chart/ProductRecommendationChartOption"
import {
  IProductRecommendationChart,
  OptionData,
  ProductRecommendationData,
} from "@/models/IProductRecommendationChart"

export default class ProductRecommendationDataStore implements IProductRecommendationChart {
  @observable public options: EChartsOption = ProductRecommendationChartOption
  @observable public click: number = 0
  @observable public date: string[][] = []
  @observable public loadedDataTime: number = 0
  @observable public money: string = "-"
  @observable public number: number = 0
  @observable public orders: number[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setLoadedDataTime(time: number): void {
    this.loadedDataTime = time
  }

  @action.bound
  public setData(data: ProductRecommendationData, legendData: string[]): void {
    let currentKeys: (keyof ProductRecommendationData)

    for (currentKeys in data) {
      // @ts-ignore
      this[currentKeys] = data[currentKeys]
    }

    this.setOptions({
      series: data.orders,
      xAxis: data.date,
    })

    this.setLegendData(legendData)
  }

  @action.bound
  setOptions(data: OptionData): void {
    const newOption = cloneDeep(this.options)

    if (data.xAxis) newOption.xAxis[0].data = data.xAxis
    if (data.series) newOption.series[0].data = data.series

    this.options = newOption
  }

  @action.bound
  setLegendData(data: string[]): void {
    const options = cloneDeep(this.options)

    // eslint-disable-next-line prefer-destructuring
    options.series[0].name = data[0]

    // eslint-disable-next-line prefer-destructuring
    options.legend.data[0] = data[0]
    this.options = options
  }
}
