import { Button as AppBridgeButton, TitleBar } from "@shopify/app-bridge/actions"
import { isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { Button, Layout, Text } from "@shopify/polaris"
import { ArrowLeftIcon } from "@shopify/polaris-icons"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { Container, IRouter, withRouter } from "@/component"
import { RecommendedAPPNew } from "@/component/Dashboard/RecommendedAPP/RecommendedAPPNew"
import { RecommendedAPPProp } from "@/component/Dashboard/RecommendedAPP/types"
import i18next from "@/i18n/config"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"

import DashboardInit from "../init"

interface IProps extends WithTranslation<"dashboard"> {
  router?: IRouter
}

interface IState {
  [k: string]: any;
}

@withRouter
@observer
class AppShowList extends Component<IProps, IState> {

  RecommendedAPPProp: RecommendedAPPProp | undefined

  state: Readonly<IState> = {}

  // 构造函数 页面加载起始函数
  componentDidMount() {
    this.requestConfig()
  }

  // 获取页面需要配置信息
  requestConfig() {
    DashboardInit.getRecommendedAPP()
  }

  /** 跳转到 settings 页面 */
  handlePageBack = () => {
    this.props.router?.navigate("/")
  }

  // 页面代码
  render() {
    const { t } = this.props

    return (
      <Container id="Page-Recommended-APP-List">

        <div className="flex items-center">
          <Button icon={ArrowLeftIcon} onClick={this.handlePageBack} />
          <div className="flex-1 ml-4">
            <Text variant="headingLg" as="h2">
              {t("RecommendedAPP.title", { ns: "dashboard" })}
            </Text>
          </div>
        </div>

        <div className="mt-5">
          <Layout>
            <Layout.Section>

              {recommendedAppList()}

            </Layout.Section>
          </Layout>
        </div>

      </Container>
    )
  }

}

export default withTranslation()(AppShowList)

function recommendedAppList() {

  const { RecommendedAPPStoreAll } = stores

  if (!RecommendedAPPStoreAll.show) return null

  return (
    <RecommendedAPPNew lists={RecommendedAPPStoreAll.data} />
  )

}
