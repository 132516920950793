import request from "@/utils/request"

export default {
  /**
   * 获取重新同步数据《同步完成需要弹出 modal》
   */
  reSyncLog() {
    return request.get("log/orders/re-sync")
  },
}
