import { observer } from "mobx-react"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { ParcelPanel } from "@/assets/svg"
import { DiyRate } from "@/component"
import stores from "@/stores"

import styles from "./GetReviewModalSection.module.scss"

interface IProps extends WithTranslation<"orders"> {
  onSelected(): void;
}

@observer
class GetReviewModalSection extends React.Component<IProps> {
  render() {
    const { t, onSelected } = this.props
    const show = stores.userInfoSore.data.rating !== 5

    return show ? (
      <div className={styles.GetReviewModalSection}>
        <div className={styles.Wrap}>
          <ParcelPanel />
          <p className={styles.Wrap__content}>{t("SyncOrders.p2", { ns: "orders" })}</p>
          <DiyRate onSelected={onSelected} />
        </div>
      </div>
    ) : <></>
  }
}

export default withTranslation("orders")(GetReviewModalSection)
