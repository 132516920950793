import { isEmpty, isEqual, isObject, transform } from "lodash-es"
import { deepObserve } from "mobx-utils"
import { IDisposer } from "mobx-utils/lib/utils"

import { CartSettingApi } from "@/pages/DeliveryGuarantee/Api/type"
import stores from "@/stores"
import { deepDiffBetweenObjects } from "@/utils/ObjectUtils"



export let disposer: IDisposer | null = null
let defaultOptions: CartSettingApi

export default {

  /**
   * 销毁监听器
   */
  destroyListener(focus: boolean = false) {

    disposer && disposer()
    focus &&
    // stores.trackingPageStore.setLoadedDataTime(0)
    stores.DeliveryGuarantee.setWidgetSaveBar("show", false)
    return true
  },



  difference(object: object, base: object) {
    function changes(object: object, base: object) {
      return transform(object, function (result: any, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
        }
      })
    }

    return changes(object, base)
  },


  /**
   * 获取初始值配置
   */
  getInitOptions(): CartSettingApi {
    return defaultOptions
  },


  /**
   * 注册监听器
   */
  registerListener() {
    console.info("TrackingPageUtils: registerListener")
    disposer = deepObserve(stores.DeliveryGuarantee.cartSetting, (change, path) => {
      if (change.type === "update" && change.observableKind === "object") {

        const newData = {
          defaultDisplayStatus: stores.DeliveryGuarantee.cartSetting.defaultDisplayStatus,
          fulfillmentType: stores.DeliveryGuarantee.cartSetting.fulfillmentType,
        }

        const oldData = {
          defaultDisplayStatus: this.getInitOptions().defaultDisplayStatus,
          fulfillmentType: this.getInitOptions().fulfillmentType,
        }



        const is = deepDiffBetweenObjects(newData, oldData)

        // stores.DeliveryGuarantee.setWidgetSaveBar("show", !isEmpty(is))

      }
    })
    return true
  },


  /**
   * 设置初始值
   * @param options
   */
  setInitOptions(options: CartSettingApi) {
    defaultOptions = options
    return true
  },
}
