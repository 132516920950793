import { getSessionToken } from "@shopify/app-bridge-utils"
import axios, { AxiosRequestConfig } from "axios"
import { get } from "lodash-es"
import qs from "qs"

import { APICode } from "@/api/APICode"
import i18next, { t } from "@/i18n/config"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"
import toast from "@/utils/toast"

import { CancelTypesEnum, IExtParam } from "./request.d"

// 导入自定义 fetch
import "./fetch.ts"

// axios 实例
const request = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 18_000,
  // responseType: 'json'
})


// 添加请求拦截器
request.interceptors.request.use(
  request => {
    const extParam: IExtParam = {
      cancelType: request.data?.cancelType ?? CancelTypesEnum.ALL,
    }

    request.data && request.data?.cancelType && (delete request.data.cancelType)

    if (request.headers) {
      // 告诉后端选中了哪个语言
      request.headers["U-Select-Lang"] = i18next.language

      request.headers["Request-Time"] = `First_${window.ParcelPanelLoadTime}`
    }

    removePending(request, extParam) // 在请求开始前，对之前的请求做检查取消操作
    addPending(request, extParam) // 将当前请求添加到 pending 中

    // 如果是从超管后台进入
    const { authToken } = stores.commonStore

    if (authToken) {
      if (request.headers) {
        request.headers.Authorization = `Bearer ${authToken}`
      }
      setFirstConsole(`-->超管后台进入${authToken}`)
      return request
    }

    // 如果是开发环境
    if (!AppBridge.exist()) {
      setFirstConsole(`-->APP Bridge 不存在: ${authToken}`)
      return request
    }

    return getSessionToken(AppBridge.app)
      .then((token: string) => {
        if (request.headers) {
          request.headers.Authorization = `Bearer ${token}`
          setFirstConsole(`-->存在 APP Bridge: ${token}`)
        }
        return request
      })
  },
  error => {
    return Promise.reject(error)
  },
)

// 添加响应拦截器
request.interceptors.response.use(
  response => {
    // 关闭加载
    removePending(response.config)

    // 根据状态码判断不同的
    const code = response?.data?.code
    const { msg } = response.data

    // 如果后端说，不让接管 Snackbar 就直接返回数据了
    if (response?.data.__isShowSnackbar === false) return response

    // todo 控制只弹出一个 toast
    // V2版本接口中有Code字段，后面版本没有code字段了，故在此增加判断条件
    if (response.config?.url && response.config?.url.startsWith("v2/") && code !== undefined &&  code !== APICode.OK) {
      toast(msg || i18next.t("common:Request.ErrorMsg"), 4e3, true)
    }

    return response
  },
  error => {
    const useDefaultExceptionHandler = (get(error, "config.useDefaultExceptionHandler", true) ?? false) !== false

    // 关闭加载
    if (axios.isCancel(error)) {
      // 中断 Promise 调用链
      return new Promise(() => void 0)
    }

    const { response } = error

    // 根据返回的http状态码做不同的处理 response?.status
    if (response?.status) {
      error.msg = getResponseErrorStatusMessage(response.status)
    } else {
      error.msg = "连接错误, 请刷新页面"
      error.msg = "Connection error, please refresh the page"
      console.error(error)
    }

    if (useDefaultExceptionHandler) {
      // 弹出错误提示
      toast(error.msg, 5e3, true)
    }

    if (error.data) {
      error.data.status = "fail"
      error.data.msg = error.msg
    }

    return Promise.reject(response || { message: error.message })
  },
)

function getResponseErrorStatusMessage(status: number) {
  const ResponseErrorMessage: { [k: number]: string } = {
    400: "Bad request",
    401: "Authentication required",
    402: "Payment Required",
    403: "Access denied",
    404: "Resource not found",
    405: "Request method not allowed",
    408: "Request timed out",
    422: "Invalid input parameters",
    429: i18next.t("common:RespErrMsg.429"),
    500: "Something went wrong. Please try again",
    501: "The network is not implemented",
    502: "Network error",
    503: "Service is not available",
    504: "Network timeout",
    505: "HTTP version does not support the request",
  }

  return ResponseErrorMessage[status] || `${t("common:ErrorInConnecting")}: ${status}`
}

export default request

// ///////////////////////////////////////////////////////////
// ////////////////////////取消重复请求配置/////////////////////
// ///////////////////////////////////////////////////////////

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()

/**
 * 添加请求
 * @param {Object} config
 * @param extParam
 */
const addPending = (config: AxiosRequestConfig, extParam?: IExtParam) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
  ]

  // 如果取消的类型是 ALL 那么就要把 POST 参数加上
  if (extParam?.cancelType && extParam.cancelType === CancelTypesEnum.ALL) {
    url.push(qs.stringify(config.data))
  }

  const urlString: string = url.join("&")

  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(urlString)) { // 如果 pending 中不存在当前请求，则添加进去
      pending.set(urlString, cancel)
    }
  })
}

/**
 * 移除请求
 * @param {Object} config
 * @param extParam
 */
const removePending = (config: AxiosRequestConfig, extParam?: IExtParam) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
  ]

  // 如果取消的类型是 ALL 那么就要把 POST 参数加上
  if (extParam?.cancelType && extParam.cancelType === CancelTypesEnum.ALL) {
    url.push(qs.stringify(config.data))
  }

  const urlString: string = url.join("&")

  if (pending.has(urlString)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(urlString)

    cancel(urlString)
    pending.delete(urlString)
  }
}

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  // 路由切换前清空掉之前的请求
  // 可能会造成一些问题，暂时去除。2021-07-01 13:50
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}

// 是否为首次加载
let firstConsole = true

function setFirstConsole(log: string) {
  if (firstConsole) console.log(`%c${log}`, "background-color:#007557; color: white; padding: 4px 8px;")
  firstConsole = false
}
