import { Button } from "@shopify/polaris"
import { XIcon } from "@shopify/polaris-icons"
import { CSSProperties } from "react"
import { LinkProps, useLocation, useNavigate } from "react-router-dom"

import { APICode } from "@/api/APICode"
import app from "@/api/app"
import stores from "@/stores"

import styles from "./ImageBanner.module.scss"

export interface Props {
  id: number;// Banner ID
  show: boolean;// 是否显示
  src: string;// 图片地址
  alt?: string;
  style?: CSSProperties

  to?: LinkProps["to"];
  target?: LinkProps["target"];

  onClose?(): void;// 关闭回调，如果有这个回调，那么就可以关闭
}

export const ImageBanner = ({ id, show, to, target, onClose, src, alt, style }: Props) => {
  const imgMarkUp = <img className={styles.Img} src={src} alt={alt} />
  const { pathname } = useLocation()
  const navigator = useNavigate()


  const updateBannerStatus = async () => {
    // show 为 true 时，表示当前 banner 已经显示过了，所以需要更新状态
    try {
      const { data: { code } } = await app.setBanner(id, { show: true })

      code === APICode.OK && onClose && onClose()
    } catch (e) {
      console.error("updateBannerStatus", e)
    }
  }

  return show ? <div id={`banner-${id}`} className={styles.ImageBanner} style={style}>
    {onClose && <div className={styles.CloseButton}>
      <Button onClick={updateBannerStatus} icon={XIcon} variant="plain" />
    </div>}

    {to ? <div style={{ cursor: "pointer" }} onClick={() => {
      stores.commonStore.setAccountSource(pathname)
      navigator(to)
    }}
    >{imgMarkUp}</div> : imgMarkUp}
  </div> : null
}
