import classNames from "classnames"
import React, { CSSProperties } from "react"

import styles from "../../DoubleColumnGuide.module.scss"

export interface ItemProps {
  children?: React.ReactNode;
  type: "left" | "right";
  className?: string | undefined;
  style?: CSSProperties | undefined;
  isStep?: boolean
}

export function Item({ children, type, style, isStep, className }: ItemProps) {
  const _className = classNames(
    type === "left" && styles.Left,
    type === "right" && styles.Right,
    isStep && styles.isStep,
    className,
  )

  return <div className={_className} style={style}>
    <div className={styles.Wrap}>
      {children}
    </div>
  </div>
}
