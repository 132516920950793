import { Text } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

import { priceInterface } from "@/api/account/type.d"

import styles from "./index.module.scss"

type PriceBlockProps = {
  price_set: priceInterface
  discount_price_set: priceInterface | null
}

function formatNumberAndComma(input: number | string) {
  // 将输入转换为数值类型
  const num = parseFloat(String(input))

  // 检查是否为有效数值
  if (isNaN(num)) {
    return "Invalid input" // 输入无效时的处理
  }

  // 格式化为两位或一位小数或整数，根据实际情况来决定
  let formattedNumber
  // eslint-disable-next-line prefer-destructuring
  const decimalPart = num.toString()
    .split(".")[1]

  if (!decimalPart) {
    formattedNumber = num.toString() // 没有小数部分，直接返回整数部分
  } else if (decimalPart.length === 1) {
    formattedNumber = num.toFixed(1) // 小数部分只有一位，保留一位小数
  } else {
    formattedNumber = num.toFixed(2) // 小数部分超过一位，保留两位小数
  }

  // 对格式化后的数字进行千分位格式化
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


const PriceBlock = ({ price_set, discount_price_set }: PriceBlockProps) => {
  const { t } = useTranslation(["common"])

  const getIniPrice = (amount: string | number) => {
    return formatNumberAndComma(amount)
  }

  // 有折扣的情况
  if (discount_price_set) {
    return <div className={"flex align-center justify-center"}>
      <div className={styles.throughPrice}>
        <Text as="span" variant="headingXl" tone={"subdued"}>
          ${getIniPrice(price_set.price_amount)}
        </Text>
      </div>

      <div className={styles.discountPlanPrice}>
        <Text as="span" variant="headingXl">
        ${getIniPrice(discount_price_set.price_amount)}
        </Text>
        <Text as="span" variant="bodyMd" fontWeight="medium" tone="subdued">
          <span className={styles.month}>/{t("common:Month")
            .toLowerCase()}</span>
        </Text>
      </div>
    </div>
  } else {
    return <div>
      <Text as="span" variant="headingXl">
        ${getIniPrice(price_set.price_amount)}
      </Text>
      <Text as="span" variant="bodyMd" fontWeight="medium" tone="subdued">
        /{t("common:Month").toLowerCase()}
      </Text>
    </div>
  }

}

export default PriceBlock
