import { Badge, Button, LegacyCard, Text } from "@shopify/polaris"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Container } from "@/component"

import styles from "./index.module.scss"

const AddOns = () => {
  const { t } = useTranslation(["add_ons"])

  const data = [
    {
      section_title: t("add_ons:Section1Title"),
      section_desc: t("add_ons:Section1Desc"),
      full: true,
      list: [
        // {
        //   logo: "https://cdn.parcelpanel.com/assets/addOns/Insurance_logo.svg",
        //   name: "Parcel Panel Insurance & Shipping Protection",
        //   ref_link: "https://insurance.parcelpanel.com/recommend/PPad-ons",
        //   pricing_detail: t("add_ons:FreeTrial"),
        //   rating_count: 66,
        //   rating_value: "4.9",
        //   summary: t("add_ons:insuranceSummary"),
        //   price: "$5.5",
        //   origin_price: "$11",
        //   badge_text: t("add_ons:insuranceBadgeText"),
        //   button_text: t("add_ons:GetTheDiscount"),
        //   main_pic: "https://cdn.parcelpanel.com/assets/addOns/Insurance_banner.png",
        // },
        {
          logo: "https://cdn.parcelpanel.com/assets/addOns/Returns_logo.svg",
          name: "ParcelPanel Returns & Exchanges",
          ref_link: "https://api-return-pro.parcelpanel.com/recommend/parcelpanel",
          pricing_detail: "Free plan available",
          rating_count: 479,
          rating_value: "4.9",
          price: "$0",
          origin_price: "$20",
          summary: t("add_ons:returnSummary"),
          badge_text: t("add_ons:returnBadgeText"),
          button_text: t("add_ons:GetTheDiscount"),
          main_pic: "https://cdn.parcelpanel.com/assets/addOns/Return_banner3.png?=1",
        },
      ],
    },
    {
      section_title: t("add_ons:Section2Title"),
      section_desc: t("add_ons:Section2Desc"),
      list: [
        {
          logo: "https://cdn.parcelpanel.com/assets/addOns/RecomSale_logo.svg",
          name: "RecomSale: Referral & Affiliate Marketing",
          ref_link: "https://apps.shopify.com/recomsale-affiliate-marketing?from=ParcelPanel_add-ons_page",
          pricing_detail: "Free plan available",
          rating_count: 493,
          rating_value: "4.9",
          price: "$4.5",
          origin_price: "$9",
          summary: t("add_ons:recomSaleSummary"),
          badge_text: t("add_ons:insuranceBadgeText"),
          button_text: t("add_ons:GetTheDiscount"),
          main_pic: "https://cdn.parcelpanel.com/assets/addOns/RecomSale_banner.png",
        },
        {
          logo: "https://cdn.parcelpanel.com/assets/addOns/Ecomsend_logo.svg",
          name: "EcomSend Pop Ups, Email Popups",
          ref_link: "https://apps.shopify.com/ecomsend?from=ParcelPanel_add-ons_page",
          pricing_detail: t("add_ons:Free"),
          rating_count: "8,269",
          rating_value: "5.0",
          summary: t("add_ons:ecomsendSummary"),
          button_text: t("add_ons:TryFree"),
          main_pic: "https://cdn.parcelpanel.com/assets/addOns/Ecomsend_banner.png",
        },
      ],
    },
  ]

  const openCustomURL = (url: string) => window.open(url)

  return (
    <Container maxWidth={"1036px"} id="Page-AddOns" className={"pt-0"}>
      {
        data.map((section, index) => <div key={index} className={classNames("mt-6", styles.sectionWrapper)}>
          <Text variant="headingLg" as="h3">
            {section.section_title}<span className={"emoji-font ml-1"}>❤️</span>
          </Text>
          <div className={"mt-1"}>
            <Text as={"p"} variant={"bodyMd"} tone={"subdued"}>{section.section_desc}</Text>
          </div>

          <div className={styles.appListWrapper}>
            {
              section.list.map((appInfo, i) => {
                return (
                  <div className={classNames(styles.flexWrapper, section.full ? styles.fullWrapper : "")} key={i}>
                    <LegacyCard>
                      <div className={styles.appInfoWrapper}>
                        <div className={styles.appLogo} onClick={() => openCustomURL(appInfo.ref_link)}>
                          <img src={appInfo.logo} />
                        </div>
                        <div className={styles.appDesc}>
                          <div className={styles.appName} onClick={() => openCustomURL(appInfo.ref_link)}>
                            <Text as={"p"} variant={"bodyMd"} fontWeight={"medium"}>{appInfo.name}</Text>
                          </div>
                          <div className={styles.score}><span></span> {appInfo.rating_value} ({appInfo.rating_count})
                            · {appInfo.pricing_detail}</div>
                          <div className={styles.summary}>{appInfo.summary}</div>

                          <div className={classNames(styles.banner, appInfo.price ? styles.priceCard : "")}>
                            {
                              appInfo.price
                                ? <>
                                  <div className={styles.prices}>
                                    <div className={styles.originalPrice}>
                                      <Text as={"span"} tone={"subdued"} variant={"headingXl"}>{appInfo.origin_price}</Text>
                                      <Text as={"span"} tone={"subdued"} variant={"bodyMd"} fontWeight={"medium"}>/{t("add_ons:month")}</Text>
                                    </div>
                                    <div className={styles.nowPrice}>
                                      <Text as={"span"} variant={"headingXl"}>{appInfo.price}</Text>
                                      <Text as={"span"} tone={"subdued"} variant={"bodyMd"} fontWeight={"medium"}>/{t("add_ons:month")}</Text>
                                    </div>
                                  </div>
                                  <Badge tone="info">{appInfo.badge_text}</Badge>
                                </>
                                : <div className={styles.freeCard}>
                                  <Text as={"span"} variant={"headingXl"}>{t("add_ons:Free100")}</Text>
                                </div>
                            }
                          </div>

                          {
                            appInfo.button_text && <div className={styles.buttonWrapper}>
                              <Button
                                fullWidth

                                onClick={() => openCustomURL(appInfo.ref_link)}
                                variant="primary"
                              >{appInfo.button_text}<span className={"emoji-font ml-1"}>❤️</span></Button>
                            </div>
                          }
                        </div>
                      </div>
                      <div className={styles.mainPicWrapper} onClick={() => openCustomURL(appInfo.ref_link)}>
                        <img src={appInfo.main_pic} />
                      </div>
                    </LegacyCard>
                  </div>
                )
              })
            }
          </div>
        </div>)
      }
    </Container>
  )
}

export default AddOns
