import { Text } from "@shopify/polaris"
import classNames from "classnames"

import { formatNumber } from "@/utils/analyticsUtils"

type AnalyticListProps = {
  header?: string[]
  widthList?: string[]
  data: {
    values: [string, string | number, string | number]
    color?: string
  }[]
}

const AnalyticList = (props: AnalyticListProps) => {
  const { header, data, widthList } = props

  const getStyle = (index:number) => {
    if (widthList){
      return { width: widthList[index] }
    } else {
      return { flex: 1 }
    }
  }

  return <div>
    {
      header && <div className="flex">
        {
          header.map((item, index) =>
            <div
              className={classNames( index === 0 ? "text-left" : "text-right")}
              key={index}
              style={getStyle(index)}
            >
              <Text variant={"bodyMd"} fontWeight={"medium"} as={"span"}>{item}</Text>
            </div>,
          )
        }
      </div>
    }
    {
      data.map((wrapper, i) =>
        <div className="flex" key={i}>
          {
            wrapper.values.map((item, index) =>
              <div
                className={classNames(i === 0 ? "mt-3" : "mt-4", "items-center", index === 0 ? "flex" +
                  " text-left" : "text-right")}
                key={index}
                style={getStyle(index)}
              >
                {wrapper.color && index === 0 && <span
                  style={{
                    backgroundColor: wrapper.color,
                    borderRadius: "100%",
                    display: "inline-block",
                    marginRight: "8px",
                    width: "14px",
                    height: "14px",
                  }}
                />}
                {item && typeof item === "number" ? formatNumber(item) : item || "-"}
              </div>,
            )
          }
        </div>,
      )
    }

  </div>
}

export default AnalyticList
