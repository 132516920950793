import React from "react"

import styles from "./ShipmentStatusBadge.module.scss"


interface ShipmentStatusBadgeProps {
  content: string
  color: string
}

export function ShipmentStatusBadge({
  content,
  color,
}: ShipmentStatusBadgeProps) {
  return (
    <div className={styles.ShipmentStatusBadge}>
      <i className={styles.ShipmentStatusBadge__Pip} style={{ backgroundColor: color }} />
      {content}
    </div>
  )
}
