import { set } from "lodash-es"
import { action, computed, makeAutoObservable, observable } from "mobx"

import { ContentType, ModulesConfigProps, orderStatusType } from "@/pages/Settings/EmailEdit/type"
import stores from "@/stores"
import { defaultConfig } from "@/stores/emailEdit/defaultConfig"

export type ModulesStoreProps = {
  config: ModulesConfigProps
  saveBtnActive: boolean
}

export default class ModulesStore implements ModulesStoreProps {
  @observable dataLoadedTime: number = 0
  @observable config: ModulesConfigProps = defaultConfig.branding_config
  @observable content: Record<orderStatusType, ContentType> = defaultConfig.content

  @observable saveBtnActive: boolean = false

  constructor() {
    // 使用这个才会在 MobX 6 上才会更新视图
    makeAutoObservable(this)
  }

  @computed get contentConfig(): ContentType {
    const { EmailEditStore } = stores

    return this.content[EmailEditStore.orderStatus]
  }

  @computed get subjectValue(): string {
    const { EmailEditStore } = stores

    let { subject } = this.contentConfig

    for (const key in EmailEditStore.vars) {
      subject = subject.replaceAll(`{{${key}}}`, EmailEditStore.vars[key])
    }

    // 2024-03-19 新增变量，进行替换
    subject = subject.replaceAll(
      "{{sha256.customer_email_order_number_remove_hash.concatenated}}",
      "80f903e8c031bff1e3e09a64059f1d5c932fac0d1969adfa61690750390ffa36",
    )

    // 2024-03-19 新增变量，进行替换
    subject = subject.replaceAll(
      "{{order_number_remove_hash}}",
      "1234",
    )


    return subject
  }

  @action.bound
  setSaveBtnActive(active: boolean) {
    this.saveBtnActive = active
  }

  @action.bound
  setConfig(data: { config: ModulesConfigProps, content: Record<orderStatusType, ContentType> }) {
    this.config = data.config
    this.content = data.content
  }

  @action.bound
  setTargetConfig<T>(path: string, v: T) {
    set(this.config, path, v)
  }

  @action.bound
  setContentConfig<T>(path: string, v: T) {
    const { EmailEditStore } = stores

    set(this.content[EmailEditStore.orderStatus], path, v)
  }

  @action.bound
  setDataLoadedTime(time: number) {
    this.dataLoadedTime = time
  }
}
