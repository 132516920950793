import { Banner, Text } from "@shopify/polaris"
import { ChatIcon } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"

import styles from "./ChineseWechatMarketingBanner.module.scss"


export default function ChineseWechatMarketingBanner() {
  const { t } = useTranslation("dashboard")

  return (
    <Banner
      tone="info" icon={ChatIcon}
    >
      <div className={styles.ContentWrapper}>
        <div className="flex-1">
          <Text variant="bodySm" as="p">{t("chineseWechatMarketing.title")}</Text>
          <p className="mt-3">{t("chineseWechatMarketing.content")}</p>
        </div>
        <img
          src="https://cdn.parcelpanel.com/upload/pp-app/Fh0Oflw1TvLGDoD2bx7DIzDSwtPY.jpg"
          alt="QR Code"
          className={styles.QRCodeImg}
        />
      </div>
    </Banner>
  )
}
