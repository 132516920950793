// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { flatten } from "flat"
import { cloneDeep, isEqual } from "lodash-es"
import { deepObserve, IDisposer } from "mobx-utils"

import EmailEditApi from "@/api/settings/emailEdit"
import { languageType, TemplateConfigType } from "@/pages/Settings/EmailEdit/type"
import stores from "@/stores"
import toast from "@/utils/toast"

export let disposer: IDisposer | null = null
export let defaultOptions: any
export let modulesDisposer: IDisposer | null = null

/**
 * 设置初始值
 * @param options
 */
const setInitOptions = (options: any) => {
  defaultOptions = cloneDeep(options)
}


/**
 * 获取初始值配置
 */
const getInitOptions = () => {
  return defaultOptions
}

/**
 * 注册监听器
 * @param options
 */
const registerListener = () => {
  const { EmailEditStore } = stores

  disposer = deepObserve(EmailEditStore, (change) => {
    if (change.type === "update" && change.observableKind === "object") {
      const base = flatten(defaultOptions)
      const now = flatten(EmailEditStore.config)
      const isChange = !isEqual(base, now)

      if (EmailEditStore.saveBtnActive !== isChange) {
        EmailEditStore.setSaveBtnActive(isChange)
      }
    }
  })
  return true
}

const destroyListener = (focus: boolean = false) => {
  const { EmailEditStore } = stores

  disposer && disposer()
  EmailEditStore.setSaveBtnActive(false)
  EmailEditStore.setLoading(false)

  focus && stores.EmailEditStore.setDataLoadedTime(0)
}


/**
 * 注册二级监听器
 * @param options
 */
const registerSubListener = () => {
  const { EmailEditStore, ModulesStore } = stores

  modulesDisposer = deepObserve(ModulesStore, (change) => {
    if (change.type === "update" && change.observableKind === "object") {
      const base = flatten({
        branding_config: EmailEditStore.config.branding_config,
        content: EmailEditStore.config.content,
      })
      const now = flatten({ branding_config: ModulesStore.config, content: ModulesStore.content })
      const isChange = !isEqual(base, now)

      if (ModulesStore.saveBtnActive !== isChange) {
        ModulesStore.setSaveBtnActive(isChange)
      }
    }
  })
  return true
}

/**
 * 初始化ModulesStore
 */
const initModulesStore = () => {
  const { ModulesStore, EmailEditStore } = stores

  ModulesStore.setConfig(cloneDeep({
    config: EmailEditStore.config.branding_config,
    content: EmailEditStore.config.content,
  }))
  ModulesStore.setSaveBtnActive(false)
  registerSubListener()
}

/**
 * 销毁二级监听器
 * @param options
 */
const destroySubListener = () => {
  modulesDisposer && modulesDisposer()
  // 初始数据
  initModulesStore()
}

const initEmailTemplate = (data: TemplateConfigType) => {
  const { EmailEditStore } = stores

  destroyListener() // 销毁监听
  EmailEditStore.setLocale(data.locale)
  EmailEditStore.setSender(data.sender)
  EmailEditStore.setNotificationEnabledData(data.notification_enabled)
  EmailEditStore.setAutoMultilingual(data.branding_config.auto_multilingual as boolean)
  EmailEditStore.setConfig(data)
  setInitOptions(data)

  destroySubListener() // 先销毁，防止回退残留
  initModulesStore()
  registerListener() // 启动监听
}
/**
 * 加载数据
 * @param locale
 */
const onloadEmailTemplate = (locale?: string, toastText?: string) => {
  const { EmailEditStore } = stores

  EmailEditStore.setLoading(true)

  // 请求模板数据
  EmailEditApi.getEmailTemplate(locale)
    .then(({ status, data }) => {
      if (status === 200 && data.code === 200) {
        initEmailTemplate(data.data)
        if (toastText) toast(toastText, 2e3)
      }
    })
    .finally(() => EmailEditStore.setLoading(false))

  // 获取变量集
  EmailEditApi.getEmailVariable(locale)
    .then(res => {
      const { code, data } = res.data

      if (code === 200) {
        EmailEditStore.setVariables(data)
      }
    })
    .finally(() => EmailEditStore.setLoading(false))
}

/**
 * 保存二级模块的数据
 */
const saveModulesConfig = () => {
  const { EmailEditStore, ModulesStore } = stores

  // 验证 btn_text 订阅按钮不能为空
  if (ModulesStore.config.footer.btn_text === "") return false

  EmailEditStore.setTargetConfig("branding_config", ModulesStore.config)
  EmailEditStore.setTargetConfig("content", ModulesStore.content)
  ModulesStore.setSaveBtnActive(false)
}
/**
 * 保存数据
 */
const saveEmailTemplateConfig = ({ successText, errorText }: { successText: string, errorText: string }) => {
  const { EmailEditStore } = stores

  // 验证并不允许保存
  if (EmailEditStore.config.content[EmailEditStore.orderStatus].subject === "") {
    return false
  }

  const config = cloneDeep(EmailEditStore.config)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // 去除其他属性
  config.content = { [EmailEditStore.orderStatus]: config.content[EmailEditStore.orderStatus] }
  config.locale = EmailEditStore.locale as languageType

  // 保存
  EmailEditStore.setSaveLoading(true)
  EmailEditApi.setEmailTemplate(config)
    .then(({ status, data }) => {
      if (status === 200 && data.code === 200) {
        initEmailTemplate(data.data)
        toast(successText, 2e3)
      }
    })
    .catch(() => {
      toast(errorText, 2e3, true)
    })
    .finally(() => {
      EmailEditStore.setSaveLoading(false)
    })
}

/**
 * 放弃更改数据
 */
const discardEmailTemplateConfig = (isInitModule: boolean = false) => {
  const { EmailEditStore, ModulesStore } = stores
  const defaultConfig = getInitOptions()

  destroyListener(true)// 先销毁监听器
  EmailEditStore.setConfig(defaultConfig)
  registerListener()// 最后再注册监听器

  // 如果此时二级编辑不是待报存保存状态，初始化ModulesStore
  // 默认不初始化modules（页面回退的时候用到初始化）
  if (isInitModule || !ModulesStore.saveBtnActive) {
    initModulesStore()
  }
}
/**
 * 重置模板数据
 */
const revertDefaultTemplate = () => {
  const { EmailEditStore } = stores

  return new Promise<void>((resolve, reject) => {
    EmailEditApi.resetEmailTemplate({
      locale: EmailEditStore.locale as string,
      shipment_status: EmailEditStore.orderStatus,
    })
      .then(({ status, data }) => {
        if (status === 200 && data.code === 200) {
          const defaultConfig = getInitOptions()

          destroyListener(true)// 先销毁监听器
          EmailEditStore.setConfig(defaultConfig)
          EmailEditStore.setContentConfig("content", data.data.content)
          EmailEditStore.setContentConfig("subject", data.data.subject)

          setInitOptions(EmailEditStore.config)

          EmailEditStore.setSaveBtnActive(false)

          destroySubListener() // 先销毁，防止回退残留
          initModulesStore()
          registerListener()// 最后再注册监听器
          resolve()
        } else {
          reject()
        }
      })
      .catch(() => reject())
  })
}

export {
  destroyListener,
  destroySubListener,
  discardEmailTemplateConfig,
  getInitOptions,
  onloadEmailTemplate,
  registerListener,
  registerSubListener,
  revertDefaultTemplate,
  saveEmailTemplateConfig,
  saveModulesConfig,
  setInitOptions,
}
