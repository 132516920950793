export enum orderStatusType {
  InTransit = "in_transit",
  OutForDelivery = "pickup",
  Delivered = "delivered",
  Exception = "exception",
  FailedAttempt = "failed_attempt",
  InfoReceived = "info_received",
}

export enum moduleType {
  DiscountUpsell = "DiscountUpsell",
  Header = "Header",
  OrderTracking = "OrderTracking",
  ProductRecommendation = "ProductRecommendation",
  ShipmentItems = "ShipmentItems",
  Social = "Social",
  Text = "Text",
  Footer = "Footer",
  FooterWrapper = "Footer-Wrapper",
  Body = "Body",
}

export enum IconColorEnum {
  Black = "black",
  White = "white",
  Grey = "grey"
}

export enum socialType {
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  TWITTER = "twitter",
  PINTEREST = "pinterest",
  SNAPCHAT = "snapchat",
  LINKEDIN = "linkedin",
  YOUTUBE = "youtube",
  WHATSAPP = "whatsapp",
}

export enum languageType {
  ENGLISH = "en", // 英语
  FRENCH = "fr", // 法语
  GERMAN = "de", // 德语
  ITALIAN = "it", // 意大利语
  DUTCH = "nl", // 荷兰语
  SPANISH = "es", // 西班牙语
  CHINESE_SIMPLIFIED = "zh-CN", // 简体中文
  CHINESE_TRADITIONAL = "zh-TW", // 繁体中文
  PORTUGUESE_BRAZIL = "pt-BR", // 葡萄牙语（巴西）
  UKRAINIAN = "uk", // 乌克兰语
  POLISH = "pl", // 波兰语
  ARABIC = "ar", // 阿拉伯语
  SWEDISH = "sv", // 瑞典语
  HEBREW = "he", // 希伯来语
  NORWEGIAN = "no", // 挪威语
  PORTUGUESE_PORTUGAL = "pt-PT", // 葡萄牙语（葡萄牙）
  LITHUANIAN = "lt", // 立陶宛语
  RUSSIAN = "ru", // 俄语
  VIETNAMESE = "vi", // 越南语
  TURKISH = "tr", // 土耳其语
  GREEK = "el", // 希腊语
  FINNISH = "fi", // 芬兰语
  ESTONIAN = "et", // 爱沙尼亚语
  MALAY = "ms", // 马来语
  ROMANIAN = "ro", // 罗马尼亚语
  CZECH = "cs", // 捷克语
  DANISH = "da", // 丹麦语
  SLOVENIAN = "sl", // 斯洛文尼亚语
  LATVIAN = "lv", // 拉脱维亚语
  JAPANESE = "ja", // 日语
  INDONESIAN = "id", // 印度尼西亚语
  BULGARIAN = "bg", // 保加利亚语
  KOREAN = "ko", // 韩语
  THAI = "th", // 韩语
}

export const languageList = [
  {
    "name": "English",
    "value": "en",
  }, {
    "name": "French",
    "value": "fr",
  }, {
    "name": "German",
    "value": "de",
  }, {
    "name": "Italian",
    "value": "it",
  }, {
    "name": "Dutch",
    "value": "nl",
  }, {
    "name": "Spanish",
    "value": "es",
  }, {
    "name": "Chinese (Simplified)",
    "value": "zh-CN",
  }, {
    "name": "Chinese (Traditional)",
    "value": "zh-TW",
  }, {
    "name": "Portuguese (Brazil)",
    "value": "pt-BR",
  }, {
    "name": "Ukrainian",
    "value": "uk",
  }, {
    "name": "Polish",
    "value": "pl",
  }, {
    "name": "Arabic",
    "value": "ar",
  }, {
    "name": "Swedish",
    "value": "sv",
  }, {
    "name": "Hebrew",
    "value": "he",
  }, {
    "name": "Norwegian",
    "value": "no",
  }, {
    "name": "Portuguese (Portugal)",
    "value": "pt-PT",
  }, {
    "name": "Lithuanian",
    "value": "lt",
  }, {
    "name": "Russian",
    "value": "ru",
  }, {
    "name": "Vietnamese",
    "value": "vi",
  }, {
    "name": "Turkish",
    "value": "tr",
  }, {
    "name": "Greek",
    "value": "el",
  }, {
    "name": "Finnish",
    "value": "fi",
  }, {
    "name": "Estonian",
    "value": "et",
  }, {
    "name": "Malay",
    "value": "ms",
  }, {
    "name": "Romanian",
    "value": "ro",
  }, {
    "name": "Czech",
    "value": "cs",
  }, {
    "name": "Danish",
    "value": "da",
  }, {
    "name": "Slovenian",
    "value": "sl",
  }, {
    "name": "Latvian",
    "value": "lv",
  }, {
    "name": "Japanese",
    "value": "ja",
  }, {
    "name": "Indonesian",
    "value": "id",
  }, {
    "name": "Bulgarian",
    "value": "bg",
  }, {
    "name": "Korean",
    "value": "ko",
  }, {
    "name": "Thai",
    "value": "th",
  },
]


export enum displayAlignEnum {
  CENTER = 2,
  LEFT = 4,
  RIGHT = 8,
}

export enum cornerRadiusEnum {
  STANDARD = 2,
  LARGE = 4,
  NONE = 8,
}

export type HeaderType = {
  "logo_url": string,
  "logo_width": number,
  // 单位：百分比
  "store_name": string,
  "go_to_url": string,
}

export type SocialType = {
  "icon_color": IconColorEnum,
  "show": boolean
  "social_media": {
    "name": socialType,
    "value": string,
  }[]
}

export type FooterType = {
  "address": string,
  "unsubscribe_text": string,
  "btn_text": string,
}

export type DisplayType = {
  "align": displayAlignEnum,
  "corner_radius": cornerRadiusEnum,
}
export type ColorsType = {
  "email_bg": string,
  "header_bg": string,
  "header_store_name": string,
  "body_bg": string,
  "body_heading": string,
  "body_desc": string,
  "body_btn_bg": string,
  "body_btn": string,
  "prod_rec_bg": string,
  "prod_rec_heading": string,
  "prod_rec_desc": string,
  "du_bg": string,
  "du_heading": string,
  "du_desc": string,
  "du_btn_bg": string,
  "du_btn": string,
  "footer_bg": string,
  "footer_text": string,
  "footer_link": string,
}


export type ModulesConfigProps = {
  header: HeaderType
  social: SocialType
  footer: FooterType
  colors: ColorsType
  display: DisplayType
  auto_multilingual?: boolean
  show_pp_branding: boolean
  is_pp_branding_disabled: boolean
  version: number
  sender: {
    name: string
  }
}


export type ContentType = {
  subject: string,

  // 邮件模版 HTML
  content: string,

  // 小眼睛 - 控制功能开关
  // 名词缩写释意：
  // si: Shipment item
  // du: Discount upsell
  status: {
    text: boolean,
    si: boolean,
    pr: boolean,
    du: boolean,
  },

  // Order tracking
  ot: {
    heading: string,
    desc: string,
    btn_text: string,
  },

  text: string,

  // si: Shipment item
  si: {
    heading: string,
  },

  // pr: Product recommendation
  pr: {
    heading: string,
    logic: number,
    coll: number,
  },

  // du: Discount upsell
  du: {
    heading: string,
    desc: string,
    discount_code: string,
    btn_text: string,
    go_to_url: string,
  },
}

export type variablesType = {
  vars: Record<string, { key: string, value: string, label: string }[]>,
  group_map: [string, string][]
}

export type TemplateConfigType = {
  locale?: languageType,
  primary_locale: languageType,
  shop_locales: languageType[],
  branding_config: ModulesConfigProps
  content: Record<orderStatusType, ContentType>
  notification_enabled: Record<orderStatusType, boolean>
  html_content_default: string
  content_default: Record<orderStatusType, { subject: string }>
  sender?: {
    email: string
    is_valid: boolean
  }
}

export type ResetTemplateType = {
  "locale": languageType,
  "shipment_status": orderStatusType,
  "content": string
  "subject": string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type sendTestEmailResult = {}
