import { Layout, LegacyCard, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris"

export default function SkeletonTrackingPage() {
  return (
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={18} />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={18} />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={18} />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  )
}
