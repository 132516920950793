import { SkeletonBodyText } from "@shopify/polaris"
import classNames from "classnames"
import React from "react"

import styles from "./index.module.scss"
import { UpgradeTipSkeletonProps } from "./UpgradeTipSkeleton"

const normal = (lines: number) => {
  const indents = []

  for (let i = 0; i < lines; i++) {
    indents.push(<div key={i} className={styles.normal} />)
  }
  return (indents)
}

export default function UpgradeTipSkeleton(props: UpgradeTipSkeletonProps) {
  const className = props.className || []

  return (<div className={classNames(styles.UpgradeTip, ...className)}>
    {normal(props.lines || 1)}
    <div className={styles.normal}>
      <div className={styles.text}>
        {props.children}
      </div>
    </div>
    {normal(props.lines || 1)}
  </div>)
}


export function UpgradeTipSkeletonNew(props: UpgradeTipSkeletonProps) {
  const className = props.className || []

  return (<div className={classNames(styles.UpgradeTip, styles.UpgradeTipNew, ...className)}>
    {normal(props.lines)}

    <div className={classNames(styles.centerWrapper, ...className)}>
      <div className={styles.text}>
        {props.children}
      </div>
    </div>
  </div>)
}
