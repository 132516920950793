import {
  ActionList,
  Box,
  Button,
  Icon,
  LegacyStack,
  Modal,
  OptionList,
  Popover,
  Spinner,
  Text,
  TextField,
} from "@shopify/polaris"
import { OptionDescriptor, SectionDescriptor } from "@shopify/polaris/build/ts/src/types"
import { PlusCircleIcon, SearchIcon, SortIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import { uniq } from "lodash-es"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { SortType } from "@/models/IOrderTagsManageStore"
import { fuzzySearch } from "@/utils/StringUtils"

import styles from "./TagsManageModal.module.scss"

export interface IProps {
  open: boolean
  type?: "detail"
  title: string
  isAdd: boolean
  isDetail: boolean
  tags: string[]
  orderTags: string[]
  selected: string[]
  /** All 版块限制展示数量, 留空则不做限制 */
  listAllLimit?: number
  loadingTags?: boolean
  saving?: boolean
  sortType: SortType

  onSelected(selected: string[]): void

  onSave(): void

  onClose(): void

  onSortTypeChange(sortType: SortType): void
}

function TagsManageModal({
  open,
  type,
  title,
  isAdd,
  isDetail,
  tags,
  loadingTags,
  orderTags,
  selected,
  saving,
  listAllLimit = 250,
  sortType,
  onSelected,
  onSave,
  onClose,
  onSortTypeChange,
}: IProps) {
  const { t } = useTranslation("orders")
  const { t: tCommon } = useTranslation("common")
  const [searchValue, setSearchValue] = useState<string>("")
  const [active, setActive] = useState(false)

  const toggleActive = () => {
    setActive(prevState => !prevState)
  }

  useEffect(() => {
    if (open) {
      setSearchValue("")
    }
    if (type === "detail"){
      // orderTagsManageStore.selectedTags
    }
  }, [open])

  // 1. 根据 tags 整理出 sections
  const sections = useMemo<SectionDescriptor[]>(
    () => {
      const sections = []

      const { selectedTags, availableTags } = tags.reduce(
        (acc, tag) => {
          // 分离出 选中 与 未选中 两块
          if (selected.includes(tag)) {
            acc.selectedTags.push(tag)
          } else {
            acc.availableTags.push(tag)
          }

          return acc
        },
        {
          selectedTags: [],
          availableTags: [],
        } as {
          selectedTags: string[]
          availableTags: string[]
        },
      )

      if (isAdd) {
        const newlyOptions = selected.reduce(
          (acc, tag) => {
            if (!selectedTags.includes(tag)) {
              acc.push({ value: tag, label: tag })
            }

            return acc
          },
          [] as OptionDescriptor[],
        )

        if (newlyOptions?.length) {
          sections.push({
            title: t("TagModal.listSections.new"),
            options: newlyOptions,
          })
        }

        if (selectedTags.length) {
          sections.push({
            title: t("TagModal.listSections.toAdd"),
            options: selectedTags.map(tag => ({ value: tag, label: tag })),
          })
        }
      } else {
        if (selected.length) {
          sections.push({
            title: t("TagModal.listSections.toRemove"),
            options: selected.map(tag => ({ value: tag, label: tag })),
          })
        }
      }

      if (availableTags.length) {
        sections.push({
          title: t("TagModal.listSections.all"),
          options: availableTags.map(tag => ({ value: tag, label: tag })),
        })
      }

      return sections
    },
    [tags, selected],
  )

  // 拥有完全匹配的 tag
  let searchHasMatchTag
  let _sections = sections

  // 2. 模糊搜索过滤出有关选项
  if (searchValue) {
    /* 模糊搜索选项 */

    _sections = sections.map((item) => {
      const { title, options } = item

      if (title === t("TagModal.listSections.new")) {
        // 不筛选过滤 `Newly added`, 直接返回完整列表
        return item
      }

      const _options = options.filter(({ value }) => fuzzySearch(searchValue, value))

      if (!_options.length) {
        return
      }

      return {
        title,
        options: _options,
      }
    })
      .filter(item => item !== undefined) as SectionDescriptor[]

    // 判断是否有与搜索关键词完全匹配的 tag
    searchHasMatchTag = _sections.some(({ options }) => options.some(({ value }) => value.toLowerCase() === searchValue.toLowerCase()))
  }

  // 3. 筛选列表截断
  if (listAllLimit) {
    // 对展示列表进行截断
    _sections = _sections.map((item) => {
      const { title, options } = item

      // 忽略其他版块, 只对 all 版块进行截断
      if (title !== t("TagModal.listSections.all")) {
        return item
      }

      return {
        title,
        options: options.slice(0, listAllLimit),
      }
    })
  }

  // 4. 如果在保存中, 禁用所有选项
  if (saving) {
    _sections = _sections.map((section) => ({
      ...section,
      options: section.options.map((option) => ({
        ...option,
        disabled: true,
      })),
    }))
  }

  const handleAddTagButtonClick = () => {
    // 添加 tags
    addToNewlyTags(searchValue)
    // 清空搜索框
    setSearchValue("")
  }

  const addToNewlyTags = (value: string) => {
    const inputTags = uniq(value.split(",")
      .map(tag => tag.trim()
        .replace(/\s+/g, " "))
      .filter((str) => str !== ""))

    if (!inputTags.length) {
      return
    }

    // 转小写, 用于比对元素
    const inputTagsLower = inputTags.map(v => v.toLowerCase())

    const _newlyTags = selected.filter((tag) => !inputTagsLower.includes(tag.toLowerCase()))

    onSelected([...inputTags, ..._newlyTags])
  }

  const isShowAddButton = isAdd && searchValue.length && !searchHasMatchTag

  const addTagButtonMarkup = isShowAddButton ? (
    <div
      className={styles.AddButtonWrapper}
      onClick={handleAddTagButtonClick}
    >
      <Button fullWidth icon={PlusCircleIcon} variant="plain">
        {t("TagModal.actions.content", { tag: searchValue.replace(/\s+/g, " ") })}
      </Button>
    </div>
  ) : null


  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      // 回车时触发 Add 按钮点击事件
      handleAddTagButtonClick()
    }
  }

  const noTagMarkup = !_sections.length ? (
    !searchValue.length ? (
      <div className="text-center mt-4">
        <Text as="span" tone="subdued">{t("TagModal.states.noTags")}</Text>
      </div>
    ) : (
      <div className="text-center mt-4">{t("TagModal.states.noTagsFound")}</div>
    )
  ) : null


  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      primaryAction={{
        content: tCommon("Save"),
        onAction: onSave,
        // 如果是order detail，则比较已选的和订单已有的tag区别
        disabled: isDetail ? selected.sort().toString() === orderTags.sort().toString()  : !selected.length, loading: saving,
      }}
      secondaryActions={[{ content: tCommon("Cancel"), onAction: onClose }]}
      limitHeight
    >
      <div className={styles.ModalBodyWrapper}>
        <Modal.Section>
          <Box paddingInline="150">
            <div onKeyDown={handleKeyDown}>
              <LegacyStack spacing="baseTight">
                <LegacyStack.Item fill>
                  <TextField
                    autoFocus
                    label={null}
                    autoComplete="off"
                    disabled={loadingTags || saving}
                    placeholder={
                      isAdd
                        ? t("TagModal.searchInput.placeholder")
                        : t("TagModal.searchInput.placeholderRemove")
                    }
                    prefix={<Icon source={SearchIcon} />}
                    value={searchValue}
                    onChange={(value) => setSearchValue(value)}
                    clearButton
                    onClearButtonClick={() => setSearchValue("")}
                  />
                </LegacyStack.Item>
                <Popover
                  active={active}
                  activator={(
                    <Button
                      icon={SortIcon}
                      size="large"
                      onClick={toggleActive}
                      disabled={loadingTags || saving}
                    >
                      {
                        sortType === SortType.Alphabetical
                          ? t("TagModal.sortTypes.alphabetical")
                          : t("TagModal.sortTypes.frequentlyUsed")
                      }
                    </Button>
                  )}
                  onClose={() => {
                    setActive(false)
                  }}
                  ariaHaspopup="menu"
                  preferredAlignment="right"
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[
                      {
                        content: t("TagModal.sortTypes.alphabetical"),
                        onAction() {
                          onSortTypeChange(SortType.Alphabetical)
                        },
                      },
                      {
                        content: t("TagModal.sortTypes.frequentlyUsed"),
                        onAction() {
                          onSortTypeChange(SortType.Popular)
                        },
                      },
                    ]}
                    onActionAnyItem={toggleActive}
                  />
                </Popover>
              </LegacyStack>
            </div>
          </Box>

          {addTagButtonMarkup}

          {
            loadingTags ? (
              <div className={styles.SpinnerLoading}>
                <Spinner />
              </div>
            ) : <>
              {noTagMarkup}
              <div className={classNames(styles.OptionWrapper)}>
                <OptionList
                  onChange={(selected) => onSelected(selected)}
                  sections={_sections}
                  selected={selected}
                  allowMultiple
                />
              </div>
            </>
          }
        </Modal.Section>
      </div>
    </Modal>
  )
}

export default TagsManageModal
