import { Banner, Button, ButtonGroup, Icon, Text, Tooltip } from "@shopify/polaris"
import { ClipboardIcon } from "@shopify/polaris-icons"
import copy from "copy-to-clipboard"
import React from "react"
import { useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"

import appApi from "@/api/app"
import stores from "@/stores"
import toast from "@/utils/toast"

interface PlanUpdateBannerProps {
  discountCode: string
}

/**
 * 套餐更新Banner
 */
function PlanUpdateBanner({ discountCode }: PlanUpdateBannerProps) {
  const { t } = useTranslation(["common", "account"])
  const { showNewMessages } = useIntercom()

  const showNewMessage = () => {
    showNewMessages()
  }

  const handleCopyClick = (copyValue: string) => {
    if (copyValue) {
      copy(copyValue)
      toast(t("common:CopiedSuccessfully"))
    }
  }

  return (
    <div className={"mt-4"}>
      <Banner
        title={t("account:PlanUpdateTitle")}

        onDismiss={() => {
          appApi.closeBillingUpdateBanner()
            .then(res => {
              if (res.status === 200) {
                stores.upgradeStore.clearPricingUpdate()
              }
            })
        }}
        tone="info"
      >
        <div>{t("account:PlanUpdateBannerDesc1")}</div>

        <div className={"mt-4"}>
          {t("account:PlanUpdateBannerDesc2")}

          {
            !!discountCode && <div className={"inline-flex"}>
              <Text as="span" variant="bodyMd" fontWeight="semibold">{discountCode}</Text>

              <Tooltip content={t("account:CopeCode")} dismissOnMouseOut>
                <div className={"inline cursor-pointer"} onClick={() => handleCopyClick(discountCode)}>
                  <Icon source={ClipboardIcon} tone="base" />
                </div>
              </Tooltip>
            </div>
          }
        </div>

        <div className={"mt-4"}>{t("account:PlanUpdateBannerDesc3")}</div>

        <div className="mt-2">
          <ButtonGroup gap="tight">
            <Button
              onClick={showNewMessage}
            >
              {t("common:ContactUs")}
            </Button>
            <Button
              variant="tertiary"
              onClick={() => window.open("https://docs.parcelpanel.com/shopify/plan-billing/pricing/")}
            >
              {t("common:LearnMore")}
            </Button>
          </ButtonGroup>
        </div>
      </Banner>
    </div>
  )
}

export default PlanUpdateBanner
