import { Backdrop, Button, Portal } from "@shopify/polaris"
import { XIcon } from "@shopify/polaris-icons"
import React from "react"
import { Link } from "react-router-dom"

import styles from "./PagePicPopup.module.scss"


interface IProps {
  open: boolean
  src: string
  link?: string
  isInnerLink?: boolean
  activator?: React.ReactElement
  closePopupText?: string;// 关闭按钮文案

  onClose(): void

  onClosePopup?(): void;// 如果有才会显示
}


export function PagePicPopup({
  open,
  src,
  link,
  isInnerLink,
  activator,
  onClose,
  closePopupText,
  onClosePopup,
}: IProps) {

  let content: React.ReactNode
  let backdrop: React.ReactNode
  const activatorMarkup = open ? null : activator

  const handleLinkClick = () => {
    // 链接到新页面后关闭弹窗；没有链接的就不允许关闭了（！如果要解耦就加个字段！）
    link && onClose()
  }

  if (open) {
    const imgMarkup = <img src={src} alt="" />

    content = (
      <div className={styles.Container}>
        <div className={styles.Modal}>
          {
            link && isInnerLink
              ? <Link to={link} onClick={handleLinkClick}>{imgMarkup}</Link>
              : <a href={link} target="_blank" rel="noreferrer" onClick={handleLinkClick}>{imgMarkup}</a>
          }
          <div className={styles["Modal-CloseButton"]}><Button icon={XIcon} onClick={onClose}
            variant="plain"
          /></div>
          {onClosePopup && <div className={"flex justify-center"} style={{ lineHeight: "18px" }}>
            <Button onClick={onClosePopup} variant="plain">{closePopupText}</Button>
          </div>}
        </div>
      </div>
    )

    backdrop = <Backdrop />
  }

  return (
    <div>
      <Portal idPrefix="adPopup">
        <div>
          {content}
        </div>
        {backdrop}
      </Portal>
      {activatorMarkup}
    </div>
  )
}
