export enum IListenerNameEnum {
  UpgradeBtnTextListener,
  ShipmentStatusListener,
  RequestGetPlanInfoListener,
  TableDatePickerListener,
  IntegrationRequestAppListListener,
  OrdersListener,
  ShippingNotificationsListener,
  ProductRecommendationListener,
  GetHelpListener,
  CourierMatchingListener
}
