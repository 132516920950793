import { Toast } from "@shopify/app-bridge/actions"

import stores from "@/stores"
import AppBridge, { isShopifyEmbedded } from "@/utils/appBridge"

/**
 * Toast提示封装
 * @param content 提示内容
 * @param duration 提示持续时间 默认2s
 * @param error 是否为Error类型的提示，默认为否
 */
export default (content: string, duration: number = 4e3, error: boolean = false) => {
  if (isShopifyEmbedded() && !window.isFromAdmin) {
    Toast.create(AppBridge.app, {
      duration,
      isError: error,
      message: content,
    })
      .dispatch(Toast.Action.SHOW)
    return
  }
  stores.commonStore.addToast({ content, duration, error })
}
