import { Button, Tag, TextField } from "@shopify/polaris"
import { Observer } from "mobx-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import accountApi from "@/api/account"
import stores from "@/stores"

import styles from "./index.module.scss"

const DiscountCode = () => {
  const { userInfoSore, upgradeStore } = stores

  // 移除折扣码
  const removeDiscountCode = () => {
    accountApi.deleteDiscountCode()
      .then(() => {
        userInfoSore.setDiscount(null)
        // 删除折扣码后，刷新套餐
        upgradeStore.updatePlans()
      })
  }

  return <Observer>{
    () => userInfoSore.discount.initialized && userInfoSore.discount.code
      ? <div className={"flex flex-wrap"}>
        <div className={"mt-1 mr-1"}>
          <Tag onRemove={removeDiscountCode}>{userInfoSore.discount.code?.code}</Tag>
        </div>
      </div>
      : null
  }</Observer>
}

const ApplyCode = () => {
  const { userInfoSore, upgradeStore } = stores
  const { t } = useTranslation(["common", "account"])
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [isInvalidDiscountCode, setIsInvalidDiscountCode] = useState(false)

  // 点击应用折扣码
  const handleApplyDiscount = () => {
    setLoading(true)

    accountApi.applyDiscountCode(value)
      .then(() => {
        userInfoSore.setDiscount({ code: value })
        // 应用完折扣码后，刷新套餐
        upgradeStore.updatePlans()
        setValue("")
      })
      .catch(() => {
        setIsInvalidDiscountCode(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleCodeChange = (value: string) => {
    setIsInvalidDiscountCode(false)
    setValue(value)
  }

  return <div className={styles.TextFieldWrapper}>
    <TextField
      label={""}
      labelHidden
      value={value}
      onChange={handleCodeChange}
      autoComplete="off"
      placeholder={t("common:DiscountCode")}
      error={isInvalidDiscountCode ? t("account:InvalidDiscountCode") : ""}
      connectedRight={(
        <Button
          loading={loading}
          disabled={value === ""}
          onClick={handleApplyDiscount}
          size="large"
        >
          {t("common:Apply")}
        </Button>
      )}
    />

    <DiscountCode />
  </div>
}

export default ApplyCode
