import { useTimeout } from "ahooks"
import { isNumber } from "lodash-es"
import React, { CSSProperties, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import appApi from "@/api/app"
import { PagePicPopup } from "@/component"


export interface PicData {
  enable: boolean
  ad_id: number
  show_advertising_popup: boolean
  link?: string
  is_inner_link?: boolean;// 是否为内部链接
  img_src: string
  popup_delay: number
  activator_id?: number
  can_close_id: number | null
}

/**
 * 广告弹框
 */
export function MarketingPopup() {
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [isAutoOpen, setIsAutoOpen] = useState(false)
  const [popupTimerDelay, setPopupTimerDelay] = useState<number | undefined>()

  const [adData, setAdData] = useState<PicData>({
    ad_id: 0,
    enable: false,
    img_src: "",
    popup_delay: 0,
    show_advertising_popup: false,
    is_inner_link: false,
    can_close_id: null,
  })

  useEffect(() => {
    appApi.advertisingData()
      .then(({ data: { data } }) => {
        setAdData(data)

        setLoading(false)

        if (data.show_advertising_popup) {
          setIsAutoOpen(true)
        }
      })
  }, [])

  const clearTimer = useTimeout(() => {
    openPopup()
  }, popupTimerDelay)

  useEffect(() => {
    /**
     * 自动弹出海报控制
     */
    if (!(isAutoOpen && pathname === "/")) {
      // 非 Dashboard 页面时不自动弹出海报
      clearTimer()
      return
    }

    setPopupTimerDelay(adData.popup_delay * 1000)
  }, [clearTimer, pathname, isAutoOpen, adData.popup_delay])

  const openPopup = () => {
    if (isAutoOpen) {
      // 标记海报已弹出过
      appApi.advertisingPopupClose(adData.ad_id)
      setIsAutoOpen(false)
    }
    setOpen(true)
  }


  let activatorStyle: CSSProperties

  if (adData.activator_id === 2) {
    activatorStyle = {
      background: "url('https://cdn.parcelpanel.com/upload/pp-app/FlJZk3k4JrcJrHrfV1qGd_87xRzv.svg') no-repeat right/contain",
      cursor: "pointer",
      height: 160,
      position: "fixed",
      right: 0,
      top: "33%",
      width: 28,
      zIndex: 100,
    }
  } else if (adData.activator_id === 3) {
    activatorStyle = {
      cursor: "pointer",
      position: "fixed",
      right: -86,
      top: "39%", // TODO 样式问题需要和产品沟通
      zIndex: 100,
    }
  } else {
    activatorStyle = {
      background: "url('https://cdn.parcelpanel.com/upload/document/2022/04/01/Fk2SJYuCHEet_B2U5XajLCFfRqcV.png') no-repeat right/contain",
      cursor: "pointer",
      height: 142,
      position: "fixed",
      right: 0,
      top: "27%",
      width: 32,
      zIndex: 100,
    }
  }

  const obj = !adData.show_advertising_popup && isNumber(adData.can_close_id) ? {
    closePopupText: "Click to close the pop-up completely",
    onClosePopup: async function () {
      if (adData.can_close_id && (await appApi.closePopup(adData.can_close_id))) {
        setOpen(false)
        setAdData((prev) => ({ ...prev, enable: false }))
      }
    },
  } : {}

  return adData.enable ? (
    <PagePicPopup
      open={open}
      src={adData.img_src}
      link={adData.link}
      isInnerLink={adData.is_inner_link}
      activator={loading ? void 0 : <div
        className="app-page-PicPopup"
        style={activatorStyle}
        onClick={() => {
          openPopup()
          // 如果是黑5
          adData.activator_id === 3 && setAdData((prev) => ({ ...prev, show_advertising_popup: false }))
        }}
      >
        {/* MARK 2022年黑色星期五活动已经过去，所以注释 */}
        {/* {adData.activator_id === 3 &&  <GiftAnimation />} */}
      </div>}
      onClose={() => setOpen(false)}
      {...obj}
    />
  ) : null
}
