import { EChartsOption } from "echarts-for-react/lib/types"
import { cloneDeep } from "lodash-es"
import { action, makeObservable, observable } from "mobx"

import { ShippingNotificationsChartOption } from "@/component/Dashboard/Chart/ShippingNotificationsChartOption"
import {
  IShippingNotificationsChart,
  OptionData,
  ShippingNotifications,
  ShippingNotificationsLineChart,
} from "@/models/IShippingNotificationsChart"

export default class ShippingNotificationsDataStore implements IShippingNotificationsChart {
  @observable public emailSendAll: number = 0
  @observable public emailSendSuc: number = 0
  @observable public emailSendOpen: number = 0
  @observable public emailSendClick: number = 0
  @observable public lineChart!: ShippingNotificationsLineChart
  @observable public loadedDataTime: number = 0
  @observable public recipients: number = 0
  @observable public options: EChartsOption = ShippingNotificationsChartOption

  constructor() {
    makeObservable(this)
  }

  /**
   * 设置加载完成时间
   * @param time
   */
  @action.bound
  public setLoadedDataTime(time: number): void {
    this.loadedDataTime = time
  }

  /**
   * 设置数据
   * @param data
   * @param legendData
   */
  @action.bound
  public setData(data: ShippingNotifications, legendData: string[]): void {
    let currentKeys: (keyof ShippingNotifications)

    for (currentKeys in data) {
      // @ts-ignore
      this[currentKeys] = data[currentKeys]
    }

    this.setOptions({
      series: data.lineChart.mails,
      xAxis: data.lineChart.date,
    })
    this.setLegendData(legendData)
  }

  /**
   * 设置更新绘制图表参数
   * @param data
   */
  @action.bound
  setOptions(data: OptionData): void {
    const newOption = cloneDeep(this.options)

    if (data.xAxis) newOption.xAxis[0].data = data.xAxis
    if (data.series) newOption.series[0].data = data.series

    this.options = newOption
  }

  @action.bound
  setLegendData(data: string[]): void {
    const options = cloneDeep(this.options)

    // eslint-disable-next-line prefer-destructuring
    options.series[0].name = data[0]

    // eslint-disable-next-line prefer-destructuring
    options.legend.data[0] = data[0]
    this.options = options
  }
}
