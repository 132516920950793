import { Icon, LegacyCard, Text, Tooltip } from "@shopify/polaris"
import { ChevronRightIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import React, { ReactNode } from "react"

import AnalyticsPaidUpgrade from "@/pages/Analytics/components/PaidUpgrade/PaidUpgrade"
import { percentNumber } from "@/utils/analyticsUtils"

import styles from "./index.module.scss"

type CardProps = {
  title: string // 标题
  description: string // tooltip 描述
  amount: number | string // 数量
  hideAmount?: boolean // 隐藏数量
  ratio?: number // 比率
  suffix?: string | ReactNode // 后缀
  onClick?: () => void
  unauthorised?: boolean
  paidGuidance?: () => void
  children?: ReactNode
  disabledTooltip?: boolean
  width?:"default" | "wide"
}

const AnalyticCard = (props: CardProps) => {
  const {
    title,
    description,
    amount,
    hideAmount,
    ratio,
    onClick,
    suffix,
    unauthorised,
    paidGuidance,
    children,
    disabledTooltip,
    width,
  } = props


  return (
    <LegacyCard>
      <div className={"p-[var(--p-space-card-padding)]"}>
        <div className={classNames("flex justify-between", onClick ? styles.pointer : "")} onClick={onClick}>
          <div className={styles.wrapper}>
            <Tooltip
              width={width}
              content={description}
              preferredPosition={"below"}
              hasUnderline
              {...(disabledTooltip ? { active: false } : {})}
            >
              <Text variant="headingMd" fontWeight="semibold" as="span">
                {title}
              </Text>
            </Tooltip>
            {
              unauthorised
                ? <div className={"mt-3"}>
                  <AnalyticsPaidUpgrade lines={3} paidGuidance={paidGuidance} />
                </div>
                : <div className={styles.info}>
                  {
                    hideAmount ? null : <Text
                      variant="headingLg"
                      as={"span"}
                    >{amount || "-"} {suffix ? suffix : ""}</Text>
                  }
                  {!hideAmount && amount && ratio !== undefined ?
                    <Text variant={"bodyMd"} fontWeight={"semibold"} tone={"subdued"} as={"p"}>
                      ({ratio ? percentNumber(ratio) : "-"})
                    </Text> : ""}
                </div>
            }
          </div>
          {
            !unauthorised && onClick && <Icon
              // @ts-ignore
              source={ChevronRightIcon}
              tone="base"
            />
          }
        </div>
        {children}
      </div>
    </LegacyCard>
  )
}

export default AnalyticCard
