const SVGDefault = (props: any) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#FCAF30" />
    <path fillRule="evenodd" clipRule="evenodd"
      d="M15.0646 7.61088V7.58857C15.0535 7.56625 15.0535 7.54394 15.0424 7.52162V7.51046C15.0313 7.48815 15.009 7.46583 14.9979 7.45468L14.9868 7.44352C14.9756 7.43236 14.9534 7.4212 14.9423 7.41005L14.9312 7.39889H14.9201L14.9089 7.38773L10.1824 5.03347C10.0935 4.98884 9.98227 4.98884 9.88218 5.03347L8.29183 5.82566L13.0406 8.26918L13.0517 8.28034C13.0628 8.28034 13.0628 8.2915 13.0739 8.2915C13.085 8.30266 13.085 8.31381 13.0962 8.32497V8.34729V8.35844V10.947C13.0962 10.9916 13.0739 11.0251 13.0406 11.0474L12.0841 11.5495C12.0286 11.583 11.9618 11.5607 11.9284 11.5049C11.9173 11.4937 11.9173 11.4714 11.9173 11.4491V8.91632L7.11299 6.42818L7.10189 6.41702L5.17794 7.37657L5.16682 7.38773H5.15569L5.14455 7.39889C5.13345 7.41005 5.11121 7.4212 5.10009 7.43236L5.08897 7.44352C5.0667 7.46583 5.0556 7.48815 5.03336 7.51046V7.52162C5.02224 7.54394 5.01112 7.56625 5.01112 7.58857V7.61088C5.01112 7.6332 5 7.64436 5 7.66667V7.67783V12.8773C5 13 5.0667 13.1227 5.18906 13.1785L9.87106 15.5216C9.93779 15.5551 10.0156 15.5663 10.0935 15.544L10.1157 15.5328C10.138 15.5328 10.1491 15.5216 10.1713 15.5105L14.8867 13.1674C14.9979 13.1116 15.0758 13 15.0758 12.8661V7.67783V7.66667C15.0646 7.64436 15.0646 7.6332 15.0646 7.61088Z"
      fill="white"
    />
  </svg>
}

export default SVGDefault
