import {
  Layout,
  LegacyCard, Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonTabs,
  TextContainer,
} from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

import { Container } from "@/component"

export default function SkeletonIntegration() {
  const { t } = useTranslation("menu")

  return (
    <Container  maxWidth={"1036px"} id="Page-Integration">
      <Page title={t("integration")} />
      <Layout>
        <Layout.Section>

          <LegacyCard title={<SkeletonDisplayText size="small" />}>
            <SkeletonTabs count={8} />
            <div className="Polaris-LegacyCard__Section">
              <SkeletonBodyText lines={28} />
            </div>
          </LegacyCard>

          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={10} />
            </TextContainer>
          </LegacyCard>

          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={6} />
            </TextContainer>
          </LegacyCard>

        </Layout.Section>
      </Layout>
    </Container>
  )
}
