import { Banner, Icon, Link, Tooltip } from "@shopify/polaris"
import { ClipboardIcon, ShareIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import copy from "copy-to-clipboard"
import i18n from "i18next"
import React from "react"
import { Trans } from "react-i18next"

import { APICode } from "@/api/APICode"
import dashboard from "@/api/dashboard"
import { ShareEmail, ShareFacebook } from "@/assets/svg"
import stores from "@/stores"
import toast from "@/utils/toast"

import styles from "./SharingBanner.module.scss"


export default function SharingBanner() {
  const link = "https://parcelpanel.com?ref=extended_trial"

  const handleSharEmailClick = () => {
    const subject = "I think you'll like Parcel Panel Order Tracking 😘"
    const body = "Hi,\n\nHighly recommend ParcelPanel - I use this app to track all my orders in one go.\nWith a branded tracking page and automatic shipping notifications, greatly decrease 70% of questions about \"Where is my order?\", and really improves customer satisfaction and drives more sales.\n\nJust have a try, use the following link and you'll get an extended 30-day free trial: https://parcelpanel.com?ref=extended_trial\n\nRegards"
    const url = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`

    window.open(
      url,
      "_blank",
    )
  }

  const handleShareFacebookClick = () => {
    const { height } = screen

    window.open(
      `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`,
      "_blank",
      `toolbar=0,personalbar=0,resizable,scrollbars,status,width=550,height=450,top=${450 < height ? Math.round(height / 2 - 225) : 40},left=${Math.round(screen.width / 2 - 275)}`,
    )
  }

  return (
    <Banner
      tone="info"
      icon={ShareIcon}
      onDismiss={() => {
        dashboard.closeSharingBanner()
          .then(res => {
            const { data } = res

            if (data.code === APICode.OK) {
              stores.userInfoSore.setDataSingle("notice_sharing", 1)
            }
          })
      }}
    >
      <div className="flex justify-between flex-wrap items-center gap-2">
        <div className={classNames("sm:flex-1 inline sm:flex items-center flex-wrap gap-1", styles.linkWrapper)}>
          <Trans
            ns="common"
            i18nKey="SharingBanner.content"
          />
          {" "}
          <CopiableLink link={link} />
        </div>
        <div className={"flex gap-[var(--p-space-button-group-gap)] items-center mr-[var(--p-space-button-group-gap)]"}>
          <div
            className={classNames("flex", styles.iconHover)}
            data-share="email"
            onClick={handleSharEmailClick}
          >
            <ShareEmail />
          </div>
          <div
            className={classNames("flex", styles.iconHover)}
            data-share="facebook"
            onClick={handleShareFacebookClick}
          >
            <ShareFacebook />
          </div>
        </div>
      </div>
    </Banner>
  )
}

function CopiableLink({
  link,
}: {
  link: string
}) {
  const handleClick = () => {
    const copyRes = copy(link)
    const msg = copyRes ? i18n.t("common:CopiedSuccessfully") : i18n.t("common:CopiedUnsuccessfully")

    toast(msg, 2e3)
  }

  return <>
    <Link
      url={link}
      target="_blank"
      removeUnderline
      monochrome
    >
      {link}
    </Link>
    <Tooltip content={i18n.t("common:CopyLink") as string} dismissOnMouseOut>
      <div
        style={{ verticalAlign: "bottom" }} className={"inline-block relative top-2 cursor-pointer ml-1 sm:ml-0"}
        onClick={handleClick}
      >
        <div style={{ position: "relative" }} className="w-5 h-5">
          <div style={{ position: "absolute", top: -8 }}>
            <Icon source={ClipboardIcon} tone={"base"} />
          </div>
        </div>
      </div>
    </Tooltip>
  </>
}
