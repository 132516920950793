import { Location } from "history"
import { NavigateFunction } from "react-router"
import { useLocation, useNavigate, useParams } from "react-router-dom"

type IReactComponent<P = any> = | React.ComponentClass<P> | React.FunctionComponent<P>

export interface IRouter {
  location: Location
  navigate: NavigateFunction
  params: typeof useParams
}

export function withRouter<T extends IReactComponent>(Component: T): T {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp as T
}
