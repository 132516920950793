import { EChartsOption } from "echarts-for-react/lib/types"

export const ShipmentsLookupsChartOption: EChartsOption = {
  calculable: false,
  grid: {
    // 距离右边的距离
    bottom: 36,

    containLabel: true,

    left: "0",

    // 距离左边的距离
    right: "0",


    x: 20,


    x2: 20,

    y: 35,
  },
  legend: { // Shipments Lookups 注释
    bottom: 0,
    data: ["Shipments", "Lookups"],
    icon: "roundRect",
    right: 0,
    itemGap: 16,
    selectedMode: false,
    textStyle: {
      fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
      "fontSize": 13,
      "fontWeight": 450,
    },
  },
  series: [
    {
      // 设定实心点的大小
      data: [],

      emphasis: {
        lineStyle: { width: 2 },
      },

      name: "Shipments",

      itemStyle: {
        normal: {
          color: "#2EB9F5",
          fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
          lineStyle: { width: 2 },
        },
      },
      symbol: "circle",
      markPoint: {
        label: {
          color: "#fff", // markPoint 标记文字颜色设置为白色
        },
      },
      type: "line",
      symbolSize: 1,
    },
    {
      // 设定实心点的大小
      data: [],

      emphasis: {
        lineStyle: { width: 2 },
      },

      name: "Lookups",

      itemStyle: {
        normal: {
          color: "#8F71EF",
          fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
          lineStyle: { width: 2 },
        },
      },
      symbol: "circle",
      markPoint: {
        label: {
          color: "#fff", // markPoint 标记文字颜色设置为白色
        },
      },
      type: "line",
      symbolSize: 1,
    },
  ],
  title: {
    bottom: 20,
    left: 16,
    text: "",
    textStyle: {
      color: "#637381",
      fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: "normal",
    },
  },
  tooltip: {
    "backgroundColor": "rgba(255, 255, 255, 1)",

    // 触发类型：坐标轴触发
    confine: true,

    // 使得tooltip一直在容器中 而不会超出
    extraCssText: "z-index: 2",
    // 通过设置rgba调节背景颜色与透明度
    "color": "#303030",


    position: function (point: any, params: any, dom: any, rect: any, size: any) {
      // 其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
      const [x, y] = point
      const [boxWidth, boxHeight] = size.contentSize
      let posX = 0 // x坐标位置
      let posY = 0 // y坐标位置

      if (x - 30 < boxWidth) { // 左边放不开
        posX = 165
      } else { // 左边放的下
        posX = x - boxWidth
      }

      if (y < boxHeight) { // 上边放不开
        posY = 5
      } else { // 上边放得下
        posY = y - boxHeight
      }
      return [posX, posY]
    },

    textStyle: {
      "color": "#303030",
      fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
      fontSize: 13,
      "fontWeight": "450",
      fontStyle: "normal",
    },
    trigger: "axis",
  },
  xAxis: [
    {
      // X 轴数据
      axisLabel: {
        align: "center",
        // showMaxLabel: true,
        textStyle: {
          color: "#637381",
          // 横坐标上label的倾斜度
          fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",

          fontSize: "12",

          interval: 30,
          // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
          rotate: 40,
        },
      },

      axisTick: {
        alignWithLabel: true,
      },

      boundaryGap: ["0", "0.1"],

      // 距离坐标原点是否有间隙
      data: [],

      type: "category",
    },
  ],
  yAxis: [
    {
      axisLabel: {
        textStyle: {
          color: "#637381",
          fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
          fontSize: "12",
        },
      },
      // 改变x轴字体颜色和大小
      max: function (value: any) {
        if (value.max < 5) {
          return 5
        }
        return null
      },


      minInterval: 1,

      type: "value",
    },
  ],
}
