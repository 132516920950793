import { WithTranslation } from "react-i18next"
import { IntercomContextValues } from "react-use-intercom/src/types"

export enum HelpCenterType {
  GetSupport,
  StartLiveChat,
  HelpDocs
}

export interface IGetHelpProps extends Pick<WithTranslation<"get_help" | "common">, "t"> {
  intercom: IntercomContextValues
}

export interface IGetHelpState {
  FAQItems: IFAQItem[]
}

export type HelpCenterItem = {
  id: HelpCenterType
  title: string
  content: string
  link?: string
  onClick?(): void
} & ({ link: string } | { onClick(): void })

export interface IFAQItem {
  title: string
  url: string
}
