import { LegacyCard, Text } from "@shopify/polaris"
import classNames from "classnames"
import React, { Component } from "react"

import { INormalListProps, NormalList } from "./components"
import styles from "./InfoCard.module.scss"


interface IProps {
  title: React.ReactNode | string;
  actionHeader?: React.ReactNode | string;
  headerClass?: string; // 自定义 class
  bodyClass?: string; // 自定义 class
  children: React.ReactNode;
  emptyChildren?: boolean;
}

class InfoCard extends Component<IProps, any> {
  public static NormalList: ({ data }: INormalListProps) => JSX.Element

  render() {
    const { children, bodyClass, emptyChildren } = this.props
    const bodyClassname = classNames(styles.InfoCard__Body, bodyClass, emptyChildren && "p-0")

    return (
      <LegacyCard>
        {this.InfoCardHeader()}
        <div className={bodyClassname}>
          {children}
        </div>
        {/* <div className={styles.InfoCard__Footer} />*/}
      </LegacyCard>
    )
  }

  /**
   * 卡片头部组件
   *
   * @constructor
   * @private
   */
  private InfoCardHeader() {
    const { title, actionHeader, headerClass } = this.props
    const className = classNames(styles.InfoCard__Header, !headerClass && "pb-4", headerClass)

    return (
      <div className={className}>
        <Text variant="headingSm" as="h3">{title}</Text>
        {actionHeader && <div>{actionHeader}</div>}
      </div>
    )
  }
}

// 普通的列表
InfoCard.NormalList = NormalList

export default InfoCard
