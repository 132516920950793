import { TFunction } from "i18next"
import { cloneDeep } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"
import { undefined } from "zod"

import { AppPricingInterval, CurrentDiscountCode } from "@/api/account/type.d"
import { APICode } from "@/api/APICode"
import appApi from "@/api/app"
import { SetupGuideData } from "@/api/dashboard/type"
import i18n from "@/i18n/config"
import { SupportLanguages } from "@/i18n/SupportLanguages"
import { BetaFeature, Data, IUserInfo, IUserInfoStoreData, LocalVar } from "@/models/IUserInfo"
import stores from "@/stores/index"
import { setBetaFeatures } from "@/utils/BetaFeature"
import { LanguageUtils } from "@/utils/languages"

class UserInfoStore implements IUserInfoStoreData {
  // @observable public name: string = "world"

  // 是否加载了用户数据信息
  @observable public isLoadData: boolean = false

  // 是否初始化了首页用户引导
  @observable public quickSetupInitialized: boolean = false
  @observable local: Record<keyof LocalVar, any> = {
    showFreeAdd: true,
    showQuotaRemain: true,
  }
  @observable quickSetupData: SetupGuideData["setup_guide"] = {
    display: true,
    task: [],
  }
  @observable data: Data = {
    beta_features: {
      features: [],
      report_url: "",
    },
    banner_discount_v2024_04: {
      billing: false,
      billing_banner_url: "",
      dashboard: false,
      dashboard_banner_url: "",
      from: 1,
      main_banner_url: "",
      second_banner_url: "",
    },
    discount: undefined,
    plan_interval: AppPricingInterval.Every30_Days,
    store_name: "",
    total_order_count: 0,
    ShopName: "",
    last_30d_count: 0,
    notice_free_order_add: 0,
    notice_remove_branding: 0,
    plan: 0,
    plan_member: 0,
    plan_name: "",
    plan_price: 0,
    quotaReminder: "",
    remaining_order_count: 0,
    selected_lang: SupportLanguages.English,
    shop_name: "",
    shop_plan_display_name: "",
    showNewUserGuide: false,
    showPPBranding: false,
    store_real_name: "",
    txt: {},
    user_email: "",
    user_name: "",
    discount_start: false,
    buttonSwitchAll: [],
    money_with_currency_format: "${{amount}} USD",
    is_paypal_connected: false,
    timezone: "",
    billing: {
      quota_reset_date: "",
    },
    current_subscription: {
      version: 2,
    },
    current_discount: {
      code: "",
      interval: AppPricingInterval.Every30_Days,
    },
    banner_data: [],
    currency: "USD", // 店铺的货币简码，为 null 则使用默认值 USD
  }
  // 用户折扣码
  @observable discount: {
    initialized: boolean
    code: CurrentDiscountCode | null
  } = {
      initialized: false,
      code: null,
    }

  constructor() {
    // 使用这个才会在 MobX 6 上才会更新视图
    makeObservable(this)
  }

  /**
   * 当前套餐等级
   */
  @computed
  get currentPlanLevel() {
    return this.data.plan_member
  }

  /**
   * 获取报告 URL
   */
  @computed
  get betaFeatureReportURL(): string {
    return this.data.beta_features.report_url
  }

  /**
   * 获取剩余额度百分比  0-100 区间
   */
  @computed
  get consumeOrderCountPercentage(): number {
    return (this.data.remaining_order_count / (this.data.total_order_count || 0)) * 100
  }

  @computed
  get showQuotaNotice(): boolean {
    // 额度不足提醒
    return !!this.data.quotaReminder
  }

  @computed
  get showPartnerFriendly(): boolean {
    // 用户店铺版本满足两种情况中的一种
    return (this.data.notice_partner_f !== 1 && this.data.is_show_partner_friendly_banner === 1) || (this.data.notice_partner_d !== 1 && this.data.is_show_partner_friendly_banner === 2)
  }

  @computed
  get showPartnerFriendlyAll(): boolean {
    return this.data.is_show_partner_friendly_banner !== 0
  }

  @computed
  get showReviewGuide(): boolean {
    return !this.showQuotaNotice && (this.data?.show_review_guide || false) && (this.data.notice_fou === 0)
  }

  @computed
  get showGetHelpReviewGuide(): boolean {
    return !!this.data?.show_review_guide && this.data.notice_fou === 0
  }

  @computed
  get showFirstSync(): boolean {
    return this.data.notice_fiv === 0 && !this.data.reg_gt_1day
  }

  @computed
  get isFirstSyncing(): boolean {
    return this.data.notice_firsync !== 1
  }

  @computed
  get showSharingBanner(): boolean {
    return this.data.notice_sharing === 0
  }

  @computed
  get showRemoveBranding(): boolean {
    if (!this.data.showPPBranding) return false
    return this.data.notice_remove_branding === 0
  }

  @computed
  get showFreeOrderAdd(): boolean {
    return this.data.notice_free_order_add === 0
  }

  @computed
  get showBeginnersGuide(): boolean {
    return this.data.notice_twe === 0
  }

  @computed
  get isFreeUser(): boolean {
    return this.data.plan_member === 1
  }

  @computed
  get isOldUser(): boolean {
    return this.data.plan_member === 0
  }

  @computed
  get oldPayingUsers(): boolean {
    return this.isOldUser && this.data.plan >= 1
  }

  /**
   * book a demo banner显示
   */
  @computed
  get showBookDemoBanner() {
    const banner = this.data.banner_data.find(item => item.id === 20482)

    return banner && banner.display
  }

  @computed
  get showRemoveBrandingBanner(): boolean {
    if (!this.data.showPPBranding) return false
    return this.data.notice_remove_branding === 0
  }

  @action.bound
  public setBannerClose(id: number): void {
    const banners = cloneDeep(this.data.banner_data)

    banners.forEach(item => {
      if (item.id === id) {
        item.display = false
      }
    })

    this.setDataKey("banner_data", banners)
  }

  @action.bound
  public setData(data?: Data): void {
    if (!data) return
    this.data = data
    this.setUpgradeBtnText(data, i18n.t)
    // 设置选择的语言
    LanguageUtils.setLocale(data.selected_lang)
  }

  // 更新用户信息
  @action.bound
  public updateUserInfo(): void {
    appApi.userInfo()
      .then(({ data: { code, data } }: { data: IUserInfo }) => {
        if (code === APICode.OK) {
          this.setData(data)
          this.setIsLoadData(true)

          if (data?.beta_features) {
            setBetaFeatures(data?.beta_features)
          }
        }
      })
  }

  @action.bound
  public setUpgradeBtnText(data: Data, t: TFunction): void {
    let btnText = ""

    if ((data.trial_days || 0) > 0) {
      btnText = t("StartTrialDaysFree", { ns: "common", replace: { trialDays: data.trial_days } })
    } else {
      btnText = t("UpgradeNow", { ns: "common" })
      // btnText = t("planInfo.changePlan", { ns: "dashboard" })
    }

    // 设置升级按钮文本
    stores.upgradeStore.setBtnText(btnText)
  }

  @action.bound
  setDataSingle<K extends keyof Data>(key: K, val: Data[K]): void {
    Object.prototype.hasOwnProperty.call(this.data, key) && (this.data[key] = val)
  }

  @action.bound
  setDiscountSingle<K extends keyof Data["discount"]>(key: K, val: Data["discount"][K]): void {
    Object.prototype.hasOwnProperty.call(this.data.discount, key) && (this.data.discount[key] = val)
  }

  @action.bound
  setDataKey<K extends keyof Data>(key: K, val: Data[K]): void {
    Object.prototype.hasOwnProperty.call(this.data, key) && (this.data[key] = val)
  }

  @action.bound
  setButtonSwitchAll<K extends keyof Data["buttonSwitchAll"]>(key: K, val: Data["buttonSwitchAll"][K]): void {
    this.data.buttonSwitchAll[key] = val
  }

  @action.bound
  setLocalVar(key: keyof LocalVar, val: any): void {
    Object.prototype.hasOwnProperty.call(this.local, key) && (this.local[key] = val)
  }

  @action.bound
  public setIsLoadData(status: boolean): void {
    this.isLoadData = status
  }

  @action.bound
  public setQuickSetupData(data: any): void {
    this.quickSetupData = data
    this.quickSetupInitialized = true
  }

  @action.bound
  public setDiscount(code: CurrentDiscountCode | null) {
    // 兼容如果后端传空数组的情况
    if (Array.isArray(code) && code.length === 0) code = null
    this.discount = {
      initialized: true,
      code,
    }
  }
}

export default UserInfoStore
