import numeral from "numeral"

export class MathUtils {
  /**
   * 求百分比
   *
   * 6/3 = 2
   * 6:被除数
   * 3:除数
   *
   * @param dividend  被除数
   * @param divisor   除数
   * @param returnStr 如果其中一个数为 0 那么输出什么。
   */
  static getPercentage(dividend: number | null | string, divisor: number | null | string, returnStr: string = "-"): | string {
    // 如果不是数字
    if (typeof dividend !== "number" || typeof divisor !== "number") return returnStr

    // 如果都是 0
    if (dividend <= 0 || divisor <= 0) return returnStr

    return (Math.round(dividend / divisor * 10000) / 100.00) + "%"
  }

  /**
   * 没有 - 将转换为千分分割的数字
   * @param num
   */
  static noDashConvertThousands(num: string | number): string {
    if (num === "-") return num
    return numeral(num)
      .format("0,0")
  }
}
