import { set } from "lodash-es"
import { action, makeAutoObservable, observable } from "mobx"

import { PostPurchaseEDDModule } from "@/models/ITrackingPageOptions"
import { defaultEDDModuleConfig } from "@/stores/tracking_page/defaultConfig"

export type ModulesStoreProps = {
  config: PostPurchaseEDDModule
  saveBtnActive: boolean
}

export default class EDDModulesStore implements ModulesStoreProps {
  @observable config: PostPurchaseEDDModule = defaultEDDModuleConfig
  @observable saveBtnActive: boolean = false

  constructor() {
    // 使用这个才会在 MobX 6 上才会更新视图
    makeAutoObservable(this)
  }

  @action.bound
  setSaveBtnActive(active: boolean) {
    this.saveBtnActive = active
  }

  @action.bound
  setConfig(data: PostPurchaseEDDModule) {
    this.config = data
  }

  @action.bound
  setTargetConfig<T>(path: string, v: T) {
    set(this.config, path, v)
  }

  @action.bound
  setBusinessDayChecked(id: string) {
    const value = Number(id)
    const business_days = [...this.config.fulfillment_workflow.business_days]
    const targetIndex = business_days.findIndex((item) => item === value)

    if (targetIndex !== -1) {
      business_days.splice(targetIndex, 1)
    } else {
      business_days.push(value)
      business_days.reverse()
    }

    // 按照日期排序
    const sortedArray = business_days.slice()
      .sort(function (a, b) {
        return a - b
      })

    this.setTargetConfig("fulfillment_workflow.business_days", sortedArray)
  }
}
