import classNames from "classnames"

import styles from "./Timeline.module.scss"

export interface TimelineItemProps {
  key?: React.Key;
  // prefixCls?: string;
  className?: string;
  // color?: LiteralUnion<Color>;
  dot?: React.ReactNode;
  // pending?: boolean;
  // position?: string;
  // style?: React.CSSProperties;
  // label?: React.ReactNode;
  children?: React.ReactNode;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  className,
  dot,
  children,
}: TimelineItemProps) => {
  const itemClassName = classNames([
    styles.Timeline_Item,
    className,
  ])

  return (
    <div className={itemClassName}>
      <div className={styles.Timeline_Item_Tail} />
      <div className={styles.Timeline_Item_Head}>
        {dot}
      </div>
      <div className={styles.ItemContent}>
        {children}
      </div>
    </div>
  )
}

export default TimelineItem
