import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

import { OrderSyncToPayPal } from "@/enums/order-status"

type SyncStatusTagProps = {
  status: OrderSyncToPayPal
}

const SyncStatusTag = (props: SyncStatusTagProps) => {
  const { status } = props
  const { t } = useTranslation("orders")

  switch (status) {
    case OrderSyncToPayPal.OtherPaymentMethods:
      return <Badge tone="info">{t("OtherPaymentMethods")}</Badge>
    case OrderSyncToPayPal.SyncedSuccessfully:
      return <Badge tone="success">{t("SyncedSuccessfully")}</Badge>
    case OrderSyncToPayPal.SyncFailed:
      return <Badge tone="attention">{t("SyncFailed")}</Badge>
    case OrderSyncToPayPal.Unsynced:
      return <Badge>{t("Unsynced")}</Badge>
    default:
      return <>-</>
  }
}

export default SyncStatusTag
