import { LegacyCard } from "@shopify/polaris"
import React, { Component, ErrorInfo } from "react"

interface IProps {
  children: React.ReactNode;
  errorMsg?: string;
}

interface IState {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export default class DynamicLoadError extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { children, errorMsg } = this.props

    if (this.state.errorInfo) {
      return (
        <LegacyCard title="Something went wrong." sectioned>
          <p>{errorMsg ?? "Network error, unable to load page"}</p>
          <br />
          {!import.meta.env.PROD && <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>}
        </LegacyCard>
      )
    }

    return children
  }
}
