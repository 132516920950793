import { difference, isArray, isEqual, isObject, transform } from "lodash-es"

export const deepDiffBetweenObjects = (object: object, base: object) => {
  const changes = (object: object, base: any) => {
    return transform(object, (result: any, value: any, key: string) => {
      if (!isEqual(value, base[key])) {
        if (isArray(value)) {
          result[key] = difference(value, base[key])
        } else if (isObject(value) && isObject(base[key])) {
          result[key] = changes(value, base[key])
        } else {
          result[key] = value
        }
      }
    })
  }

  return changes(object, base)
}

type Changes = {
  [key: string]: any; // 只记录修改的键
};

export function compareObjects<T>(obj1: T, obj2: T): Changes {
  const changes: Changes = {}

  function compare(o1: any, o2: any) {
    for (const key of Object.keys(o1)) {
      if (o2[key] !== undefined) {
        if (typeof o1[key] === "object" && o1[key] !== null) {
          // 如果是对象，递归比较
          const nestedChanges = compareObjects(o1[key], o2[key])

          if (Object.keys(nestedChanges).length > 0) {
            changes[key] = nestedChanges // 记录有变化的嵌套对象
          }
        } else if (o1[key] !== o2[key]) {
          changes[key] = o1[key] // 记录被修改的值
        }
      }
    }
  }

  compare(obj1, obj2)
  return changes
}

export function hasEmptyString(obj: Record<string, any>): boolean {
  // 检查对象是否为 null 或不是对象
  if (obj === null || typeof obj !== "object") {
    return false
  }

  // 遍历对象的每个属性
  for (const key in obj) {
    // 确保属性是对象自身的属性
    if (obj[key] !== undefined) {
      const value = obj[key]

      // 如果值为空字符串，返回 true
      if (value === "") {
        return true
      }

      // 如果值是对象，递归检查
      if (typeof value === "object") {
        if (hasEmptyString(value)) {
          return true
        }
      }
    }
  }

  // 如果没有找到空字符串，返回 false
  return false
}

