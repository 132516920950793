import { action, makeObservable, observable } from "mobx"

import { PostTemplate } from "@/component/PostLayer/PostTemplate.d"
import IPost from "@/models/IPost"

export default class StatisticsStore implements IPost {
  @observable public showCommentModal = false// 是否展示评价弹窗
  @observable public rate = 0// 评价几颗星
  @observable public handleClickShowMessage = true// 是否响应 click 事件弹窗聊天对话框
  @observable public show: boolean = false// 是否显示 POST 消息
  @observable public template = PostTemplate.GREET// 模版 ID
  @observable public showNumb = 0 // 展示 POST 统计
  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setRate(data: number) {
    this.rate = data
  }

  @action.bound
  public setShowCommentModal(data: boolean): void {
    this.showCommentModal = data
  }

  @action.bound
  public setPostTemplate(template: number): void {
    this.template = template
    this.setPostShow(true)
  }

  @action.bound
  public setPostShow(data: boolean): void {
    // 将要弹出 Post 时检测是否有会话弹出
    if (data && document.querySelector("#intercom-container iframe.intercom-borderless-frame")) {
      return
    }
    this.show = data
  }


}
