import { Modal, Text } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

type StaffingCalculatorModalProps = {
  open: boolean
  loading: boolean
  disabled?: boolean
  title?: string
  description?: string
  onClose: () => void
  onSave?: () => void
  children?: React.ReactNode
}
export function StaffingCalculatorModal({
  open,
  loading,
  title,
  disabled,
  description,
  onClose,
  onSave,
  children,
}:StaffingCalculatorModalProps) {
  const { t } = useTranslation(["dashboard", "common"])

  return <Modal
    open={open}
    onClose={onClose}
    title={title}
    primaryAction={{
      content: t("common:Save"),
      onAction: onSave,
      loading,
      disabled,
    }}
    secondaryActions={[
      {
        content: t("common:Cancel"),
        onAction: onClose,
      },
    ]}
  >
    <Modal.Section>
      <Text as={"h3"} variant={"headingSm"}>{t("dashboard:HowCalculated")}</Text>
      <div className={"mt-1 mb-3"}>{description}</div>
      {children}
    </Modal.Section>
  </Modal>
}
