import { Button, Text } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

import { Thumbnail } from "@/component"
import { APPItem } from "@/models/IRecommendedAPP"

import styles from "./index.module.scss"

export default function RecommendedAPPItemNew(props: APPItem) {
  const { t } = useTranslation("common")

  return <>
    <div className={styles.RecommendedAPPOut}>
      <div className={styles.RecommendedAPPItem}>
        <div className={styles.logo}>
          <Thumbnail
            source={props.logo}
            size="48"
            alt={`${props.name} LOGO`}
          />
        </div>

        <div className={styles.info}>
          <div className={styles.title}>
            <h3 className={styles.titleP}>
              {props.name}
            </h3>
          </div>
          <div className={styles.summary}>
            <Text as="span" tone="subdued">
              {props.summary}
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.link}>
        <Button url={props.link} fullWidth external>{t("FreeInstall")}</Button>
      </div>
    </div>
  </>
}
