const SVGDefault = (props: any) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="7" fill="white" />
    <path
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L15.3661 7.63599C15.7565 7.24552 15.7565 6.6125 15.3661 6.22206C14.9756 5.83157 14.3425 5.8316 13.952 6.22212L9.003 11.172L6.881 9.05C6.49053 8.65954 5.85747 8.65953 5.467 9.05C5.07653 9.44046 5.07654 10.0735 5.467 10.464L9.003 14Z"
      fill="#1BBE73"
    />
  </svg>
}

export default SVGDefault
