import classNames from "classnames"
import React from "react"

import styles from "./NormalList.module.scss"

export interface NormalListItem {
  filed: React.ReactNode
  value: React.ReactNode
}

export interface INormalListProps {
  data: NormalListItem[],
  styleField?: StyleMedia;
  styleVal?: StyleMedia;
}

export default function NormalList({ data, styleField, styleVal }: INormalListProps) {
  return (<div className={styles.NormalList}>
    {data.map((item, index) => {
      return NormalListItem(item, `${index}-NL`, { styleField, styleVal })
    })}
  </div>)
}

function NormalListItem({
  filed,
  value,
}: NormalListItem, key: string, _styles: Pick<INormalListProps, "styleField" | "styleVal">) {
  return (
    <div className={styles.NormalList__Item} key={key}>
      <div className={classNames(styles.NormalList__Item_Field)}>{filed}</div>
      <div className={styles.NormalList__Item_Val}>{value}</div>
    </div>
  )
}
