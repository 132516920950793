import { includes } from "lodash-es"
import { action, makeObservable, observable } from "mobx"

import { AppList } from "@/api/dashboard/type/recommendedAPP"
import { APPItem, IRecommendedAPP } from "@/models/IRecommendedAPP"

export default class RecommendedAPPStoreAll implements IRecommendedAPP {
  @observable public loadedDataTime = 0
  @observable public show = false
  @observable public goLink = "https://www.parcelpanel.com/affiliate/#partner"
  @observable public tip = "Try one of these top recommended apps to help your business"
  @observable public title = "Download it for Free today!"
  @observable public data: APPItem[] = [
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=S7Fow5K2YO9CMqVkuLJNlA%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/c2fde25432c44d6aeefb31ba06bbb974/icon/CNSh36Ki0/MCEAE=.jpeg?height=84&quality=90&width=84",
      "name": "LAI AliExpress Reviews",
      "summary": "Photo product reviews. Build trust & boost sales with coupon",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=K7PZ9bJuBqXUko88Tvltzw%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/6d72140e25d3f444e352738033321b98/icon/CJCIkdONi/UCEAE=.jpeg?height=72&quality=90&width=72",
      "name": "GemPages Landing Page Builder",
      "summary": "Build custom no-code Landing Pages, Product Pages & more!",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=JVg42z96GKhPQy%2BYnSrRrw%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/1252d9d2612d6b071896af7336148d30/icon/CIuP6oSGoPACEAE=.png?height=84&width=84",
      "name": "AliExpress Dropshipping&Source",
      "summary": "7-15 days shipping worldwide. Scale your dropshipping business to 7-figures.",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=ZRYRmVlImY6Xdfo2p%2F4Oww%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/9e850173749ed1fed1175c97237dc3cb/icon/CJ3R39/0lu8CEAE=.jpg?height=84&quality=90&width=84",
      "name": "TinyIMG SEO & Image Optimizer",
      "summary": "SEO image & site speed optimizer. Compression, ALT, PNG to JPG",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=Bj7QAPclSoll47qrPNIH7w%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/538bedf0d0417d22b416e97eed880aa5/icon/CKKsgrP0lu8CEAE=.jpg?height=84&quality=90&width=84",
      "name": "Easy Contact Form Builder",
      "summary": "Build a contact form with Popup, Embed, File upload, Captcha... all features available",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=9Ojzkmg3yt5hmbSsMzJaBQ%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/59da3ad107e08fd5430db47cc4e0a75c/icon/CKq1o6jxo/MCEAE=.png?height=84&width=84",
      "name": "Growave - Loyalty program rewards, referral, product reviews, gift cards",
      "summary": "Powerful all-in-one marketing platform that empowers your Shopify store",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=DPmioF%2B8ckDI1c5T0lrj7A%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/3c87b0179bad81cd5b2a0b6f0c595df1/icon/CNmYi+Hcw/ACEAE=.jpeg?height=84&quality=90&width=84",
      "name": "ETranslate‑Language Translate",
      "summary": "Translate your store into multi languages",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=R5ZWpzfRYhpkbTZ4bTBnhw%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/9c2f67b482aeae04937fd544c0bfe6a8/icon/CIGR67+a1fACEAE=.png?height=84&width=84",
      "name": "UpPromote: Affiliate Marketing",
      "summary": "Grow With Ambassador, Influencer, Referral & Affiliate Program",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=1uBpbWTMUoCFATrcU%2F%2FHiQ%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/f85ee597169457da8ee70b6652cae768/icon/CKWystP0lu8CEAE=.jpg?height=84&quality=90&width=84",
      "name": "PageFly #1 Shopify Page Builder.",
      "summary": "Create landing page, product page, etc with drag-and-drop and customization. No coding needed.",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=okZTL57uFmr5ErOn0cUsYA%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/84e0aa977370beb48bcf0fac08b7be2d/icon/CLTr9Mf0lu8CEAE=.png?height=84&width=84",
      "name": "Broken link SEO 404 Redirect",
      "summary": "Full control of 404 errors + 301 Redirect + URL suggestion",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=KT35baXovopnSATMQds8eA%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/57953f1b3d5799998ef9e4eaafc2a0e0/icon/CIXg86v0lu8CEAE=.png?height=84&width=84",
      "name": "SEO Booster ‑ SEO Marketing",
      "summary": "Easy SEO, Google traffic & higher ranking. 24/7 Live Support",
    },
    {
      "link": "https://appapi.sealapps.net/api/v1/install?app_id=7sMdniki2GmjhU%2B8Lxwhkg%3D%3D",
      "logo": "https://cdn.shopify.com/app-store/listing_images/d78d3ba6780d879b764fa9a175953e44/icon/CI+Qvvbx0vMCEAE=.jpeg?height=84&quality=90&width=84",
      "name": "Add+| Free Upsell & Cross Sell",
      "summary": "Post-purchase and thank you page upsell, cross sell promotions",
    },
  ]

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setData(data: IRecommendedAPP | AppList) {
    const currentVar = Object.keys(this)
    // @ts-ignore

    Object.keys(data)
      .map(value => includes(currentVar, value) && (this[value] = data[value]))
  }

  @action.bound
  setLoadedDataTime(time: number) {
    this.loadedDataTime = time
  }
}
