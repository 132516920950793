enum OrdersNav {
  All,
  Pending,
  "In transit",
  "Out for delivery",
  "Delivered",
  Expired,
  "Failed attempt",
  Exception,
  "Info received",
}

export default OrdersNav