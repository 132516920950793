import classNames from "classnames"
import React, { CSSProperties } from "react"

import styles from "./Container.module.scss"


export interface ContainerDescriptor {
  children: React.ReactNode;
  id?: string;
  fullWidth?: boolean;
  maxWidth?: string;
  className?: string;
}

interface CustomCSS extends CSSProperties {
  "--default-max-width"?: string;
}

export function Container(
  {
    id,
    fullWidth,
    maxWidth = "950px",
    children,
    className: _className,
  }: ContainerDescriptor) {

  const className = classNames(
    styles.Container,
    _className,
    fullWidth && styles.fullWidth,
  )

  const styleVars: CustomCSS = {}

  maxWidth && (styleVars["--default-max-width"] = maxWidth)

  return (
    <div id={id} className={className} style={styleVars}>
      {children}
    </div>
  )
}
