import { Icon, LegacyCard, Text } from "@shopify/polaris"
import { ChatIcon, EmailIcon } from "@shopify/polaris-icons"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"

import styles from "@/pages/GetHelp/GetHelp.module.scss"
import { HelpCenterType } from "@/pages/GetHelp/types"


const ParcelpanelHelpDocsIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.75 6C6.33579 6 6 6.33579 6 6.75C6 7.16421 6.33579 7.5 6.75 7.5H7.25C7.66421 7.5 8 7.16421 8 6.75C8 6.33579 7.66421 6 7.25 6H6.75Z" fill="#4A4A4A" />
  <path d="M6.75 9C6.33579 9 6 9.33579 6 9.75C6 10.1642 6.33579 10.5 6.75 10.5H7.25C7.66421 10.5 8 10.1642 8 9.75C8 9.33579 7.66421 9 7.25 9H6.75Z" fill="#4A4A4A" />
  <path d="M6.75 12C6.33579 12 6 12.3358 6 12.75C6 13.1642 6.33579 13.5 6.75 13.5H7.25C7.66421 13.5 8 13.1642 8 12.75C8 12.3358 7.66421 12 7.25 12H6.75Z" fill="#4A4A4A" />
  <path d="M12 6.75C12 6.33579 12.3358 6 12.75 6H13.25C13.6642 6 14 6.33579 14 6.75C14 7.16421 13.6642 7.5 13.25 7.5H12.75C12.3358 7.5 12 7.16421 12 6.75Z" fill="#4A4A4A" />
  <path d="M12 9.75C12 9.33579 12.3358 9 12.75 9H13.25C13.6642 9 14 9.33579 14 9.75C14 10.1642 13.6642 10.5 13.25 10.5H12.75C12.3358 10.5 12 10.1642 12 9.75Z" fill="#4A4A4A" />
  <path fillRule="evenodd" clipRule="evenodd" d="M7.58579 3.5C8.45675 3.5 9.29958 3.77285 10 4.27122C10.7004 3.77285 11.5433 3.5 12.4142 3.5H14.75C15.7165 3.5 16.5 4.2835 16.5 5.25V14.75C16.5 15.7165 15.7165 16.5 14.75 16.5H14.5118C13.4336 16.5 12.3638 16.6885 11.3506 17.0569L10.2563 17.4548C10.0973 17.5127 9.92336 17.515 9.76283 17.4615L8.30233 16.9747C7.35914 16.6603 6.37143 16.5 5.37722 16.5H5.25C4.2835 16.5 3.5 15.7165 3.5 14.75V5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H7.58579ZM5 5.25C5 5.11193 5.11193 5 5.25 5H7.58579C8.19356 5 8.77992 5.20767 9.25 5.58371V15.7094L8.77667 15.5517C7.68053 15.1863 6.53266 15 5.37722 15H5.25C5.11193 15 5 14.8881 5 14.75V5.25ZM10.75 15.6792V5.58371C11.2201 5.20767 11.8064 5 12.4142 5H14.75C14.8881 5 15 5.11193 15 5.25V14.75C15 14.8881 14.8881 15 14.75 15H14.5118C13.2588 15 12.0155 15.219 10.838 15.6472L10.75 15.6792Z" fill="#4A4A4A" />
</svg>

export default function HelpCenterCard() {
  const { t } = useTranslation("get_help")

  const intercom = useIntercom()
  const contactUS = (value: string) => {
    intercom.showNewMessages(value)
  }

  const getHelpCenterItems = () => {
    return [
      {
        content: t("HelpCenter.Module1.content"),
        icon: <Icon source={EmailIcon} />,
        id: HelpCenterType.GetSupport,
        link: "mailto:parcelpanel@uppercommerce.com",
        title: t("HelpCenter.Module1.title"),
      },
      {
        content: t("HelpCenter.Module2.content"),
        icon: <Icon source={ChatIcon} />,
        id: HelpCenterType.StartLiveChat,
        onClick: contactUS,
        title: t("HelpCenter.Module2.title"),
      },
      {
        content: t("HelpCenter.Module3.content"),
        icon: ParcelpanelHelpDocsIcon,
        id: HelpCenterType.HelpDocs,
        link: "https://docs.parcelpanel.com/",
        title: t("HelpCenter.Module3.title"),
      },
    ]
  }

  const handleClick = useCallback((e: { preventDefault: () => void }, onClick: () => void) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  }, [])

  return (
    <LegacyCard
      title={<Text variant={"headingMd"} as={"h2"}>{t("HelpCenter.title")}</Text>}
      sectioned
    >
      <div className={styles.HelpCenter}>
        {
          getHelpCenterItems()
            .map(({ id, icon, title, content, link, onClick }) => (
              <div key={id} className={styles.HelpCenter__Item}>
                <a
                  href={link} target="_blank" onClick={e => handleClick(e, onClick)} rel="noreferrer"
                >
                  <div className="flex gap-1">
                    <i>{icon}</i>
                    <div>
                      <Text as={"h3"} variant={"headingSm"}>{title}</Text>
                      <p className={styles["HelpCenter__Item-Content"]}>{content}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))
        }
      </div>
    </LegacyCard>
  )
}
