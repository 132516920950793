import { Button, LegacyCard, Text } from "@shopify/polaris"
import classNames from "classnames"
import { t } from "i18next"
import { Observer, observer } from "mobx-react"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { IRouter, SimpleTableListFields, Spacing, withRouter } from "@/component"
import BlockMaskLayer from "@/component/BlockMaskLayer/BlockMaskLayer"
import CasingPly from "@/component/CasingPly/CasingPly"
import DynamicLoadError from "@/component/DynamicLoadError/DynamicLoadError"
import stores from "@/stores"
import { LanguageUtils } from "@/utils/languages"
import { IListenerNameEnum } from "@/utils/languages.d"
import { MathUtils } from "@/utils/MathUtils"

import SimpleTableList from "../../SimpleTableList"
import STableStyles from "../../SimpleTableList/index.module.scss"
import SpinnerContainer from "../../SpinnerContainer/SpinnerContainer"
import styles from "./index.module.scss"

interface IProps extends WithTranslation<"dashboard"> {
  router?: IRouter
}

// const ReactECharts = React.lazy(() => import("echarts-for-react"))
const DashboardLineChart = React.lazy(() => import("@/component/Charts/DashboardLineChart"))

// import DashboardLineChart from "@/component/Charts/DashboardLineChart"

@withRouter
@observer
class ProductRecommendationChart extends React.Component<IProps, any> {
  public componentDidMount() {
    // 因为切换语言，数据未改变，不会触发组件更新导致内容未翻译，所以用此方法触发
    LanguageUtils.addListener(IListenerNameEnum.ProductRecommendationListener, function () {
      stores.productRecommendationDataStore.setLegendData([t("ProductRecommendationChart.legend")])
    })
  }

  public componentWillUnmount() {
    LanguageUtils.removeListener(IListenerNameEnum.ProductRecommendationListener)
  }

  render() {
    const { t } = this.props

    return (
      <Observer>{() => {
        const loadingData = stores.productRecommendationDataStore.loadedDataTime === 0
        const click = stores.productRecommendationDataStore.click || "-"

        const { userInfoSore } = stores

        return <>
          <LegacyCard.Section>

            <CasingPly
              show={loadingData}
            >
              <p
                className={
                  styles.CardSection__Allocation
                }
              >{MathUtils.noDashConvertThousands(click)}</p>

              {this.DataTable()}

              <p className={styles.Chart__Subhead}>
                <Text as="span" tone="subdued" variant={"bodySm"} fontWeight={"semibold"}>{t("ProductRecommendationChart.subTitle")}</Text>
              </p>
            </CasingPly>

            <BlockMaskLayer
              show={!loadingData && userInfoSore?.isFreeUser === true && userInfoSore?.isOldUser !== true}
              maskNode={(
                <div className={classNames(["flex", "items-center", "flex-col", "-mt-20", "mb-0"])}>
                  <p>{t("ProductRecommendationChart.enableMsg")}</p>
                  <Spacing />
                  <Button

                    onClick={() => this.props.router?.navigate("/tracking-page/productRecommendation")}
                    variant="primary"
                  >
                    {t("ProductRecommendationChart.enableThisFeature")}
                  </Button>
                </div>
              )}
            >
              <SpinnerContainer
                loading={loadingData}
                spinnerClassName={["relative", "-top-20"]}
              >
                <DynamicLoadError errorMsg={"Network error, unable to load charts"}>
                  <React.Suspense fallback={
                    <SpinnerContainer loading={!loadingData}>
                      <div className={styles.CardSection__ReactECharts} />
                    </SpinnerContainer>
                  }
                  >
                    <DashboardLineChart
                      className={styles.CardSection__ReactECharts}
                      option={stores.productRecommendationDataStore.options}
                    />
                  </React.Suspense>
                </DynamicLoadError>
              </SpinnerContainer>
            </BlockMaskLayer>
          </LegacyCard.Section>
        </>
      }
      }</Observer>
    )
  }

  DataTable() {
    const { t } = this.props

    const number = MathUtils.noDashConvertThousands(stores.productRecommendationDataStore.number || "-")
    const fields: SimpleTableListFields[] = [
      { field: "title", title: "title" },
      { className: STableStyles.AlignRight, field: "val", title: "Val" }, // 第2列
      { className: STableStyles.AlignRight, field: "percentage", title: "percentage" }, // 第3列
    ],
      data = [
        { percentage: number, title: t("ProductRecommendationChart.orders"), val: "" },
        {
          percentage: stores.productRecommendationDataStore.money || "-",
          title: t("ProductRecommendationChart.revenue"),
          val: "",
        },
      ]

    return (<SimpleTableList noHead fields={fields} data={data} />)
  }
}

export default withTranslation("dashboard")(ProductRecommendationChart)
