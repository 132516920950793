import dayjs from "dayjs"
import { t } from "i18next"

import { APICode } from "@/api/APICode"
import { BaseResponse } from "@/api/base"
import dashboard from "@/api/dashboard"
import { FilterDateParam } from "@/api/dashboard/type/FilterDateParam"
import { RecommendedAPP } from "@/api/dashboard/type/recommendedAPP"
import { ProductRecommendationData } from "@/models/IProductRecommendationChart"
import { ShippingNotifications } from "@/models/IShippingNotificationsChart"
import stores from "@/stores"

abstract class IInit {
  static batchGetChartTimer: null | number | NodeJS.Timer

  static init = (): void => {
    return undefined
  }

  /**
   * 获取推荐 APP
   */
  static getRecommendedAPP = (focus?: boolean): void => {
    return undefined
  }

  /**
   * 批量读取获取这些数据
   */
  static batchGetChart = (focus?: boolean, param?: FilterDateParam): void => {
    return undefined
  }

  /**
   * 获取统计数据
   * @param focus
   * @param param
   */
  static getStatistics = (focus?: boolean, param?: FilterDateParam) => {
    return undefined
  }

  /**
   * 获取 shipments lookups 数据
   * @param focus
   * @param param
   */
  static getShipmentsData = (focus?: boolean, param?: FilterDateParam) => {
    return undefined
  }

  static getShippingNotifications = (focus?: boolean, param?: FilterDateParam) => {
    return undefined
  }

  static getProductRecommendationChart = (focus?: boolean, param?: FilterDateParam) => {
    return undefined
  }
}

// ////////////////////////////////////////////////////////////////////

export default class DashboardInit extends IInit {
  static batchGetChartTimer: null | number | NodeJS.Timer = null

  private static checkFirstSyncTimer: NodeJS.Timeout

  static init() {
    this.batchGetChart(true, { value6: 60 })
    // this.getRecommendedAPP()
  }

  /**
   * 获取推荐 APP
   */
  static getRecommendedAPP(focus?: boolean) {
    const { recommendedAPPStore, RecommendedAPPStoreAll } = stores

    // only once load data
    if (!focus && recommendedAPPStore.loadedDataTime > 0 && RecommendedAPPStoreAll.loadedDataTime > 0) return

    dashboard.recommendedAPP()
      .then(({ data: response }: { data: RecommendedAPP }) => {
        if (response.code === 200 && response.data.appList) {

          RecommendedAPPStoreAll.setData(response.data.appListAll)
          RecommendedAPPStoreAll.setLoadedDataTime(dayjs()
            .unix())

          recommendedAPPStore.setData(response.data.appList)
          recommendedAPPStore.setLoadedDataTime(dayjs()
            .unix())
        }
      })
  }

  /**
   * 批量读取获取这些数据
   */
  static batchGetChart(focus?: boolean, param?: FilterDateParam) {
    if (typeof this.batchGetChartTimer === "number") {
      clearTimeout(this.batchGetChartTimer)
    }

    this.batchGetChartTimer = setTimeout(() => {
      // this.getStatistics(focus, param)
      this.getShipmentsData(focus, param)
      // this.getShippingNotifications(focus, param)
      // this.getProductRecommendationChart(focus, param)
    }, 0)
  }

  /**
   * 获取统计数据
   * @param focus
   * @param param
   */
  static getStatistics(focus?: boolean, param?: FilterDateParam) {
    const { statisticsStore } = stores

    if (!focus && statisticsStore.loadedDataTime > 0) return
    if (focus) statisticsStore.setLoadedDataTime(0)

    dashboard.statistics(param)
      .then((res) => {
        const { data } = res

        if (data.code !== APICode.OK) {
          console.warn("getStatistics: 获取数据失败", res)
          return
        }
        statisticsStore.setLoadedDataTime(dayjs()
          .unix())
        data.data && statisticsStore.setData(data.data)
      })
  }

  /**
   * 获取 shipments lookups 数据
   * @param focus
   * @param param
   */
  static getShipmentsData(focus?: boolean, param?: FilterDateParam) {
    const shipmentsLookupsChartStore = stores.shipmentsLookupsDataStore

    if (!focus && shipmentsLookupsChartStore.loadedDataTime > 0) return
    if (focus) shipmentsLookupsChartStore.setLoadedDataTime(0)

    dashboard.getShipmentsAndLookups(param)
      .then(res => {
        const { data } = res

        shipmentsLookupsChartStore.setLoadedDataTime(dayjs()
          .unix())
        data.shipments_lookups && shipmentsLookupsChartStore.setNewData(data, [
          t("ShipmentsLookups.Shipments", { ns: "dashboard" }),
          t("ShipmentsLookups.Lookups", { ns: "dashboard" }),
        ])
      })
  }

  static getShippingNotifications(focus?: boolean, param?: FilterDateParam) {
    const { shippingNotificationsDataStore } = stores

    if (!focus && shippingNotificationsDataStore.loadedDataTime > 0) return
    if (focus) shippingNotificationsDataStore.setLoadedDataTime(0)

    interface dataResponse extends BaseResponse {
      data?: ShippingNotifications
    }

    dashboard.shippingNotifications(param)
      .then(res => {
        const { data } = res

        if (data.code !== APICode.OK) {
          console.warn("shippingNotifications: 获取数据失败", res)
          return
        }

        console.info("shipmentsData: 获取数据成功", data.data)
        shippingNotificationsDataStore.setLoadedDataTime(dayjs()
          .unix())

        data.data && shippingNotificationsDataStore.setData(data.data, [
          t("ShippingNotificationsChart.title", { ns: "dashboard" }),
        ])
        !data.data && console.warn("shippingNotifications: 无 data 对象")
      })
  }

  static getProductRecommendationChart(focus?: boolean, param?: FilterDateParam) {
    const { productRecommendationDataStore } = stores

    if (!focus && productRecommendationDataStore.loadedDataTime > 0) return
    if (focus) productRecommendationDataStore.setLoadedDataTime(0)

    interface dataResponse extends BaseResponse {
      data?: ProductRecommendationData
    }

    dashboard.productRecommendation(param)
      .then(res => {
        const { data } = res

        if (data.code !== APICode.OK) {
          console.warn("productRecommendation: 获取数据失败", res)
          return
        }

        console.info("productRecommendation: 获取数据成功", data.data)
        productRecommendationDataStore.setLoadedDataTime(dayjs()
          .unix())

        data.data && productRecommendationDataStore.setData(data.data, [
          t("ProductRecommendationChart.legend", { ns: "dashboard" }),
        ])
        !data.data && console.warn("productRecommendation: 无 data 对象")
      })
  }

  static checkFirstSync() {
    // if (import.meta.env.VUE_APP_NOT_CHECK_FIRST_SYNC === 'true') return

    this.checkFirstSyncTimer && clearTimeout(this.checkFirstSyncTimer)

    this.checkFirstSyncTimer = setTimeout(() => {
      dashboard.checkFirstSync()
        .then(({ data: { code, data } }) => {
          if (code === APICode.OK) {
            stores.userInfoSore.setDataSingle("notice_firsync", data.notice_firsync)

            if (data.notice_firsync === 1) {
              return
            }
          }
          DashboardInit.checkFirstSync()
        })
    }, 2000)
  }
}
