import { Text, Tooltip } from "@shopify/polaris"
import { useEffect, useRef, useState } from "react"

import styles from "./index.module.scss"

type propsType = {
  content: string
}

const SubjectText = (props: propsType) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    const target = textRef.current

    // 如果滚动高度超出实际高度，说明已经超出
    if (target && target.scrollHeight > target.offsetHeight) {
      setIsOverflow(true)
    }
  }, [props.content])


  return (
    isOverflow
      // 超出时hover tip
      ? <Tooltip active={false} content={props.content}>
        <div className={styles.subjectText} style={{ cursor: "pointer" }} ref={textRef}>
          <Text as="span" variant="bodyMd" tone="subdued">{props.content}</Text>
        </div>
      </Tooltip>
      // 未超出时，默认
      : <div className={styles.subjectText} ref={textRef}>
        <Text as="span" variant="bodyMd" tone="subdued">{props.content}</Text>
      </div>
  )

}

export default SubjectText
