import { ShipmentStatus } from "@/models/IShipmentsLookupsData"

export const ShipmentsStatusData: ShipmentStatus[] = [
  {
    "name": "Pending",
    "type": 1,
    "value": 0,
  },
  {
    "name": "In Transit",
    "type": 2,
    "value": 0,
  },
  {
    "name": "Delivered",
    "type": 4,
    "value": 0,
  },
  {
    "name": "Out for Delivery",
    "type": 3,
    "value": 0,
  },
  {
    "name": "Info Received",
    "type": 8,
    "value": 0,
  },
  {
    "name": "Exception",
    "type": 7,
    "value": 0,
  },
  {
    "name": "Failed Attempt",
    "type": 6,
    "value": 0,
  },
  {
    "name": "Expired",
    "type": 5,
    "value": 0,
  },
]
