import { BetaFeature, BetaFeatures } from "@/models/IUserInfo"

let _betaFeatures: BetaFeatures = {
  features: [],
  report_url: "",
}

export const setBetaFeatures = (beta_features: BetaFeatures) => {
  _betaFeatures = beta_features
  // _betaFeatures.features = beta_features.features
  // _betaFeatures.report_url = beta_features.report_url
}

/**
 * 判断是否可以使用实验性功能
 *
 * @param feature
 */
export const canIUseBetaFeature = (feature: BetaFeature): boolean => {
  return _betaFeatures.features.includes(feature)
}

export const isInputElement = (element: any) => {
  return (
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element?.isContentEditable
  )
}
