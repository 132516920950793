import { findKey, get } from "lodash-es"

export namespace TrackStatus {
  export type IStatus = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

  export interface IStatusInfo {
    en_status: string;
    status: IStatus;
    color: string;
    progress: number;
  }

  export type IStatusList = Record<string, IStatusInfo>

  export const statusList: IStatusList = {
    delivered: {
      color: "#1BBE73",
      en_status: "Delivered",
      progress: 100,
      status: 4,
    },
    exception: {
      color: "#FD5749",
      en_status: "Exception",
      progress: 51,
      status: 7,
    },
    expired: {
      color: "#BABEC3",
      en_status: "Expired",
      progress: 51,
      status: 5,
    },
    info_received: {
      color: "#00A0AC",
      en_status: "Info Received",
      progress: 30,
      status: 8,
    },
    pending: {
      color: "#6D7175",
      en_status: "Pending",
      progress: 0,
      status: 1,
    },
    pickup: {
      color: "#FCAF30",
      en_status: "Out for Delivery",
      progress: 68,
      status: 3,
    },
    transit: {
      color: "#1E93EB",
      en_status: "In Transit",
      progress: 40,
      status: 2,
    },
    undelivered: {
      color: "#8109FF",
      en_status: "Failed Attempt",
      progress: 68,
      status: 6,
    },
  }

  export function getInfoByStatus(status: IStatus): IStatusInfo {
    return get(statusList, `${findKey(statusList, ["status", status])}`)
  }
}
