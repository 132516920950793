import {  Link, SkeletonBodyText } from "@shopify/polaris"
import { get } from "lodash-es"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { InfoCard } from "@/component"
import { NormalListItem } from "@/component/InfoCard/components"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"
import { openLink } from "@/utils/redirectShopify"

interface IProps extends WithTranslation<"orders"> {
  title: string[],
  titleImg: string[]
  product_urls: string[]
}

@observer
class ShipmentItems extends Component<IProps, any> {
  get computedImgList(): NormalListItem[] {
    const { title, titleImg, product_urls } = this.props
    const data: NormalListItem[] = []

    const emptySvg = "https://cdn.parcelpanel.com/front-end/common/imgs/image-not-found.svg"


    title.forEach((text, index) => {
      const productURL = get(product_urls, index)

      data.push({
        filed: <img width="40" src={get(titleImg, index, emptySvg) || emptySvg} alt="" />,
        value: <Link removeUnderline
          url={AppBridge.exist() ? undefined : productURL} target={"_blank"}
          onClick={AppBridge.exist() ? () => openLink(productURL) : undefined}
        >
          <span style={{ textAlign: "right" }}>{text}</span>
        </Link> || "",
      })
    })

    return data
  }

  public render() {
    const dataLoading = stores.ordersStore.orderInfoDataLoadedTime === 0
    const { t } = this.props

    const normalList = <InfoCard.NormalList data={this.computedImgList} />

    return (
      <InfoCard title={t("ShipmentItems")} emptyChildren={this.computedImgList.length === 0}>
        {dataLoading ? (
          // 匹配 .InfoCard__Body 样式
          <div style={{
            borderBottom: "0.0625rem solid var(--p-color-border-secondary, #EBEBEB)",
            padding: "0 1.25rem 1rem",
          }}
          >
            <SkeletonBodyText lines={3} />
          </div>
        ) : normalList}
      </InfoCard>
    )
  }
}

export default withTranslation("orders")(ShipmentItems)
