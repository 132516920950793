import { EChartsOption } from "echarts-for-react/lib/types"

// Shipping notifications
export const ProductRecommendationChartOption: EChartsOption = {
  calculable: false,
  grid: {
    bottom: 50,
    containLabel: true,
    left: 0,
    right: 0,
    x: 20,
    x2: 20,
    y: 35,
  },
  legend: {
    bottom: 0,
    data: ["Product recommendation clicks "],
    icon: "roundRect",
    right: 0,
    selectedMode: false,
    textStyle: {
      fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
      fontSize: 14,
    },
  },
  series: [
    {
      data: [],
      // 设定实心点的大小
      emphasis: {
        lineStyle: {
          width: 2,
        },
      },

      name: "Product recommendation clicks ",

      lineStyle: {
        width: 2,
      },

      symbol: "circle",
      itemStyle: {
        color: "#2EB9F5",
        fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
      },
      markPoint: {
        // 使图标变大一点
        /*     symbol: 'pin',
         symbolSize: 70,*/
        label: {
          color: "#fff", // markPoint 标记文字颜色设置为白色
        },
      },

      type: "line",

      symbolSize: 1,

      // itemStyle: {
      //   normal: {
      //     lineStyle: {
      //       width: 2,
      //     },
      //     color: '#2EB9F5',
      //     fontFamily: '-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif'
      //   }
      // }
    },
  ],
  title: {
    bottom: 20,
    left: 16,
    text: "",
    textStyle: {
      color: "#637381",
      fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: "normal",
    },
  },
  tooltip: {
    backgroundColor: "rgba(0, 0, 0,0.8)",
    borderColor: "gray",

    confine: true,

    borderWidth: "1",
    // 使得tooltip一直在容器中 而不会超出
    extraCssText: "z-index: 2",
    // 通过设置rgba调节背景颜色与透明度
    color: "black",


    position: function (point: number[], params: any, dom: any, rect: any, size: any) {
      // 其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
      const [x, y] = point
      // var viewWidth = size.viewSize[0];
      // var viewHeight = size.viewSize[1];
      const [boxWidth, boxHeight] = size.contentSize
      let posX = 0 // x坐标位置
      let posY = 0 // y坐标位置

      if (x - 36 < boxWidth) { // 左边放不开
        posX = 165
      } else { // 左边放的下
        posX = x - boxWidth
      }
      if (y < boxHeight) { // 上边放不开
        posY = 5
      } else { // 上边放得下
        posY = y - boxHeight
      }
      return [posX, posY]
    },

    textStyle: {

      color: "#fff",

      fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",

      // 'sans-serif',
      fontSize: 14,


      fontStyle: "normal",


      fontWeight: "normal",
      // 提示框浮层的文本样式。
      icon: "roundRect",
    },
    trigger: "axis",
  },
  xAxis: [
    {
      // X 轴数据
      // 改变x轴字体颜色和大小
      axisLabel: {
        align: "center",
        // showMaxLabel: true,
        textStyle: {
          color: "#637381",
          // 横坐标上label的倾斜度
          fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",

          fontSize: "12",

          interval: 30,
          // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
          rotate: 40,
        },
      },


      axisTick: {
        alignWithLabel: true,
      },


      boundaryGap: ["0", "0.1"],


      data: [],
      // 距离坐标原点是否有间隙
      type: "category",
    },
  ],
  yAxis: [
    {
      // 改变x轴字体颜色和大小
      axisLabel: {
        textStyle: {
          color: "#637381",
          fontFamily: "-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif",
          fontSize: "12",
        },
      },

      max: function (value: any) {
        if (value.max < 5) {
          return 5
        }
        return null
      },

      min: 0,

      minInterval: 1,

      splitNumber: 5,

      type: "value",
    },
  ],
}
