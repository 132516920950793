// TODO: chuwen 2022/6/27 09:44 传入状态自动计算进度、颜色

import hexRgb from "hex-rgb"
import React, { CSSProperties } from "react"

import { TrackStatus } from "@/utils/TrackStatus"

import styles from "./TrackStatusProgressBar.module.scss"
import IStatus = TrackStatus.IStatus

interface IProps {
  orderStatus: IStatus;// 订单状态
}

interface CustomCSS extends CSSProperties {
  "--track-status-progress": string;
  "--track-status-color": string;// rgb 颜色
}

function TrackStatusProgressBar({ orderStatus }: IProps) {
  // 计算百分比
  const computedProgress = (progress: number) => {
    // 每 1% 进度，占百分之几
    const perServing: number = 98 / 100

    return (perServing * progress) + 2
  }

  const infoByStatus = TrackStatus.getInfoByStatus(orderStatus)

  const TrackStatusProgressBarStyle: CustomCSS = {
    "--track-status-color": hexRgb(infoByStatus.color, { format: "array" })
      .slice(0, 3)
      .join(" "),
    "--track-status-progress": `${computedProgress(infoByStatus.progress)}%`,
  }

  return (
    // 主背景色
    <div className={styles.TrackStatusProgressBar} style={TrackStatusProgressBarStyle}>
      {/* 进度条颜色 */}
      <div className={styles.TrackStatusProgress}>
        <span className={styles.ProgressIndicator}></span>
      </div>
    </div>
  )
}

export default React.memo(TrackStatusProgressBar)
