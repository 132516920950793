import { IconColorEnum, languageType, socialType } from "@/pages/Settings/EmailEdit/type"

const defaultConfig = {
  // 当前选择的语言
  "locale": languageType.ENGLISH,
  "primary_locale": languageType.ENGLISH,
  "sender": {
    "email": "",
    "is_valid": false,
  },
  // 用户店铺发布的语言
  "shop_locales": [],

  "notification_enabled": {
    delivered: true,
    exception: true,
    failed_attempt: true,
    in_transit: true,
    info_received: true,
    pickup: true,
  },
  "html_content_default": "",

  // 品牌设置，除部分文本是根据不同语言设置的，其他全部是全局的
  "branding_config": {
    "sender": {
      "name": "Hr",
    },

    // 是否验证过了发送者
    "version": 1,

    // 是否开启自动切换语言
    "auto_multilingual": false,
    "show_pp_branding": false,
    "is_pp_branding_disabled": false,

    // 头部板块设置
    "header": {
      "logo_url": "",
      "logo_width": 30,
      // 单位：百分比
      "store_name": "Hr\r1",
      "go_to_url": "https://shine-api-test.myshopify.com/",
    },

    // 社交媒体板块设置
    "social": {
      "show": true,
      "icon_color": IconColorEnum.Grey,
      // icon 风格
      "social_media": [
        {
          "name": socialType.FACEBOOK,
          "value": "",
        },
        {
          "name": socialType.INSTAGRAM,
          "value": "",
        },
        {
          "name": socialType.TIKTOK,
          "value": "",
        },
        {
          "name": socialType.TWITTER,
          "value": "",
        },
        {
          "name": socialType.PINTEREST,
          "value": "",
        },
        {
          "name": socialType.SNAPCHAT,
          "value": "",
        },
        {
          "name": socialType.LINKEDIN,
          "value": "",
        },
        {
          "name": socialType.YOUTUBE,
          "value": "",
        },
        {
          "name": socialType.WHATSAPP,
          "value": "",
        },
      ],
    },

    // 底部板块设置
    "footer": {
      "address": "Chuwen, Batian street Shenzhn, 110011 Guangdong, China",
      "unsubscribe_text": "No longer want to receive these shipping notifications?",
      "btn_text": "Unsubscribe",
    },

    // 显示板块设置
    "display": {
      "align": 2,
      "corner_radius": 2,
    },

    // 颜色设置，名词缩写释意：
    // prod_rec: Product recommendation
    // du: Discount upsell
    "colors": {
      "email_bg": "#F3F3F6",
      "header_bg": "#FFFFFF",
      "header_store_name": "#121212",
      "body_bg": "#FFFFFF",
      "body_heading": "#121212",
      "body_desc": "#121212",
      "body_btn_bg": "#0066CC",
      "body_btn": "#FFFFFF",
      "prod_rec_bg": "#FFFFFF",
      "prod_rec_heading": "#121212",
      "prod_rec_desc": "#121212",
      "du_bg": "#E5E5EA",
      "du_heading": "#121212",
      "du_desc": "#121212",
      "du_btn_bg": "#0066CC",
      "du_btn": "#FFFFFF",
      "footer_bg": "#FFFFFF",
      "footer_text": "#454545",
      "footer_link": "#334FB4",
    },
  },

  // 邮件模版相关
  "content": {
    "in_transit": {
      "subject": "",

      // 邮件模版 HTML
      "content": "",

      // 小眼睛 - 控制功能开关
      // 名词缩写释意：
      // si: Shipment item
      // du: Discount upsell
      "status": {
        "text": true,
        "si": true,
        "pr": false,
        "du": false,
      },


      // Order tracking
      "ot": {
        "heading": "Your order is in transit 🚚",
        "desc": "",
        "btn_text": "Track your order",
      },

      "text": "",

      // si: Shipment item
      "si": {
        "heading": "Items in this shipment",
      },

      // pr: Product recommendation
      "pr": {
        "heading": "You may also like…",
        "logic": 0,
        "coll": 0,
      },

      // du: Discount upsell
      "du": {
        "heading": "10% OFF DISCOUNT",
        "desc": "",
        "discount_code": "",
        "btn_text": "Shop now",
        "go_to_url": "",
      },
    },
    "info_received": {
      "subject": "",
      "content": "",
      "status": {
        "text": true,
        "si": true,
        "pr": false,
        "du": false,
      },
      "ot": {
        "heading": "Your order is about to ship",
        "desc": "",
        "btn_text": "Track your order",
      },
      "text": "Tracking number: {{tracking_number}}\nCarrier: {{carrier_name}}",
      "si": {
        "heading": "Items in this shipment",
      },
      "pr": {
        "heading": "You may also like…",
        "logic": 0,
        "coll": 0,
      },
      "du": {
        "heading": "10% OFF DISCOUNT",
        "desc": "",
        "discount_code": "",
        "btn_text": "Shop now",
        "go_to_url": "",
      },
    },
    "delivered": {
      "subject": "",
      "content": "",
      "status": {
        "text": true,
        "si": true,
        "pr": false,
        "du": false,
      },
      "ot": {
        "heading": "Your order has been delivered 🎉",
        "desc": "",
        "btn_text": "Track your order",
      },
      "text": "",
      "si": {
        "heading": "Items in this shipment",
      },
      "pr": {
        "heading": "You may also like…",
        "logic": 0,
        "coll": 0,
      },
      "du": {
        "heading": "10% OFF DISCOUNT",
        "desc": "",
        "discount_code": "",
        "btn_text": "Shop now",
        "go_to_url": "",
      },
    },
    "exception": {
      "subject": "",
      "content": "",
      "status": {
        "text": true,
        "si": true,
        "pr": false,
        "du": false,
      },
      "ot": {
        "heading": "Your order has a delivery exception 🚨",
        "desc": "",
        "btn_text": "Track your order",
      },
      "text": "",
      "si": {
        "heading": "Items in this shipment",
      },
      "pr": {
        "heading": "You may also like…",
        "logic": 0,
        "coll": 0,
      },
      "du": {
        "heading": "10% OFF DISCOUNT",
        "desc": "",
        "discount_code": "",
        "btn_text": "Shop now",
        "go_to_url": "",
      },
    },
    "failed_attempt": {
      "subject": "",
      "content": "",
      "status": {
        "text": true,
        "si": true,
        "pr": false,
        "du": false,
      },
      "ot": {
        "heading": "",
        "desc": "",
        "btn_text": "",
      },
      "text": "",
      "si": {
        "heading": "",
      },
      "pr": {
        "heading": "",
        "logic": 0,
        "coll": 0,
      },
      "du": {
        "heading": "",
        "desc": "",
        "discount_code": "",
        "btn_text": "Shop now",
        "go_to_url": "",
      },
    },
    "pickup": {
      "subject": "",
      "content": "",
      "status": {
        "text": true,
        "si": true,
        "pr": false,
        "du": false,
      },
      "ot": {
        "heading": "",
        "desc": "",
        "btn_text": "",
      },
      "text": "",
      "si": {
        "heading": "Items in this shipment",
      },
      "pr": {
        "heading": "You may also like…",
        "logic": 0,
        "coll": 0,
      },
      "du": {
        "heading": "10% OFF DISCOUNT",
        "desc": "",
        "discount_code": "",
        "btn_text": "Shop now",
        "go_to_url": "",
      },
    },
  },
}


const defaultVariables = {
  // 当前选择的语言
  "vars": {},
  "group_map": [],
}


export { defaultConfig, defaultVariables }
