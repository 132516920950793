import { cloneDeep } from "lodash-es"
import { IDisposer } from "mobx-utils/lib/utils"

import { CheckoutSetting_Checkout, CheckoutSetting_Script, ToggleConveyConfig } from "@/api/settings/type/emailConfig"
import { IUserGuide } from "@/models/ICommonStore"
import stores from "@/stores"
import baseRequest from "@/utils/baseRequest"
import request from "@/utils/request"

export const disposer: IDisposer | null = null
let defaultOptions: any
let defaultScriptOptions: any
let defaultCheckoutOptions: any

export default {

  // ///////分割线////////
  /** 获取设置信息 */
  getConfig() {
    return request.get("setting/config/get")
  },

  /** 获取所有运输商列表<默认是读取缓存的列表> */
  // getCouriersList(isCache = true) {
  //   return request.get("courier-list/get", { params: { cache: isCache ? null : 0 } })
  // },

  /**
   * 获取所有运输商列表<新 2024.06.05>
   * */
  getCouriersList() {
    return baseRequest.get("v2/settings/couriers")
  },


  /** 第4项的开关 */
  // setcustomerAccountPageLink(param) {
  //   return request.post('setting/config/set-customer-accountpage', param)
  // },

  /**
   * 获取发送给用户自己的邮件设置信息
   * @deprecated v2023.04
   */
  getSelfConfig() {
    return request.get("setting/email/get-self-email")
  },

  /**
   * 获取发送给用户自己的邮件设置信息
   */
  getNotificationSelf() {
    return baseRequest.get("v2/settings/notification-self")
  },


  /** 设置“设置信息” */
  // setConfig(param) {
  //   return request.post('setting/config/set', param)
  // },
  /** 保存设置 */
  saveCheckoutText(param: ToggleConveyConfig) {
    return request.post("setting/config/set-checkout-text", param)
  },


  /** 第1项的开关 */
  setChangeCheckout(param: {
    enable: boolean
    is_trigger_native_notification?: boolean
  }) {
    return request.post("setting/config/set-change-checkout", param)
  },


  /** 用户邮件发送开关保存 */
  saveUserEmailSet(param: {
    open: boolean
    type: string
  }) {
    return request.post(`setting/email/save-open?_type=${param.type}`, param)
  },

  // /** 设置启用"运输商" */
  // setEnable1(param: {
  //   enabledList: string[]
  // }) {
  //   return request.post("courier-list/set-enable", param)
  // },

  /**
   * 设置启用"运输商"
   * 新 2024.6.6
   * */
  setEnable(couriers: string[]) {
    return baseRequest.post("/v2/settings/couriers", { couriers })
  },


  /** 设置排序 */
  setSort(param: {
    courier: string[]
    type: string
  }) {
    return request.post("courier-list/set-sort", param)
  },

  /**
   * Enable排序
   * @param couriers
   */
  sortEnable(couriers: string[]) {
    return baseRequest.post("v2/settings/couriers/sort", {
      couriers: couriers,
    })
  },

  /** 设置禁用"运输商" */
  setDisable(couriers: string[]) {
    return baseRequest.delete("v2/settings/couriers", { data: { couriers } })
  },


  /** 上传 邮件 logo */
  /** 上传图片方法，采用二进制流上传文件 */
  newUploadImg(form: FormData) {
    return request.post("setting/email/new-upload-logo", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },


  /**
   * 销毁监听器
   */
  destroyListener(focus: boolean = false) {
    // disposer && disposer()
    focus && stores.commonStore.setContextualSaveBar("show", false)
    return true
  },


  /** 第3项的开关 */
  setCheckoutLink(param: {
    enable: boolean
  }) {
    return request.post("setting/config/set-checkout-link", param)
  },


  setDropShippingMode() {
    return request.post("dashboard/set-dropshipping-mode-enable", {
      enable: true,
    })
  },
  setDropShippingModeDis() {
    return request.post("dashboard/disable-dropshipping-mode-enable", {
      enable: true,
    })
  },


  /** 第2项的开关 */
  setReplaceNumber(param: {
    enable: boolean
  }) {
    return request.post("setting/config/set-replace-number", param)
  },


  /**
   * 获取初始值配置
   */
  getInitOptions() {
    return defaultOptions
  },

  /**
   * 获取初始值配置
   */
  getScriptInitOptions() {
    return defaultScriptOptions
  },
  /**
   * 获取初始值配置
   */
  getCheckoutInitOptions() {
    return defaultCheckoutOptions
  },


  /** 用户发送给自己邮件发送开关保存 */
  saveUserSelfEmailSet(param: {
    sendToEmailComment: {
      user_email: string
    }
  }) {
    return request.post("setting/email/save-self-email", param)
  },


  /**
   * 注册监听器
   */
  registerListener() {
    return true
  },

  /** 发送邮件测试 */
  sendTestEmail(param: {
    emailMode: {
      subject: string
      content: string
    }
    type: string
    emailTo: string
  }) {
    return request.post("setting/email/send-email", param)
  },


  /**
   * 设置初始值
   * @param options
   */
  setInitOptions(options: any) {
    defaultOptions = cloneDeep(options)

    return true
  },

  /**
   * 设置初始值
   * @param options
   */
  setScriptInitOptions(options: any) {
    defaultScriptOptions = cloneDeep(options)

    return true
  },
  /**
   * 设置初始值
   * @param options
   */
  setCheckoutInitOptions(options: any) {
    defaultCheckoutOptions = cloneDeep(options)

    return true
  },

  senderSendVerificationCode(data: { email: string }) {
    return baseRequest.post("v2/settings/sender-email/send-verification-code", data)
  },

  senderVerifyCode(data: { email: string; code: string }) {
    return baseRequest.post("v2/settings/sender-email/verify", data)
  },

  recipientSendVerificationCode(data: { email: string }) {
    return baseRequest.post("v2/settings/recipient-email/send-verification-code", data)
  },

  recipientVerifyCode(data: { email: string; code: string }) {
    return baseRequest.post("v2/settings/recipient-email/verify", data)
  },

  userGuideSetEmailTemplate(data: {
    branding_config: Pick<IUserGuide, "header" | "colors">
  }) {
    return baseRequest.patch("v2/settings/user-guide/email-templates", data)
  },


  /** Banner */
  getBanner(id: number) {
    return baseRequest.get("v1/common/banner/" + id)
  },
  /** Banner */
  patchBanner(id: number, display: boolean = false) {
    return baseRequest.patch("v1/common/banner/" + id, { display })
  },

  /** 获取Script版本的tracking widget配置 */
  getTrackingWidget() {
    return baseRequest.get<{ tracking_widget_v1: CheckoutSetting_Script}>("v2/settings/tracking_widget_v1")
  },

  /** 设置Script版本的tracking widget配置 */
  setTrackingWidget(data: Partial<CheckoutSetting_Script>) {
    return baseRequest.put<{ tracking_widget_v1: CheckoutSetting_Script}>("v2/settings/tracking_widget_v1", { tracking_widget_v1: data })
  },

  /** 获取Checkout版本的tracking widget配置 */
  getTrackingCheckoutWidget() {
    return baseRequest.get<{ tracking_widget_v2: CheckoutSetting_Checkout}>("v2/settings/tracking_widget_v2")
  },

  /** 设置Checkout版本的tracking widget配置 */
  setTrackingCheckoutWidget(data: Partial<CheckoutSetting_Checkout>) {
    return baseRequest.put<{ tracking_widget_v2: CheckoutSetting_Checkout}>("v2/settings/tracking_widget_v2", { tracking_widget_v2: data })
  },


}
