const SVGDefault = (props: any) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#1E93EB" />
    <mask id="mask0_12_79" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="4" y="5" width="12" height="10">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.20001 5.8H15.825V14.2545H4.20001V5.8Z" fill="white" />
    </mask>
    <g mask="url(#mask0_12_79)">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12.6545 10.0273V8.70627H13.9755L15.0139 10.0273H12.6545ZM12.3903 12.6693C12.3903 12.2307 12.7444 11.8767 13.183 11.8767C13.6215 11.8767 13.9756 12.2307 13.9756 12.6693C13.9756 13.1079 13.6215 13.4619 13.183 13.4619C12.7444 13.4619 12.3903 13.1079 12.3903 12.6693ZM6.04945 12.6693C6.04945 12.2307 6.40348 11.8767 6.84207 11.8767C7.28065 11.8767 7.63468 12.2307 7.63468 12.6693C7.63468 13.1079 7.28063 13.4619 6.84207 13.4619C6.40348 13.4619 6.04945 13.1079 6.04945 12.6693ZM14.2398 7.91363H12.6545V7.91363C12.6545 6.7463 11.7082 5.8 10.5409 5.8H5.25684C4.67293 5.8 4.20001 6.27292 4.20001 6.85683V12.6693H5.25684C5.25684 13.5438 5.96754 14.2545 6.84206 14.2545C7.71659 14.2545 8.42729 13.5438 8.42729 12.6693H11.5977C11.5977 13.5438 12.3084 14.2545 13.183 14.2545C14.0575 14.2545 14.7682 13.5438 14.7682 12.6693H15.825V10.0273L14.2398 7.91363Z"
        fill="white"
      />
    </g>
  </svg>
}

export default SVGDefault
