import {
  Button,
  Checkbox,
  FormLayout,
  LegacyCard,
  Link,
  List,
  Modal,
  Text,
  TextContainer,
  TextField,
} from "@shopify/polaris"
import { observer } from "mobx-react"
import React, { Component, useCallback, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"

import getHelpApi from "@/api/get-help"
import { Container, NormalLink, ReviewGuide } from "@/component"
import stores from "@/stores"
import { LanguageUtils } from "@/utils/languages"
import { IListenerNameEnum } from "@/utils/languages.d"
import toast from "@/utils/toast"

import CannyWrapper from "./components/CannyWrapper"
import HelpCenterCard from "./components/HelpCenterCard"
import { IFAQItem, IGetHelpProps, IGetHelpState } from "./types"

export function GetHelp() {
  const { t } = useTranslation("get_help")
  const intercom = useIntercom()

  return (
    <GetHelpPage t={t} intercom={intercom} />
  )
}

@observer
export class GetHelpPage extends Component<IGetHelpProps, IGetHelpState> {

  state: Readonly<IGetHelpState> = {
    FAQItems: [],
  }

  componentDidMount() {
    this.initItem()
    LanguageUtils.addListener(IListenerNameEnum.GetHelpListener, () => this.initItem())
  }

  public componentWillUnmount() {
    LanguageUtils.removeListener(IListenerNameEnum.GetHelpListener)
  }

  public initItem() {
    this.setState({
      FAQItems: this.getFAQItems(),
    })
  }

  getFAQItems(): IFAQItem[] {
    const { t } = this.props

    return t("FAQItems1" as any, { returnObjects: true })
  }


  render() {
    return (
      <Container id="Page-GetHelp">

        {/* 新评论引导 */}
        {stores.userInfoSore.showGetHelpReviewGuide &&
        <ReviewGuide doIconHidden={false} showDismiss showButton={false} />}

        {/* 评论引导 */}
        {/* {stores.userInfoSore.data.show_review_guide && <Evaluation hiddenDismiss/>}*/}

        {/* 间距，当有在此之上有元素时才会显示 */}
        <div className={"BannerSpacing"} />

        <HelpCenterCard />

        <LegacyCard>
          <LegacyCard.Section>
            <CannyWrapper />
          </LegacyCard.Section>
        </LegacyCard>

        {/* <ExpertInstallServiceCard />*/}

        {/* <FAQCard items={this.state.FAQItems} />*/}
      </Container>
    )
  }
}


function SendYourRequestModal({
  open,
  onClose,
}: { open: boolean, onClose(): void }) {
  const [sending, setSending] = useState(false)
  const [isDropshipper, setIsDropshipper] = useState(false)
  const [comment, setComment] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const { t } = useTranslation(["common", "get_help", "email_edit"])

  const handleSendClick = useCallback(() => {
    if (!checkEmail(email)) {
      if (email) {
        setEmailError(t("email_edit:InvalidEmail"))
      }
      return
    } else {
      setEmailError("")
    }

    setSending(true)
    getHelpApi.sendRequest({
      addComment: comment,
      contactEmail: email,
      pickdropshipper: isDropshipper,
    })
      .then(({ data: { msg } }) => {
        toast(msg, 2e3)
      })
      .finally(() => {
        onClose()
        setSending(false)
      })
  }, [isDropshipper, comment, email, open])

  const handleSetEmailTextFieldChange = (value: string) => {
    if (value === "" || checkEmail(value)) {
      setEmailError("")
    }
    setEmail(value)
  }

  const checkEmailValue = () => {
    setEmail(stores.userInfoSore.data.user_email)
    setEmailError("")
  }

  useEffect(() => {
    setEmail(stores.userInfoSore.data.user_email)
  }, [stores.userInfoSore.data.user_email])

  if (stores.userInfoSore.data.user_email && emailError && !open) {
    checkEmailValue()
  }

  return (
    <Modal
      instant
      open={open}
      onClose={onClose}
      title={t("get_help:SendYourRequestModal.title")}
      primaryAction={{
        content: t("common:Send"),
        loading: sending,
        onAction: handleSendClick,
        disabled: email === "",
      }}
      secondaryActions={[{ content: t("common:Cancel"), onAction: onClose }]}
      sectioned
    >
      <TextContainer>
        <div>
          <Text as="span" fontWeight="semibold">{t("get_help:SendYourRequestModal.content.title")}</Text>

          <p>
            {t("get_help:SendYourRequestModal.content.p1")}
            <Link url="https://docs.parcelpanel.com/article/51" external>
              {t("get_help:SendYourRequestModal.content.p1_end")}
            </Link>
          </p>
        </div>

        <div>
          <Checkbox
            label={t("get_help:SendYourRequestModal.content.IAmDropshipper")}
            checked={isDropshipper}
            onChange={newChecked => setIsDropshipper(newChecked)}
          />

          <FormLayout>
            <TextField
              autoComplete="off"
              value={comment}
              label={t("get_help:SendYourRequestModal.content.AdditionalComments_optional")}
              multiline={6}
              onChange={value => setComment(value)}
            />

            <TextField
              type="email"
              autoComplete="off"
              label={t("get_help:SendYourRequestModal.content.YourContactEmail")}
              placeholder="parcelpanel100@gmail.com"
              value={email}
              onChange={value => handleSetEmailTextFieldChange(value)}
              onBlur={() => {
                if (email && !checkEmail(email)) {
                  setEmailError(t("email_edit:InvalidEmail"))
                }
              }}
              error={emailError}
            />
          </FormLayout>
        </div>
      </TextContainer>
    </Modal>
  )
}

function ExpertInstallServiceCard() {
  const { t } = useTranslation("get_help")
  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  return <>
    <SendYourRequestModal open={open} onClose={toggleOpen} />

    <LegacyCard title={t("ExpertInstallServiceCard.title")} sectioned>
      <TextContainer>
        <TextContainer spacing="tight">
          <p>{t("ExpertInstallServiceCard.content.p1")}</p>

          <p><Text as="span" fontWeight="semibold">{t("ExpertInstallServiceCard.content.p2")}</Text></p>

          <List>
            <List.Item>
              <Trans
                ns="get_help"
                i18nKey="ExpertInstallServiceCard.content.list.5"
                components={{
                  link1: (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <NormalLink
                      link="https://docs.parcelpanel.com/article/1"
                      external
                    />
                  ),
                }}
              />
            </List.Item>
            <List.Item>
              <NormalLink
                link="https://docs.parcelpanel.com/article/40"
                external
              >{t("ExpertInstallServiceCard.content.list.3")}</NormalLink>
            </List.Item>
            <List.Item>
              {t("ExpertInstallServiceCard.content.list.6")}
            </List.Item>
          </List>
        </TextContainer>

        <p>{t("ExpertInstallServiceCard.content.p4")}</p>

        <Button onClick={toggleOpen} variant="primary">{t("ExpertInstallServiceCard.SendRequest")}</Button>
      </TextContainer>
    </LegacyCard>
  </>
}

function FAQCard({
  items,
}: { items: IFAQItem[] }) {
  const { t } = useTranslation("get_help")

  return (
    <LegacyCard title={t("FAQCard.title")} sectioned>
      <TextContainer>
        <List>
          {
            items.map(({ title, url }) => (
              <List.Item key={title}>
                <NormalLink link={url} external>{title}</NormalLink>
              </List.Item>
            ))
          }
        </List>

        {/* <p><Trans ns="get_help" i18nKey="FAQCard.p">*/}
        {/*    Click to find a solution for your problem with <NormalLink link="https://docs.parcelpanel.com/" external>ParcelPanel Help Docs.</NormalLink>*/}
        {/* </Trans></p>*/}
      </TextContainer>
    </LegacyCard>
  )
}

/** 邮件地址检测 */
function checkEmail(v: string) {
  const partner = /^.+@.+\..+/

  return v && partner.test(v)
}
