import { BlockStack, Icon, Modal, OptionList, Scrollable, SkeletonBodyText, Text, TextField } from "@shopify/polaris"
import { SearchIcon } from "@shopify/polaris-icons"
import { debounce } from "lodash-es"
import React, { useCallback, useEffect, useState } from "react"

import orders from "@/api/orders"
import { fuzzySearch } from "@/utils/StringUtils"
import toast from "@/utils/toast"

interface UpdateShipmentCourierProps {
  open: boolean
  numberIDs: string[]
  i18n: {
    title: string
    confirm: string
    cancel: string
    success: string
    error: string
  }

  onClose(): void
}

interface ISearchCarrier {
  onSearchTextChange(newText: string): void
}

function SearchCarrier({ onSearchTextChange }: ISearchCarrier) {
  const [value, setValue] = useState("")
  const handleSearchTextChange = debounce(function (newValue: string) {
    onSearchTextChange(newValue)
  }, 200)

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue)
      handleSearchTextChange(newValue)
    },
    [],
  )

  return (
    <TextField
      onChange={handleChange}
      label="Filter carrier"
      labelHidden
      value={value}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search carrier"
      autoComplete="off"
    />
  )
}


interface CarrierListProps {
  search?: string
  couriers: {
    value: string
    label: string
  }[]

  onSelected(express: string): void
}

function CarrierList({ search, couriers, onSelected }: CarrierListProps) {
  const [selected, setSelected] = useState<string[]>([])

  const filterCouriers = couriers.filter((courier) => {
    if (!search || search === "") {
      return true
    }

    return fuzzySearch(search, `${courier.value}\n${courier.label}`)
  })

  return (
    <OptionList
      options={filterCouriers}
      onChange={(express) => {
        setSelected(express)
        onSelected(express[0])
      }}
      selected={selected}
    />
  )
}

interface IState {
  couriers?: Courier[]
}

interface Courier {
  express: string;
  name: string;
  title: string;
  img: string;
  track_url: string;
}

const state: IState = {
  couriers: undefined,
}

export default function UpdateShipmentCourier({
  open,
  numberIDs,
  i18n,
  onClose,
}: UpdateShipmentCourierProps) {
  const [searchText, setSearchText] = useState("")
  const [courier, setCourier] = useState<string>("")
  const [saving, setSaving] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [couriers, setCouriers] = useState<{
    value: string
    label: string
  }[]>([])

  useEffect(() => {
    if (!open) {
      setDataLoading(true)
      setCourier("")
    } else if (open) {
      if (couriers.length > 0) {
        setTimeout(() => {
          setDataLoading(false)
        }, 100)
      } else {
        orders.getCourier()
          .then(({ status, data: { couriers } }) => {
            state.couriers = couriers

            setCouriers(couriers.map((courier: any) => {
              return {
                value: courier.express,
                label: courier.name,
              }
            }))
            if (status === 200) {
              setDataLoading(false)
            }
          })
      }
    }
  }, [open])

  const handleSelectedCourier = useCallback((_courier: string) => {
    setCourier(_courier)
  }, [])

  const filterCourier = state.couriers?.filter(item => item.express === courier)[0]

  return (
    <Modal
      open={open}
      title={i18n.title}
      onClose={onClose}
      primaryAction={{
        content: i18n.confirm,
        loading: !dataLoading && saving,
        onAction: () => {
          console.log("确认操作", courier, numberIDs)
          setSaving(true)

          orders.updateShipmentCourier({
            nids: numberIDs,
            courier_code: courier,
          })
            .then(({ status, data }) => {
              if (status !== 200) {
                toast(i18n.error)
                return
              }

              console.log("操作成功", numberIDs, data)
              toast(i18n.success)
              onClose()
            })
            .finally(() => {
              setSaving(false)
            })
        },
      }}
      secondaryActions={[
        {
          content: i18n.cancel,
          onAction: () => {
            onClose()
            setDataLoading(true)
          },
        },
      ]}
      sectioned
    >
      {dataLoading ? (
        <Modal.Section>
          <SkeletonBodyText lines={20} />
        </Modal.Section>
      ) : (
        <Modal.Section>
          <BlockStack gap={"200"}>
            <SearchCarrier onSearchTextChange={setSearchText} />

            <p>Chosen: <Text
              as={courier ? "strong" : "span"}
            >{filterCourier?.name ? `${filterCourier?.name} (${courier})` : "(none)"}</Text>
            </p>

            <Scrollable open style={{ height: "320px" }}>
              <CarrierList
                search={searchText}
                couriers={couriers}
                onSelected={handleSelectedCourier}
              />
            </Scrollable>
          </BlockStack>
        </Modal.Section>
      )}
    </Modal>
  )
}
