import {
  CurrentDiscountCodeResponse,
  CurrentSubscriptionAndPlan,
  CurrentSubscriptionResponse,
  PlanListRequest,
  QuotaReminder,
  SubscriptionResponse,
  SubscriptionSpendingLimitResponse,
} from "@/api/account/type.d"
import baseRequest from "@/utils/baseRequest"
import request from "@/utils/request"

export default {
  /**
   * 当前套餐信息获取
   */
  getCurrentSubscription() {
    return baseRequest.get<CurrentSubscriptionResponse>("/v3/billing/current_subscription")
  },

  /**
   * 套餐列表获取
   */
  getPlanList() {
    return baseRequest.get<PlanListRequest>("/v3/billing/plans")
  },

  /**
   * 当前套餐信息+套餐列表获取 聚合接口
   */
  getPlanListAndCurrent() {
    return baseRequest.get<CurrentSubscriptionAndPlan>("/v3/billing/index")
  },

  /**
   * 调整使用量上限
   */
  patchSpendingLimit(param: {
    amount: string
  }) {
    return baseRequest.patch("/v3/billing/subscription/spending_limit", param)
  },

  /**
   * 保存额度不足提醒设置
   */
  saveQuotaReminder(param: QuotaReminder) {
    return request.post("account/save-quota-reminder", param)
  },

  /**
   * 选择套餐
   */
  selectPlanNew(param: {
    price: number
    count: number
    key: string
  }) {
    return request.post("account/select-plan-new", param)
  },


  /**
   * 创建订阅
   */
  createSubscription(param: {
    pricing_id: number
  }) {
    return baseRequest.post<SubscriptionResponse>("/v3/billing/subscription?pricing_id=" + param.pricing_id)
  },


  /**
   * 撤销降级套餐
   */
  cancelDowngrade() {
    return baseRequest.post<{
      subscription_resume: {
        pricing_id: number
      }
    }>("/v3/billing/subscription/resume")
  },

  /**
   * 撤销降级套餐
   */
  changeSpendingLimit(amount: string) {
    return baseRequest.patch<SubscriptionSpendingLimitResponse>("/v3/billing/subscription/spending_limit", { amount })
  },

  /**
   * 申请自定义套餐额度
   */
  requestCustomPlan(data: {
    order_volume_min: string
    order_volume_max: string
    email: string
    content: string
  }) {
    return baseRequest.post("/v2/billing/request_custom_plan", data)
  },

  /**
   * 获取折扣码
   */
  getDiscountCode() {
    return baseRequest.get<CurrentDiscountCodeResponse>("/v3/billing/discount_code")
  },

  /**
   * 应用折扣码
   */
  applyDiscountCode(discount_code: string) {

    return baseRequest.post<{
      message: string;
    }>("/v3/billing/discount_code", { discount_code }, {
      // @ts-ignore
      useDefaultExceptionHandler: false,
    })
  },

  /**
   * 删除绑定的折扣码
   */
  deleteDiscountCode() {
    return baseRequest.delete("/v3/billing/discount_code")
  },
}
