import { Grid, LegacyCard } from "@shopify/polaris"
import React from "react"

import style from "./index.module.scss"
import RecommendedAPPItemNew from "./RecommendedAPPItemNew"
import { RecommendedAPPProp } from "./types"

export function RecommendedAPPNew(props: RecommendedAPPProp) {
  const cN = props.className || [style.RecommendedAPP]

  if (!props.lists) {
    console.warn("组件[Dashboard/RecommendedAPP]：请传入 lists 参数")
    return (<Grid />)
  }

  return (
    <Grid>
      {props.lists.map((item, index) => {
        return (
          <Grid.Cell key={index} columnSpan={{ xs: 6, sm: 6, md: 6, lg: 4, xl: 4 }}>
            <LegacyCard sectioned>
              <RecommendedAPPItemNew
                key={index}
                name={item.name} summary={item.summary}
                logo={item.logo} link={item.link}
              />
            </LegacyCard>
          </Grid.Cell>
        )
      })}
    </Grid>
  )
}
