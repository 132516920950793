import * as React from "react"

const SvgShareEmail = (props: any) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0Zm6.72 7.296-6.7 6.991-6.763-6.991H18.72ZM4.8 8.313l3.743 3.814L4.8 15.87V8.313Zm.47 8.583 3.982-4.144 2.753 2.805 2.713-2.805 4.022 4.144H5.27Zm13.93-1.027-3.845-3.752 3.845-3.86v7.612Z"
      fill="#004338"
    />
  </svg>
)

export default SvgShareEmail
