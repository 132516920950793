import React from "react"

import stores from "@/stores"

import styles from "./DiyRate.module.scss"

type IPerson = {
  starNum: number
}
const count: IPerson = {
  starNum: 5,
}

export interface IProps {
  onSelected(rating: number): void;
}

// 星星
export default function (props: IProps) {

  const contList = () => {
    const cont = []

    for (let i = count.starNum; i >= 1; i--) {
      cont.push(i)
    }
    return cont
  }

  const rate = (e: any) => {
    const $radio = e.target || e.path[0], val = $radio.value || -1

    $radio.checked = false// 每当用户点击后都取消星星选中
    if (val === -1) return
    stores.Post.setShowCommentModal(true)
    stores.Post.setRate(parseInt(val))
    props.onSelected(parseInt(val))
    if (parseInt(val) === 5) {
      window.open("https://apps.shopify.com/parcelpanel?reveal_new_review=true", "_blank")
    }
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.rateContent}>
          {
            contList()
              .map(o => {
                return (
                  <input key={o} onClick={rate} value={o} name="rate" type="radio" />
                )
              })
          }
        </div>
      </div>
    </div>
  )
}
