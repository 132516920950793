import { useEffect, useState } from "react"

/**
 * 判断错误中有hash值
 * @param errorMessage
 * // 测试示例
 * const errorMessage = 'TypeError: Failed to fetch dynamically imported module: https://shopify-app-test.parcelpanel.com/assets/Settings.js';
 * const hasHash = hasJSFileHash(errorMessage);
 * console.log(hasHash); // 输出 true
 */

function hasJSFileHash(errorMessage: string) {
  // 使用正则表达式匹配 JavaScript 文件 URL
  const jsFileRegex = /https?:\/\/\S+\/\S+\.(\w+)\.js/
  const match = errorMessage.match(jsFileRegex)

  if (match) {
    return !!match[1]
  }

  return false
}

/**
 * 检测如果更新后刷新页面
 */
function useErrorHandler() {
  const [error, setError] = useState(null)

  useEffect(() => {
    // 设置全局错误处理程序
    // 保存原始的 console.error 方法
    const originalConsoleError = console.error

    // 重写 console.error 方法
    console.error = function(message, ...args) {
      // 打印原始错误信息
      // @ts-ignore
      originalConsoleError.apply(console, ...args)

      if (typeof message === "object"){
        message = message.toString()
      }
      // 检查是否是特定的错误消息，这个代表资源可能因为项目发布覆盖了，原来的文件找不到, 则刷新页面
      if (message && message.includes("TypeError: Failed to fetch dynamically imported module") && hasJSFileHash(message)) {

        // 从 sessionStorage 恢复查询参数, 前提先设置值 sessionStorage.setItem("iframeQueryParams", 1)
        const savedParams = sessionStorage.getItem("iframeQueryParams")
        const iframeUrl = new URL(window.location.href)

        if (savedParams) {
          iframeUrl.search = savedParams
          window.location.replace(iframeUrl.toString())
        }
      }
    }
    // 清理函数，在组件卸载时恢复原始的 console.error 方法
    return () => {
      console.error = originalConsoleError
    }
  }, [])

  // 重置错误状态
  const resetError = () => {
    setError(null)
  }

  return [error, resetError]
}

export default useErrorHandler
