/**
 * 比较两个数组,返回多出的元素和缺失的元素
 *
 * 参数:
 * arr1 (Array): 原数组
 * arr2 (Array): 新数组
 *
 * 返回:
 * Array: 第一个元素是多出的元素数组,第二个元素是缺失的元素数组
 */
export function compareArrays(arr1:string[], arr2:string[]) {
  const extra = arr2.filter(item => !arr1.includes(item))
  const missing = arr1.filter(item => !arr2.includes(item))

  return [extra, missing]
}
