import { Redirect } from "@shopify/app-bridge/actions"
import { AdminSectionPayload, Section } from "@shopify/app-bridge/actions/Navigation/Redirect"
import { isShopifyEmbedded } from "@shopify/app-bridge-utils"

import stores from "@/stores"
import AppBridge from "@/utils/appBridge"
import toast from "@/utils/toast"

const redirectAdminSection = (payload: Section | AdminSectionPayload) => {
  if (!window.isFromAdmin && isShopifyEmbedded()) {
    const redirect = Redirect.create(AppBridge.app)

    redirect.dispatch(Redirect.Action.ADMIN_SECTION, payload)
  } else {
    toast("Cannot be redirected in a non-Shopify environment", 2000, true)
  }
}

/**
 * 打开新链接
 * @param url
 */
export const openLink = (url: string, notShopifyUrl?: string) => {
  if (!window.isFromAdmin && isShopifyEmbedded()) {
    AppBridge.redirect && AppBridge.redirect.dispatch(Redirect.Action.REMOTE, {
      url,
      newContext: true,
    })
  } else {
    window.open(notShopifyUrl || url)
  }
}

/**
 * 进入Checkout edit order status page
 * @param id
 */
export const navigateToCheckoutStatusPage = (id: string | number) => {
  const { userInfoSore } = stores
  const shopName = userInfoSore.data.store_name.replace(".myshopify.com", "")

  openLink(`https://admin.shopify.com/settings/checkout/editor/profiles/${id}?page=customer-account-order-status`,
    `https://admin.shopify.com/store/${shopName}/settings/checkout/editor/profiles/${id}?page=customer-account-order-status&context=extensibility`)
  return true
}
/**
 * 通过订单id 重定向到Shopify Order详情页面
 * @param id
 */
export const navigateToOrderById = (id: string) => {
  const { userInfoSore } = stores
  const shopName = userInfoSore.data.store_name.replace(".myshopify.com", "")

  openLink(`https://admin.shopify.com/orders/${id}`, `https://admin.shopify.com/store/${shopName}/orders/${id}`)
  return true
}
/**
 * 通过订单id 重定向到Shopify Customer详情页面
 * @param id
 */
export const navigateToCustomerById = (id: string) => {
  const { userInfoSore } = stores
  const shopName = userInfoSore.data.store_name.replace(".myshopify.com", "")

  openLink(`https://admin.shopify.com/customers/${id}`, `https://admin.shopify.com/store/${shopName}/customers/${id}`)
  return true
}

/**
 * 通过订单id 重定向到Shopify 指定Id Product页面
 * @param id
 */
export const navigateToProductById = (id: string) => {
  redirectAdminSection({
    name: Redirect.ResourceType.Product,
    resource: { id },
    newContext: true,
  })
  return true
}
