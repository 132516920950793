import { BlockStack, ChoiceListProps, Icon,  TextField } from "@shopify/polaris"
import { SearchIcon } from "@shopify/polaris-icons"
import React from "react"

import ChoiceRightHelpText from "@/component/ChoiceRightHelpText/ChoiceRightHelpText"
import { fuzzySearch } from "@/utils/StringUtils"

interface IProps {
  autoComplete: string
  placeholder: string
  choices: ChoiceListProps["choices"]
  selected: string[],
  allowMultiple?: boolean
  keyword: string

  onChange?(selected: string[], name: string): void

  onKeywordChange?(value: string): void
}

// todo 会闪屏, 筛选结果应该存到父组件上? 为了保持关闭 popover 前的数据状态
export function ChoiceWithSearch({
  autoComplete,
  placeholder,
  choices,
  selected,
  allowMultiple,
  onChange,
  keyword,
  onKeywordChange,
}: IProps) {

  // const [choicesShow, setChoicesShow] = useState(choices)

  function handleKeywordChange(value: string, id: string) {
    onKeywordChange?.(value)
  }

  // const searchItems = useCallback((items, queryValue) => {
  //   setChoicesShow(selectMatchItem(items, queryValue))
  // }, [])
  //
  // const debounceQuery = useMemo(() => debounce(searchItems, 200), [searchItems])
  //
  // useEffect(() => {
  //   // 加载组件时, 如果存在搜索关键词, 则立即搜索
  //   searchItems(choices, keyword)
  // }, [])
  //
  // useEffect(() => {
  //   debounceQuery(choices, keyword)
  // }, [debounceQuery, choices, keyword])

  return (
    <BlockStack gap="100">
      <TextField
        label={null}
        autoComplete={autoComplete}
        prefix={<Icon source={SearchIcon} />}
        placeholder={placeholder}
        value={keyword}
        onChange={handleKeywordChange}
      />
      <ChoiceRightHelpText
        title={null}
        // choices={choicesShow}
        choices={choices}
        // choices={choicesShow.slice(0, 150)}
        selected={selected}
        onChange={onChange}
        allowMultiple={allowMultiple}
      />
    </BlockStack>
  )
}

/** 模糊搜索对象 */
function selectMatchItem<IItemType>(items: IItemType[], keyword: string) {
  return items.filter((item: any) => fuzzySearch(keyword, item.value))
}
